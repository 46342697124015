import { useState } from 'react';
import { useNavigate } from '@tanstack/react-router';
import { useAppStore } from '../AppLoader/stores';
import { Errors } from '@/Components/Errors';
import { Button } from '@/Components/v2/Button';
import { SeoAiLogoBlack } from '@/Components/SVGs/SeoAiLogoBlack';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { Input } from '@/Components/v2/Input/Input';
import { Link } from '@tanstack/react-router';
import { useInitApp } from '../AppLoader/hooks/useInitApp';
import { useAuthNewPassword, useAuthToken } from '@/api/openapiComponents';
import { SEO_AI_AUTH } from '@/api/openapiFetcher';

type Props = {
  email: string;
  token: string;
};

export default function ResetPassword({ email, token }: Props) {
  const [formData, setFormData] = useState({
    email,
    password: '',
    password_confirmation: '',
  });

  const appState = useAppStore();

  const initializeApp = useInitApp();

  const { mutate: loginUser, isPending: isLoggingIn } = useAuthToken({
    onSuccess: (data) => {
      localStorage.setItem(SEO_AI_AUTH, data.data.token);
      initializeApp().then(() => {
        navigate({ to: '/' });
      });
    },
  });

  const {
    mutate: resetPassword,
    isPending: isSubmittingResetPassword,
    error: errors,
  } = useAuthNewPassword({
    onSuccess: () => {
      loginUser({
        body: { email: formData.email, password: formData.password },
      });
    },
  });

  const navigate = useNavigate();

  const onHandleChange = (value: string, name: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    resetPassword({
      body: {
        ...formData,
        token,
      },
    });
  };

  appState.pageTitle('Reset password');

  return (
    <div className="flex h-screen flex-col items-center justify-center text-gray-800">
      <div className="absolute left-0 right-0 top-2 flex w-full justify-center">
        <SeoAiLogoBlack />
      </div>
      <div className="w-96">
        <h2 className="mb-10 text-center font-work text-[25px] font-bold leading-normal text-black">
          Reset password
        </h2>

        {!!errors && (
          <div className={'mb-5'}>
            <Errors error={errors} />
          </div>
        )}

        <form onSubmit={submit}>
          <InputDecoration required label="Email">
            <Input
              type="text"
              name="email"
              value={formData.email}
              disabled={true}
              onChange={() => { }}
            />
          </InputDecoration>

          <div className="h-4" />

          <InputDecoration required label="New password">
            <Input
              type="password"
              name="password"
              placeholder="• • • • •"
              value={formData.password}
              autoComplete="password"
              onChange={(value) => onHandleChange(value, 'password')}
            />
          </InputDecoration>

          <div className="h-4" />

          <InputDecoration required label="Confirm password">
            <Input
              type="password"
              name="password_confirmation"
              placeholder="• • • • •"
              value={formData.password_confirmation}
              autoComplete="password"
              onChange={(value) =>
                onHandleChange(value, 'password_confirmation')
              }
            />
          </InputDecoration>

          <div className="mt-8 flex items-center justify-center">
            <Button
              color="secondary"
              fullWidth
              type="submit"
              isLoading={isSubmittingResetPassword || isLoggingIn}
              text="Reset password"
            />
          </div>
          <div className={'mt-5 text-center'}>
            <Link to="/login" className=" underline">
              Back to login
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
