import { Banner } from '../Banner';
import dayjs from 'dayjs';
import { useNavigate } from '@tanstack/react-router';
import { Button } from '@/Components/v2/Button';
import { useAppStore } from '@/Pages/AppLoader/stores';

export const TrialPeriodBanner = () => {
  const navigate = useNavigate();

  const appState = useAppStore();

  return (
    <Banner
      isOpen={
        appState.subscription?.is_trialing &&
        appState.subscription?.is_canceled === false &&
        appState.subscription?.usage.credits.content.is_allowed === true
      }
      className="flex items-center gap-4 bg-amber-100 py-3"
    >
      <p>
        You have{' '}
        <b>
          {dayjs(appState.subscription?.usage.period_end).diff(dayjs(), 'days')}{' '}
          days{' '}
        </b>
        left of your trial subscription.
      </p>

      <Button
        color="white"
        text="UPGRADE NOW"
        size="sm"
        dense
        onClick={() => navigate({ to: '/account/subscription' })}
      />

      <Button
        color="gray"
        variant="outline"
        text="BOOK A DEMO"
        size="sm"
        dense
        onClick={() => window.open('https://seo.ai/demo')}
      />
    </Banner>
  );
};
