import * as React from 'react';
import { twMerge } from 'tailwind-merge';
type Props = {
  value: string | number | readonly string[];
  max?: number;
  count?: number;
  counterAppendix?: string;
  hint?: string;
  container?: string;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>

export function SimpleTextArea({
  max,
  count,
  counterAppendix,
  value,
  rows,
  hint,
  container,
  className,
  ...props
}: Props) {
  const [isFocussed, setIsFocussed] = React.useState(false);
  const ref = React.useRef<HTMLTextAreaElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const lengthOfValue = count ?? value.toString().length;

  const paragraphClasses = ' font-work text-end';

  const valueIsMoreThanLength = max && lengthOfValue && lengthOfValue > max;
  return (
    <>
      <div
        className={twMerge(
          'relative rounded-2xl border-[1.5px] bg-white py-2 pr-2 shadow-inner',
          container,
          isFocussed
            ? 'border-green-400 outline outline-[0.5px] outline-green-400 ring-green-400'
            : '',
        )}
      >
        <textarea
          ref={ref}
          value={value}
          onFocus={(e) => {
            if (inputRef.current) {
              if (valueIsMoreThanLength) {
                inputRef.current.className = paragraphClasses + ' text-red-400';
              } else {
                inputRef.current.className =
                  paragraphClasses + ' text-green-400';
              }
            }
            if (props.onFocus) props.onFocus(e);
            setIsFocussed(true);
          }}
          onBlur={(e) => {
            if (inputRef.current) {
              if (valueIsMoreThanLength) {
                inputRef.current.className = paragraphClasses + ' text-red-400';
              } else {
                inputRef.current.className =
                  paragraphClasses + ' text-gray-400';
              }
            }
            if (props.onBlur) props.onBlur(e);
            setIsFocussed(false);
          }}
          rows={rows || 4}
          className={twMerge(
            `slim-scroll block w-full rounded-2xl border-none px-5 py-3  text-gray-900 placeholder:text-gray-400`,
            className,
          )}
          {...props}
        />
        {max && (
          <p
            ref={inputRef}
            className={`${paragraphClasses} ${
              valueIsMoreThanLength ? 'text-red-400' : 'text-gray-400'
            }`}
          >
            {lengthOfValue}/{max} {counterAppendix}
          </p>
        )}
      </div>
      {hint && (
        <p
          className={`ml-5 mt-1 text-sm text-gray-400 transition-opacity ${
            isFocussed ? 'opacity-75' : 'opacity-0'
          }`}
        >
          {hint}
        </p>
      )}
    </>
  );
}
