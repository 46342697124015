import { AlertTriangle, CheckIcon } from 'lucide-react';
import * as React from 'react';
type Props = {
  title: string;
  optimised?: boolean;
  icon?: React.ReactNode;
}
export function ReportSectionRecommendationsContent({
  title,
  optimised,
  icon,
}: Props) {
  return (
    <div className="flex w-full items-center justify-between text-gray-600">
      <p className="text-start text-sm font-normal">{title}</p>
      {optimised ? (
        <div className="flex items-center gap-1 px-1 py-0.5 font-semibold text-green-500">
          <p className="text-sm normal-case">Optimised</p>
          <CheckIcon size={16} />
        </div>
      ) : icon ? (
        icon
      ) : (
        <AlertTriangle className="mr-1" size={16} />
      )}
    </div>
  );
}
