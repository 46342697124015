import { useEffect, useRef } from 'react';

export const useDropInitialMountEffect = (
  callback: () => void,
  deps: React.DependencyList | undefined,
) => {
  const initialMount = useRef(true);

  useEffect(() => {
    if (initialMount.current) {
      initialMount.current = false;
      return;
    }
    return callback();
  }, deps);
};
