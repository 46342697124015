import { Dialog } from '@/Components/Dialog';
import { Button } from '@/Components/v2/Button';
import { useEffect, useState } from 'react';

type Props = {
  isOpen: boolean;
  error: boolean;
  finished: boolean;
  onRetry: () => void;
}

export const GenerateLoaderOverlay = ({
  isOpen,
  error,
  onRetry,
  finished,
}: Props) => {
  const texts = [
    'Starting up the AI engine...',
    'Generating content outline...',
    'Composing the narrative...',
    'Sketching out SEO optimisations...',
    'Adding human-touch to writing...',
    'Infusing SEO magic into the text...',
  ];

  const [textIndex, setTextIndex] = useState(0);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (isOpen) {
      const textTimer = setInterval(
        () => setTextIndex((prev) => (prev >= texts.length - 1 ? 0 : prev + 1)),
        3500,
      );

      const progressTimer = setInterval(() => {
        setProgress((prev) => {
          if (prev < 85) {
            return prev + 5;
          }
          return prev;
        });
      }, 1000);

      return () => {
        clearInterval(textTimer);
        clearInterval(progressTimer);
      };
    }
  }, [isOpen]);

  const handleRetry = () => {
    setProgress(0);
    onRetry();
  };

  return (
    <Dialog isOpen={isOpen} dense>
      <div className="flex flex-col items-center gap-6 rounded-lg border-gray-100 bg-white p-4">
        {error ? (
          <>
            <p>Something went wrong</p>
            <Button variant="outline" onClick={handleRetry} text="Retry" />
          </>
        ) : (
          <>
            <div className="relative h-4 w-96">
              {texts.map((text, index) => (
                <p
                  className={`unselectable absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center transition-all duration-500 ${
                    index === textIndex ? 'opacity-50' : 'scale-50 opacity-0'
                  }`}
                >
                  {text}
                </p>
              ))}
            </div>
            <div className="relative h-4 w-full rounded-full bg-gray-200">
              <div
                className="absolute bottom-0 left-0 top-0 rounded-full bg-gray-400 transition-all"
                style={{ right: finished ? '0%' : -progress + 100 + '%' }}
              />
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
};
