import * as React from 'react';
type Props = {
  title: string;
  children: React.ReactNode | React.ReactNode[];
}
export function SlideOverContentBox({ title, children }: Props) {
  return (
    <div className="my-6 overflow-hidden">
      <p className="font-medium">{title}</p>
      {children}
    </div>
  );
}
