import { useAppStore } from '@/Pages/AppLoader/stores';
import { Banner } from '../Banner';
import { Button } from '@/Components/v2/Button';
import { UrlCreditsManagementDialog } from '@/Components/UrlCreditsManagementDialog/UrlCreditsManagementDialog';
import { useState } from 'react';

export const OutOfUrlCreditsBanner = () => {
  const appState = useAppStore();
  const [showUrlCreditsDialog, setShowUrlCreditsDialog] = useState(false);

  return (
    <>
      <UrlCreditsManagementDialog
        isOpen={showUrlCreditsDialog}
        onClose={() => setShowUrlCreditsDialog(false)}
      />
      <Banner
        isOpen={
          appState.currentProject!.url_credits.used >=
          appState.currentProject!.url_credits.total
        }
        className="flex gap-2 bg-yellow-50 p-4"
      >
        This website is out of URL credits{' '}
        <Button
          size="sm"
          dense
          text="Manage credits"
          variant="outline"
          onClick={() => setShowUrlCreditsDialog(true)}
        />
      </Banner>
    </>
  );
};
