import { DocumentReportCompetitorResource } from '@/api/openapiSchemas';
import * as Table from '@/Components/Table';
import { ScoreLabel } from '@/Components/Labels/ScoreLabel';

type Props = {
  keyword: string;
  competitors: DocumentReportCompetitorResource[];
  keywordProperty: string;
  keywords: any[];
  hideTarget?: boolean;
};

export const CompetitorTable = ({
  competitors,
  hideTarget,
  keywords,
  keyword,
  keywordProperty,
}: Props) => {
  const keywordCount = competitors.reduce(
    (prev, curr) => Math.max(prev, curr[keywordProperty].length),
    0,
  );

  const renderItems = () =>
    new Array(keywordCount).fill(0).map((_, i) => {
      const keyword = competitors[0][keywordProperty][i].keyword;
      return {
        keyword,
        you: keywords.find(
          (missingKeyword) => missingKeyword.keyword === keyword,
        ),
        competitors: competitors.map((competitor) => ({
          name: new URL(competitor.url).host.replaceAll('www', ''),
          score: competitor[keywordProperty].find(
            (keyword) =>
              keyword.keyword === competitors[0][keywordProperty][i].keyword,
          )?.occurrences,
          density: competitor[keywordProperty].find(
            (keyword) =>
              keyword.keyword === competitors[0][keywordProperty][i].keyword,
          )?.density,
        })),
      };
    });

  const renderHeading = (title: string, maxChars: number, url?: string) => (
    <div className="relative h-32">
      <div
        onClick={url ? () => window.open(url) : undefined}
        className={`absolute bottom-0 left-0 h-2 origin-top rotate-[225deg] whitespace-nowrap pl-8 font-normal text-gray-800 ${
          url ? 'cursor-pointer hover:underline' : ''
        }`}
        style={{ writingMode: 'vertical-rl' }}
      >
        {title.length > maxChars ? `${title.slice(0, maxChars - 3)}...` : title}
      </div>
    </div>
  );

  return (
    <div className="">
      <p className="mb-4 mt-6 text-sm">
        Distribution of the keyword <b>{keyword}</b> across top 10 competitors
      </p>
      <Table.Root
        dense
        items={renderItems()}
        renderRow={({ cells, classNames, index }) => (
          <tr
            className={`${classNames} ${index % 2 === 1 ? 'bg-gray-50' : ''}`}
          >
            {cells}
          </tr>
        )}
        columns={[
          {
            heading: (
              <div className="flex h-32 flex-col justify-end">Keyword</div>
            ),
            render: (item) => <p className="pl-2">{item.keyword}</p>,
          },
          {
            heading: (
              <div className="flex h-32 w-5 flex-col justify-end">You</div>
            ),

            render: (item) => (
              <div className="flex h-12 w-8 items-center">
                <ScoreLabel
                  score={item.you?.actual_count}
                  color={item.you?.is_completed ? 'green' : 'gray'}
                />
              </div>
            ),
          },
          ...(hideTarget
            ? []
            : [
                {
                  heading: (
                    <div className="flex h-32 flex-col justify-end">Target</div>
                  ),
                  render: (item) => (
                    <div className="flex h-12 items-center border-r border-r-gray-200 font-extrabold">
                      {item.you?.target_count}
                      {item.you?.target_density && (
                        <sup className={'ml-0.5 text-[9px] text-gray-500'}>
                          {item.you.target_density}
                          <span className={'text-[7.5px]'}>%</span>
                        </sup>
                      )}
                    </div>
                  ),
                },
              ]),
          ...competitors.map((competitor, index) => ({
            heading: renderHeading(
              new URL(competitor.url).host.replace('www.', ''),
              window.innerWidth / 100 +
                (competitors.length - 1 - index) * (window.innerWidth / 150),
              competitor.url,
            ),
            render: (item) => (
              <div className="px-2">
                {item.competitors[index].score === 0 ? (
                  '-'
                ) : (
                  <>
                    {item.competitors[index].score}
                    {item.competitors[index].density && (
                      <sup
                        className={'-top-1.5 ml-0.5 text-[9px] text-gray-500'}
                      >
                        {item.competitors[index].density}
                        <span className={'text-[7.5px]'}>%</span>
                      </sup>
                    )}
                  </>
                )}
              </div>
            ),
          })),
        ]}
      >
        <Table.Header></Table.Header>
      </Table.Root>
    </div>
  );
};
