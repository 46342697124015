import {
  ProductResource,
  ProductSettingResource,
  SubscriptionResource,
} from '@/api/openapiSchemas';

export const getFeatures = (
  settings: ProductSettingResource[],
  subscription?: SubscriptionResource,
) => {
  return [
    getFeature(settings, 'generated_article_limit', subscription),
    getFeature(settings, 'seo_audit_limit', subscription),
    getFeature(settings, 'generated_words_limit', subscription),
    getFeature(settings, 'tracked_keyword_limit', subscription),
    getFeature(settings, 'organisation_url_limit', subscription),
    getFeature(settings, 'organisation', subscription),
  ];
};

const getFeature = (
  settings: ProductSettingResource[],
  feature: string,
  subscription?: SubscriptionResource,
): { text: string; disabled: boolean; unlimitedOver?: number } => {
  if (feature === 'organisation') {
    return {
      text:
        (subscription?.usage.credits.projects.limit ??
          settings.find(
            (setting) => setting.setting?.name === 'organisation_project_limit',
          )?.default_value) +
        ' websites / ' +
        (subscription?.usage.credits.users.limit ??
          settings.find(
            (setting) => setting.setting?.name === 'organisation_user_limit',
          )?.default_value) +
        ' users',
      disabled: false,
    };
  }
  if (feature === 'generated_words_limit') {
    return {
      text: 'Unlimited AI chat & AI-writing',
      disabled: false,
    };
  }
  for (const setting of settings) {
    if (
      setting.setting?.name === feature &&
      setting.setting?.name === 'seo_audit_limit'
    ) {
      return {
        text:
          (subscription?.usage.credits.content.limit ?? setting.default_value) +
          ' Content audits per month',
        disabled: setting.default_value === 0,
        unlimitedOver: 999,
      };
    } else if (
      setting.setting?.name === feature &&
      setting.setting?.name === 'generated_article_limit'
    ) {
      return {
        text:
          (subscription?.usage.credits.generated_article.limit ??
            setting.default_value) + ' AI articles per month',
        disabled: setting.default_value === 0,
      };
    } else if (
      setting.setting?.name === feature &&
      setting.setting?.name === 'tracked_keyword_limit'
    ) {
      return {
        text:
          (subscription?.usage.credits.tracked_keywords.limit ??
            setting.default_value) + ' Tracked keywords',
        disabled: setting.default_value === 0,
      };
    } else if (
      setting.setting?.name === feature &&
      setting.setting?.name === 'organisation_url_limit'
    ) {
      return {
        text:
          (subscription?.usage.credits.urls.limit ?? setting.default_value) +
          ' internal linking URLs',
        disabled: setting.default_value === 0,
      };
    }
  }
  return { text: '', disabled: false };
};

export const isAddonsEnabled = (product: ProductResource) => {
  return (
    product?.settings?.find(
      (setting) => setting.setting?.name === 'subscription_addons',
    )?.default_value === 1
  );
};

export const addonFilter = (
  plan?: ProductResource,
  addon?: ProductResource,
  interval?: 'month' | 'year',
) => {
  if (!plan || !addon || !interval) {
    return false;
  }
  if (addon.interval !== interval) {
    return false;
  }

  if (
    !plan.settings?.find(
      (setting) =>
        setting?.setting?.name === 'subscription_addons' &&
        setting.default_value === 1,
    )
  ) {
    return false;
  }

  const settingsMap: Record<string, string | undefined> = {
    'Additional websites': 'subscription_addon_projects',
    'Additional users': 'subscription_addon_users',
    'Additional AI articles': 'subscription_addon_generated_articles',
    'Additional content audits': 'subscription_addon_content_audits',
    'Additional tracked keywords': 'subscription_addon_tracked_keywords',
    'Additional organisation urls': 'subscription_addon_organisation_urls',
  };

  const settingName = settingsMap[addon.name ?? ''];

  return (
    plan.settings?.find((setting) => setting.setting?.name === settingName)
      ?.default_value === 1 ?? false
  );
};
