import { PropsWithChildren, useContext } from 'react';
import { CardContext } from '..';

export const CardBody = ({ children }: PropsWithChildren) => {
  const { dense } = useContext(CardContext);

  return (
    <div
      className={`flex h-full flex-col overflow-hidden ${
        dense ? 'p-3' : 'p-6'
      }`}
    >
      {children}
    </div>
  );
};
