import { ChevronDown, ChevronUp, X } from 'lucide-react';
import React, { ReactNode, useEffect, useRef } from 'react';
import { IconButton } from '../IconButton/IconButton';
import * as RadixPopover from '@radix-ui/react-popover';

type Props = {
  zIndex?: number;
  selection: ReactNode;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  dropdown: ReactNode;
  error?: string;
  disabled?: boolean;
  onClear?: () => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  clearable?: boolean;
  fullWidth?: boolean;
  onFocus?: () => void;
};

const DropdownSkeleton = ({
  dropdown,
  isOpen,
  zIndex = 9999,
  onClose,
  onOpen,
  selection,
  error,
  onKeyDown,
  onClear,
  clearable,
  fullWidth,
  disabled,
  onFocus,
}: Props) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const menuContainerRef = useRef<HTMLDivElement | null>(null);

  const getContainerColors = () => {
    if (disabled) return 'bg-gray-100 border-primary-300';

    if (error) {
      return `${isOpen ? 'border-red-500' : 'border-red-300 hover:bg-red-50'}`;
    }

    return `${
      isOpen
        ? 'border-secondary-400 bg-primary-50'
        : ' bg-white border-primary-300 hover:bg-primary-50 focus:border-secondary-300'
    }`;
  };

  useEffect(() => {
    if (isOpen) {
      const listener = (e: MouseEvent) => {
        if (
          !containerRef.current?.contains(e.target as HTMLDivElement) &&
          !menuContainerRef.current?.contains(e.target as HTMLDivElement)
        ) {
          onClose();
        }
      };
      window.addEventListener('click', listener);
      return () => {
        window.removeEventListener('click', listener);
      };
    }
  }, [containerRef, isOpen]);

  return (
    <RadixPopover.Root open={isOpen}>
      <RadixPopover.Trigger asChild className={fullWidth ? 'w-full' : ''}>
        <div>
          <div
            tabIndex={0}
            onKeyDown={onKeyDown}
            onFocus={onFocus}
            ref={containerRef}
            className={`relative flex cursor-pointer justify-center rounded-lg border py-1 pr-2 text-base outline-none ring-0 ${getContainerColors()} ${
              fullWidth ? 'w-full' : ''
            }`}
            onClick={
              disabled ? undefined : () => (isOpen ? onClose() : onOpen())
            }
          >
            <div className="flex w-full items-center gap-1 overflow-hidden truncate rounded">
              <div className="flex-grow overflow-hidden">{selection}</div>
              {clearable && (
                <div className={onClear ? '' : 'pointer-events-none opacity-0'}>
                  <IconButton
                    icon={X}
                    size={'sm'}
                    onClick={(e) => {
                      e.stopPropagation();
                      onClear && onClear();
                    }}
                    dense
                  />
                </div>
              )}
              {isOpen ? (
                <ChevronUp
                  className="pointer-events-none h-5 w-5 flex-shrink-0 text-gray-400"
                  size={20}
                />
              ) : (
                <ChevronDown
                  className="pointer-events-none h-5 w-5 flex-shrink-0 text-gray-400"
                  size={20}
                />
              )}
            </div>
          </div>
          {error && (
            <div className="w-full">
              <div
                className={`flex w-full items-start pl-3 pr-2 pt-1 text-sm text-red-500`}
              >
                {error}
              </div>
            </div>
          )}
        </div>
      </RadixPopover.Trigger>
      <RadixPopover.Portal>
        <RadixPopover.Content
          side="bottom"
          avoidCollisions={false}
          className="h-full overflow-hidden rounded-lg border border-primary-200 shadow-lg outline-none outline-0 ring-0"
          style={{
            width: containerRef.current?.clientWidth,
            zIndex: zIndex,
            maxHeight:
              'calc(var(--radix-popover-content-available-height) - 0.5rem)',
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              containerRef.current?.focus();
            }
            onKeyDown?.(e);
          }}
          tabIndex={-1}
          sideOffset={4}
          align="center"
        >
          <div
            ref={menuContainerRef}
            style={{
              maxHeight:
                'calc(var(--radix-popover-content-available-height) - 0.5rem)',
            }}
            className="overflow-y-auto"
          >
            {dropdown}
          </div>
        </RadixPopover.Content>
      </RadixPopover.Portal>
    </RadixPopover.Root>
  );
};

DropdownSkeleton.displayName = 'DropdownSkeleton';
export { DropdownSkeleton };
