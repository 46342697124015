import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useLayoutEffect, useRef, useState } from 'react';
import { IconButton } from '../IconButton/IconButton';

type Props = {
  color?:
    | 'green'
    | 'red'
    | 'blue'
    | 'gray'
    | 'yellow'
    | 'primary'
    | 'secondary'
    | 'white';
  children: React.ReactNode | React.ReactNode[];
  alignment: 'center' | 'left' | 'right' | 'grow';
  noBorder?: boolean;
};

export const TabBarSkeleton = ({
  alignment: type,
  children,
  color = 'primary',
  noBorder,
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [canScroll, setCanScroll] = useState(false);

  useLayoutEffect(() => {
    if (!containerRef.current) return;
    if (containerRef.current.clientWidth < containerRef.current.scrollWidth) {
      setCanScroll(true);
    } else {
      setCanScroll(false);
    }
  }, [containerRef, children]);

  const getALignment = () => {
    if (canScroll) return '';
    switch (type) {
      case 'center':
        return 'justify-center';
      case 'left':
        return 'justify-start';
      case 'right':
        return 'justify-end';
      default:
        return '';
    }
  };

  const getBorderColor = () => {
    switch (color) {
      case 'green':
        return 'border-green';
      case 'red':
        return 'border-red';
      case 'blue':
        return 'border-blue';
      case 'yellow':
        return 'border-yellow';
      case 'primary':
        return 'border-primary';
      case 'secondary':
        return 'border-secondary';
      case 'white':
        return 'border-white';
      default:
        return '';
    }
  };

  const getBorder = noBorder ? '' : 'border-b';

  return (
    <div
      className={`relative flex w-full overflow-hidden ${getBorder} ${getBorderColor()}`}
    >
      {canScroll && (
        <div className="flex flex-shrink-0 items-center justify-center">
          <IconButton
            icon={ChevronLeft}
            color={color}
            onClick={() => {
              if (!containerRef.current) return;
              containerRef.current.scrollLeft -= 100;
            }}
          />
        </div>
      )}
      <div
        ref={containerRef}
        className={`flex w-full overflow-hidden ${getALignment()} `}
      >
        {children}
      </div>
      {canScroll && (
        <div className="flex flex-shrink-0 items-center justify-center">
          <IconButton
            icon={ChevronRight}
            color={color}
            onClick={() => {
              if (!containerRef.current) return;
              containerRef.current.scrollLeft += 100;
            }}
          />
        </div>
      )}
    </div>
  );
};
