import { useDropInitialMountEffect } from './useDropInitialMountEffect';

export const useDebounce = (
  callback: React.EffectCallback,
  deps?: React.DependencyList | undefined,
  timeout?: number,
) => {
  useDropInitialMountEffect(() => {
    const id = setTimeout(() => {
      callback();
    }, timeout ?? 1000);
    return () => {
      clearTimeout(id);
    };
  }, deps);
};
