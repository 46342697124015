import { InternalLinkingStore, KeywordInjectionStore } from '@/types';
import { create } from 'zustand';

import { sentenceExistsInEditor, sortSuggestions } from './utils';
import {
  getKeywordIndexes,
  trimPunctuation,
} from './components/DocumentSlideOvers/utils';
import { ProjectUrlResource } from '@/api/openapiSchemas';

const documentStoreInitialState = {
  isLoadingHeadingDescription: true,
  heading: '',
  metaDescription: '',
  metaTitle: '',
  showOutlineEditor: false,
  projectUrl: undefined,
  customFieldValues: {},
};

export const useDocumentStore = create<{
  customFieldValues: Record<string, string>;
  setCustomFieldValues: (customFieldValues: Record<string, string>) => void;
  isLoadingHeadingDescription: boolean;
  heading: string;
  showOutlineEditor: boolean;
  metaDescription: string;
  metaTitle: string;
  projectUrl?: ProjectUrlResource;
  setHeading: (heading: string) => void;
  setShowOutlineEditor: (showOutlineEditor: boolean) => void;
  setMetaDescription: (metaDescription: string) => void;
  setProjectUrl: (projectUrl: ProjectUrlResource | undefined) => void;
  setMetaTitle: (metaTitle: string) => void;
  setInitialProps: (
    heading: string,
    metaDescription: string,
    metaTitle: string,
    projectUrl: ProjectUrlResource | undefined,
  ) => void;
  reset: () => void;
}>((set) => ({
  ...documentStoreInitialState,
  setCustomFieldValues: (customFieldValues) =>
    set((state) => ({ ...state, customFieldValues })),
  setProjectUrl: (projectUrl) => set((state) => ({ ...state, projectUrl })),
  setHeading: (heading) => set((state) => ({ ...state, heading })),
  setMetaDescription: (metaDescription) =>
    set((state) => ({ ...state, metaDescription })),
  setMetaTitle: (metaTitle) => set((state) => ({ ...state, metaTitle })),
  reset: () => set((state) => ({ ...state, ...documentStoreInitialState })),
  setInitialProps: (heading, metaDescription, metaTitle, projectUrl) =>
    set((state) => ({
      ...state,
      heading,
      metaDescription,
      metaTitle,
      isLoadingHeadingDescription: false,
      projectUrl,
    })),
  setShowOutlineEditor: (value) =>
    set((state) => ({ ...state, showOutlineEditor: value })),
}));

const initialInternalLinkingStore = {
  hasNoMatches: false,
  isLoading: false,
  data: [],
  isCached: false,
};

export const useInternalLinkingStore = create<InternalLinkingStore>((set) => ({
  ...initialInternalLinkingStore,
  hardReset: () => set((prev) => ({ ...prev, ...initialInternalLinkingStore })),
  softReset: () =>
    set((prev) => ({
      ...prev,
      isCached: !prev.hasNoMatches,
      data: prev.data.map((v) => ({ ...v, isHovered: false })),
    })),
  isLoadingInternalLinks: () =>
    set((prev) => ({
      ...prev,
      ...initialInternalLinkingStore,
      isLoading: true,
      isCached: true,
    })),
  populateInternalLinks: (internalLinkSuggestions) =>
    set((prev) => ({
      ...prev,
      ...initialInternalLinkingStore,
      hasNoMatches: internalLinkSuggestions.length === 0,
      isLoading: false,
      data: internalLinkSuggestions.map((linkResource) => ({
        linkResource,
        accepted: false,
        isHoveredFromTab: false,
        verified: false,
        isVerifying: true,
        isHoveredFromEditor: false,
        anchorTextEndIndex: null,
        anchorTextStartIndex: null,
        rejected: false,
        ignored: false,
      })),
    })),
  handleHoverInternalLinkFromEditor: (resource, isHoveredFromEditor) =>
    set((prev) => ({
      ...prev,
      data: prev.data.map((ls) => {
        if (
          ls.linkResource.sentence === resource.sentence &&
          ls.linkResource.link.id === resource.link.id
        ) {
          return { ...ls, isHoveredFromEditor };
        }
        return ls;
      }),
    })),
  handleHoverInternalLinkFromTab: (resource, isHoveredFromTab) =>
    set((prev) => ({
      ...prev,
      data: prev.data.map((ls) => {
        if (
          ls.linkResource.keyword === resource.keyword &&
          ls.linkResource.sentence === resource.sentence &&
          ls.linkResource.link.id === resource.link.id
        ) {
          return { ...ls, isHoveredFromTab };
        }
        return ls;
      }),
    })),
  handleAcceptInternalLink: (resource) =>
    set((prev) => ({
      ...prev,
      data: prev.data.map((ls) => {
        if (
          ls.linkResource.keyword === resource.keyword &&
          ls.linkResource.sentence === resource.sentence &&
          ls.linkResource.link.id === resource.link.id
        ) {
          return {
            ...ls,
            accepted: true,
          };
        }
        return ls;
      }),
    })),
  handleVerifyKeyword: (keyword, verified, content) =>
    set((prev) => {
      const newLinkResources = prev.data
        .map((ls) => {
          if (ls.linkResource.keyword === keyword) {
            const [anchorTextStartIndex, anchorTextEndIndex] =
              getKeywordIndexes(ls.linkResource.sentence, keyword);
            return {
              ...ls,
              linkResource: {
                ...ls.linkResource,
                sentence: ls.linkResource.sentence.replaceAll('*', ''),
              },
              verified,
              isVerifying: false,
              anchorTextStartIndex,
              anchorTextEndIndex,
            };
          }
          return ls;
        })
        .toSorted((a, b) =>
          sortSuggestions(
            content,
            a.linkResource.sentence,
            b.linkResource.sentence,
            a.linkResource.keyword,
            b.linkResource.keyword,
          ),
        );

      if (
        newLinkResources.every((lr) => !lr.isVerifying) &&
        newLinkResources.filter((vlr) => vlr.verified).length === 0
      ) {
        return {
          ...prev,
          hasNoMatches: true,
          data: newLinkResources,
        };
      }
      return {
        ...prev,
        data: newLinkResources,
      };
    }),
  handleRemoveInternalLink: (resource) =>
    set((prev) => ({
      ...prev,
      data: prev.data.filter((ls) => {
        if (
          ls.linkResource.sentence === resource.sentence &&
          ls.linkResource.link.id === resource.link.id
        ) {
          return false;
        }
        return ls;
      }),
    })),
  handleRejectInternalLink: (resource) =>
    set((prev) => ({
      ...prev,
      data: prev.data.map((ls) => {
        if (
          ls.linkResource.keyword === resource.keyword &&
          ls.linkResource.sentence === resource.sentence &&
          ls.linkResource.link.id === resource.link.id
        ) {
          return {
            ...ls,
            rejected: true,
          };
        }
        return ls;
      }),
    })),
  handleIgnoreInternalLink: (resource) =>
    set((prev) => ({
      ...prev,
      data: prev.data.map((ls) => {
        if (
          ls.linkResource.keyword === resource.keyword &&
          ls.linkResource.sentence === resource.sentence &&
          ls.linkResource.link.id === resource.link.id
        ) {
          return {
            ...ls,
            ignored: true,
          };
        }
        return ls;
      }),
    })),
  handleChangeSentence: (
    linkingResource,
    firstPart,
    secondPart,
    operationPart = '',
  ) => {
    const newSentence =
      firstPart.trimStart() + operationPart + secondPart.trimEnd();
    return set((prev) => ({
      ...prev,
      data: prev.data.map((ls) => {
        if (
          linkingResource.linkResource.sentence.includes(
            ls.linkResource.sentence,
          ) &&
          ls.linkResource.link.id === linkingResource.linkResource.link.id
        ) {
          const keyword =
            ls.anchorTextStartIndex !== null && ls.anchorTextEndIndex !== null
              ? ls.linkResource.sentence
                  .split(' ')
                  .slice(ls.anchorTextStartIndex, ls.anchorTextEndIndex + 1)
                  .join(' ')
              : null;

          if (keyword) {
            const startOfAnchor = newSentence.indexOf(
              keyword.replace(trimPunctuation, ''),
            );
            const anchorTextStartIndex =
              newSentence.slice(0, startOfAnchor).split(' ').length - 1;

            const anchorTextEndIndex =
              anchorTextStartIndex + keyword.split(' ').length - 1;

            return {
              ...ls,
              anchorTextStartIndex:
                startOfAnchor === -1 ? null : anchorTextStartIndex,
              anchorTextEndIndex:
                startOfAnchor === -1 ? null : anchorTextEndIndex,
              linkResource: {
                ...ls.linkResource,
                sentence: newSentence,
              },
            };
          } else {
            return {
              ...ls,
              anchorTextStartIndex: null,
              anchorTextEndIndex: null,
              linkResource: {
                ...ls.linkResource,
                sentence: newSentence,
              },
            };
          }
        }
        return ls;
      }),
    }));
  },
  handleChangeAnchorText: (resource, index) =>
    set((prev) => ({
      ...prev,
      data: prev.data.map((ls) => {
        if (
          ls.linkResource.sentence === resource.linkResource.sentence &&
          resource.linkResource.keyword === ls.linkResource.keyword &&
          ls.linkResource.link.id === resource.linkResource.link.id
        ) {
          let anchorTextStartIndex = index;
          let anchorTextEndIndex = index;

          if (ls.anchorTextStartIndex && ls.anchorTextEndIndex) {
            const inbetweenAnchor =
              index >= ls.anchorTextStartIndex &&
              index <= ls.anchorTextEndIndex;
            if (!inbetweenAnchor) {
              if (index < ls.anchorTextStartIndex) {
                anchorTextEndIndex = ls.anchorTextEndIndex;
              } else {
                anchorTextStartIndex = ls.anchorTextStartIndex;
              }
            }
          }
          return {
            ...ls,
            anchorTextStartIndex,
            anchorTextEndIndex,
          };
        }
        return ls;
      }),
    })),
}));

const initialKeywordInjectionStore = {
  keywordType: undefined,
  keyword: '',
  mode: undefined,
  keywordSuggestions: [],
  newKeywordSuggestions: [],
  isLoadingUpdatedKeywordSuggestions: false,
  isLoadingAddedKeywordSuggestions: false,
  noKeywordSuggestionsFound: false,
  noNewKeywordSuggestionsFound: false,
  noMoreKeywordSuggestions: false,
  noMoreNewKeywordSuggestions: false,
};

export const useKeywordInjectionStore = create<KeywordInjectionStore>(
  (set) => ({
    ...initialKeywordInjectionStore,
    hardResetSlideOver: () => {
      set((state) => ({
        ...state,
        ...initialKeywordInjectionStore,
        mode: 'UPDATE EXISTING',
      }));
    },
    resetSlideOver: (keyword, keywordType) =>
      set((state) => ({
        ...state,
        ...initialKeywordInjectionStore,
        keyword,
        keywordType: keywordType || state.keywordType,
        mode: 'UPDATE EXISTING',
      })),
    changeKeywordType: (keywordType) =>
      set((state) => ({
        ...state,
        ...initialKeywordInjectionStore,
        mode: 'UPDATE EXISTING',
        keywordType,
      })),
    handleChangeUpdatedLoading: (isLoading) => {
      return set((state) => ({
        ...state,
        noMoreKeywordSuggestions: false,
        noKeywordSuggestionsFound: state.keywordSuggestions.length === 0,
        isLoadingUpdatedKeywordSuggestions: isLoading,
        keywordSuggestions: isLoading ? [] : state.keywordSuggestions,
      }));
    },

    handleChangeAddedLoading: (isLoading) => {
      return set((state) => ({
        ...state,
        noMoreNewKeywordSuggestions: false,
        noNewKeywordSuggestionsFound: state.newKeywordSuggestions.length === 0,
        isLoadingAddedKeywordSuggestions: isLoading,
        newKeywordSuggestions: isLoading ? [] : state.newKeywordSuggestions,
      }));
    },

    handleInitialLoadSuggestions: () =>
      set((state) => ({
        ...state,
        isLoadingUpdatedKeywordSuggestions: true,
        isLoadingAddedKeywordSuggestions: true,
      })),
    handleChangeTab: (mode) => set((state) => ({ ...state, mode: mode })),
    addKeywordSuggestion: (keywordSuggestion, editor, content) => {
      return set((state) => {
        const newKeywordSuggestions = sentenceExistsInEditor(
          editor,
          keywordSuggestion.text,
        )
          ? [
              ...state.keywordSuggestions,
              {
                ...keywordSuggestion,
                hoveredFromEditor: false,
                hoveredFromTab: false,
                accepted: false,
              },
            ].toSorted((a, b) => sortSuggestions(content, a.text, b.text))
          : state.keywordSuggestions;
        return {
          ...state,
          keywordSuggestions: newKeywordSuggestions,
        };
      });
    },
    addNewKeywordSuggestion: (keywordSuggestion, editor, content) => {
      return set((state) => {
        const newKeywordSuggestions = sentenceExistsInEditor(
          editor,
          keywordSuggestion.text,
        )
          ? [
              ...state.newKeywordSuggestions,
              {
                ...keywordSuggestion,
                hoveredFromEditor: false,
                hoveredFromTab: false,
                accepted: false,
              },
            ].toSorted((a, b) => sortSuggestions(content, a.text, b.text))
          : state.newKeywordSuggestions;
        return {
          ...state,
          newKeywordSuggestions,
        };
      });
    },
    removeKeywordSuggestion: (keywordSuggestion) =>
      set((state) => {
        const filteredKeywordSuggestions = state.keywordSuggestions.filter(
          (ks) => {
            if (
              ks.markdownText === keywordSuggestion.markdownText &&
              ks.text === keywordSuggestion.text &&
              ks.newMarkdownText === keywordSuggestion.newMarkdownText
            ) {
              return false;
            }
            return true;
          },
        );

        return {
          ...state,
          keywordSuggestions: filteredKeywordSuggestions,
          noMoreKeywordSuggestions: filteredKeywordSuggestions.length === 0,
        };
      }),

    removeNewKeywordSuggestion: (keywordSuggestion) =>
      set((state) => {
        const filteredKeywordSuggestions = state.newKeywordSuggestions.filter(
          (ks) => {
            if (
              ks.text === keywordSuggestion.text &&
              ks.newMarkdownText === keywordSuggestion.newMarkdownText
            ) {
              return false;
            }
            return true;
          },
        );
        return {
          ...state,
          newKeywordSuggestions: filteredKeywordSuggestions,
          noMoreNewKeywordSuggestions: filteredKeywordSuggestions.length === 0,
        };
      }),
    handleHoverFromEditor: (keywordSuggestion, isHovered) =>
      set((state) => ({
        ...state,
        keywordSuggestions: state.keywordSuggestions.map((ks) => {
          if (
            ks.markdownText === keywordSuggestion.markdownText &&
            ks.text === keywordSuggestion.text &&
            ks.newMarkdownText === keywordSuggestion.newMarkdownText
          ) {
            return { ...ks, hoveredFromEditor: isHovered };
          }
          return ks;
        }),
      })),
    handleHoverFromTab: (keywordSuggestion, isHovered) =>
      set((state) => ({
        ...state,
        keywordSuggestions: state.keywordSuggestions.map((ks) => {
          if (
            ks.markdownText === keywordSuggestion.markdownText &&
            ks.text === keywordSuggestion.text &&
            ks.newMarkdownText === keywordSuggestion.newMarkdownText
          ) {
            return { ...ks, hoveredFromTab: isHovered };
          }
          return ks;
        }),
      })),
    handleHoverNewKeywordPositionFromTab: (keywordSuggestion, isHovered) =>
      set((state) => ({
        ...state,
        newKeywordSuggestions: state.newKeywordSuggestions.map((ks) => {
          if (
            ks.text === keywordSuggestion.text &&
            ks.newMarkdownText === keywordSuggestion.newMarkdownText
          ) {
            return { ...ks, hoveredFromTab: isHovered };
          }
          return ks;
        }),
      })),
    handleHoverNewKeywordPositionFromEditor: (keywordSuggestion, isHovered) =>
      set((state) => ({
        ...state,
        newKeywordSuggestions: state.newKeywordSuggestions.map((ks) => {
          if (
            ks.text === keywordSuggestion.text &&
            ks.newMarkdownText === keywordSuggestion.newMarkdownText
          ) {
            return { ...ks, hoveredFromEditor: isHovered };
          }
          return ks;
        }),
      })),
    handleAcceptSuggestion: (keywordSuggestion) =>
      set((state) => ({
        ...state,
        keywordSuggestions: state.keywordSuggestions.map((ks) => {
          if (
            ks.markdownText === keywordSuggestion.markdownText &&
            ks.text === keywordSuggestion.text &&
            ks.newMarkdownText === keywordSuggestion.newMarkdownText
          ) {
            return { ...ks, accepted: true };
          }
          return ks;
        }),
      })),
    handleAcceptNewSuggestion: (keywordSuggestion) =>
      set((state) => ({
        ...state,
        newKeywordSuggestions: state.newKeywordSuggestions.map((ks) => {
          if (
            ks.text === keywordSuggestion.text &&
            ks.newMarkdownText === keywordSuggestion.newMarkdownText
          ) {
            return { ...ks, accepted: true };
          }
          return ks;
        }),
      })),
    handleRejectSuggestion: (keywordSuggestion) =>
      set((state) => ({
        ...state,
        keywordSuggestions: state.keywordSuggestions.map((ks) => {
          if (
            ks.markdownText === keywordSuggestion.markdownText &&
            ks.text === keywordSuggestion.text &&
            ks.newMarkdownText === keywordSuggestion.newMarkdownText
          ) {
            return { ...ks, rejected: true };
          }
          return ks;
        }),
      })),
    handleRejectNewSuggestion: (keywordSuggestion) =>
      set((state) => ({
        ...state,
        newKeywordSuggestions: state.newKeywordSuggestions.map((ks) => {
          if (
            ks.text === keywordSuggestion.text &&
            ks.newMarkdownText === keywordSuggestion.newMarkdownText
          ) {
            return { ...ks, rejected: true };
          }
          return ks;
        }),
      })),
  }),
);
