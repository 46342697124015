export const events = {
  // General
  page: 'Change page',

  // Subscription
  subscription_started: 'Subscription - Started',

  // Onboarding
  onboarding_create_project: 'Onboarding - Create project',
  onboarding_cancel: 'Onboarding - Cancel',
  onboarding_suggest_more_titles: 'Onboarding - Suggest more titles',
  onboarding_back_to_keywords: 'Onboarding - Back to keywords',

  // Document Chat
  document_chat_inserted_content: 'Document Chat - Inserted content',
  document_chat_replaced_content: 'Document Chat - Replaced content',
  document_chat_dragged_content: 'Document Chat - Dragged content',
  document_chat_toggle_maximize: 'Document Chat - Toggle maximise',
  document_chat_early_cancel: 'Document Chat - Cancelled response early',
  document_chat_quick_prompt: 'Document Chat - Quick prompt',

  // Link suggestions
  link_suggestions_accept_all: 'Link suggestions - Accept all',
  link_suggestions_retry: 'Link suggestions - Retry',
  link_suggestions_accepted: 'Link suggestions - Accepted',
  link_suggestions_rejected: 'Link suggestions - Rejected',
  link_suggestions_opened: 'Link suggestions - Opened',

  // Keyword suggestions
  keyword_injection_opened: 'Keyword injection - Opened',
  keyword_injection_accepted: 'Keyword injection - Accepted',
  keyword_injection_rejected: 'Keyword injection - Rejected',

  // Slideovers
  title_suggestions_opened: 'Title suggestions - Opened',
  content_length_opened: 'Content length - Opened',
  metadescription_suggestions_opened: 'Meta description suggestions - Opened',
  questions_opened: 'Report questions - Opened',
  serp_visualizer_opened: 'SERP visualizer - Opened',
  competitor_keyword_overview_opened: 'Competitor keyword overview - Opened',
  competitor_overview_opened: 'Competitor overview - Opened',
  keyword_gaps_opened: 'Keyword gaps - Opened',
  copy_editor_content: 'Editor content copy',

  // Subheadings
  subheading_suggestions_opened: 'Subheading suggestions - Opened',
  subheading_suggestions_inserted: 'Subheading suggestions - Inserted',
  subheading_suggestions_generated_outline:
    'Subheading suggestions - Generated outline',

  // Global websocket events
  update_notification_displayed: 'Update notification - Displayed',
  update_notification_updated_browser: 'Update notification - Updated browser',
};
