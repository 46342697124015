/* eslint-disable prefer-spread */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Destination } from '../Destinations';

export const segment: Destination = {
  name: 'segment',
  enabledByEnvKey: 'VITE_SEGMENT_ENABLED',
  initialize: () => {
    const disableTracking = ['/signup/external'].some((path) =>
      window.location.pathname.startsWith(path),
    );

    if (!disableTracking) {
      const analytics = (window.analytics = window.analytics || []);
      if (!analytics.initialize)
        if (analytics.invoked)
          window.console &&
            console.error &&
            console.error('Segment snippet included twice.');
        else {
          analytics.invoked = !0;
          analytics.methods = [
            'trackSubmit',
            'trackClick',
            'trackLink',
            'trackForm',
            'pageview',
            'identify',
            'reset',
            'group',
            'track',
            'ready',
            'alias',
            'debug',
            'page',
            'once',
            'off',
            'on',
            'addSourceMiddleware',
            'addIntegrationMiddleware',
            'setAnonymousId',
            'addDestinationMiddleware',
          ];
          analytics.factory = function (e) {
            return function () {
              if (window.analytics.initialized)
                return window.analytics[e].apply(window.analytics, arguments);
              const i = Array.prototype.slice.call(arguments);
              i.unshift(e);
              analytics.push(i);
              return analytics;
            };
          };
          for (let i = 0; i < analytics.methods.length; i++) {
            const key = analytics.methods[i];
            analytics[key] = analytics.factory(key);
          }
          analytics.load = function (key, i) {
            const t = document.createElement('script');
            t.type = 'text/javascript';
            t.async = !0;
            t.src =
              'https://cdn.segment.com/analytics.js/v1/' +
              key +
              '/analytics.min.js';
            const n = document.getElementsByTagName('script')[0];
            n.parentNode?.insertBefore(t, n);
            analytics._loadOptions = i;
          };
          analytics._writeKey = 'BY9DO6FdW4t2D2PcFmPY0dSMWOYSH4z8';
          analytics.SNIPPET_VERSION = '4.16.1';
          if (import.meta.env.VITE_SEGMENT_ENABLED) {
            analytics.load(import.meta.env.VITE_SEGMENT_FRONTEND_API_KEY);
          }
        }
    }
  },
  identify: (user) => {
    analytics.identify(user.id);
  },
  reset: () => {
    analytics.reset();
  },
  trackEvent: (event) => {
    if (event.name === 'page') {
      analytics.page(event.payload);
      return;
    }
    analytics.track(event.displayName, event.payload);
  },
  ignore: ['subscription_started', 'onboarding_create_project'],
};
