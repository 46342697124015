import { Menus } from '@/Components/Menus';
import { useAppStore } from './AppLoader/stores';

export default function Project() {
  const appState = useAppStore();

  appState.pageTitle(appState.currentProject!.name);

  return (
    <>
      <Menus>
        <div />
      </Menus>
    </>
  );
}
