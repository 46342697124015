import { ReactNode, createContext } from 'react';
import { ChevronDown } from 'lucide-react';
import { FilterItem } from './FilterItem';
import { SimplePopover } from '@/Components/SimplePopover';

export const FilterPopoverContext = createContext(() => {});

type Props = {
  name: string;
  filterName?: string | number;
  children: ReactNode;
  onRemoveFilter: () => void;
  disabled?: boolean;
};

export const FilterPopover = ({
  children,
  onRemoveFilter,
  name,
  filterName,
  disabled,
}: Props) => {
  const filter = '' + (filterName ? filterName : '');

  return (
    <SimplePopover
      disabled={disabled}
      align="start"
      trigger={
        <>
          <FilterItem
            disabled={disabled}
            active={filter && filter.length > 0}
            onRemove={onRemoveFilter}
          >
            {filter && filter.length > 0 ? (
              filter
            ) : (
              <>
                {name} <ChevronDown size={16} />
              </>
            )}
          </FilterItem>
        </>
      }
    >
      {children}
    </SimplePopover>
  );
};
