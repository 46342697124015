import { Menus } from '@/Components/Menus';
import PageContainer from '@/Components/PageContainer';
import SimpleTitle from '@/Components/SimpleTitle';
import * as Table from '@/Components/Table';
import {
  RangeSelector,
  formatRange,
} from '@/Components/Table/components/RangeSelector';
import { SelectionActions } from '@/Components/Table/components/SelectionActions';
import { useCopyTable, useTableSelection } from '@/Components/Table/hooks';
import { Button } from '@/Components/v2/Button';
import { PopoverMenu } from '@/Components/v2/Menu/ButtonMenu';
import {
  ChevronDown,
  Copy,
  File,
  FileText,
  Globe,
  LineChart,
  Plus,
  RefreshCcw,
  Scan,
  Settings,
  Stars,
} from 'lucide-react';
import { useAppStore } from '../AppLoader/stores';
import {
  fetchListUrlKeywords,
  useExportProjectUrls,
  useProjectImportUrls,
  useProjectsDomainUrlsUpdate,
  useProjectsUrlsIndex,
} from '@/api/openapiComponents';
import Pagination from '@/Components/ProjectKeywords/Pagination';
import { useEffect, useState } from 'react';
import { useConnectToGoogleSearchConsole } from '../Settings/hooks';
import { URLDialog } from './components/URLDialog';
import { useNavigate, useSearch } from '@tanstack/react-router';
import { downloadCSV, formatThousandSeperator } from '@/utils';
import { GoogleConsoleConnect } from '@/Components/Table/components/GoogleConsoleConnect';
import { useGoogleLogin } from '@react-oauth/google';
import { useShallow } from 'zustand/react/shallow';
import { OutOfUrlCreditsBanner } from '@/Components/Banner/specific/OutOfUrlCreditsBanner';
import { EditableText } from '@/Components/EditableText';
import {
  ProjectDomainUrlResource,
  ScannedContentResource,
  ScannedContentSubResource,
} from '@/api/openapiSchemas';
import { useQueryClient } from '@tanstack/react-query';
import { useAllPageActions } from './hooks';
import { ConfirmDialog } from '@/Components/ConfirmDialog';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { BulkGenerateDialog } from './components/BulkGenerateDialog';
import { WarningAlert } from '@/Components/v2/Alert';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { hasher, useFeatureFlagging } from '../AppLoader';
import { Icon } from '@/Components/v2/Icon';
import { faShopify } from '@fortawesome/free-brands-svg-icons';

const dummyData = [
  {
    id: -1,
    url: '/seo/keyword-research/basics',
    title: 'Mastering Keyword Research: Basics',
    clicks: 150,
    topKeyword: 'Keyword Research',
    contentLength: 1400,
  },
  {
    id: -2,
    url: '/seo/on-page-optimization/guide',
    title: 'On-Page Optimization: The Ultimate Guide',
    clicks: 200,
    topKeyword: 'On-Page SEO',
    contentLength: 1900,
  },
  {
    id: -3,
    url: '/seo/link-building/strategies',
    title: 'Effective Link Building Strategies for 2024',
    clicks: 180,
    topKeyword: 'Link Building',
    contentLength: 2200,
  },
  {
    id: -4,
    url: '/seo/local-seo/ranking-factors',
    title: 'Top Local SEO Ranking Factors',
    clicks: 95,
    topKeyword: 'Local SEO',
    contentLength: 1700,
  },
  {
    id: -5,
    url: '/seo/meta-tags/importance',
    title: 'The Importance of Meta Tags in SEO',
    clicks: 110,
    topKeyword: 'Meta Tags',
    contentLength: 1200,
  },
  {
    id: -6,
    url: '/seo/technical-seo/checklist',
    title: 'Technical SEO Checklist for Beginners',
    clicks: 135,
    topKeyword: 'Technical SEO',
    contentLength: 1850,
  },
  {
    id: -7,
    url: '/seo/website-speed/optimization',
    title: 'How Website Speed Impacts SEO',
    clicks: 165,
    topKeyword: 'Website Speed',
    contentLength: 1500,
  },
  {
    id: -8,
    url: '/seo/google-algorithm/updates',
    title: 'Navigating Google Algorithm Updates',
    clicks: 210,
    topKeyword: 'Google Algorithm',
    contentLength: 2300,
  },
  {
    id: -9,
    url: '/seo/mobile-seo/best-practices',
    title: 'Best Practices for Mobile SEO',
    clicks: 175,
    topKeyword: 'Mobile SEO',
    contentLength: 1600,
  },
  {
    id: -10,
    url: '/seo/content-marketing/seo-strategy',
    title: 'How Content Marketing Supports SEO Strategy',
    clicks: 145,
    topKeyword: 'Content Marketing',
    contentLength: 2000,
  },
  {
    id: -11,
    url: '/seo/image-optimization/tips',
    title: 'SEO Image Optimization Tips',
    clicks: 130,
    topKeyword: 'Image Optimization',
    contentLength: 1350,
  },
  {
    id: -12,
    url: '/seo/voice-search/optimization',
    title: 'Optimizing for Voice Search in 2024',
    clicks: 185,
    topKeyword: 'Voice Search',
    contentLength: 1800,
  },
  {
    id: -13,
    url: '/seo/rich-snippets/guide',
    title: 'A Guide to Rich Snippets for SEO',
    clicks: 115,
    topKeyword: 'Rich Snippets',
    contentLength: 1250,
  },
  {
    id: -14,
    url: '/seo/user-experience/impact',
    title: 'How User Experience (UX) Impacts SEO',
    clicks: 145,
    topKeyword: 'User Experience',
    contentLength: 2100,
  },
  {
    id: -15,
    url: '/seo/e-commerce/seo-tips',
    title: 'E-commerce SEO Tips to Increase Sales',
    clicks: 195,
    topKeyword: 'E-commerce SEO',
    contentLength: 1900,
  },
  {
    id: -16,
    url: '/seo/international-seo/best-practices',
    title: 'Best Practices for International SEO',
    clicks: 170,
    topKeyword: 'International SEO',
    contentLength: 1750,
  },
  {
    id: -17,
    url: '/seo/backlink-analysis/tools',
    title: 'Top Tools for Backlink Analysis',
    clicks: 100,
    topKeyword: 'Backlink Analysis',
    contentLength: 1650,
  },
  {
    id: -18,
    url: '/seo/featured-snippets/optimization',
    title: 'Optimizing for Featured Snippets',
    clicks: 205,
    topKeyword: 'Featured Snippets',
    contentLength: 1950,
  },
  {
    id: -19,
    url: '/seo/schema-markup/basics',
    title: 'The Basics of Schema Markup for SEO',
    clicks: 160,
    topKeyword: 'Schema Markup',
    contentLength: 1500,
  },
  {
    id: -20,
    url: '/seo/page-experience/update',
    title: "Understanding Google's Page Experience Update",
    clicks: 220,
    topKeyword: 'Page Experience',
    contentLength: 2400,
  },
];

export const AllPages = () => {
  const appState = useAppStore();
  const client = useQueryClient();
  const [sitemapSettingsDialog, setSitemapSettingsDialog] = useState(false);
  const navigate = useNavigate();
  const { hasFeature } = useFeatureFlagging();
  const { projectId, pageTitle, refreshCurrentProject, currentProject } =
    useAppStore(
      useShallow((state) => ({
        projectId: state.currentProject!.id,
        currentProject: state.currentProject,
        pageTitle: state.pageTitle,
        refreshCurrentProject: state.refreshCurrentProject,
      })),
    );
  const search = useSearch({ from: '/all-pages' });
  const { selection, setSelection } = useTableSelection();
  const [isScanning, setIsScanning] = useState(!!currentProject!.is_crawling);
  const [showBulkGenerateDialog, setShowBulkGenerateDialog] = useState(false);

  const [showSyncConfirmDialog, setShowSyncConfirmDialog] = useState(false);

  const variables = {
    pathParams: {
      project: projectId,
    },
  };

  const gscHookProps = useConnectToGoogleSearchConsole(projectId);
  const isGoogleSearchConsoleActive =
    gscHookProps.googleSearchConsoleData?.data.state === 'active';

  const handleCopyToClipboard = useCopyTable(
    selection.map((item) => (item.url ? item.url : '')).join('\n'),
  );

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      gscHookProps.setupGoogleConsole({
        pathParams: {
          project: projectId,
        },
        body: {
          code: codeResponse.code,
        },
      });
    },
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/webmasters.readonly',
    ux_mode: 'popup',
  });

  const sitemapSyncMutation = useProjectImportUrls();

  const { mutate: exportProjectUrls, isPending: isLoadingProjectUrlExport } =
    useExportProjectUrls({
      onSuccess(data) {
        downloadCSV(data, 'all_pages.csv');
      },
    });

  const updateProjectUrlMutation = useProjectsDomainUrlsUpdate();
  const {
    data: urls,
    isLoading: isLoadingListUrls,
    refetch: refetchProjectUrls,
  } = useProjectsUrlsIndex({
    ...variables,
    queryParams: {
      limit: isGoogleSearchConsoleActive ? 100 : 5,
      sort_by: search.sortBy ?? 'clicks',
      sort_direction: search.sortDirection,
      page: search.page,
      filters: { ...search.filters, search: search.filters?.search },
    },
  });

  const injectSubrowData = (
    id: number,
    data: ScannedContentSubResource,
    total: number,
  ) => {
    client.setQueriesData(
      {
        predicate: (query) =>
          query.queryKey.includes('listUrls') |
          query.queryKey.includes('projectsUrlsIndex'),
      },
      (prev: any) => {
        return {
          ...prev,
          data: prev.data.map((item: ScannedContentResource) => {
            if (item.id === id) {
              return {
                ...item,
                ...(data ?? {}),
                clicks: item.clicks,
                keyword_count: total,
                top_keyword: (data ?? {}).keyword,
                is_scanning: false,
                is_single_scan_loading: false,
              };
            }
            return item;
          }),
        };
      },
    );
  };

  const loadSubrowData = async (id: number) => {
    const response = await fetchListUrlKeywords({
      pathParams: {
        project: appState.currentProject!.id,
        url: id,
      },
      queryParams: {
        limit: 1,
      },
    });

    injectSubrowData(id, response.data[0], response.meta.total);
  };

  useEffect(() => {
    const singleScanChannel = window.Echo.private(
      `projects.${appState.currentProject?.id}`,
    ).listen('.ProjectUrlScanCompleted', (e: { project_url_id: number }) => {
      if (
        urls?.data.some((item) => {
          return item.id === e.project_url_id;
        })
      ) {
        loadSubrowData(e.project_url_id);
      }
    });

    return () => {
      singleScanChannel.stopListening('.ProjectUrlScanCompleted');
    };
  }, [urls?.data]);

  const isLoadingTable = () => {
    if (isGoogleSearchConsoleActive) {
      return isLoadingListUrls;
    }
    return gscHookProps.isGettingGoogleSearchConsoleData;
  };

  const handleSitemapSync = () => {
    setIsScanning(true);
    refreshCurrentProject();
    client.setQueriesData(
      { predicate: (query) => query.queryKey.includes('projectsUrlsIndex') },
      (prev: any) => {
        return {
          ...prev,
          data: [],
        };
      },
    );
    setShowSyncConfirmDialog(false);
    sitemapSyncMutation.mutate({
      pathParams: {
        project: projectId,
      },
    });
  };

  useEffect(() => {
    const callBack = () => {
      setIsScanning(false);
      refetchProjectUrls();
      refreshCurrentProject();
    };

    const channel = window.Echo.private(`projects.${projectId}`).listen(
      '.ImportCompleted',
      callBack,
    );

    return () => {
      channel.stopListening('.ImportCompleted');
    };
  }, []);

  const items = isGoogleSearchConsoleActive
    ? urls?.data.map((url) => ({
        id: url.id,
        path: url.path,
        document: url.document,
        cms: url.cms,
        url: url.url,
        title: url.meta_title,
        clicks: url.clicks ?? 0,
        topKeyword: url.top_keyword ?? '',
        contentLength: url.content_length ?? '',
        primaryKeyword: url.primary_keyword?.name ?? '',
        isScanning: url.is_scanning,
        isSingleScanning: url.is_single_scan_loading,
      }))
    : [
        ...(urls?.data.map((url) => ({
          id: url.id,
          path: url.path,
          document: url.document,
          cms: url.cms,
          url: url.url,
          title: url.meta_title,
          clicks: url.clicks ?? 0,
          topKeyword: url.top_keyword ?? '',
          contentLength: url.content_length ?? '',
          primaryKeyword: url.primary_keyword?.name ?? '',
          isScanning: url.is_scanning,
          isSingleScanning: url.is_single_scan_loading,
        })) ?? []),
        ...dummyData,
      ];

  const handleTargetKeywordChange = (
    value: string,
    item: ProjectDomainUrlResource,
  ) => {
    client.setQueriesData<any>(
      {
        predicate: (query) => query.queryKey.includes('projectsUrlsIndex'),
      },
      (prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          data: prev.data.map((currentItem) => {
            if (item.id === currentItem.id) {
              return {
                ...currentItem,
                primary_keyword: {
                  ...(currentItem.primary_keyword ?? {}),
                  name: value,
                },
              };
            }
            return currentItem;
          }),
        };
      },
    );
  };

  const { handleBlankEditor, handleImport, handleRescan, isLoadingForId } =
    useAllPageActions();

  pageTitle('All pages');
  return (
    <>
      <ConfirmDialog
        title="Are you sure you want to sync the sitemap?"
        isOpen={showSyncConfirmDialog}
        onClose={() => setShowSyncConfirmDialog(false)}
        onConfirm={handleSitemapSync}
      />
      <URLDialog
        setSitemapSettingsDialog={setSitemapSettingsDialog}
        sitemapSettingsDialog={sitemapSettingsDialog}
        refetchProjectUrls={() => {
          refetchProjectUrls();
        }}
      />
      <BulkGenerateDialog
        urlIds={selection.map((item) => item.id)}
        isOpen={showBulkGenerateDialog}
        onClose={() => setShowBulkGenerateDialog(false)}
        onSucces={() => {
          navigate({ to: '/created-content' });
        }}
      />
      <Menus additionalBanners={<OutOfUrlCreditsBanner />}>
        <PageContainer>
          <div className="flex justify-between">
            <SimpleTitle>All pages</SimpleTitle>
            <div className="flex">
              <Button
                disabled={!isGoogleSearchConsoleActive}
                text="Sitemap sync"
                prependIcon={RefreshCcw}
                variant="ghost"
                isLoading={isScanning}
                onClick={() => setShowSyncConfirmDialog(true)}
              />
              <Button
                disabled={!isGoogleSearchConsoleActive}
                text="Sitemap settings"
                prependIcon={Settings}
                variant="ghost"
                onClick={() => setSitemapSettingsDialog(true)}
              />
            </div>
          </div>
          <p className="my-4">
            Easily identify and enhance thin content across your site's pages
            for improved performance.{' '}
            <a
              href="https://docs.seo.ai/a-38-all-pages"
              target="_blank"
              className="underline"
              rel="noreferrer"
            >
              Read more
            </a>
            .
          </p>
          {appState.subscription?.is_trialing && (
            <WarningAlert title="Only 10 URLs are imported in trial version" />
          )}
          <Table.Root
            disabled={!isGoogleSearchConsoleActive}
            isLoading={isLoadingTable()}
            items={items}
            renderRow={({ index, cells, classNames }) => (
              <tr
                className={`${
                  index > urls?.data?.length - 1 && !isGoogleSearchConsoleActive
                    ? 'pointer-events-none blur'
                    : ''
                } ${classNames}`}
              >
                {cells}
              </tr>
            )}
            columns={[
              {
                heading: 'URL',
                property: 'url',
                sortableHeader: true,
                render: (item) => (
                  <div>
                    <a
                      title={item.url ?? ''}
                      href={item.url ?? ''}
                      target="_blank"
                      className="inline max-w-[350px] break-all hover:opacity-50"
                      rel="noreferrer"
                    >
                      {item.path ?? item.url}
                    </a>
                  </div>
                ),
              },
              {
                heading: 'TITLE',
                property: 'title',
                sortableHeader: true,
                render: (item) => item.title,
              },
              {
                heading: 'CLICKS',
                rightAlign: true,
                property: 'clicks',
                sortableHeader: true,
                render: (item) =>
                  !isGoogleSearchConsoleActive ? (
                    <div className="relative -left-8 -top-2.5 text-primary">
                      <div className="absolute flex">
                        <p
                          className="cursor-pointer italic underline"
                          onClick={login}
                        >
                          Connect
                        </p>
                      </div>
                    </div>
                  ) : item.isScanning || item.isSingleScanning ? (
                    <SkeletonLoader height="2xs" />
                  ) : (
                    <div>
                      {item.topKeyword
                        ? formatThousandSeperator(item.clicks ?? 0)
                        : '-'}
                    </div>
                  ),
              },
              {
                heading: 'TOP KEYWORD',
                property: 'top_keyword',
                sortableHeader: true,
                render: (item) =>
                  !isGoogleSearchConsoleActive ? (
                    <div className="relative -top-2.5 left-0 text-primary">
                      <div className="absolute flex w-[8rem]">
                        <p className="w-full italic">Search Console</p>
                      </div>
                    </div>
                  ) : item.isScanning || item.isSingleScanning ? (
                    <SkeletonLoader height="2xs" />
                  ) : (
                    <div>{item.topKeyword ?? '-'}</div>
                  ),
              },
              {
                heading: 'TARGET KEYWORD',
                property: 'primary_keyword',
                render: (item) => (
                  <EditableText
                    text={item.primaryKeyword ?? ''}
                    onChange={(value) => {
                      handleTargetKeywordChange(value, item);
                    }}
                    onDebounceChange={(value) => {
                      updateProjectUrlMutation.mutate({
                        pathParams: {
                          domainUrl: item.id.toString(),
                          project: appState.currentProject!.id,
                        },
                        body: {
                          primary_keyword: value,
                        },
                      });
                    }}
                  />
                ),
              },
              {
                heading: 'CONTENT LENGTH',
                rightAlign: true,
                property: 'content_length',
                sortableHeader: true,
                render: (item) =>
                  item.isScanning || item.isSingleScanning ? (
                    <SkeletonLoader height="2xs" />
                  ) : (
                    formatThousandSeperator(item.contentLength ?? 0)
                  ),
              },
              {
                render: (item) => {
                  return (
                    <PopoverMenu
                      disabled={isLoadingForId === item.id}
                      trigger={
                        <Button
                          text="Actions"
                          variant="outline"
                          dense
                          size="xs"
                          appendIcon={ChevronDown}
                          disabled={item.isScanning}
                          isLoading={
                            isLoadingForId === item.id || item.isSingleScanning
                          }
                        />
                      }
                      items={[
                        {
                          prependIcon: Stars,
                          title: 'Create AI draft',
                          onClick: () => {
                            navigate({
                              to: '/create-content/ai-generated',
                              search: () => ({
                                keyword: item.topKeyword,
                                url: item.url,
                              }),
                            });
                          },
                        },
                        {
                          prependIcon: Globe,
                          title: 'Open in editor',
                          onClick: () => {
                            if (item.document) {
                              navigate({
                                to: '/documents/$documentId',
                                params: {
                                  documentId: hasher.encode(item.document.id),
                                },
                              });
                            } else {
                              handleImport(
                                item,
                                item.primaryKeyword || item.topKeyword,
                              );
                            }
                          },
                        },
                        {
                          prependIcon: Plus,
                          title: 'Create new content',
                          onClick: () =>
                            handleBlankEditor(
                              item.id,
                              item.url,
                              item.primaryKeyword || item.topKeyword,
                            ),
                        },
                        {
                          prependIcon: Scan,
                          title: 'Rescan',
                          onClick: () => handleRescan(item.id),
                        },
                      ]}
                    />
                  );
                },
              },
              {
                render: (item) => {
                  if (!hasFeature('shopify-cms-integration')) return null;
                  return (
                    <div className="flex items-center gap-2">
                      {item.cms?.has_reference && (
                        <IconButton
                          icon={
                            <Icon
                              icon={faShopify}
                              className="h-[18px] w-[18px] text-primary"
                            />
                          }
                          onClick={() => window.open(item.cms.admin_link)}
                        />
                      )}
                      {item.document && (
                        <IconButton
                          icon={FileText}
                          onClick={() => {
                            navigate({
                              to: '/documents/$documentId',
                              params: {
                                documentId: hasher.encode(item.document.id),
                              },
                            });
                          }}
                        />
                      )}
                    </div>
                  );
                },
              },
            ]}
            onSelectionChange={
              isGoogleSearchConsoleActive ? setSelection : undefined
            }
            selection={isGoogleSearchConsoleActive ? selection : undefined}
            meta={urls?.meta}
            overlay={
              gscHookProps && (
                <GoogleConsoleConnect {...gscHookProps} page="all pages" />
              )
            }
            sorting={search}
            disableFrontendSorting
            onSortChange={({ sortBy, sortDirection }) => {
              navigate({
                search: (prev) => ({
                  ...prev,
                  page: 1,
                  sortBy: sortBy,
                  sortDirection,
                }),
              });
            }}
          >
            <Table.Header
              disabled={!isGoogleSearchConsoleActive}
              onExportCSV={{
                isLoading: isLoadingProjectUrlExport,
                onClick: () => {
                  exportProjectUrls({
                    pathParams: { project: projectId },
                    body: {
                      filters: {
                        search: search.filters?.search,
                        is_active: search.filters?.is_active,
                        clicks_min: search.filters?.clicks_min,
                        clicks_max: search.filters?.clicks_max,
                        content_length_min: search.filters?.content_length_min,
                        content_length_max: search.filters?.content_length_max,
                      },
                      sort_by: search.sortBy,
                      sort_direction: search.sortDirection,
                    },
                  });
                },
              }}
              onSearchChange={(value) => {
                navigate({
                  search: {
                    ...search,
                    page: 1,
                    filters: {
                      ...search.filters,
                      search: value,
                    },
                  },
                });
              }}
            >
              {selection.length > 0 ? (
                <SelectionActions
                  selection={selection}
                  actions={[
                    {
                      size: 'sm',
                      dense: true,
                      prependIcon: Copy,
                      text: 'Copy',
                      variant: 'ghost',
                      onClick: handleCopyToClipboard,
                    },
                    {
                      size: 'sm',
                      dense: true,
                      prependIcon: Stars,
                      text: 'Run template',
                      variant: 'ghost',
                      onClick: () => setShowBulkGenerateDialog(true),
                    },
                  ]}
                />
              ) : (
                <>
                  <Table.ResultsTotal
                    isLoading={isLoadingListUrls}
                    title="URLs"
                    total={urls?.meta.total}
                  />
                  <Table.FilterPopover
                    disabled={!isGoogleSearchConsoleActive}
                    name="Clicks"
                    onRemoveFilter={() => {
                      navigate({
                        search: {
                          ...search,
                          page: 1,
                          filters: {
                            ...search.filters,
                            clicks_min: undefined,
                            clicks_max: undefined,
                          },
                        },
                      });
                    }}
                    filterName={formatRange({
                      max: search.filters?.clicks_max,
                      min: search.filters?.clicks_min,
                      name: 'Clicks',
                    })}
                  >
                    <RangeSelector
                      onAccept={(values) => {
                        navigate({
                          search: {
                            ...search,
                            page: 1,
                            filters: {
                              ...search.filters,
                              clicks_min: values.min,
                              clicks_max: values.max,
                            },
                          },
                        });
                      }}
                      values={{
                        max: search.filters?.clicks_max,
                        min: search.filters?.clicks_min,
                      }}
                    />
                  </Table.FilterPopover>
                  <Table.FilterPopover
                    disabled={!isGoogleSearchConsoleActive}
                    name="Content length"
                    onRemoveFilter={() => {
                      navigate({
                        search: {
                          ...search,
                          page: 1,
                          filters: {
                            ...search.filters,
                            content_length_min: undefined,
                            content_length_max: undefined,
                          },
                        },
                      });
                    }}
                    filterName={formatRange({
                      max: search.filters?.content_length_max,
                      min: search.filters?.content_length_min,
                      name: 'Content length',
                    })}
                  >
                    <RangeSelector
                      onAccept={(values) => {
                        navigate({
                          search: {
                            ...search,
                            page: 1,
                            filters: {
                              ...search.filters,
                              content_length_min: values.min,
                              content_length_max: values.max,
                            },
                          },
                        });
                      }}
                      values={{
                        max: search.filters?.content_length_max,
                        min: search.filters?.content_length_min,
                      }}
                    />
                  </Table.FilterPopover>
                </>
              )}
            </Table.Header>
            <Table.NoContent>
              <div className="flex flex-col items-center gap-4">
                <LineChart size={128} className="text-primary-200" />
                <p className="text-lg font-bold text-primary">No results</p>
              </div>
            </Table.NoContent>
            <Table.Footer>
              {urls?.data.length > 0 && (
                <Pagination
                  disabled={!isGoogleSearchConsoleActive}
                  currentPage={search.page ?? 1}
                  setCurrentPage={(page) =>
                    navigate({ search: { ...search, page } })
                  }
                  lastPage={urls?.meta.last_page}
                />
              )}
            </Table.Footer>
          </Table.Root>
        </PageContainer>
      </Menus>
    </>
  );
};
