import { useKeywordInjectionStore } from '@/Pages/Document/stores';
import { useShallow } from 'zustand/react/shallow';
import { useKeywordInjectionListener } from '../hooks';
import Tabs from '@/Components/Tabs';
import { WarningAlert, Alert } from '@/Components/v2/Alert';
import { TextLoader } from '@/Pages/Document/components/ReportSection/components/TextLoader';
import { KeywordInjectionTabs } from '@/types';
import { RefreshCcw } from 'lucide-react';
import { LoadingSuggestionsAnimation } from '../../LoadingSuggestionsAnimation';
import { SuggestionInsert } from './SuggestionInsert';
import { SuggestionUpdate } from './SuggestionUpdate';
import { Button } from '@/Components/v2/Button';
import { DocumentResource, ProjectResource } from '@/api/openapiSchemas';

type Props = {
  project: ProjectResource;
  document: DocumentResource;
};

export const KeywordInjectionContent = ({ document, project }: Props) => {
  const {
    keyword,
    handleChangeTab,
    mode,
    isLoadingUpdatedKeywordSuggestions,
    isLoadingAddedKeywordSuggestions,
    noKeywordSuggestionsFound,
    noNewKeywordSuggestionsFound,
    noMoreKeywordSuggestions,
    noMoreNewKeywordSuggestions,
  } = useKeywordInjectionStore(
    useShallow((state) => ({
      keyword: state.keyword,
      handleChangeTab: state.handleChangeTab,
      mode: state.mode,
      isLoadingUpdatedKeywordSuggestions:
        state.isLoadingUpdatedKeywordSuggestions,
      isLoadingAddedKeywordSuggestions: state.isLoadingAddedKeywordSuggestions,
      noNewKeywordSuggestionsFound: state.noNewKeywordSuggestionsFound,
      noKeywordSuggestionsFound: state.noKeywordSuggestionsFound,
      noMoreKeywordSuggestions: state.noMoreKeywordSuggestions,
      noMoreNewKeywordSuggestions: state.noMoreNewKeywordSuggestions,
    })),
  );

  const { startLookingForKeywordSuggestions, initialize, loadNewData } =
    useKeywordInjectionListener(
      document,
      mode === 'UPDATE EXISTING'
        ? 'update_existing_paragraph'
        : 'new_paragraph',
    );

  const isCurrentlyLoadingSuggestions = () => {
    if (mode === 'UPDATE EXISTING') {
      return isLoadingUpdatedKeywordSuggestions;
    } else {
      return isLoadingAddedKeywordSuggestions;
    }
  };

  const noKeywordSuggestionsHasBeenFound =
    (mode === 'UPDATE EXISTING' && noKeywordSuggestionsFound) ||
    (mode === 'NEW PARAGRAPHS' && noNewKeywordSuggestionsFound);

  const noMoreKeywordSuggestionsToAccept =
    (mode === 'UPDATE EXISTING' && noMoreKeywordSuggestions) ||
    (mode === 'NEW PARAGRAPHS' && noMoreNewKeywordSuggestions);
  return (
    <>
      <Tabs
        className="mb-4"
        onChange={(v) => handleChangeTab(v as KeywordInjectionTabs)}
        selectedTab={mode || 'UPDATE EXISTING'}
        tabs={[
          {
            name: 'UPDATE EXISTING',
          },
          { name: 'NEW PARAGRAPHS' },
        ]}
      />
      {mode === 'UPDATE EXISTING' && !isLoadingUpdatedKeywordSuggestions && (
        <SuggestionUpdate document={document} />
      )}
      {mode === 'NEW PARAGRAPHS' && !isLoadingAddedKeywordSuggestions && (
        <SuggestionInsert document={document} />
      )}
      <div className="flex w-full justify-center">
        {isCurrentlyLoadingSuggestions() ? (
          <div className="flex min-h-[70vh] items-center">
            <div className="flex flex-col gap-2">
              <LoadingSuggestionsAnimation />
              <TextLoader
                texts={[
                  'Finding optimal keyword locations',
                  'Generating keyword integration suggestions',
                  `Hold on, it's taking a bit longer than unusual`,
                ]}
                durations={[7000, 7000, 11500]}
              />
            </div>
          </div>
        ) : (document.document_report?.word_count.actual || 0) >= 100 ? (
          <div className="flex w-full flex-col">
            {noKeywordSuggestionsHasBeenFound && (
              <div className="mb-4 flex flex-col gap-4">
                <WarningAlert
                  title="No sentences found"
                  body="Please provide more content to your document and press retry"
                />
              </div>
            )}
            {noMoreKeywordSuggestionsToAccept && (
              <div className="mb-4 flex flex-col gap-4">
                <Alert
                  color="green"
                  title="Completed"
                  body="All suggestions have been handled!"
                />
              </div>
            )}
            <div className="flex justify-start">
              <Button
                variant="ghost"
                size="sm"
                dense
                prependIcon={RefreshCcw}
                isLoading={isCurrentlyLoadingSuggestions()}
                disabled={isCurrentlyLoadingSuggestions()}
                onClick={() => {
                  startLookingForKeywordSuggestions({
                    pathParams: {
                      document: document.id,
                      project: project.id,
                    },
                    body: {
                      keyword,
                      amount: 3,
                      suggestion_type:
                        mode === 'UPDATE EXISTING'
                          ? 'update_existing_paragraph'
                          : 'new_paragraph',
                    },
                  }).then((data) => {
                    loadNewData();
                    initialize(
                      data,
                      mode === 'UPDATE EXISTING'
                        ? 'update_existing_paragraph'
                        : 'new_paragraph',
                    );
                  });
                }}
                text={
                  noMoreKeywordSuggestionsToAccept ? 'GENERATE MORE' : 'RETRY'
                }
              />
            </div>
          </div>
        ) : (
          <h1 className="mb-2 mt-4 ">
            In order to get suggestions, please write at least 100 words of
            content
          </h1>
        )}
      </div>
    </>
  );
};
