import { UserResource } from '@/api/openapiSchemas';
import { events } from './Events';
import { TrackingEvent } from './Tracking';
import { consoleDestination } from './destinations/Console';
import { googleTagManager } from './destinations/GoogleTagManager';
import { mixPanel } from './destinations/Mixpanel';
import { posthogDestination } from './destinations/Posthog';
import { segment } from './destinations/Segment';

export type Destination = {
  name: string;
  enabledByEnvKey: string;
  initialize: () => Promise<void> | void;
  identify: (user: UserResource) => Promise<void> | void;
  reset: () => Promise<void> | void;
  trackEvent: (
    event: TrackingEvent,
    user?: UserResource,
  ) => Promise<void> | void;
  resetGroup?: () => void;
  group?: (
    group: { id: number; type: string },
    payload?: Record<string, any>,
  ) => Promise<void> | void;
  onlyAllow?: (keyof typeof events)[];
  ignore?: (keyof typeof events)[];
};

export const destinations = [
  segment,
  mixPanel,
  consoleDestination,
  googleTagManager,
  posthogDestination,
];
