import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearch } from '@tanstack/react-router';
import { useAppStore } from '../AppLoader/stores';
import { Button } from '@/Components/v2/Button';
import { SeoAiLogoBlack } from '@/Components/SVGs/SeoAiLogoBlack';
import { Input } from '@/Components/v2/Input/Input';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { GoogleLogo } from '@/Components/GoogleLogo';
import { useAuthToken } from '@/api/openapiComponents';
import { ErrorAlert } from '@/Components/v2/Alert';
import { useInitApp } from '../AppLoader/hooks/useInitApp';
import { SEO_AI_AUTH } from '@/api/openapiFetcher';
import { useGoogleAuth } from './hooks';
import { Dialog } from '@/Components/Dialog';
import { SEO_AI_AUTH_REQUIRED } from '@/utils';

type Props = {
  email?: string;
};

export default function Login({ email }: Props) {
  const [authRequired, setAuthRequired] = useState(false);
  const params = useSearch({ from: '/login' });
  const goToGoogleAuth = useGoogleAuth('LOGIN');
  const [formData, setFormData] = useState({
    email: email ?? '',
    password: '',
  });

  const navigate = useNavigate();

  const appState = useAppStore();

  const handleInitApp = useInitApp();
  const [loggingInViaToken, setLoggingInViaToken] = useState(!!params.token);

  const {
    mutate: login,
    isPending: isSubmittingLoginForm,
    error: loginError,
    isError: isLoginError,
  } = useAuthToken({
    onSuccess: (data) => {
      localStorage.setItem(SEO_AI_AUTH, data.data.token);
      handleInitApp().then(() => {
        const redirect = params.redirect
          ? decodeURIComponent(params.redirect)
          : '/';
        navigate({ to: redirect });
      });
    },
  });

  if (localStorage.getItem(SEO_AI_AUTH_REQUIRED)) {
    setAuthRequired(true);
    localStorage.removeItem(SEO_AI_AUTH_REQUIRED);
  }

  const isLoggingIn = isSubmittingLoginForm || loggingInViaToken;

  useEffect(() => {
    if (params.token) {
      localStorage.setItem(SEO_AI_AUTH, params.token);
      handleInitApp()
        .then(() => {
          const redirect = params.redirect
            ? decodeURIComponent(params.redirect)
            : '/';
          navigate({ to: redirect });
        })
        .catch(() => setLoggingInViaToken(false));
    }
  }, [params.token]);

  const onHandleChange = (value: string, name: string) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    login({ body: { email: formData.email, password: formData.password } });
  };

  appState.pageTitle('Log in');

  return (
    <>
      <Dialog
        isOpen={authRequired}
        title="Authentication required"
        actions={[
          {
            text: 'Okay',
            onClick: () => setAuthRequired(false),
            variant: 'fill',
            color: 'secondary',
          },
        ]}
      >
        <p>
          Your session has expired. You can only be logged in on one browser at
          a time.
        </p>
      </Dialog>
      <div className="flex h-screen flex-col items-center justify-center text-gray-800">
        <div className="absolute left-0 right-0 top-2 flex w-full justify-center">
          <SeoAiLogoBlack />
        </div>
        <div className="w-96">
          <h2 className="mb-3 text-center font-work text-[25px] font-bold leading-normal text-black">
            Sign in
          </h2>

          {status && (
            <div className="mb-4 font-work  text-green-600">{status}</div>
          )}

          <Button
            fullWidth
            variant="outline"
            prependIcon={<GoogleLogo />}
            onClick={goToGoogleAuth}
            text="Sign in with Google"
          />

          <div className="my-4 flex items-center">
            <div className="flex-grow border-t border-zinc-300"></div>
            <span className="mx-2 flex-shrink font-normal text-gray-400">
              or
            </span>
            <div className="flex-grow border-t border-zinc-300"></div>
          </div>

          <form onSubmit={handleSubmit}>
            <InputDecoration required label="Email">
              <Input
                error={loginError?.errors?.email}
                type="text"
                name="email"
                placeholder="name@company.com"
                value={formData.email}
                autoComplete="username"
                disabled={isLoggingIn}
                onChange={(value) => onHandleChange(value, 'email')}
              />
            </InputDecoration>

            <div className="h-4" />

            <InputDecoration required label="Password">
              <Input
                error={loginError?.errors?.password}
                type="password"
                name="password"
                placeholder="• • • • •"
                disabled={isLoggingIn}
                value={formData.password}
                autoComplete="current-password"
                onChange={(value) => onHandleChange(value, 'password')}
              />
            </InputDecoration>

            {isLoginError && (
              <div className="mt-4">
                <ErrorAlert title={loginError.message} />
              </div>
            )}
            <div className="mt-8 flex items-center justify-center">
              <Button
                color="secondary"
                fullWidth
                type="submit"
                isLoading={isLoggingIn}
                text="Sign in"
              />
            </div>

            <div className="mt-4 text-center">
              <Link to="/forgot-password" className=" underline">
                Forgot your password?
              </Link>
            </div>

            <div className="mt-3 text-center ">
              New to SEO.AI? {` `}
              <Link to="/register" className=" underline">
                Create account
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
