import { DocumentResource } from '@/api/openapiSchemas';
import { useCallback, useEffect, useRef, useState } from 'react';
import { PlateEditor as PlateEditorRef } from '@udecode/plate-common';
import { useAppStore } from '../AppLoader/stores';
import { useShallow } from 'zustand/react/shallow';
import PlateEditorErrorBoundaryHOC from './components/PlateEditorErrorBoundary';
import {
  ActiveDraggableState,
  BlockSelection,
  CurrentSlideOver,
  isEditorUniqueID,
  NodeTypesEnum,
} from '@/types';
import { PlateEditorV2 } from './components/PlateEditor/PlateEditorV2';
import { Chat } from './components/Chat/Chat';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import {
  ArrowLeft,
  Copy,
  Edit3,
  Hammer,
  Link,
  MessageCircleQuestion,
  SearchX,
  Settings,
  Table,
} from 'lucide-react';
import { useRouter } from '@tanstack/react-router';
import { Button } from '@/Components/v2/Button';
import { Movable, MoveEvent } from '@/Components/Movable';
import { DocumentInfoContext } from './components/PlateEditor/contexts/DocumentInfoContext';
import {
  ActiveDraggableContext,
  DocumentActionContext,
  EditorContext,
  EditorRefContext,
} from './context';
import {
  DndContext,
  DragEndEvent,
  DragStartEvent,
  pointerWithin,
} from '@dnd-kit/core';
import { deserializeSnippet } from './components/PlateEditor/lib/deserialize';
import { tracking } from '@/Services/tracking/Tracking';
import {
  KeywordLabel,
  ReportSectionKeyword,
} from './components/ReportSection/components';
import { SettingsDialog } from './components/SettingDialogs/SettingsDialog';
import { useGetSettingsState } from './hooks';
import { DocumentSlideOvers } from './components/DocumentSlideOvers';
import { ReactEditor } from 'slate-react';
import { OutlineItem } from '@/Components/DocumentForm/hooks';
import { PopoverMenu } from '@/Components/v2/Menu/ButtonMenu';
import { Dialog } from '@/Components/Dialog';
import { CompetitorTable } from './components/ReportSection/components/CompetitorTable';

type Props = {
  refetchDocument: () => void;
  document: DocumentResource;
};

export const DocumentLayout = ({ document, refetchDocument }: Props) => {
  const router = useRouter();

  const [editor, setEditor] = useState<PlateEditorRef | null>(null);
  const [currentSlideOver, setCurrentSlideOver] = useState<CurrentSlideOver>();
  const [selection, setSelection] = useState<BlockSelection | null>(null);
  const [showSettings, setShowSettings] = useState(false);
  const [showKeywordDistribution, setShowKeywordDistribution] = useState(false);

  const chatPanelRef = useRef<HTMLDivElement>(null);
  const editorFocusRef = useRef<HTMLDivElement>(null);

  const [draggableState, setDraggableState] =
    useState<ActiveDraggableState | null>(null);

  const { settings, handleDialog, handleSettings } =
    useGetSettingsState(document);

  const { currentProject, auth, pageTitle } = useAppStore(
    useShallow((state) => ({
      currentProject: state.currentProject,
      auth: state.auth,
      pageTitle: state.pageTitle,
    })),
  );

  useEffect(() => {
    if (chatPanelRef.current) {
      const width = localStorage.getItem('SEO_AI_chatWidth');
      if (width) {
        setChatWidth(parseInt(width));
      } else {
        setChatWidth(400);
      }
    }
  }, [chatPanelRef.current]);

  const setChatWidth = (width: number) => {
    if (chatPanelRef.current === null) return;
    const limitedWidth = Math.max(width, 300);
    chatPanelRef.current.style.width = `${limitedWidth}px`;
    localStorage.setItem('SEO_AI_chatWidth', limitedWidth.toString());
  };

  const handleDragChatBorder = (e: MoveEvent) => {
    if (chatPanelRef.current === null) return;

    setChatWidth(
      chatPanelRef.current.getBoundingClientRect().width - e.offset.x,
    );
  };

  const handleDragStart = (e: DragStartEvent) => {
    if (e.active.data.current?.text) {
      setDraggableState((prev) =>
        prev === null
          ? {
              activeElement: {
                id: e.active.id,
                text: e.active.data.current?.text,
              },
              hoveredElementID: undefined,
            }
          : { ...prev },
      );
    }
  };

  const handleDrag = (e: DragEndEvent) => {
    const activeId = e.active.id;
    const overId = e.over?.id;
    if (editor) {
      const overIndex = editor.children.findIndex((x) => x.id === overId);
      const activeIndex = editor.children.findIndex((x) => x.id === activeId);
      if (overId && isEditorUniqueID(activeId) && isEditorUniqueID(overId)) {
        if (activeIndex >= 0 && overIndex >= 0) {
          editor.moveNodes({
            at: [activeIndex],
            to: [overIndex],
          });
        }
      }
      if (
        overId &&
        !isEditorUniqueID(activeId) &&
        isEditorUniqueID(overId) &&
        draggableState
      ) {
        editor.insertNodes(
          deserializeSnippet(draggableState.activeElement.text),
          {
            at: [overIndex + 1],
          },
        );
      }
    }
    tracking.event('document_chat_dragged_content');
    setDraggableState(null);
  };

  const handleInsertText = (markdownString: string) => {
    if (editor) {
      const nodes = deserializeSnippet(markdownString);
      if (editor.selection) {
        tracking.event('document_chat_inserted_content');
        const focusPath = editor.selection.focus.path[0];
        const anchorPath = editor.selection.anchor.path[0];
        if (focusPath >= anchorPath) {
          editor.insertNodes(nodes, {
            at: [focusPath + 1],
          });
          return;
        }
        if (anchorPath > focusPath) {
          editor.insertNodes(nodes, {
            at: [anchorPath + 1],
          });
          return;
        }
      }
    }
  };

  const handleReplaceText = (markdownString: string) => {
    if (editor) {
      editor.deleteFragment();
      const nodes = deserializeSnippet(markdownString);
      editor.insertFragment(nodes);
      tracking.event('document_chat_replaced_content');
    }
  };

  const handleInsertHeading = (value: string, type?: string, focus = true) => {
    if (!editor) return;

    tracking.event('subheading_suggestions_inserted', {
      type: type,
      value: value,
    });

    const wasEmptyDocument = editor.children.length === 1;

    const fragment = editor.children[editor.selection?.anchor.path[0] ?? 0];
    if (fragment.children[0]?.text === '') {
      if (editor.children.length - 1 === editor.selection?.anchor.path[0]) {
        editor.deleteBackward('block');
      } else {
        editor.deleteForward('block');
      }
    }

    editor?.insertNode({
      type: (() => {
        if (type === 'H1') return NodeTypesEnum.H2;
        if (type === 'H6') return NodeTypesEnum.H5;
        return type
          ? (NodeTypesEnum[type as keyof typeof NodeTypesEnum] ??
              NodeTypesEnum.H2)
          : NodeTypesEnum.H2;
      })(),
      children: [{ text: value }],
    });

    if (wasEmptyDocument) {
      editor.removeNodes({ at: { path: [0], offset: 0 } });
    }

    if (focus) {
      // To supress the warning in Sentry we have to catch and ignore the error
      try {
        ReactEditor.focus(editor as ReactEditor);
      } catch (e) {}
    }
  };

  const handleInsertOutline = (outline: OutlineItem[]) => {
    for (const h2 of outline) {
      handleInsertHeading(h2.title, 'H2', false);
      for (const h3 of h2.items ?? []) {
        handleInsertHeading(h3.title, 'H3', false);
      }
    }

    ReactEditor.focus(editor as ReactEditor);
  };

  pageTitle(document.title);

  const handleDeselect = useCallback(() => {
    setSelection(null);
  }, []);

  return (
    <>
      <DocumentSlideOvers
        onInsertHeading={handleInsertHeading}
        onInsertOutline={handleInsertOutline}
        editor={editor}
        document={document}
        project={currentProject!}
        onClose={() => setCurrentSlideOver(undefined)}
        currentlyOpenSlideover={currentSlideOver}
      />
      <SettingsDialog
        document={document}
        isOpen={showSettings}
        onClose={() => setShowSettings(false)}
        settings={settings}
        handleSettings={handleSettings}
      />
      <Dialog
        title="Competitor overview"
        size={'xl'}
        isOpen={showKeywordDistribution}
        handleClose={() => setShowKeywordDistribution(false)}
      >
        <CompetitorTable
          keyword={document.keyword.name}
          competitors={document?.document_report?.competitors ?? []}
          keywordProperty="keywords"
          keywords={document.document_report?.missing_keywords ?? []}
        />
      </Dialog>
      <DocumentInfoContext.Provider
        value={{
          documentId: document.id,
          projectId: currentProject!.id,
        }}
      >
        <EditorRefContext.Provider value={editorFocusRef}>
          <DndContext
            onDragOver={(e) => {
              if (draggableState) {
                setDraggableState((prev) => {
                  if (prev) {
                    return { ...prev, hoveredElementID: e.over?.id };
                  }
                  return prev;
                });
              }
            }}
            onDragStart={handleDragStart}
            onDragEnd={handleDrag}
            autoScroll={{ layoutShiftCompensation: false }}
            collisionDetection={pointerWithin}
          >
            <ActiveDraggableContext.Provider value={draggableState}>
              <EditorContext.Provider value={editor}>
                <DocumentActionContext.Provider
                  value={{
                    handleInsertText,
                    handleReplaceText,
                  }}
                >
                  <div className="flex h-screen bg-primary-100">
                    <div className="flex flex-grow flex-col items-center bg-white shadow-lg">
                      <div className="flex w-full items-center justify-between p-2">
                        <div className="flex items-center gap-2 text-xl font-bold">
                          <IconButton
                            icon={ArrowLeft}
                            onClick={() => router.history.back()}
                          />
                          {document.keyword.name}
                        </div>
                        <div className="flex items-center">
                          <Button
                            prependIcon={Edit3}
                            text={'AI Draft'}
                            variant="ghost"
                          />
                          <IconButton icon={Copy} />
                          <IconButton
                            icon={Settings}
                            onClick={() => setShowSettings(true)}
                          />
                        </div>
                      </div>
                      <div className="w-full flex-grow overflow-hidden">
                        <PlateEditorErrorBoundaryHOC
                          document={document.id}
                          projectId={currentProject!.id}
                          fallback={<div>Loading</div>}
                          text={document.text}
                          refetchDocument={refetchDocument}
                        >
                          <PlateEditorV2
                            user={auth!.user}
                            setCurrentSlideOver={setCurrentSlideOver}
                            currentSlideOver={currentSlideOver}
                            projectId={currentProject!.id}
                            selection={selection}
                            setSelection={setSelection}
                            setEditor={setEditor}
                            document={document}
                            editor={editor}
                          />
                        </PlateEditorErrorBoundaryHOC>
                      </div>
                    </div>
                    <div
                      ref={chatPanelRef}
                      className="relative flex flex-shrink-0 justify-center"
                    >
                      <div className="absolute right-8 top-4 z-[40]">
                        <PopoverMenu
                          type="hover"
                          trigger={
                            <div className="aspect-square cursor-pointer rounded-full bg-white p-2 shadow hover:opacity-60">
                              <Hammer className="text-primary" size={20} />
                            </div>
                          }
                          items={[
                            {
                              title: 'Related questions',
                              prependIcon: SearchX,
                              onClick: () => setCurrentSlideOver('questions'),
                            },
                            {
                              title: 'Keyword gaps',
                              prependIcon: MessageCircleQuestion,
                              onClick: () => setCurrentSlideOver('keyword-gap'),
                            },
                            {
                              title: 'Competitor benchmark table',
                              prependIcon: Table,
                              onClick: () => setShowKeywordDistribution(true),
                            },
                            {
                              title: 'External links',
                              prependIcon: Link,
                              onClick: () =>
                                setCurrentSlideOver('external-links'),
                            },
                          ]}
                        />
                      </div>
                      <div className="absolute bottom-0 left-0 top-0 z-50 flex">
                        <Movable onMove={handleDragChatBorder}>
                          <div className="h-full w-2 cursor-move hover:bg-secondary" />
                        </Movable>
                      </div>
                      <Chat
                        deselectSelection={handleDeselect}
                        document={document}
                        selection={selection}
                        initialMessage={
                          document.document_report &&
                          !document.document_report.is_generating && (
                            <div className="px-2">
                              <ReportSectionKeyword
                                title="USE THESE KEYWORDS MORE"
                                useToolTip
                              >
                                {document.document_report.missing_keywords
                                  .toSorted((a, b) =>
                                    !document.ignored_keywords?.includes(
                                      a.keyword,
                                    )
                                      ? -1
                                      : 1,
                                  )
                                  .map((keyword) => (
                                    <KeywordLabel
                                      key={keyword.keyword}
                                      currentProject={currentProject!}
                                      document={document}
                                      keywordType="missing"
                                      keyword={keyword}
                                      ignored={document.ignored_keywords?.includes(
                                        keyword.keyword,
                                      )}
                                      setCurrentSlideOver={setCurrentSlideOver}
                                    />
                                  ))}
                              </ReportSectionKeyword>
                              <ReportSectionKeyword
                                title="CONSIDER SEMANTIC RICH KEYWORDS"
                                useToolTip
                              >
                                {document?.document_report?.semantic_keywords
                                  .toSorted((a, b) =>
                                    !document.ignored_keywords?.includes(
                                      a.keyword,
                                    )
                                      ? -1
                                      : 1,
                                  )
                                  .map((keyword) => (
                                    <KeywordLabel
                                      key={keyword.keyword}
                                      currentProject={currentProject!}
                                      document={document}
                                      keywordType="semantic"
                                      keyword={keyword}
                                      ignored={document.ignored_keywords?.includes(
                                        keyword.keyword,
                                      )}
                                      setCurrentSlideOver={setCurrentSlideOver}
                                    />
                                  ))}
                              </ReportSectionKeyword>
                            </div>
                          )
                        }
                      />
                    </div>
                    {/* TODO: remove is daniel really does not like it */}
                    {/* <div className="flex flex-shrink-0 flex-col items-center gap-4 bg-white p-2">
                      <p className="-mb-3 mt-2 text-sm font-extrabold">TOOLS</p>
                      <IconButton
                        text="Questions"
                        icon={SearchX}
                        onClick={() => setCurrentSlideOver('questions')}
                      />
                      <IconButton
                        text="KW gaps"
                        icon={MessageCircleQuestion}
                        onClick={() => setCurrentSlideOver('keyword-gap')}
                      />
                      <IconButton
                        text="BM table"
                        icon={Table}
                        onClick={() => setShowKeywordDistribution(true)}
                      />
                      <IconButton
                        text="EXT. links"
                        icon={Link}
                        onClick={() => setCurrentSlideOver('external-links')}
                      />
                    </div> */}
                  </div>
                </DocumentActionContext.Provider>
              </EditorContext.Provider>
            </ActiveDraggableContext.Provider>
          </DndContext>
        </EditorRefContext.Provider>
      </DocumentInfoContext.Provider>
    </>
  );
};
