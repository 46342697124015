type Props = {
  completed: boolean;
  dense?: boolean;
  disabled?: boolean;
};

export const TaskDot = ({ completed, dense, disabled }: Props) => {
  return (
    <div
      className={`${
        disabled ? 'bg-primary-300' : completed ? 'bg-green-300' : 'bg-red-200'
      } h-2.5 w-2.5 rounded-full ${dense ? '' : ''}`}
    />
  );
};
