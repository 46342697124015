import * as Diff from 'diff';

export const handleAccept = (diffs: Diff.Change[], diffIndex: number) => {
  const diff = diffs[diffIndex];
  const previousDiff = diffs[diffIndex - 1];

  if (diff.added && previousDiff?.removed) {
    return {
      actualValue: diffs
        .filter(
          (d, index) =>
            (!d.added || d.removed || index === diffIndex) &&
            index !== diffIndex - 1,
        )
        .map((part) => part.value)
        .join(''),
    };
  }
  if (diff.added && !previousDiff?.removed) {
    return {
      actualValue: diffs
        .filter(
          (d, index) =>
            (!d.added && !d.removed) || d.removed || index === diffIndex,
        )
        .map((part) => part.value)
        .join(''),
    };
  }
  if (diff.removed) {
    return {
      actualValue: diffs
        .filter(
          (d, index) =>
            ((!d.added && !d.removed) || d.removed) && index !== diffIndex,
        )
        .map((part) => part.value)
        .join(''),
    };
  }

  return {};
};

export const handleReject = (diffs: Diff.Change[], diffIndex: number) => {
  const diff = diffs[diffIndex];
  const previousDiff = diffs[diffIndex - 1];

  if (diff.added && previousDiff?.removed) {
    return {
      newValue: diffs
        .filter(
          (d, index) =>
            (d.added || !d.removed || index === diffIndex - 1) &&
            index !== diffIndex,
        )
        .map((part) => part.value)
        .join(''),
    };
  }
  if (diff.added && !previousDiff?.removed) {
    return {
      newValue: diffs
        .filter((d, index) => (d.added || !d.removed) && index !== diffIndex)
        .map((part) => part.value)
        .join(''),
    };
  }
  if (diff.removed) {
    return {
      newValue: diffs
        .filter((d, index) => d.added || !d.removed || index === diffIndex)
        .map((part) => part.value)
        .join(''),
    };
  }

  return {};
};

export const handleAcceptAll = (diffs: Diff.Change[]) => {
  return {
    actualValue: diffs
      .filter((d, index) => !d.removed)
      .map((part) => part.value)
      .join(''),
  };
};

export const handleRejectAll = (diffs: Diff.Change[]) => {
  return {
    newValue: diffs
      .filter((d, index) => !d.added)
      .map((part) => part.value)
      .join(''),
  };
};
