import { createRoute } from '@tanstack/react-router';
import { rootRoute } from '../Router';
import {
  guards,
  hasActiveSubscription,
  hasProject,
  isAuthenticated,
} from '../Router/guards';
import { Account } from './Account';
import { CancelSubscription } from './CancelSubscription';
import { Subscription } from './Subscription';

export const routes = [
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
    },
    path: '/account',
    component: () => <Account />,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
    },
    path: '/account/subscription/cancel',
    component: () => <CancelSubscription />,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
    },
    path: '/account/subscription',
    component: () => <Subscription />,
  }),
];
