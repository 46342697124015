import { Stars } from 'lucide-react';
import { useEffect, useState } from 'react';
import { ArrowLeft } from 'lucide-react';
import { GenerateLoaderOverlay } from './components/GenerateLoaderOverlay';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { useNavigate } from '@tanstack/react-router';
import { hasher } from '@/Pages/AppLoader';
import { tracking } from '@/Services/tracking/Tracking';
import { Button } from '@/Components/v2/Button';
import {
  useProjectGenerateDocument,
  useProjectProjectGenerateDocumentTitle,
} from '@/api/openapiComponents';

const SkeletonRow = () => {
  return (
    <tr>
      <td colSpan={2}>
        <div className="my-1 h-12 w-full animate-pulse rounded-lg bg-gray-200" />
      </td>
    </tr>
  );
};

export default function ChooseTitle() {
  const appState = useAppStore();
  const navigate = useNavigate();

  const [keyword, setKeyword] = useState<string>();
  const [loadingIndex, setLoadingIndex] = useState<number>();

  const generateMutation = useProjectGenerateDocument({
    onSuccess: (data) => {
      navigate({
        to: `/documents/$documentId`,
        params: { documentId: hasher.encode(data?.data.id) },
        search: { generate: true },
      });
    },
  });

  const titleSuggestionMutation = useProjectProjectGenerateDocumentTitle();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    const keyword = params.get('keyword');
    if (!keyword) {
      navigate({ to: '/onboarding/choose-keyword' });
      return;
    }

    titleSuggestionMutation.mutate({
      pathParams: {
        project: appState.currentProject!.id,
      },
      body: { keyword },
    });
    setKeyword(keyword);
  }, []);

  const handleNewSuggestions = () => {
    tracking.event('onboarding_suggest_more_titles', {
      selectedkeyword: keyword,
      project: appState.currentProject!,
      titles:
        titleSuggestionMutation.data?.data.map((entry) => entry.title) ?? [],
    });
    titleSuggestionMutation.mutate({
      pathParams: {
        project: appState.currentProject!.id,
      },
      body: { keyword: keyword! },
    });
  };

  const handleSelectTitle = (title: string | undefined, index: number) => {
    setLoadingIndex(index);
    generateMutation.mutate({
      pathParams: {
        project: appState.currentProject!.id,
      },
      body: {
        keyword: keyword!,
        title,
        autostart: false,
      } as any,
    });
  };

  const handleGoBack = () => {
    tracking.event('onboarding_back_to_keywords', {
      selectedKeyword: keyword,
      project: appState.currentProject!,
    });
    navigate({ to: '/onboarding/choose-keyword' });
  };

  const handleSkip = () => {
    tracking.event('onboarding_cancel');
    navigate({
      to: `/`,
    });
  };

  const handleRetry = () => {
    generateMutation.reset();
    if (loadingIndex !== undefined) {
      handleSelectTitle(
        titleSuggestionMutation.data?.data[loadingIndex].title,
        loadingIndex,
      );
    }
  };

  appState.pageTitle('Choose a title');

  return (
    <div className="flex h-screen w-full flex-col bg-gray-50">
      <GenerateLoaderOverlay
        isOpen={loadingIndex !== undefined}
        finished={generateMutation.isSuccess}
        error={generateMutation.isError}
        onRetry={handleRetry}
      />
      <ArrowLeft
        onClick={handleGoBack}
        className="absolute left-4 top-4 cursor-pointer"
      />
      <div className="absolute right-2 top-2">
        <Button text="SKIP" onClick={handleSkip} variant="ghost" />
      </div>
      <div className="flex w-full justify-center gap-4 border-b-2 border-b-gray-200 p-6">
        <div className="flex w-full max-w-4xl flex-col items-center">
          <p className=" text-gray-400">CREATE YOUR FIRST CONTENT</p>
          <h1 className="w-96 text-center text-3xl font-extrabold text-black">
            Choose a title for your content
          </h1>
          <p className="w-[32rem] text-center  text-gray-400">
            Generated titles based on "
            <b className="text-emerald-400">{keyword}</b>"
          </p>
        </div>
      </div>
      <div className="mt-10 flex flex-grow justify-center bg-gray-50 px-12">
        <div className="w-full max-w-4xl p-4">
          <table className="w-full border-separate ">
            <thead>
              <tr className="text-left text-sm uppercase">
                <th className="border-input-border-light-grey border-b py-3 pr-2 text-left font-work font-normal">
                  Title suggestions
                </th>
                <th className=" border-input-border-light-grey border-b py-3 pr-16 text-right font-work font-normal">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {titleSuggestionMutation.isPending ? (
                <>
                  <SkeletonRow />
                  <SkeletonRow />
                  <SkeletonRow />
                </>
              ) : (
                titleSuggestionMutation.data?.data.map((suggestion, index) => (
                  <tr className="animate-fade-slow border-b font-bold">
                    <td colSpan={2}>
                      <div className="mt-1 flex items-center justify-between rounded-md border border-gray-200 bg-white px-4 py-2 shadow-lg shadow-gray-200">
                        {suggestion.title}

                        <Button
                          isLoading={
                            loadingIndex !== undefined && loadingIndex === index
                          }
                          onClick={() =>
                            handleSelectTitle(suggestion.title, index)
                          }
                          color="secondary"
                          text="START WRITING"
                          prependIcon={Stars}
                        />
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>

          {titleSuggestionMutation.isSuccess && (
            <div className="mt-4 flex w-full flex-col items-center gap-2">
              <p className="text-sm text-gray-600">
                Not happy with these titles?
              </p>
              <Button
                text="Generate new suggestions"
                variant="outline"
                onClick={handleNewSuggestions}
              />
            </div>
          )}
          {titleSuggestionMutation.isError &&
            (titleSuggestionMutation.error.status === 400 ? (
              <div className="mt-4 flex w-full flex-col items-center gap-2">
                <p>The chosen keyword is not allowed</p>
                <Button
                  variant="outline"
                  text="Choose a new keyword"
                  onClick={handleGoBack}
                />
              </div>
            ) : (
              <div className="mt-4 flex w-full flex-col items-center gap-2">
                <p>Something went wrong</p>
                <Button
                  text="Try again"
                  variant="outline"
                  onClick={handleNewSuggestions}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
