import React, { useState } from 'react';
import ValidationErrors from '@/Components/ValidationErrors';
import Label from '@/Components/Label';
import SimpleInput from '@/Components/SimpleInput';
import { Button } from '@/Components/v2/Button';
import { useAuthSignUp } from '@/api/openapiComponents';

type FormValues = {
  email: string;
};

export const SignUpWidget = () => {
  const initialValues: FormValues = {
    email: '',
  };

  const [formValues, setFormValues] = useState(initialValues);

  const signUpMutation = useAuthSignUp();

  const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    signUpMutation.mutate({ body: formValues });
  };

  return (
    <div className="p-2">
      <ValidationErrors
        errors={signUpMutation.error?.errors}
        apiError={signUpMutation.error}
      />

      <form onSubmit={submit}>
        {/* Google sign in */}
        <div className="mt-4 flex items-center justify-center">
          <a
            className="px-15 w-full rounded-3xl border border-zinc-300 py-3 text-center  font-bold leading-tight text-neutral-500 hover:border-zinc-400"
            href={'auth/google/redirect'}
          >
            <img
              className="inline-block h-[20px] pr-3"
              src="/svg/google-icon.svg"
            />
            SIGN UP WITH GOOGLE
          </a>
        </div>

        {/* Divider */}
        <div className="my-8 flex w-full items-center">
          <div className="h-[1px] w-full" />
          <p className="mx-2">or</p>
          <div className="h-[1px] w-full" />
        </div>

        {/* Email input */}
        <div className="mb-6">
          <Label
            forInput="domain"
            className="text-signup-header font-extrabold"
          >
            Email address (work e-mail) <span className="text-red-600">*</span>
          </Label>

          <SimpleInput
            type="text"
            name="email"
            autoComplete="email"
            placeholder="you@example.com"
            value={formValues.email}
            onChange={onHandleChange}
            className={'mt-1 block w-full'}
          />
        </div>

        {/* Submit */}
        <Button
          isLoading={signUpMutation.isPending}
          color="secondary"
          fullWidth
          type="submit"
          text="Sign up"
        />

        <div className="mt-5 flex items-center justify-center">
          <div className="mr-1 ">Already have an account?</div>
          <a
            className=" underline"
            target="_blank"
            href={'https://app.seo.ai/login'}
            rel="noreferrer"
          >
            Sign in here
          </a>
          <div className="">.</div>
        </div>
        {signUpMutation.isSuccess && (
          <>
            <h2 className="my-3 text-center font-work  font-bold leading-normal text-gray-600">
              Check your email
            </h2>

            <p className="text-center  text-gray-600">
              If you haven't received the email within a few minutes, please
              check your spam or junk folder.
            </p>
          </>
        )}
      </form>
    </div>
  );
};
