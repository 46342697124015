import { useDebounce } from '@/Hooks/useDebounce';
import { useUpdateDocument } from '@/api/openapiComponents';
import { DocumentContextType, DocumentResource } from '@/api/openapiSchemas';

export const useSaveSettingsListener = (
  document: DocumentResource,
  projectId: number,
  brief: string,
  type: string,
  audience: string,
  tone: string,
) => {
  const { mutate: saveSettings } = useUpdateDocument();
  useDebounce(
    () => {
      saveSettings({
        pathParams: {
          document: document.id,
          project: projectId,
        },
        body: {
          settings: {
            target_audience: audience,
            tone_of_voice: tone,
            context: {
              type: document.settings.context.type as DocumentContextType,
              type_value: document.settings.context.type_value,
              value: brief,
            },
            type,
          },
        },
      });
    },
    [brief, type, audience, tone],
    200,
  );
};

export const useSaveInternalNameListener = (
  document: DocumentResource,
  projectId: number,
  internalName: string,
) => {
  const { mutate: saveSettings } = useUpdateDocument();
  useDebounce(
    () => {
      saveSettings({
        pathParams: {
          document: document.id,
          project: projectId,
        },
        body: {
          name: internalName,
        },
      });
    },
    [internalName],
    200,
  );
};

export const useSaveContextListener = (
  document: DocumentResource,
  projectId: number,
  brief: string,
  url?: string,
) => {
  const { mutate: saveSettings } = useUpdateDocument();
  useDebounce(
    () => {
      saveSettings({
        pathParams: {
          document: document.id,
          project: projectId,
        },
        body: {
          settings: {
            context: {
              value: brief,
              type: url ? 'url' : 'none',
              type_value: url ?? null,
            },
          },
        },
      });
    },
    [brief, url],
    400,
  );
};
