import * as React from 'react';
import BeatLoader from '@/Components/BeatLoader';
import { Pencil, SendHorizontal } from 'lucide-react';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { Button } from '@/Components/v2/Button';
import { TextArea } from '@/Components/v2/TextArea/TextArea';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

type Props = {
  text: string;
  id: number;
  isLoading?: boolean;
  disabled?: boolean;
  selectedText?: string;
  onSubmitEdit: (messageId: number, text: string) => void;
};

export function HumanMessageV2({
  text,
  id,
  isLoading,
  disabled,
  selectedText,
  onSubmitEdit,
}: Props) {
  const [isEditing, setIsEditing] = React.useState(false);
  const [editedMessageValue, setEditedMessageValue] = React.useState('');

  const handleEdit = () => {
    setIsEditing(true);
    setEditedMessageValue(text);
  };

  const handleSubmitEdit = () => {
    onSubmitEdit(id, editedMessageValue);
    setIsEditing(false);
  };

  return (
    <div className="group pl-16">
      <div className="rounded-xl bg-primary-200">
        {selectedText && (
          <div className="p-2">
            <div className="rounded-lg bg-primary-50 p-2">
              <p className="font-bold">Seleced text</p>
              <div className="max-h-48 overflow-y-auto">
                <ReactMarkdown
                  remarkPlugins={[remarkGfm]}
                  className="prose text-base"
                >
                  {selectedText}
                </ReactMarkdown>
              </div>
            </div>
          </div>
        )}
        {isEditing ? (
          <div className="relative p-2">
            <TextArea
              value={editedMessageValue}
              rows={5}
              maxHeight={15}
              resize
              onChange={(value) => setEditedMessageValue(value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  if (e.shiftKey) {
                    return;
                  }
                  e.preventDefault();

                  handleSubmitEdit();
                }
              }}
              appendIcon={
                <IconButton
                  icon={SendHorizontal}
                  onClick={handleSubmitEdit}
                  color="secondary"
                />
              }
            />
          </div>
        ) : (
          <p className="whitespace-pre-wrap px-6 pt-6">
            {text}
            {isLoading && (
              <BeatLoader
                className="mt-6"
                size={'0.5rem'}
                color="rgb(74 222 128 / 1)"
              />
            )}
          </p>
        )}
        <div className="flex justify-end pb-2 pr-2 opacity-0 group-hover:opacity-100">
          <Button
            size="sm"
            color="primary"
            onClick={handleEdit}
            disabled={disabled}
            text="Edit"
            prependIcon={Pencil}
            variant="ghost"
            dense
          />
        </div>
      </div>
    </div>
  );
}
