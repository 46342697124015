import { Button } from '@/Components/v2/Button';

type Props = {
  type: string;
  onClick: () => void;
};

export const NoYourElement = ({ type, onClick }: Props) => {
  return (
    <div className="flex flex-col gap-2">
      <p className="text-center text-primary-600">You do not have {type}</p>
      <div className="flex justify-center">
        <Button
          dense
          size="sm"
          text="Get ideas"
          variant="outline"
          onClick={onClick}
        />
      </div>
    </div>
  );
};
