import {
  useDocumentExport,
  useGetCmsConnection,
  useProjectsConnectionsIndex,
} from '@/api/openapiComponents';
import { PopoverMenu } from '@/Components/v2/Menu/ButtonMenu';
import {
  GoogleDocsReconnectDialog,
  GoogleDocsStatusDialog,
} from './GoogleDocsDialogs';
import { PublishDocumentDialog } from './PublishDocumentDialog';
import { WordPressDialog } from './WordPressDialog';
import {
  CmsConnectionResource,
  ConnectionType,
  DocumentResource,
} from '@/api/openapiSchemas';
import { useGoogleDocsDialog, useWordPressDialog } from '../hooks';
import { tracking } from '@/Services/tracking/Tracking';
import { createExportOption, plateToHTML } from '../utils';
import { Icon } from '@/Components/v2/Icon';
import {
  faGoogleDrive,
  faMarkdown,
  faShopify,
  faWordpress,
} from '@fortawesome/free-brands-svg-icons';
import { useState } from 'react';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { ExportOptions, BlockType, LeafType } from '@/types';
import { faFile } from '@fortawesome/free-regular-svg-icons';
import { Files } from 'lucide-react';
import { serializeToMarkdown } from './PlateEditor/lib/serialize';
import { useDocumentStore } from '../stores';
import { useShallow } from 'zustand/react/shallow';
import { useEditor } from '@/Components/Utils/v2/api';
import copyToClipboard from 'copy-to-clipboard';
import { useQuery } from '@tanstack/react-query';
import { Button } from '@/Components/v2/Button';
import { VerticalMenuItem } from '@/Components/v2/Menu/VerticalMenu';
import { ShopifyExportDialog } from './ShopifyExportDialog/ShopifyExportDialog';
import { ShopifySetupDialog } from '@/Pages/Settings/Connections/ShopifySetupDialog';
import { useFeatureFlagging } from '@/Pages/AppLoader';

type Props = {
  document: DocumentResource;
  refetchDocument: () => void;
};

export const DocumentExports = ({ document, refetchDocument }: Props) => {
  const appState = useAppStore();
  const editor = useEditor();

  const { heading, projectUrl } = useDocumentStore(
    useShallow(({ heading, projectUrl }) => ({
      heading,
      projectUrl,
    })),
  );

  const { hasFeature } = useFeatureFlagging();

  const { data: isGenerating } = useQuery<boolean>({
    queryKey: ['autoGenerationStream'],
  });

  const [showCreateShopifyDialog, setShowCreateShopifyDialog] = useState(false);
  const [showShopifyExportDialog, setShowShopifyExportDialog] = useState(false);
  const [openExportMenu, setOpenExportMenu] = useState(false);
  const [publishDocumentDialog, setPublishDocumentDialog] =
    useState<ExportOptions>({
      isOpen: false,
      type: 'wordpress',
    });

  const cmsConnectionQuery = useGetCmsConnection({
    pathParams: {
      project: appState.currentProject!.id,
    },
  });

  const { data: currentConnections, refetch: handleRefreshConnections } =
    useProjectsConnectionsIndex({
      pathParams: {
        project: appState.currentProject!.id,
      },
    });

  const getPlateToHTML = () => {
    return `<h1>${heading}</h1>\n\n${plateToHTML(
      editor!.children as (BlockType | LeafType)[],
    )}`;
  };

  const {
    mutate: publishDocument,
    isPending: isPublishingDocument,
    isSuccess: isDocumentPublished,
    isError: isPublishingError,
    error: publishError,
    reset: handleResetPublishingDocument,
    data: publishedData,
  } = useDocumentExport();

  const {
    handleWordPressDialog,
    wordPressDialog,
    handleChangeWordPress,
    wordPressSettings,
    submitWordPressConnection,
    handleEditConnection,
    errors,
    isEstablishingConnection,
    handleAddConnection,
  } = useWordPressDialog(
    isPublishingDocument,
    {
      isPublishingError,
      publishError,
    },
    currentConnections,
    handleRefreshConnections,
  );

  const {
    handleCreateGoogleDocConnection,
    googleDocsMode,
    handleOpenReconnectDialog,
    reconnectGoogleDialog,
    handleCloseGoogleDocsStatusDialog,
    statusSettings,
  } = useGoogleDocsDialog(currentConnections);

  const handleErrorAction = () => {
    switch (publishDocumentDialog.type) {
      case 'wordpress':
        return handleWordPressDialog;
      case 'google_docs':
        return handleCreateGoogleDocConnection;
      default:
        return handleCreateGoogleDocConnection;
    }
  };

  const wordPressExportOption = createExportOption({
    title: 'Publish to WordPress',
    icon: <Icon icon={faWordpress} />,
    mode: wordPressSettings.mode,
    publishingDocument: isPublishingDocument,
    handleEditConnection,
    handleAddConnection: () => {
      handleAddConnection();
    },
    handlePublishDocument: () => {
      submitPublishDocument('wordpress');
    },
  });
  const googleDocsExportOption = createExportOption({
    title: 'Publish to Google Docs',
    icon: <Icon icon={faGoogleDrive} />,
    publishingDocument: isPublishingDocument,
    mode: googleDocsMode,
    handleAddConnection: handleCreateGoogleDocConnection,
    handleEditConnection: handleOpenReconnectDialog,
    handlePublishDocument: () => {
      submitPublishDocument('google_docs');
    },
  });
  const shopifyExportOption = createExportOption({
    title: 'Export to Shopify',
    icon: <Icon icon={faShopify} />,
    disabled: projectUrl && !projectUrl.cms.has_reference,
    mode:
      cmsConnectionQuery.data?.data?.type === 'shopify'
        ? 'EDIT_CONNECTION'
        : 'ADD_CONNECTION',
    publishingDocument: isPublishingDocument,
    handleAddConnection: () => {
      cmsConnectionQuery.refetch();
      setShowCreateShopifyDialog(true);
    },
    handlePublishDocument: () => {
      cmsConnectionQuery.refetch();
      setShowShopifyExportDialog(true);
    },
  });

  const exportOptions: VerticalMenuItem[] = [
    {
      title: 'Copy all content',
      onClick: () => {
        tracking.event('copy_editor_content', { content_type: 'default' });
        copyToClipboard(' ', {
          onCopy: (dataTransfer) => {
            const data = dataTransfer as DataTransfer;
            if (!data) return;

            const html = getPlateToHTML();
            data.setData('text/html', html);
          },
        });
      },
      prependIcon: <Icon icon={faFile} />,
    },
    {
      title: 'Copy to markdown',
      onClick: () => {
        tracking.event('copy_editor_content', { content_type: 'markdown' });
        navigator.clipboard.writeText(
          `# ${heading}\n` + serializeToMarkdown(editor?.children),
        );
      },
      prependIcon: <Icon icon={faMarkdown} />,
    },
    wordPressExportOption,
    googleDocsExportOption,
    ...(hasFeature('shopify-cms-integration') ? [shopifyExportOption] : []),
  ];

  const submitPublishDocument = (type: ConnectionType) => {
    handleResetPublishingDocument();
    setPublishDocumentDialog((prev) => ({ ...prev, isOpen: true, type }));
    publishDocument({
      pathParams: {
        project: appState.currentProject!.id,
        document: document.id,
      },

      body: {
        connection_id: currentConnections!.data.find(
          (connection) => connection.type === type,
        )!.id,
      },
    });
  };

  return (
    <>
      <ShopifySetupDialog
        isOpen={showCreateShopifyDialog}
        onClose={() => setShowCreateShopifyDialog(false)}
        onSuccess={() => {
          handleRefreshConnections();
          setShowShopifyExportDialog(true);
        }}
      />
      <ShopifyExportDialog
        key={'shopify-export-dialog' + showShopifyExportDialog}
        documents={[{ ...document, project_url: projectUrl }]}
        onClose={() => setShowShopifyExportDialog(false)}
        isOpen={showShopifyExportDialog}
        cmsConnection={cmsConnectionQuery.data?.data as CmsConnectionResource}
        onSuccess={refetchDocument}
      />
      <GoogleDocsReconnectDialog
        handleCreateGoogleDocConnection={handleCreateGoogleDocConnection}
        handleOpenReconnectDialog={handleOpenReconnectDialog}
        reconnectGoogleDialog={reconnectGoogleDialog}
      />
      <GoogleDocsStatusDialog
        statusSettings={statusSettings}
        handleCloseGoogleDocsStatusDialog={handleCloseGoogleDocsStatusDialog}
        handleTryAgain={handleCreateGoogleDocConnection}
        handleExportDocument={() => submitPublishDocument('google_docs')}
      />
      <PublishDocumentDialog
        publishDocumentDialog={publishDocumentDialog}
        documentIsPublished={isDocumentPublished}
        handleErrorAction={handleErrorAction()}
        isPublishingError={isPublishingError}
        setPublishDocumentDialog={setPublishDocumentDialog}
        publishingDocument={isPublishingDocument}
        publishedData={publishedData}
        document={document}
        errors={errors}
      />
      <WordPressDialog
        errors={errors}
        handleChangeWordPress={handleChangeWordPress}
        handleWordPressDialog={handleWordPressDialog}
        isEstablishingConnection={isEstablishingConnection}
        submitWordPressConnection={submitWordPressConnection}
        wordPressDialog={wordPressDialog}
        wordPressSettings={wordPressSettings}
      />
      <PopoverMenu
        type="hover"
        isOpen={openExportMenu}
        items={exportOptions}
        disabled={!!isGenerating}
        onOpen={() => {
          setOpenExportMenu(true);
        }}
        onClose={() => setOpenExportMenu(false)}
        trigger={
          <Button
            size="sm"
            text="EXPORT"
            variant="ghost"
            disabled={!!isGenerating}
            appendIcon={<Files size={20} className="text-gray-500" />}
          />
        }
      />
    </>
  );
};
