import React from 'react';

type Props = {
  name?: string;
  value: boolean;
  required?: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};
export default function Checkbox({
  name,
  value,
  required,
  handleChange,
}: Props) {
  return (
    <input
      type="checkbox"
      name={name}
      value={value}
      required={required}
      className="border-input-border-light-grey text-dark-green focus:border-dark-green focus:ring-dark-green rounded shadow-sm focus:ring focus:ring-opacity-50"
      onChange={(e) => handleChange(e)}
    />
  );
}
