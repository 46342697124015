
export const NoQuestions = () => {
  return (
    <div className="mt-16 flex flex-col items-center justify-center gap-8">
      <h1 className="text-md font-work font-extrabold text-gray-700">
        NO QUESTIONS IN THE SERP
      </h1>
      <svg
        width="128"
        height="125"
        viewBox="0 0 128 125"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_7533_217)">
          <path
            d="M81.2569 28.1102H46.7665C45.9805 28.1112 45.227 28.424 44.6712 28.98C44.1155 29.536 43.8028 30.2899 43.8019 31.0762V107.766L43.4066 107.887L34.9455 110.479C34.5445 110.601 34.1114 110.559 33.7413 110.362C33.3711 110.166 33.0941 109.83 32.9711 109.429L7.80337 27.1829C7.68089 26.7817 7.72263 26.3483 7.91943 25.9779C8.11622 25.6075 8.45196 25.3304 8.85286 25.2076L21.8913 21.2134L59.6903 9.63826L72.7287 5.64411C72.9271 5.58301 73.1357 5.56166 73.3423 5.58128C73.549 5.6009 73.7498 5.6611 73.9332 5.75844C74.1166 5.85578 74.279 5.98834 74.4111 6.14855C74.5432 6.30876 74.6425 6.49345 74.7032 6.69208L81.1364 27.7148L81.2569 28.1102Z"
            fill="#F2F2F2"
          />
          <path
            d="M88.7847 27.715L81.0312 2.37785C80.9022 1.95568 80.6914 1.56308 80.4106 1.22249C80.1299 0.881892 79.7849 0.599975 79.3952 0.392844C79.0055 0.185713 78.5789 0.0574262 78.1397 0.0153198C77.7004 -0.0267867 77.2572 0.0181121 76.8353 0.147446L58.504 5.76101L20.7071 17.3381L2.37578 22.9537C1.52421 23.2153 0.81117 23.8042 0.393185 24.5913C-0.0247994 25.3783 -0.113585 26.2991 0.14632 27.1515L26.6462 113.744C26.8573 114.432 27.2833 115.034 27.8617 115.462C28.4401 115.89 29.1404 116.122 29.8599 116.122C30.1929 116.122 30.524 116.072 30.8421 115.974L43.4082 112.126L43.8034 112.004V111.59L43.4082 111.711L30.7255 115.596C29.9738 115.826 29.162 115.747 28.468 115.378C27.774 115.01 27.2546 114.38 27.0237 113.629L0.525874 27.0348C0.411457 26.6625 0.371579 26.2712 0.408524 25.8834C0.445468 25.4957 0.558505 25.119 0.74117 24.775C0.923834 24.4309 1.17254 24.1264 1.47303 23.8786C1.77353 23.6309 2.11991 23.4449 2.49235 23.3313L20.8236 17.7158L58.6207 6.14066L76.952 0.525115C77.2345 0.438865 77.5282 0.394887 77.8235 0.394612C78.4574 0.396036 79.0741 0.60048 79.5834 0.978001C80.0928 1.35552 80.4679 1.88628 80.6538 2.49253L88.3717 27.715L88.4942 28.1105H88.9053L88.7847 27.715Z"
            fill="#3F3D56"
          />
          <path
            d="M24.2475 25.2745C23.8666 25.2742 23.4958 25.1518 23.1894 24.9252C22.8831 24.6987 22.6575 24.3798 22.5455 24.0156L19.9998 15.6967C19.9314 15.4732 19.9077 15.2385 19.93 15.0058C19.9523 14.7732 20.0202 14.5472 20.1297 14.3409C20.2393 14.1345 20.3885 13.9517 20.5687 13.803C20.7489 13.6543 20.9566 13.5425 21.18 13.4742L55.9529 2.82365C56.404 2.68594 56.8913 2.73291 57.3078 2.95425C57.7243 3.17559 58.036 3.55321 58.1746 4.00424L60.7203 12.3232C60.8578 12.7745 60.8108 13.262 60.5896 13.6786C60.3684 14.0953 59.991 14.4072 59.5402 14.5458L24.7672 25.1964C24.5988 25.2481 24.4236 25.2744 24.2475 25.2745Z"
            fill="#6C63FF"
          />
          <path
            d="M37.5856 8.88969C39.7687 8.88969 41.5385 7.11915 41.5385 4.93508C41.5385 2.75101 39.7687 0.980469 37.5856 0.980469C35.4026 0.980469 33.6328 2.75101 33.6328 4.93508C33.6328 7.11915 35.4026 8.88969 37.5856 8.88969Z"
            fill="#6C63FF"
          />
          <path
            d="M37.5812 7.43805C38.9636 7.43805 40.0842 6.31689 40.0842 4.93387C40.0842 3.55085 38.9636 2.42969 37.5812 2.42969C36.1988 2.42969 35.0781 3.55085 35.0781 4.93387C35.0781 6.31689 36.1988 7.43805 37.5812 7.43805Z"
            fill="white"
          />
          <path
            d="M119.108 115.114H52.305C51.8596 115.114 51.4326 114.936 51.1176 114.621C50.8027 114.306 50.6255 113.879 50.625 113.433V33.3526C50.6255 32.907 50.8027 32.4798 51.1176 32.1647C51.4325 31.8496 51.8596 31.6724 52.305 31.6719H119.108C119.553 31.6724 119.98 31.8496 120.295 32.1647C120.61 32.4798 120.787 32.907 120.788 33.3526V113.433C120.787 113.879 120.61 114.306 120.295 114.621C119.98 114.936 119.553 115.114 119.108 115.114Z"
            fill="#E6E6E6"
          />
          <path
            d="M88.3698 27.7148H46.7662C45.8754 27.7161 45.0216 28.0707 44.3917 28.7008C43.7619 29.3309 43.4075 30.1851 43.4062 31.0763V111.711L43.8015 111.59V31.0763C43.8025 30.2899 44.1151 29.5361 44.6709 28.9801C45.2267 28.4241 45.9802 28.1113 46.7662 28.1103H88.4923L88.3698 27.7148ZM124.637 27.7148H46.7662C45.8754 27.7161 45.0216 28.0707 44.3917 28.7008C43.7619 29.3309 43.4075 30.1851 43.4062 31.0763V121.637C43.4075 122.528 43.7619 123.382 44.3917 124.012C45.0216 124.642 45.8754 124.997 46.7662 124.998H124.637C125.528 124.997 126.382 124.642 127.011 124.012C127.641 123.382 127.996 122.528 127.997 121.637V31.0763C127.996 30.1851 127.641 29.3309 127.011 28.7008C126.382 28.0707 125.528 27.7161 124.637 27.7148ZM127.602 121.637C127.601 122.423 127.288 123.177 126.732 123.733C126.177 124.289 125.423 124.602 124.637 124.603H46.7662C45.9802 124.602 45.2267 124.289 44.6709 123.733C44.1151 123.177 43.8025 122.423 43.8015 121.637V31.0763C43.8025 30.2899 44.1151 29.5361 44.6709 28.9801C45.2267 28.4241 45.9802 28.1113 46.7662 28.1103H124.637C125.423 28.1113 126.177 28.4241 126.732 28.9801C127.288 29.5361 127.601 30.2899 127.602 31.0763V121.637Z"
            fill="#3F3D56"
          />
          <path
            d="M103.887 36.4155H67.521C67.0494 36.415 66.5972 36.2273 66.2638 35.8937C65.9303 35.5601 65.7427 35.1078 65.7422 34.636V25.9358C65.7427 25.464 65.9303 25.0117 66.2638 24.6781C66.5972 24.3444 67.0494 24.1568 67.521 24.1562H103.887C104.359 24.1568 104.811 24.3444 105.144 24.6781C105.478 25.0117 105.665 25.464 105.666 25.9358V34.636C105.665 35.1078 105.478 35.5601 105.144 35.8937C104.811 36.2273 104.359 36.415 103.887 36.4155Z"
            fill="#6C63FF"
          />
          <path
            d="M85.7028 24.7491C87.8859 24.7491 89.6557 22.9785 89.6557 20.7945C89.6557 18.6104 87.8859 16.8398 85.7028 16.8398C83.5197 16.8398 81.75 18.6104 81.75 20.7945C81.75 22.9785 83.5197 24.7491 85.7028 24.7491Z"
            fill="#6C63FF"
          />
          <path
            d="M85.7045 23.2042C87.0342 23.2042 88.1122 22.1257 88.1122 20.7954C88.1122 19.4651 87.0342 18.3867 85.7045 18.3867C84.3748 18.3867 83.2969 19.4651 83.2969 20.7954C83.2969 22.1257 84.3748 23.2042 85.7045 23.2042Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_7533_217">
            <rect width="128" height="125" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
