import { Dialog } from '@/Components/Dialog';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import {
  CustomFieldDefinitionResource,
  DocumentElementActionType,
  DocumentElementResource,
  DocumentResource,
  HeadingType,
} from '@/api/openapiSchemas';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Pencil } from 'lucide-react';
import { TemplateItemInputs } from './TemplateItemInputs';
import { useState } from 'react';
import { TestPromptSection } from './TestPromptSection';
import { TemplateItemLayout } from './TemplateItemLayout';
import { Autocomplete } from '@/Components/v2/Select/AutoComplete';
import { useListCustomFieldDefinitions } from '@/api/openapiComponents';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { DropdownItem } from '@/Components/v2/Select/hooks';
import { Select } from '@/Components/v2/Select';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';

type Props = {
  el: DocumentElementResource & { uniqueId: string };
  onChange: (
    type: DocumentElementActionType,
    el: DocumentElementResource & { uniqueId: string },
    value: string | CustomFieldDefinitionResource,
    property?: 'subheading' | 'display_name' | 'custom_field',
  ) => void;
  onDelete: (el: DocumentElementResource & { uniqueId: string }) => void;
  onSelect: (
    el: DocumentElementResource & { uniqueId: string },
    value: HeadingType,
  ) => void;
  removeDrag?: boolean;
  testPromptDialog: {
    document?: DocumentResource;
    uniqueId: string;
    preview: string;
  };
  onCloseDialog: () => void;
  onAddPreview: (preview: string) => void;
  onOpenDialog: (uniqueId: string) => void;
  onAddDocumentId: (document: DocumentResource | undefined) => void;
};

export const TemplateItem = ({
  el,
  onChange,
  onDelete,
  onSelect,
  removeDrag,
  testPromptDialog,
  onAddPreview,
  onCloseDialog,
  onOpenDialog,
  onAddDocumentId,
}: Props) => {
  const appState = useAppStore();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: 'movable ' + el.uniqueId,
      data: { el },
      disabled: removeDrag,
    });

  const [isEditing, setIsEditing] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const isOpen = testPromptDialog.uniqueId === el.uniqueId;

  const style = !removeDrag
    ? {
        transform: CSS.Transform.toString(transform),
        transition,
      }
    : {};

  const customFieldQuery = useListCustomFieldDefinitions({
    pathParams: {
      project: appState.currentProject!.id,
    },
    queryParams: {
      limit: 100,
    },
  });

  return (
    <>
      <Dialog
        dense
        isOpen={isOpen}
        title={
          <div className="mt-5 flex w-full items-center gap-2 px-7 pr-4 text-lg font-bold">
            {isEditing ? (
              <input
                onClick={(e) => e.stopPropagation()}
                type="text"
                autoFocus
                value={el.values?.display_name ?? ''}
                placeholder={el.display_name}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setIsEditing(false);
                  }
                }}
                onBlur={() => setIsEditing(false)}
                onChange={(e) =>
                  onChange(el.action_type, el, e.target.value, 'display_name')
                }
                className="w-full border-none px-0 py-0.5 placeholder:text-primary hover:bg-primary-50 focus:bg-primary-50"
              />
            ) : (
              <>
                {el.values?.display_name
                  ? el.values.display_name
                  : el.display_name}
                <IconButton
                  icon={Pencil}
                  onClick={() => setIsEditing(true)}
                  dense
                />
              </>
            )}
          </div>
        }
        handleClose={onCloseDialog}
      >
        <div className="bg-primary-50">
          <div className="mt-5 border-b bg-white px-7 pb-12">
            <TemplateItemInputs
              onSelect={onSelect}
              el={el}
              onChange={onChange}
            />
          </div>
          {(customFieldQuery.data?.data.length ?? 0) > 0 && (
            <div className="px-7 pt-4">
              <InputDecoration label="Custom field">
                <Select
                  clearable
                  value={{
                    value: el.values?.custom_field?.id,
                    title: el.values?.custom_field?.name,
                  }}
                  options={
                    customFieldQuery.data?.data.map((el) => ({
                      value: el.id,
                      title: el.name,
                    })) ?? []
                  }
                  onChange={(value) => {
                    const field = customFieldQuery.data?.data.find(
                      (field) => field.id === value,
                    );
                    onChange(el.action_type, el, field, 'custom_field');
                  }}
                />
              </InputDecoration>
            </div>
          )}
          <TestPromptSection
            el={el}
            onAddDocumentId={onAddDocumentId}
            onAddPreview={onAddPreview}
            testPromptDialog={testPromptDialog}
          />
        </div>
      </Dialog>

      <div
        style={style}
        className="relative w-full cursor-grab rounded-md border bg-white px-2 py-2 shadow-base-small"
        ref={setNodeRef}
        {...attributes}
        {...listeners}
        onClick={() => onOpenDialog(el.uniqueId)}
        onMouseEnter={(e) => {
          e.stopPropagation();
          setIsHovering(true);
        }}
        onMouseLeave={(e) => {
          e.stopPropagation();
          setIsHovering(false);
        }}
      >
        <TemplateItemLayout
          el={el}
          onDelete={onDelete}
          onOpenDialog={onOpenDialog}
          isHovering={isHovering}
          removeDrag={removeDrag}
        />
      </div>
    </>
  );
};
