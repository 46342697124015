import { createRoute } from '@tanstack/react-router';
import { rootRoute } from '../Router';
import {
  guards,
  hasActiveSubscription,
  hasFeature,
  hasProject,
  isAuthenticated,
} from '../Router/guards';
import { validateTanStackSearch } from '@/utils';
import { KeywordScannner } from './KeywordScanner';

export const routes = [
  createRoute({
    getParentRoute: () => rootRoute,
    validateSearch: validateTanStackSearch,
    beforeLoad: ({ context }) => {
      guards(context, [
        isAuthenticated,
        hasProject,
        hasActiveSubscription,
        (context) => hasFeature(context, undefined, 'keyword-scanner'),
      ]);
    },
    path: '/keyword-clustering',
    component: () => <KeywordScannner />,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    validateSearch: validateTanStackSearch,
    beforeLoad: ({ context }) => {
      guards(context, [
        isAuthenticated,
        hasProject,
        hasActiveSubscription,
        (context) => hasFeature(context, undefined, 'keyword-scanner'),
      ]);
    },
    path: '/keyword-clustering/v2',
    component: () => <KeywordScannner v2 />,
  }),
];
