import { PlateElement, PlateElementProps, Value } from '@udecode/plate-common';
import {
  ELEMENT_IMAGE,
  Image,
  TImageElement,
  useMediaState,
} from '@udecode/plate-media';
import { useResizableStore } from '@udecode/plate-resizable';

import { cn } from '../lib/utils';

import {
  MediaPopover,
  mediaResizeHandleVariants,
  Resizable,
  ResizeHandle,
} from '../components';
import { useState } from 'react';
import { ConfirmDialog } from '@/Components/ConfirmDialog';
import { createPortal } from 'react-dom';

export function ImageElement({
  className,
  children,
  nodeProps,
  ...props
}: PlateElementProps<Value, TImageElement>) {
  const { readOnly, focused, selected, align = 'center' } = useMediaState();
  const width = useResizableStore().get.width();

  const [showWarningDialog, setShowWarningDialog] = useState(true);

  const isImageUrl =
    props.element?.url?.startsWith('http://') ||
    props.element?.url?.startsWith('https://');

  if (!isImageUrl) {
    return createPortal(
      <ConfirmDialog
        title="Warning"
        description={[
          "It's not possible to paste raw images into the editor. Use the insert image button instead.",
        ]}
        noCancel
        confirmText="OK"
        isOpen={showWarningDialog}
        onConfirm={() => setShowWarningDialog(false)}
      />,
      document.body,
    );
  }

  return (
    <MediaPopover pluginKey={ELEMENT_IMAGE}>
      <PlateElement className={cn('py-2.5', className)} {...props}>
        <figure className="group relative m-0" contentEditable={false}>
          <Image
            className={cn(
              'block max-w-full cursor-pointer object-cover px-0 italic',
              'rounded-sm',
              'before:w-full before:bg-primary-100 before:px-2 before:py-6 before:italic before:text-primary',
            )}
            alt="The image failed to load :("
            {...nodeProps}
          />
        </figure>

        {children}
      </PlateElement>
    </MediaPopover>
  );
}
