import AppLabel, { Variant } from '@/Components/Labels/AppLabel';
import { PopoverMenu } from '@/Components/v2/Menu/ButtonMenu';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { useKeywordInjectionStore } from '@/Pages/Document/stores';
import { tracking } from '@/Services/tracking/Tracking';
import { useUpdateDocument } from '@/api/openapiComponents';
import {
  DocumentResource,
  KeywordTargetResource,
  ProjectResource,
} from '@/api/openapiSchemas';
import { CurrentSlideOver } from '@/types';
import { useQueryClient } from '@tanstack/react-query';
import { Check, Stars } from 'lucide-react';
import * as React from 'react';
import { useShallow } from 'zustand/react/shallow';

type Props = {
  setCurrentSlideOver: React.Dispatch<
    React.SetStateAction<CurrentSlideOver | undefined>
  >;
  keyword: KeywordTargetResource;
  keywordType: 'missing' | 'semantic' | 'secondary';
  document: DocumentResource;
  currentProject: ProjectResource;
  ignored?: boolean;
  disabled?: boolean;
  canIgnore?: boolean;
};
const KeywordLabel = ({
  keyword,
  setCurrentSlideOver,
  keywordType,
  currentProject,
  ignored,
  disabled,
  document,
  canIgnore = true,
}: Props) => {
  const {
    keyword: storeKeyword,
    keywordType: storeKeywordType,
    resetSlideOver,
    changeKeywordType,
  } = useKeywordInjectionStore(
    useShallow((state) => ({
      keyword: state.keyword,
      keywordType: state.keywordType,
      resetSlideOver: state.resetSlideOver,
      changeKeywordType: state.changeKeywordType,
    })),
  );

  const app = useAppStore();

  const client = useQueryClient();
  const updateDocumentMutation = useUpdateDocument();

  const handleIgnoreKeyword = () => {
    const newIgnoredKeywords = [
      ...(ignored
        ? (document.ignored_keywords ?? []).filter((k) => k !== keyword.keyword)
        : [...(document.ignored_keywords ?? []), keyword.keyword]),
    ];
    client.setQueriesData(
      {
        predicate: (query) => query.queryKey.includes('getDocument'),
      },
      (oldData: DocumentResource | undefined) =>
        ({
          ...oldData,
          data: {
            ...document,
            ignored_keywords: newIgnoredKeywords,
          },
        }) as any,
    );
    updateDocumentMutation.mutate(
      {
        pathParams: {
          document: document.id,
          project: currentProject.id,
        },
        body: {
          ignored_keywords: newIgnoredKeywords,
        },
      },
      {
        onSuccess: () => {
          client.refetchQueries({
            predicate: (query) => query.queryKey.includes('getDocument'),
          });
        },
      },
    );
  };

  return (
    <PopoverMenu
      type="hover"
      disabled={disabled}
      trigger={
        <AppLabel
          onClick={() => {}}
          disabled={keyword.is_completed || disabled}
          variant={
            ignored
              ? Variant.gray
              : keyword.is_completed
                ? Variant.green
                : Variant.red
          }
        >
          <div className={`flex ${ignored ? 'line-through' : ''}`}>
            {keyword.keyword}{' '}
            {keyword.missing_count > 1 && (
              <span className="opacity-60">
                &nbsp;(+{keyword.missing_count})
              </span>
            )}{' '}
            {keyword.is_completed && !ignored && (
              <Check className="ml-1" size={16} />
            )}
          </div>
        </AppLabel>
      }
      items={[
        {
          title: 'Suggest in text',
          onClick: () => {
            if (storeKeyword !== keyword.keyword) {
              resetSlideOver(keyword.keyword, keywordType);
            } else {
              if (storeKeywordType !== keywordType) {
                changeKeywordType(keywordType);
              }
            }
            tracking.event('keyword_injection_opened', {
              keyword: keyword.keyword,
              document: {
                id: document.id,
                word_count: document.document_report?.word_count.actual || 0,
              },
              language: app.currentProject?.language.language || 'en',
              keyword_type: keywordType,
            });
            setCurrentSlideOver('keyword-injection');
          },
        },
        ...(canIgnore
          ? [
              {
                disabled: disabled,
                title: ignored ? 'Add to scoring' : 'Remove from scoring',
                onClick: handleIgnoreKeyword,
              },
            ]
          : []),
      ]}
    ></PopoverMenu>
  );
};

KeywordLabel.displayName = 'KeywordLabel';

export { KeywordLabel };
