import { Subheading } from '../components/Subheading';
import { Outlet } from '@tanstack/react-router';

export const CustomFields = () => {
  return (
    <>
      <Subheading
        text="Lorem ipsum dollars sit amet and so on"
        link={{
          href: 'https://google.com',
          text: 'Read more',
        }}
      />
      <Outlet />
    </>
  );
};
