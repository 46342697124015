import { useState, useEffect } from 'react';
import Checkbox from '@/Components/Checkbox';
import { LanguageResource } from '@/api/openapiSchemas';
import LanguageComboboxV2 from '@/Components/Inputs/LanguageComboBoxV2';
import { Menus } from '@/Components/Menus';
import { useNavigate } from '@tanstack/react-router';
import { useAppStore } from './AppLoader/stores';
import SimpleTitle from '@/Components/SimpleTitle';
import PageContainer from '@/Components/PageContainer';
import { Errors } from '@/Components/Errors';
import { Button } from '@/Components/v2/Button';
import { Alert } from '@/Components/v2/Alert';
import { Input } from '@/Components/v2/Input/Input';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { AlertCircle } from 'lucide-react';
import { useLanguagesIndex, useProjectsStore } from '@/api/openapiComponents';

type FormValues = {
  domain: string;
  language?: LanguageResource;
  projectName: string;
};

export default function CreateProject() {
  const navigate = useNavigate();
  const appState = useAppStore();
  const [data, setData] = useState<FormValues>({
    domain: '',
    language: undefined,
    projectName: '',
  });
  const [showForceDomain, setShowForceDomain] = useState(false);
  const [forceDomain, setForceDomain] = useState(false);

  const createProjectMutation = useProjectsStore();

  const languageQuery = useLanguagesIndex({ queryParams: { limit: 500 } });
  const languages = languageQuery.data?.data ?? [];

  const isLoading =
    createProjectMutation.isPending || createProjectMutation.isSuccess;
  const errors = createProjectMutation.error;

  const handleChange = (name: keyof FormValues, value: any) => {
    if (name === 'domain' && data.domain === data.projectName) {
      const strippedValue =
        value.startsWith('http://') || value.startsWith('https://')
          ? value.replace(/^(http:\/\/|https:\/\/)/, '')
          : value;
      setData({ ...data, [name]: strippedValue, projectName: strippedValue });
    } else {
      setData({ ...data, [name]: value });
    }
  };

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    createProjectMutation.mutate(
      {
        body: {
          domain: data.domain,
          language_id: data.language?.id!,
          name: data.projectName,
          force: forceDomain,
        },
      },
      {
        onSuccess: (data) => {
          appState.handleCreateProject(data.data);
          appState.refreshSubscription().then(() => {
            navigate({ to: '/' });
          });
        },
      },
    );
    e.preventDefault();
    return false;
  };

  useEffect(() => {
    if (
      createProjectMutation.error?.errors?.domain?.includes(
        'The domain must be reachable.',
      )
    ) {
      setShowForceDomain(true);
    } else {
      setShowForceDomain(false);
      setForceDomain(false);
    }
  }, [createProjectMutation.error]);

  const canCreateProject =
    appState.subscription?.usage.credits.projects.is_allowed;

  appState.pageTitle('Create project');

  return (
    <Menus>
      <PageContainer>
        <SimpleTitle>Create website</SimpleTitle>

        {!canCreateProject && (
          <Alert
            title="You have reached the limit of websites you can create"
            color="red"
            icon={AlertCircle}
            actions={[
              {
                name: 'Upgrade plan',
                onClick: () => {
                  navigate({ to: '/account/subscription' });
                },
              },
            ]}
          />
        )}

        <div className="mb-7 mt-4 flex h-max w-full justify-center rounded-lg border border-gray-200 bg-white py-12">
          <div className="flex w-[480px] flex-wrap">
            <form onSubmit={submit} autoComplete="off" className="w-full">
              <div className="mb-8 w-full ">
                Launch your next SEO website and harness the power of artificial
                intelligence.
              </div>

              <div className="mb-6 w-full ">
                <InputDecoration required label="Domain or website URL path">
                  <Input
                    disabled={!canCreateProject}
                    onChange={(value) => handleChange('domain', value)}
                    value={data.domain}
                    placeholder="www.yoursite.com"
                  />
                </InputDecoration>
              </div>

              <div className="mb-6 w-full ">
                <InputDecoration required label="Website name">
                  <Input
                    disabled={!canCreateProject}
                    onChange={(value) => handleChange('projectName', value)}
                    value={data.projectName}
                    placeholder="Name of the website"
                  />
                </InputDecoration>
              </div>

              <InputDecoration required label="Language">
                <LanguageComboboxV2
                  disabled={!canCreateProject}
                  classNames="h-10"
                  placeholder="Choose language (Market)"
                  languages={languages}
                  selectedLanguage={data.language ?? null}
                  setSelectedLanguage={(language) =>
                    handleChange('language', language)
                  }
                />
              </InputDecoration>
              <div className="ml-1 mt-1 w-full text-sm">
                <a
                  href="https://docs.seo.ai/a-4-how-to-create-projects"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  Learn more about <u>website languages</u>
                </a>
              </div>

              <div className="mb-6 mt-6 w-full">
                <Errors error={errors} />
              </div>

              {showForceDomain && (
                <label className="mb-6 flex items-center gap-2 ">
                  <Checkbox
                    value={forceDomain}
                    handleChange={(e) => setForceDomain(e.target.checked)}
                  />
                  I accept that my domain can't be crawled
                </label>
              )}

              <div className="flex w-full justify-center">
                <Button
                  disabled={!canCreateProject}
                  text="Create Website"
                  type="submit"
                  isLoading={isLoading}
                  color="secondary"
                  variant="outline"
                />
              </div>
            </form>
          </div>
        </div>
      </PageContainer>
    </Menus>
  );
}
