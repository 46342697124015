import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';

export const TemplateLoader = () => (
  <div className="flex flex-col gap-2">
    <div className="w-1/3">
      <SkeletonLoader height="xs" round />
    </div>
    <SkeletonLoader height="xs" round />
    <SkeletonLoader height="xs" round />
    <SkeletonLoader height="xs" round />
    <SkeletonLoader height="xs" round />
    <SkeletonLoader height="xs" round />
    <SkeletonLoader height="xs" round />
    <SkeletonLoader height="xs" round />
    <SkeletonLoader height="xs" round />
    <SkeletonLoader height="xs" round />
  </div>
);
