import { SimpleCheckbox } from '@/Components/SimpleCheckbox';
import ToggleButton from '@/Components/ToggleButton';
import { useState } from 'react';
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Area,
  AreaChart,
  LineChart,
  Line,
} from 'recharts';
import { CustomGraphTooltip } from './CustomGraphTooltip';
import { useProjectTrackedKeywordGraph } from '@/api/openapiComponents';
import { useAppStore } from '@/Pages/AppLoader/stores';
import dayjs from 'dayjs';
import { Select } from '@/Components/v2/Select';

export const OverviewGraph = () => {
  const appState = useAppStore();

  const [chosenStacks, setChosenStacks] = useState({
    top3: true,
    top10: true,
    top20: true,
    top50: true,
    remaining: true,
  });
  const [chosenInterval, setChosenInterval] = useState<
    'daily' | 'weekly' | 'monthly' | 'yearly'
  >('daily');
  const [chosenGraph, setChosenGraph] = useState<'average' | 'stack'>('stack');
  const [chosenPeriod, setChosenPeriod] = useState<'1M' | '1Y' | '2Y' | '6M'>(
    '1M',
  );

  const graphDataQuery = useProjectTrackedKeywordGraph({
    pathParams: {
      project: appState.currentProject!.id,
    },
    queryParams: {
      range: 'P' + chosenPeriod,
      interval: 'P1' + chosenInterval.slice(0, 1).toUpperCase(),
    },
  });

  return (
    <div className="relative my-4 flex flex-col rounded-lg  border-gray-200">
      <div className="mb-4 flex items-center justify-between">
        <div className="flex flex-col">
          {/* Hide graphs */}
          <div className="flex gap-2">
            {chosenGraph === 'stack' && (
              <>
                {/* top 3 */}
                <SimpleCheckbox
                  color="top3"
                  checked={chosenStacks.top3}
                  label="0-3"
                  onChange={() =>
                    setChosenStacks({
                      ...chosenStacks,
                      top3: !chosenStacks.top3,
                    })
                  }
                />

                {/* top 10 */}
                <SimpleCheckbox
                  color="top10"
                  checked={chosenStacks.top10}
                  label="4-10"
                  onChange={() =>
                    setChosenStacks({
                      ...chosenStacks,
                      top10: !chosenStacks.top10,
                    })
                  }
                />

                {/* top 20 */}
                <SimpleCheckbox
                  color="top20"
                  checked={chosenStacks.top20}
                  label="11-20"
                  onChange={() =>
                    setChosenStacks({
                      ...chosenStacks,
                      top20: !chosenStacks.top20,
                    })
                  }
                />

                {/* top 50 */}
                <SimpleCheckbox
                  color="top50"
                  checked={chosenStacks.top50}
                  label="21-50"
                  onChange={() =>
                    setChosenStacks({
                      ...chosenStacks,
                      top50: !chosenStacks.top50,
                    })
                  }
                />

                {/* remaining */}
                <div className="flex">
                  <div className="flex items-center text-primary-600">
                    <SimpleCheckbox
                      color="remaining"
                      checked={chosenStacks.remaining}
                      onChange={() =>
                        setChosenStacks({
                          ...chosenStacks,
                          remaining: !chosenStacks.remaining,
                        })
                      }
                    />
                    51+
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="flex gap-2">
          <ToggleButton
            value={chosenPeriod}
            onChange={setChosenPeriod}
            options={[
              { displayName: '1M', value: '1M' },
              { displayName: '6M', value: '6M' },
              { displayName: '1Y', value: '1Y' },
              // { displayName: '2Y', value: '2Y' }, TODO: Add in 2 years
            ]}
          />
          <ToggleButton<'average' | 'stack'>
            value={chosenGraph}
            onChange={setChosenGraph}
            options={[
              { displayName: 'average', value: 'average' },
              { displayName: 'Distribution', value: 'stack' },
            ]}
          />
          <Select
            onChange={setChosenInterval}
            options={[
              {
                title: 'Daily',
                value: 'daily',
              },
              {
                title: 'Weekly',
                value: 'weekly',
              },
              {
                title: 'Monthly',
                value: 'monthly',
              },
              {
                title: 'Yearly',
                value: 'yearly',
              },
            ]}
            value={chosenInterval}
          />
        </div>
      </div>
      <div className="-mr-8 rounded-lg bg-white">
        {chosenGraph === 'average' && (
          <ResponsiveContainer width="100%" height={300}>
            <LineChart
              data={
                graphDataQuery.data?.data.filter(
                  (data) => data.average !== 0,
                ) ?? []
              }
            >
              <Tooltip
                isAnimationActive={false}
                position={{ y: 50 }}
                content={(row) => CustomGraphTooltip(row, true)}
              />
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                tickFormatter={(value) => dayjs(value).format('MMM D')}
              />
              <YAxis
                reversed
                allowDataOverflow
                domain={[0, 100]}
                orientation="right"
              />
              <Line
                animationDuration={300}
                type="linear"
                dataKey="average"
                stroke="#4D984D"
                strokeWidth={2}
              />
            </LineChart>
          </ResponsiveContainer>
        )}
        {chosenGraph === 'stack' && (
          <ResponsiveContainer width="100%" height={300}>
            <AreaChart data={graphDataQuery.data?.data ?? []}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                tickFormatter={(value) => dayjs(value).format('MMM D YYYY')}
              />
              <YAxis orientation="right" />
              <Tooltip
                isAnimationActive={false}
                position={{ y: 50 }}
                content={(row) => CustomGraphTooltip(row, false)}
                itemSorter={(item) =>
                  Number((item.name as string).substring(3))
                }
              />
              <Area
                animationDuration={300}
                hide={!chosenStacks.top3}
                type="linear"
                dataKey="distribution.1-3"
                stackId={1}
                stroke="#006600"
                fillOpacity={1}
                strokeWidth={2}
                fill="#22c55e80"
              />
              <Area
                animationDuration={300}
                hide={!chosenStacks.top10}
                type="linear"
                dataKey="distribution.4-10"
                stackId={1}
                stroke="#338533"
                strokeWidth={2}
                fillOpacity={1}
                fill="#4ade8080"
              />
              <Area
                animationDuration={300}
                hide={!chosenStacks.top20}
                type="linear"
                dataKey="distribution.11-20"
                stackId={1}
                stroke="#4D984D"
                strokeWidth={2}
                fillOpacity={1}
                fill="#86efac80"
              />
              <Area
                animationDuration={300}
                hide={!chosenStacks.top50}
                type="linear"
                dataKey="distribution.21-50"
                stackId={1}
                stroke="#94a3b8"
                strokeWidth={2}
                fillOpacity={1}
                fill="#94a3b850"
              />

              <Area
                animationDuration={300}
                hide={!chosenStacks.remaining}
                type="linear"
                dataKey="distribution.51+"
                stackId={1}
                stroke="#cbd5e1"
                strokeWidth={2}
                fillOpacity={1}
                fill="#cbd5e150"
              />
            </AreaChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};
