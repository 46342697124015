import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { PopoverMenu } from '@/Components/v2/Menu/ButtonMenu';
import { KeywordGapGroupResource } from '@/api/openapiSchemas';
import { formatThousandSeperator } from '@/utils';
import { ChevronDown, ChevronUp, Plus } from 'lucide-react';
import { useState } from 'react';

type Props = {
  keywordGap: KeywordGapGroupResource;
  onKeywordInsert: (keyword: string) => void;
};

export const KeywordGapRow = ({ keywordGap, onKeywordInsert }: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <>
      <tr
        className="cursor-pointer border-t border-primary-200 hover:bg-primary-50"
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <td className="py-4">
          <div className="flex max-w-[16rem] items-center justify-between gap-6">
            <PopoverMenu
              type="hover"
              side="left"
              items={[
                {
                  title: 'Insert keyword',
                  prependIcon: Plus,
                  onClick: () => onKeywordInsert(keywordGap.name),
                },
              ]}
              trigger={
                <span className="cursor-default select-text text-red">
                  {keywordGap.name}
                </span>
              }
            />
            {keywordGap.phrases.length > 0 ? (
              <div
                onClick={() => setIsCollapsed(!isCollapsed)}
                className="flex cursor-pointer items-center whitespace-nowrap text-xs hover:underline hover:opacity-60"
              >
                {keywordGap.phrases.length} phrases{' '}
                {isCollapsed ? (
                  <ChevronDown size={14} />
                ) : (
                  <ChevronUp size={14} />
                )}
              </div>
            ) : null}
          </div>
        </td>
        <td className="text-end font-extrabold">
          {formatThousandSeperator(keywordGap.total_clicks)}
        </td>
        <td className="text-end font-extrabold">
          {formatThousandSeperator(keywordGap.total_impressions)}
        </td>
      </tr>
      {!isCollapsed &&
        keywordGap.phrases.map((phrase) => (
          <tr key={phrase.keyword}>
            <td>
              <PopoverMenu
                type="hover"
                side="left"
                items={[
                  {
                    title: 'Insert keyword',
                    prependIcon: Plus,
                    onClick: () => onKeywordInsert(phrase.keyword),
                  },
                ]}
                trigger={
                  <div className="break-word max-w-[16rem] cursor-default select-text text-start">
                    {phrase.keyword.split(' ').map((word) => (
                      <span
                        className={`${
                          keywordGap.missing_words.some(
                            (missingWord) =>
                              ('' + missingWord).toLowerCase() ===
                              word.toLowerCase(),
                          )
                            ? 'text-red'
                            : ''
                        }`}
                      >
                        {word}{' '}
                      </span>
                    ))}
                  </div>
                }
              />
            </td>
            <td className="text-end">
              {formatThousandSeperator(phrase.clicks)}
            </td>
            <td className="text-end">
              {formatThousandSeperator(phrase.impressions)}
            </td>
          </tr>
        ))}
      {!isCollapsed && (
        <tr>
          <td className="h-4"></td>
        </tr>
      )}
    </>
  );
};
