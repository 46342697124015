import { ReactNode, createContext } from 'react';

export type CardColorStyles = {
  primary: string;
  secondary: string;
  red: string;
  green: string;
  blue: string;
  gray: string;
  white: string;
};

export type CardProps = {
  /**
   * The content of the card. Can be any react component
   */
  children?: ReactNode | ReactNode[];

  /**
   * Select a variant
   */
  variant?: 'fill' | 'outline' | 'filled-outline';

  /**
   * Sets a border for the card
   */
  color?: keyof CardColorStyles;

  /**
   * If true the card expands to its parent width
   */
  fullWidth?: boolean;

  /**
   * If true the card expands to its parent height
   */
  fullHeight?: boolean;

  /**
   * If true the card will have a shadow
   */
  elevated?: boolean;

  /**
   * Makes the card more compact
   */
  dense?: boolean;

  /**
   * The card border type
   */
  border?: 'dashed' | 'solid';

  /**
   * Makes the card clickable
   */
  onClick?: () => void;
};

export const CardContext = createContext<{
  dense: boolean;
  color: keyof CardColorStyles;
}>({ color: 'white', dense: false });

export const Card = ({
  children,
  color = 'white',
  variant = 'filled-outline',
  fullHeight,
  fullWidth,
  dense,
  elevated,
  onClick,
  border = 'solid',
}: CardProps) => {
  const colorStyles: CardColorStyles = {
    blue: `${variant.includes('fill') ? 'bg-blue-100' : ''} ${
      variant.includes('outline') ? 'border border-blue-300' : ''
    }`,
    green: `${variant.includes('fill') ? 'bg-green-100' : ''} ${
      variant.includes('outline') ? 'border border-green-300' : ''
    }`,
    red: `${variant.includes('fill') ? 'bg-red-100' : ''} ${
      variant.includes('outline') ? 'border border-red-300' : ''
    }`,
    primary: `${variant.includes('fill') ? 'bg-primary-50' : ''} ${
      variant.includes('outline') ? 'border border-primary-300' : ''
    }`,
    secondary: `${variant.includes('fill') ? 'bg-secondary-100' : ''} ${
      variant.includes('outline') ? 'border border-secondary-300' : ''
    }`,
    gray: `${variant.includes('fill') ? 'bg-gray-50' : ''} ${
      variant.includes('outline') ? 'border border-gray-300' : ''
    }`,
    white: `${variant.includes('fill') ? 'bg-white' : ''} ${
      variant.includes('outline') ? 'border border-gray-300' : ''
    }`,
  };

  return (
    <CardContext.Provider value={{ dense: dense ?? false, color: color }}>
      <div
        onClick={onClick}
        className={`flex flex-col rounded-xl ${colorStyles[color]} ${
          fullHeight ? 'h-full' : ''
        } ${fullWidth ? 'w-full' : ''} ${elevated ? 'shadow-lg' : ''} ${
          border === 'dashed' ? 'border-dashed' : 'border-solid'
        } ${onClick ? 'cursor-pointer hover:opacity-60' : ''}`}
      >
        {children}
      </div>
    </CardContext.Provider>
  );
};
