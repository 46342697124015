import { ConfirmDialog } from '@/Components/ConfirmDialog';
import { SimpleRadioButton } from '@/Components/SimpleRadioButton';
import { SimpleTextArea } from '@/Components/SimpleTextArea';
import { Button } from '@/Components/v2/Button';
import { UserResource } from '@/api/openapiSchemas';
import { useState } from 'react';
import {
  useBrandVoiceIndex,
  useListDocuments,
  useOrganisationSubscriptionCancel,
  useOrganisationSubscriptionCancelReasonsIndex,
  useProjectsTrackedKeywordsIndex,
} from '@/api/openapiComponents';
import { WarningAlert } from '@/Components/v2/Alert';
import { SimpleCheckbox } from '@/Components/SimpleCheckbox';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { useShallow } from 'zustand/react/shallow';

type Props = {
  auth: { user: UserResource & { org_id: number } };
  onCancel: () => void;
};

const initialState = { isOpen: false, acceptsCondition: false };

export const CancelSubscriptionForm = ({ auth, onCancel }: Props) => {
  const [showCancelDialog, setShowCancelDialog] = useState(initialState);
  const cancelReasonsQuery = useOrganisationSubscriptionCancelReasonsIndex({
    pathParams: {
      organisation: auth.user.org_id,
    },
  });
  const { projects, projectId } = useAppStore(
    useShallow((state) => ({
      projects: state.projects,
      projectId: state.currentProject!.id,
    })),
  );
  const documentsQuery = useListDocuments({
    pathParams: {
      project: projectId,
    },
  });
  const brandVoicesQuery = useBrandVoiceIndex({});
  const projectKeywordsQuery = useProjectsTrackedKeywordsIndex({
    pathParams: {
      project: projectId,
    },
  });

  const cancelSubscriptionMutation = useOrganisationSubscriptionCancel();

  const handleCancelSubscription = () => {
    cancelSubscriptionMutation.mutate(
      {
        pathParams: {
          organisation: auth.user.org_id,
        },
        body: {
          reason: cancelReason as any,
          comment: comment.length > 0 ? comment : undefined,
        },
      },
      {
        onSuccess: () => {
          setShowCancelDialog(initialState);
          onCancel();
        },
      },
    );
  };

  const [cancelReason, setCancelReason] = useState<string>();
  const [comment, setComment] = useState('');

  const brandVoiceLength = (brandVoicesQuery.data?.data ?? []).length;

  const getBody = () => {
    const initialBody = [
      `${(projects ?? []).length} websites`,
      `${(documentsQuery.data?.data ?? []).length} created content pieces`,
      `${(projectKeywordsQuery.data?.data ?? []).length} tracked keywords`,
    ];
    if (brandVoiceLength > 0) {
      return [
        ...initialBody,
        `${brandVoiceLength} brand voices`,
      ];
    }
    return initialBody;
  };
  return (
    <>
      <ConfirmDialog
        isOpen={showCancelDialog.isOpen}
        onClose={() => setShowCancelDialog(initialState)}
        title="Cancel subscription?"
        description={[
          'You will lose access to your account features by the end of the billing period.',
        ]}
        content={
          <>
            <div className="my-4">
              <WarningAlert
                title={`
                      All of your data will get deleted 60 days after your subscription ends. This includes:
                   `}
                body={getBody()}
              />
            </div>
            <div className="flex items-center">
              <SimpleCheckbox
                checked={showCancelDialog.acceptsCondition}
                onChange={(acceptsCondition) => {
                  setShowCancelDialog((prev) => ({
                    ...prev,
                    acceptsCondition,
                  }));
                }}
              />
              <p>I understand that my data will be deleted</p>
            </div>
          </>
        }
        cancelText="close"
        confirmText="Cancel subscription"
        error={cancelSubscriptionMutation.error}
        isLoading={cancelSubscriptionMutation.isPending}
        confirmColor="red"
        onConfirm={handleCancelSubscription}
        disabled={!showCancelDialog.acceptsCondition}
      />

      <div className={'mb-8 flex flex-col items-center bg-white p-6'}>
        <div className={'text-md mb-4 w-96 font-bold'}>
          We're sad to see you go 🥲
          <br />
          <small>Please tell us why you want to cancel your subscription</small>
        </div>
        <div className="flex w-96 flex-col">
          {cancelReasonsQuery.isLoading ? (
            <div className="flex flex-col gap-4">
              <div className="h-8 w-full animate-pulse rounded-lg bg-gray-200" />
              <div className="h-8 w-full animate-pulse rounded-lg bg-gray-200" />
              <div className="h-8 w-full animate-pulse rounded-lg bg-gray-200" />
              <div className="h-8 w-full animate-pulse rounded-lg bg-gray-200" />
              <div className="h-8 w-full animate-pulse rounded-lg bg-gray-200" />
              <div className="h-8 w-full animate-pulse rounded-lg bg-gray-200" />
              <div className="h-8 w-full animate-pulse rounded-lg bg-gray-200" />
              <div className="h-24 w-full animate-pulse rounded-lg bg-gray-200" />
            </div>
          ) : (
            <>
              {cancelReasonsQuery.data?.data.map((reason) => (
                <div
                  className="flex cursor-pointer items-center"
                  onClick={() => setCancelReason(reason.reason)}
                >
                  <SimpleRadioButton
                    checked={reason.reason === cancelReason}
                    label={
                      reason.text +
                      (reason.reason === 'OTHER' ? ' (Comment below)' : '')
                    }
                  />
                </div>
              ))}

              <h1 className="mb-2 mt-6">Comment:</h1>
              <SimpleTextArea
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
              <div className="mt-4 w-full">
                <Button
                  fullWidth
                  color="red"
                  onClick={() =>
                    setShowCancelDialog((prev) => ({ ...prev, isOpen: true }))
                  }
                  disabled={
                    !cancelReason ||
                    (cancelReason === 'OTHER' && comment.trim().length < 10)
                  }
                  text="Cancel subscription"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
