import { SlideOver } from '@/Components/SlideOver';
import {
  DocumentMetaTitleSuggestionsPromptType,
  DocumentResource,
} from '@/api/openapiSchemas';
import { TitleScoreBreakDown } from '../../../TitleScoreBreakdown';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { ScoreLabel } from '@/Components/Labels/ScoreLabel';
import { useEffect, useState } from 'react';
import { useDocumentMetaTitleSuggestions } from '@/api/openapiComponents';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { Sparkles, X } from 'lucide-react';
import { CompetitorItem } from './components/CompetitorItem';
import { SlideOverGeneratedContent } from '@/Components/SlideOverGeneratedContent';
import { SuggestionItem } from '../TitleScoreSlideOver/components/SuggestionItem';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { SerpVisualizerButton } from '../../../ReportSection/components/SerpVisualizerButton';
import { useDocumentStore } from '@/Pages/Document/stores';
import { Button } from '@/Components/v2/Button';
import Tabs from '@/Components/Tabs';
import { useShallow } from 'zustand/react/shallow';
import { NoYourElement } from '../NoYourElement';

interface Props {
  document: DocumentResource;
  isOpen: boolean;
  onClose: () => void;
}

export const MetaTitleSlideOver = ({ document, isOpen, onClose }: Props) => {
  const [tab, setTab] = useState<'IDEAS' | 'OPTIMIZE' | 'COMPETITORS' | 'YOU'>(
    'IDEAS',
  );
  const [rewriteParams, setRewriteParams] = useState<{
    title: string;
    type: 'variants' | 'optimised' | 'paraphrase' | 'elaborate' | 'default';
  }>({
    title: document.meta_title ?? '',
    type: 'default',
  });
  const currentProject = useAppStore((state) => state.currentProject);
  const { setMetaTitle, metaTitle } = useDocumentStore(
    useShallow((state) => ({
      setMetaTitle: state.setMetaTitle,
      metaTitle: state.metaTitle,
    })),
  );

  const projectId = currentProject!.id;

  const competitors = document.document_report?.competitors ?? [];
  const isLoading = Boolean(document.document_report?.is_generating ?? true);

  const {
    data: titleSuggestions,
    isLoading: isLoadingTitleSuggestions,
    isFetching: isFetchingTitleSuggestions,
    refetch: fetchIdeas,
    isError: ideasError,
    isFetchedAfterMount: ideasIsFetchedAfterMount,
  } = useDocumentMetaTitleSuggestions(
    {
      pathParams: {
        document: document.id,
        project: projectId,
      },
      queryParams: {
        type: rewriteParams
          ? (rewriteParams.type as DocumentMetaTitleSuggestionsPromptType)
          : undefined,
        context:
          rewriteParams?.title.length > 0 ? rewriteParams?.title : undefined,
      },
    },
    {
      enabled: false,
      structuralSharing: (oldData: any, newData: any) => {
        if (oldData) {
          return { data: [...oldData.data, ...newData.data] };
        }
        return newData;
      },
    },
  );
  const metaTitleCompetitiveScore =
    document.document_report?.meta_title_competitiveness_score;

  useEffect(() => {
    if (isOpen && !ideasIsFetchedAfterMount && !titleSuggestions?.data) {
      fetchIdeas();
    }
  }, [isOpen, ideasIsFetchedAfterMount, rewriteParams]);

  const handleSuggestionsBasedOf = (
    title: string,
    type: 'variants' | 'optimised' | 'paraphrase' | 'elaborate',
  ) => {
    setTab('IDEAS');
    setRewriteParams({ title, type });
  };

  const handleSetTab = (newTab: string) => {
    if (newTab !== 'OPTIMIZE') {
      setTab(newTab as any);
    }
    if (newTab === 'OPTIMIZE') {
      setTab('IDEAS');
      setRewriteParams({ title: document.meta_title ?? '', type: 'optimised' });
    }
  };

  return (
    <>
      <SlideOver
        title="SEO TITLE"
        subHeading="The SEO Title is displayed in Google search results and is key for search rankings and your CTR."
        color="bg-lime-500"
        onClose={onClose}
        isOpen={isOpen}
      >
        <div className="flex w-full flex-col">
          {!document.document_report ? (
            <div className="flex flex-col gap-2">
              <div className="mb-6">
                <SkeletonLoader />
              </div>
              <SkeletonLoader height="2xs" />
              <SkeletonLoader height="2xs" />
              <SkeletonLoader height="2xs" />
              <SkeletonLoader height="2xs" />
              <SkeletonLoader height="2xs" />
              <SkeletonLoader height="2xs" />
              <SkeletonLoader height="2xs" />
            </div>
          ) : (
            <>
              <div className="mb-6 flex items-center gap-2 text-sm">
                Current score is{' '}
                <ScoreLabel
                  color={
                    metaTitleCompetitiveScore?.is_completed ? 'green' : 'gray'
                  }
                  score={metaTitleCompetitiveScore?.score ?? 0}
                />
              </div>
              <TitleScoreBreakDown
                tasks={[
                  {
                    task: 'Includes target keyword',
                    completed:
                      !!metaTitleCompetitiveScore?.elements
                        .includes_target_keyword,
                  },
                  {
                    task: 'Keywords in close proximity',
                    completed:
                      !!metaTitleCompetitiveScore?.elements
                        .keywords_in_close_proximity,
                  },
                  {
                    task: 'Target keyword in initial part',
                    completed:
                      !!metaTitleCompetitiveScore?.elements
                        .target_keyword_in_initial_part,
                  },
                  {
                    task: `Character count between ${metaTitleCompetitiveScore?.meta.character_count_min}-${metaTitleCompetitiveScore?.meta.character_count_max} (Current: ${metaTitle.length})`,
                    completed:
                      !!metaTitleCompetitiveScore?.elements
                        .character_count_within_optimal_range,
                  },
                  {
                    task: 'Uniqueness vs competitors',
                    completed:
                      !!metaTitleCompetitiveScore?.elements
                        .uniqueness_vs_competitors,
                  },
                  {
                    task: `Reach ${metaTitleCompetitiveScore?.target}% to average top competitors`,
                    completed: metaTitleCompetitiveScore?.is_completed ?? false,
                  },
                ]}
              />
            </>
          )}
          <div className="mt-10">
            <SerpVisualizerButton
              isInsideTitleTag
              domain={currentProject!.domain}
              keyword={document.keyword.name}
              documentId={document.id}
              projectId={currentProject!.id}
            />
          </div>
          {/* Tab section */}
          <div className="relative mt-6">
            <Tabs
              className="relative mb-4 mt-6"
              tabs={[
                {
                  name: 'IDEAS',
                },
                {
                  name: 'COMPETITORS',
                },
                {
                  name: 'YOU',
                },
              ]}
              onChange={handleSetTab}
              selectedTab={tab}
            >
              <div className="absolute bottom-0 right-0 top-0">
                <Button
                  color="secondary"
                  variant="ghost"
                  prependIcon={Sparkles}
                  disabled={document.title.length === 0}
                  onClick={() => handleSetTab('OPTIMIZE')}
                  text="Optimize"
                  size="xs"
                />
              </div>
            </Tabs>
          </div>
          {tab === 'COMPETITORS' &&
            (isLoading ? (
              <div className="flex flex-col gap-2">
                <SkeletonLoader height="sm" />
                <SkeletonLoader height="sm" />
                <SkeletonLoader height="sm" />
                <SkeletonLoader height="sm" />
                <SkeletonLoader height="sm" />
              </div>
            ) : (
              competitors.map((competitor) => {
                return (
                  <CompetitorItem
                    value={competitor.title}
                    tcs={competitor.title_competitiveness_score}
                    url={competitor.url}
                    targetTcs={metaTitleCompetitiveScore?.target ?? 0}
                    position={competitor.position}
                    onElaborate={(title) =>
                      handleSuggestionsBasedOf(title, 'elaborate')
                    }
                    onParaphrase={(title) =>
                      handleSuggestionsBasedOf(title, 'paraphrase')
                    }
                    onOptimize={(title) =>
                      handleSuggestionsBasedOf(title, 'optimised')
                    }
                    onMore={(title) =>
                      handleSuggestionsBasedOf(title, 'variants')
                    }
                    onInsert={setMetaTitle}
                  />
                );
              })
            ))}
          {tab === 'IDEAS' && (
            <SlideOverGeneratedContent
              key={tab}
              loading={isLoadingTitleSuggestions || isFetchingTitleSuggestions}
              error={ideasError}
              topContent={
                <>
                  {rewriteParams && rewriteParams.type !== 'default' && (
                    <p className="mb-2 flex items-center justify-between text-sm italic text-gray-600">
                      {{
                        variants: 'More like',
                        optimised: 'Optimizing',
                        paraphrase: 'Paraphrasing',
                        elaborate: 'Elaborating on',
                      }[rewriteParams.type] +
                        ' "' +
                        rewriteParams.title +
                        '"'}
                      <IconButton
                        size="sm"
                        icon={X}
                        onClick={() =>
                          setRewriteParams({
                            title: document.meta_title ?? '',
                            type: 'default',
                          })
                        }
                      />
                    </p>
                  )}
                </>
              }
              onFetchItems={fetchIdeas}
            >
              {(titleSuggestions?.data ?? [])?.map((item) => (
                <SuggestionItem
                  key={item.title + item.score}
                  targetTcs={metaTitleCompetitiveScore?.target ?? 100}
                  value={{ title: item.title, score: item.score }}
                  onInsert={setMetaTitle}
                  onElaborate={(title) =>
                    handleSuggestionsBasedOf(title, 'elaborate')
                  }
                  onParaphrase={(title) =>
                    handleSuggestionsBasedOf(title, 'paraphrase')
                  }
                  onOptimize={(title) =>
                    handleSuggestionsBasedOf(title, 'optimised')
                  }
                  onMore={(title) =>
                    handleSuggestionsBasedOf(title, 'variants')
                  }
                />
              ))}
            </SlideOverGeneratedContent>
          )}
          {tab === 'YOU' && (
            <>
              {document.meta_title?.length ? (
                <SuggestionItem
                  value={{
                    title: document.meta_title,
                    score: metaTitleCompetitiveScore?.score,
                  }}
                  onElaborate={(title) =>
                    handleSuggestionsBasedOf(title, 'elaborate')
                  }
                  onParaphrase={(title) =>
                    handleSuggestionsBasedOf(title, 'paraphrase')
                  }
                  onOptimize={(title) =>
                    handleSuggestionsBasedOf(title, 'optimised')
                  }
                  onMore={(title) =>
                    handleSuggestionsBasedOf(title, 'variants')
                  }
                />
              ) : (
                <NoYourElement
                  type="a meta title"
                  onClick={() => handleSetTab('IDEAS')}
                />
              )}
            </>
          )}
        </div>
      </SlideOver>
    </>
  );
};
