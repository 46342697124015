import { DocumentReportCompetitorResource } from '@/api/openapiSchemas';
import * as React from 'react';
import psl from 'psl';
import { ChevronRight, MoreVertical } from 'lucide-react';
import { getScreenPropertiesForSerp, getSerpDate } from '../utils';
import { isNotDocumentReportCompetitorResource } from '@/types';
import { ScoreLabel } from '@/Components/Labels/ScoreLabel';
type Props = {
  competitor:
    | DocumentReportCompetitorResource
    | {
        id: number;
        title: string;
        description: string;
        url: string;
      };
  showDate: boolean;
  keyword: string;
  mode: 'Desktop' | 'Mobile';
};
export const SerpSuggestions = ({
  competitor,
  showDate,
  keyword,
  mode,
}: Props) => {
  const titleRef = React.useRef<HTMLHeadingElement>(null);
  const parentRef = React.useRef<HTMLDivElement>(null);
  const url = new URL(competitor.url);
  const publicSuffix: string = psl.parse(url.hostname).sld;
  const title =
    (publicSuffix?.charAt(0).toUpperCase() ?? '') +
    (publicSuffix?.substring(1) ?? '');
  /*
  React.useEffect(() => {
    let data: any = [];
    if (titleRef.current && parentRef.current) {
      const container = titleRef.current;

      const words = title.split(' ');
      const { x: parentX, y: parentY } =
        parentRef.current.getBoundingClientRect();
      const positions = words.map((word, index) => {
        const wordElement = container.children[index];
        const { x, y, height, width } = wordElement.getBoundingClientRect();
        const finalX = x - parentX;
        const finalY = y - parentY;
        const halfHeight = height / 1.2;
        const halfWidth = width / 2;
        return [finalX - halfWidth, finalY + halfHeight, 1];
      });
      data = [...data, ...positions];
    }

    const heat = simpleheat('heatmap');
    heat.data(data);
    heat.radius(5, 10);
    // draw the heatmap with optional minimum point opacity (0.05 by default)
    heat.draw(0.05);

    return () => {
      heat.clear();
    };
  }, [titleRef]);
  */

  const renderPathUrl = (path: string, search: string) => {
    let prefix = path;
    if (prefix.startsWith('/')) {
      prefix = prefix.substring(1);
    }
    if (prefix.endsWith('/')) {
      prefix = prefix.substring(0, prefix.length - 1);
    }
    const pathLength = prefix.split('/');
    return (
      <span className="flex items-center">
        {
          <>
            {prefix !== '' && <ChevronRight size={10} />}
            {pathLength.length < 2 ? (
              <span>
                {decodeURI(prefix)}
                {search}
              </span>
            ) : (
              <>
                ...
                <ChevronRight size={10} />
                <span className="whitespace-nowrap">
                  {decodeURI(pathLength[pathLength.length - 1]) + search}
                </span>
              </>
            )}
          </>
        }
      </span>
    );
  };
  const screenProps = getScreenPropertiesForSerp(mode);
  return (
    <div className="flex">
      <div
        style={{
          maxWidth: screenProps.containerMax,
          minWidth: screenProps.containerMin,
          ...screenProps.mobileContainerProps,
        }}
        className={`relative`}
        key={competitor.title + competitor.url}
        ref={parentRef}
      >
        {mode === 'Mobile' &&
          isNotDocumentReportCompetitorResource(competitor) && (
            <div className="absolute right-4 translate-y-1 font-google">
              <ScoreLabel color="gray" score="You" />
            </div>
          )}
        <canvas
          id="heatmap"
          className="pointer-events-none absolute z-10 h-full w-full"
        />
        <div className="flex items-center gap-2">
          {!isNotDocumentReportCompetitorResource(competitor) &&
          competitor.favicon ? (
            <div className="max-w-7 relative flex h-7 max-h-7 w-7 rounded-3xl bg-gray-100 outline outline-1 outline-gray-300">
              <img
                className="m-auto my-auto min-h-[18px] min-w-[18px] rounded-2xl"
                src={competitor.favicon}
              />
            </div>
          ) : (
            <div className="relative h-7 w-7 rounded-3xl bg-[rgb(226,238,255)]">
              <span className="absolute h-[18px] w-[18px] translate-x-[5px] translate-y-[5px]">
                <svg
                  focusable="false"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="rgb(0,97,240)"
                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z"
                  ></path>
                </svg>
              </span>
            </div>
          )}
          <div>
            <h6
              className="font-google  font-medium leading-[20px]"
              ref={titleRef}
            >
              {title.split(' ').map((word, key) => (
                <span key={key}>{word}</span>
              ))}
            </h6>
            <div className="flex gap-2">
              <span className="flex font-google text-sm font-medium leading-[18px]">
                {url.origin} {renderPathUrl(url.pathname, url.search)}
              </span>
              <MoreVertical size={14} className="mt-[1px]" />
            </div>
          </div>
        </div>
        <div className="mt-[4px] inline-block font-google text-[20px] font-normal leading-[1.3] text-[#1a0dab]">
          {competitor.title || 'Example Title'}
        </div>
        <br />
        <div className="mt-1 inline-block font-google text-[14px]">
          {isNotDocumentReportCompetitorResource(competitor)
            ? `${getSerpDate(showDate)}${
                competitor.description
                  ? competitor.description
                  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.'
              }`
            : competitor.snippet}
        </div>
      </div>

      {mode === 'Desktop' &&
        isNotDocumentReportCompetitorResource(competitor) && (
          <div className="mr-6 mt-2 font-google">
            <ScoreLabel color="gray" score="You" />
          </div>
        )}
    </div>
  );
};
