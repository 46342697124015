import { Collapsable } from '@/Components/v2/Collapsable';
import { DynamicIcon } from '@/Components/v2/DynamicIcon/DynamicIcon';
import { DocumentElementResource } from '@/api/openapiSchemas';
import { FileQuestion, GripVertical } from 'lucide-react';

type Props = { el: DocumentElementResource; isHovered?: boolean };

export const TemplateCollapsable = ({ el, isHovered }: Props) => {
  return (
    <div className="w-full">
      <Collapsable
        title={
          <div className="relative flex items-center">
            <div
              className={`absolute -left-11 ${
                isHovered ? 'visible' : 'hidden'
              } px-2`}
            >
              <GripVertical size={20} className="stroke-primary opacity-60" />
            </div>
            <div className="mr-3">
              {el.icon ? (
                <DynamicIcon
                  className="stroke-primary opacity-60"
                  size={20}
                  name={el.icon as any}
                />
              ) : (
                <FileQuestion className="stroke-primary opacity-60" size={20} />
              )}
            </div>
            <p className="text-base font-bold text-black">{el.display_name}</p>
          </div>
        }
        fullWidth
      >
        <p className="font-sm mx-[calc(0.75rem*2+23px)] mt-1 pb-4 font-normal text-primary">
          {el.display_description}
        </p>
      </Collapsable>
    </div>
  );
};
