import { DocumentTemplateResource } from './api/openapiSchemas';
import { InputDecoration } from './Components/v2/Input/InputDecoration';
import { Autocomplete } from './Components/v2/Select/AutoComplete';
import { DropdownItem } from './Components/v2/Select/hooks';
import { Tooltip } from './Components/v2/Tooltip';
import { setTemplateToForm } from './Pages/CreateNewContent/pages/AiGenerated/utils';

type Props = {
  onChange: (value: {
    contentTypeId: number;
    templateId: number | undefined;
  }) => void;
  customTemplates?: DocumentTemplateResource[];
  predefinedTemplates?: DocumentTemplateResource[];
  value: number | undefined;
  disabled?: boolean;
  featureLocked?: boolean;
};

export const TemplateSelect = ({
  featureLocked,
  customTemplates = [],
  onChange,
  predefinedTemplates = [],
  value,
  disabled,
}: Props) => {
  const allTemplates: (
    | DropdownItem
    | {
        group: string;
      }
  )[] = [
    { group: 'Your own templates' },
    ...customTemplates.map((template) => ({
      value: template.id.toString(),
      title: template.name || 'Untitled',
      faded: template.name === '',
    })),
    { group: 'Predefined templates' },
    ...predefinedTemplates.map((template) => ({
      value: template.id.toString(),
      title: template.name,
      faded: template.name === '',
    })),
  ];

  return (
    <InputDecoration label="Templates">
      <Tooltip
        asChild
        side="bottom"
        title="Upgrade to a higher plan to get access to this feature."
        navLink="/account/subscription"
        navLinkText="See pricing"
        disabled={!featureLocked}
      >
        <div className="w-full">
          <Autocomplete
            clearable
            disabled={disabled || featureLocked}
            options={allTemplates}
            value={value?.toString() ?? ''}
            onChange={(value) => {
              const values = setTemplateToForm(
                [...customTemplates, ...predefinedTemplates],
                value,
              );

              onChange(values);
            }}
          />
        </div>
      </Tooltip>
    </InputDecoration>
  );
};
