import * as React from 'react';

export const DocumentInfoContext = React.createContext<{
  documentId: number;
  projectId: number;
}>({ documentId: 0, projectId: 0 });

export const useDocumentInfoContext = () => {
  return React.useContext(DocumentInfoContext);
};
