import { Variant } from './AppLabel';

type Props = {
  score: number | string;
  color: keyof typeof Variant;
}

export const ScoreLabel = ({ color, score }: Props) => {
  return (
    <div
      className={`flex h-6 flex-shrink-0 items-center justify-center rounded  ${Variant[color]} w-8`}
    >
      {score}
    </div>
  );
};
