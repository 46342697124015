import React from 'react';

import { cn } from '../lib/utils';

import { Toolbar, ToolbarProps } from '../components';

const FixedToolbar = React.forwardRef<HTMLDivElement, ToolbarProps>(
  ({ className, ...props }: ToolbarProps, ref) => {
    return (
      <Toolbar
        ref={ref}
        className={cn(
          'supports-backdrop-blur:bg-white/60 border-b-border dark:supports-backdrop-blur:bg-slate-950/60 sticky left-0 w-full flex-shrink-0 justify-between overflow-x-auto border-b  p-1 backdrop-blur dark:bg-slate-950/95',
          className,
        )}
        {...props}
      />
    );
  },
);
FixedToolbar.displayName = 'FixedToolbar';

export { FixedToolbar };
