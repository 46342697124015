import AppLabel, { TagVariant } from '@/Components/Labels/AppLabel';
import SimpleButton from '@/Components/SimpleButton';
import { SimplePopover } from '@/Components/SimplePopover';
import { Button } from '@/Components/v2/Button';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { useDebounce } from '@/Hooks/useDebounce';
import { OrganisationTagResource } from '@/api/openapiSchemas';
import { Check, Trash2, X } from 'lucide-react';
import { useState } from 'react';

type Props = {
  tag: OrganisationTagResource;
  isLoading?: boolean;
  isEditing?: boolean;
  onEditTag: (id: number) => void;
  isEditingTag?: boolean;
  onFinishEditingTag: (
    values: {
      color: string;
      name: string;
      id: number;
    },
    close?: boolean,
  ) => void;
  onRemove: (id: number) => void;
  onDelete: (id: number) => void;
  isError?: boolean;
};

export const EditableTag = ({
  tag,
  onRemove,
  onDelete,
  onEditTag,
  onFinishEditingTag,
  isEditingTag,
  isLoading,
  isEditing,
  isError,
}: Props) => {
  const [tagName, setTagName] = useState(tag.name);
  const [color, setColor] = useState(tag.color);

  useDebounce(
    () => onFinishEditingTag({ name: tagName, color, id: tag.id }),
    [tagName, color],
    200,
  );

  return (
    <div className="relative flex max-w-[14vw] items-center">
      <div className="flex-grow overflow-hidden">
        <SimplePopover
          isOpen={isEditingTag}
          onOpen={() => {
            setTagName(tag.name);
            setColor(tag.color);
            onEditTag(tag.id);
          }}
          onClose={() => {
            onFinishEditingTag({ color, name: tagName, id: tag.id }, true);
          }}
          trigger={
            <AppLabel variant={TagVariant[tag.color]}>
              <div
                className="flex max-w-[13vw] items-center gap-1"
                title={tag.name}
              >
                <div className="flex-grow truncate">{tag.name} </div>
                {isEditing && (
                  <IconButton
                    icon={X}
                    onClick={(e) => {
                      e.stopPropagation();
                      onRemove(tag.id);
                    }}
                    dense
                    size="sm"
                    color="gray"
                  />
                )}
              </div>
            </AppLabel>
          }
        >
          <div
            className="flex flex-col gap-1 p-2 indent-0"
            onClick={(e) => e.stopPropagation()}
          >
            <input
              value={tagName}
              onChange={(e) => {
                setTagName(e.target.value);
              }}
              className={`rounded-md border border-gray-300 px-2 py-1 ${
                isError ? 'bg-red-300' : ''
              }`}
            />
            <Button
              text="DELETE"
              prependIcon={Trash2}
              onClick={() => onDelete(tag.id)}
              variant="ghost"
            />
            <div className="my-2 h-px w-full bg-gray-200" />

            {[
              'gray',
              'brown',
              'orange',
              'yellow',
              'green',
              'blue',
              'purple',
              'pink',
              'red',
            ].map((c) => (
              <SimpleButton
                className={`p-1 hover:bg-gray-50`}
                onClick={() => setColor(c)}
              >
                <div className="flex w-full items-center justify-between normal-case">
                  <span
                    className={`${TagVariant[c]} rounded px-1.5 py-0.5 text-sm`}
                  >
                    {c.slice(0, 1).toUpperCase()}
                    {c.slice(1).toLowerCase()}
                  </span>
                  {c === color ? <Check size={16} /> : null}
                </div>
              </SimpleButton>
            ))}
          </div>
        </SimplePopover>
      </div>
    </div>
  );
};
