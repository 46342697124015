import { FileText } from 'lucide-react';
import { useNavigate } from '@tanstack/react-router';
import { hasher } from '@/Pages/AppLoader';
import { useListDocuments } from '@/api/openapiComponents';
import { PopoverMenu } from '@/Components/v2/Menu/ButtonMenu';
import { IconButton } from '@/Components/v2/IconButton/IconButton';

type Props = {
  projectId: number;
  keyword: string | undefined;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  isOpen?: boolean;
};

const ContentTitle = ({
  projectId,
  keyword,
  onMouseEnter,
  onMouseLeave,
  isOpen,
}: Props) => {
  const navigate = useNavigate();
  const { data: documents, isFetching: isLoadingDocuments } = useListDocuments(
    {
      pathParams: {
        project: projectId,
      },
      queryParams: {
        filters: { keyword },
      },
    },
    {
      enabled: isOpen,
    },
  );
  return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <PopoverMenu
        items={
          isLoadingDocuments
            ? [{ disabled: true, title: 'Loading...' }]
            : documents?.data.map((doc) => ({
                title: doc.internal_name || 'Untitled document',
                onClick: () => {
                  navigate({
                    to: `/documents/$documentId`,
                    params: { documentId: hasher.encode(doc.id) },
                  });
                },
              })) ?? []
        }
        trigger={<IconButton icon={FileText} />}
        isOpen={isOpen}
      />
    </div>
  );
};

ContentTitle.displayName = 'ContentTitle';

export { ContentTitle };
