import { createContext, useContext, useEffect } from 'react';
import { useAppStore } from './stores';
import Router from '../Router/Router';
import HashIds from 'hashids';
import { useInitApp } from './hooks/useInitApp';
import { SubscriptionResource } from '@/api/openapiSchemas';

export const hasher = new HashIds('iaoes', 10);

const FeatureFlaggingContext = createContext<{
  hasFeature: (featureName: string) => boolean;
}>({ hasFeature: () => false });

export const useFeatureFlagging = () => {
  return useContext(FeatureFlaggingContext);
};

export default function AppLoader() {
  const appState = useAppStore();

  const handleInitializeApp = useInitApp();

  useEffect(() => {
    handleInitializeApp();
  }, []);

  useEffect(() => {
    if (appState.organisation) {
      const url = `organisations.${appState.organisation?.id}`;
      window.Echo.private(url).listen(`SubscriptionUpdatedEvent`, () => {
        appState.refreshSubscription();
      });
      return () => {
        window.Echo.leave(url);
      };
    }
  }, [appState.organisation?.id]);

  const hasFeature = (featureName: string) => {
    return !!appState.organisation?.features?.some(
      (feature) => feature.name === featureName,
    );
  };

  const hasPermission = (
    permissionName: keyof SubscriptionResource['permissions'],
  ) => {
    return !!appState.subscription?.permissions[permissionName];
  };

  if (!appState.initialized) {
    return <div />;
  }
  return (
    <FeatureFlaggingContext.Provider value={{ hasFeature }}>
      <Router
        projectCount={appState.projects?.length ?? 0}
        isAuthenticated={!!appState.auth}
        hasProject={!!appState.currentProject}
        hasActiveSubscription={!!appState.subscription?.is_active}
        hasOrganisation={!!appState.organisation}
        hasUnpaidInvoices={!!appState.subscription?.is_unpaid}
        hasPermission={hasPermission}
        hasFeature={hasFeature}
      />
    </FeatureFlaggingContext.Provider>
  );
}
