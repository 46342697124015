import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';

const Icon = (props: FontAwesomeIconProps) => (
  <FontAwesomeIcon {...props} fixedWidth />
);

export { Icon };
