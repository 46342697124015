import { LucideIcon, X } from 'lucide-react';
import { SkeletonLoader } from '../SkeletonLoader/SkeletonLoader';
import { IconButton } from '../IconButton/IconButton';

type Props = {
  title: string;
  appendIcon?: LucideIcon;
  onClick?: () => void;
  variant?: 'dashed' | 'solid';
  onDelete?: () => void;
  isLoading?: boolean;
  isActive?: boolean;
};

export const Chip = ({
  title,
  appendIcon: AppendIcon,
  onClick,
  variant = 'solid',
  onDelete,
  isLoading,
  isActive,
}: Props) => {
  const onClickCss = onClick ? 'cursor-pointer' : '';

  const variantCss = variant === 'dashed' ? 'border-dashed' : 'border-solid';

  const activeBgCss = isActive ? 'bg-amber-50' : '';
  return !isLoading ? (
    <>
      <div
        onClick={onClick}
        className={`flex max-w-[12rem] items-center justify-center gap-2 rounded-lg border px-2.5 py-1.5 text-base text-primary ${onClickCss} ${variantCss} ${activeBgCss}`}
      >
        <span className="truncate py-1">{title}</span>
        {AppendIcon && !isActive && <AppendIcon size={18} />}
        {isActive && onDelete && (
          <IconButton
            icon={X}
            dense
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
          />
        )}
      </div>
    </>
  ) : (
    <div className="w-[12rem]">
      <SkeletonLoader height="xs" />
    </div>
  );
};
