
import { Icons } from './icons';

import { ToolbarButton, ToolbarButtonProps } from '../components';
import {
  useListToolbarButton,
  useListToolbarButtonState,
} from '@udecode/plate-list';

export enum ListStyleType {
  UL = 'ul',
  OL = 'ol',
}

type Props = {
  disabled?: boolean;
}

export function ListToolbarButton({
  nodeType = ListStyleType.UL,
  disabled,
}: ToolbarButtonProps & { nodeType?: ListStyleType } & Props) {
  const state = useListToolbarButtonState({ nodeType });
  const { props } = useListToolbarButton(state);
  return (
    <ToolbarButton
      tooltip={
        nodeType === ListStyleType.UL ? 'Bulleted List' : 'Numbered List'
      }
      disabled={disabled}
      {...props}
    >
      {nodeType === ListStyleType.UL ? <Icons.ul /> : <Icons.ol />}
    </ToolbarButton>
  );
}
