import { CreatePageContainer } from '@/Pages/components';
import { TemplateSlideOver } from './components/TemplateSlideOver';
import { TemplateItem } from './components/TemplateItem';
import { TemplateDrop } from './components/TemplateDrop';
import {
  Active,
  closestCenter,
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  BrandVoiceResource,
  ContentTypeResource,
  CustomFieldDefinitionResource,
  DocumentElementActionType,
  DocumentElementResource,
  DocumentElementType,
  DocumentResource,
  DocumentTemplateElementResource,
  DocumentTemplateResource,
  HeadingType,
} from '@/api/openapiSchemas';
import { useEffect, useRef, useState } from 'react';
import { TemplateDragItem } from './components/TemplateDragItem';
import { arrayMove } from '@dnd-kit/sortable';
import { createUniqueIdentifier } from '@/Pages/Document/components/PlateEditor/lib/transform';
import { ElementDropzone } from './components/ElementDropZone';
import {
  useBrandVoiceIndex,
  useListContentTypes,
  useListDocumentElementByCategory,
  useListDocuments,
  useUpdateDocumentTemplate,
} from '@/api/openapiComponents';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { LucideIcon, Pencil, Plus } from 'lucide-react';
import { useDebounce } from '@/Hooks/useDebounce';
import { ConfirmDialog } from '@/Components/ConfirmDialog';
import { TemplateSectionTitle } from './components/TemplateSectionTitle';
import { Chip } from '@/Components/v2/Chip/Chip';
import { TemplateMetaFieldDialog } from './components/TemplateMetaFieldDialog';
import { Dialog } from '@/Components/Dialog';
import { ContentTypeSelect } from '@/Components/ContentTypeSelect';
import { TemplateCollapsable } from './components/TemplateCollapsable';
import { TemplateItemLayout } from './components/TemplateItemLayout';
import { createPortal } from 'react-dom';
import { snapCenterToCursor } from '@dnd-kit/modifiers';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { TextArea } from '@/Components/v2/TextArea/TextArea';
import { BrandVoiceSelect } from '@/Components/BrandVoiceSelect';
import { ShrinkableDragOverlay } from '@/Components/ShrinkableDragOverlay';

type Props = {
  template: DocumentTemplateResource | undefined;
  isLoading?: boolean;
};

export type ElementKey =
  | 'title'
  | 'meta_title'
  | 'meta_description'
  | 'brand_voice';

const values: DocumentTemplateElementResource = {
  prompt_instruction: null,
  raw_markdown: null,
  subheading: null,
  display_name: null,
};

export const Template = ({ template, isLoading }: Props) => {
  const appState = useAppStore();

  const [elementToBeDeleted, setElementToBeDeleted] = useState<
    (DocumentElementResource & { uniqueId: string }) | null
  >(null);
  const [metaFieldToBeDeleted, setMetaFieldToBeDeleted] = useState<
    (DocumentElementResource & { uniqueId: string }) | null
  >(null);
  const [activeElement, setActiveElement] = useState<Active | null>(null);
  const [activeMetaFieldSlideOver, setActiveMetaFieldSlideOver] =
    useState<DocumentElementType | null>(null);
  const [latestDraggedElementId, setLatestDraggedElementId] = useState('');
  const [contentTypeDialogOptions, setContentTypeDialogOptions] = useState<{
    isOpen: boolean;
    content: ContentTypeResource | null;
  }>({
    isOpen: false,
    content: template?.content_type ?? null,
  });
  const [brandVoiceDialogOptions, setBrandVoiceDialogOptions] = useState<{
    isOpen: boolean;
    brandVoice: BrandVoiceResource | null;
  }>({
    isOpen: false,
    brandVoice: template?.brand_voice ?? null,
  });
  const [showContextDialog, setShowContextDialog] = useState(false);

  const descriptionRef = useRef<HTMLTextAreaElement | null>(null);
  const contextRef = useRef<HTMLTextAreaElement | null>(null);

  const [templateInfo, setTemplateInfo] = useState({
    id: template?.id ?? -1,
    name: template?.name ?? '',
    description: template?.description ?? '',
    context: template?.context ?? '',
  });
  const initialState = {
    uniqueId: '',
    preview: '',
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );

  useEffect(() => {
    if (descriptionRef.current) {
      descriptionRef.current.style.height = '1px';
      descriptionRef.current.style.height =
        (descriptionRef.current?.scrollHeight ?? 42) + 'px';
    }
    if (contextRef.current) {
      contextRef.current.style.height = '1px';
      contextRef.current.style.height =
        (contextRef.current?.scrollHeight ?? 42) + 'px';
    }
  });

  const contentTypeQuery = useListContentTypes({});

  useListDocuments({ pathParams: { project: appState.currentProject!.id } });

  const [testPromptDialog, setTestPromptDialog] = useState<{
    document?: DocumentResource;
    uniqueId: string;
    preview: string;
  }>(initialState);

  const h1TitleSection =
    template?.elements?.find((el) => el.element_type === 'title') ?? null;

  const metaTitleDescription =
    template?.elements?.find((el) => el.element_type === 'meta_description') ??
    null;

  const metaTitleSection =
    template?.elements?.find((el) => el.element_type === 'meta_title') ?? null;

  const [templateElements, setTemplateElements] = useState<{
    h1TitleSection: (DocumentElementResource & { uniqueId: string }) | null;
    metaTitleSection: (DocumentElementResource & { uniqueId: string }) | null;
    metaDescriptionSection:
      | (DocumentElementResource & { uniqueId: string })
      | null;
    bodySections: (DocumentElementResource & { uniqueId: string })[];
  }>({
    h1TitleSection: h1TitleSection
      ? { ...h1TitleSection, uniqueId: createUniqueIdentifier() }
      : null,
    metaDescriptionSection: metaTitleDescription
      ? { ...metaTitleDescription, uniqueId: createUniqueIdentifier() }
      : null,
    metaTitleSection: metaTitleSection
      ? { ...metaTitleSection, uniqueId: createUniqueIdentifier() }
      : null,
    bodySections: template?.elements
      ? template.elements
          .filter((el) => el.element_type === 'body')
          .map((el) => ({
            ...el,
            uniqueId: createUniqueIdentifier(),
          }))
      : [],
  });

  const listElementsQuery = useListDocumentElementByCategory({});

  const getFilteredSections = (type: DocumentElementType) =>
    (listElementsQuery.data?.data ?? [])
      .filter(
        (el) =>
          (el.elements ?? []).filter(
            (sectionElement) => sectionElement.element_type === type,
          ).length > 0,
      )
      .map((elementWithBodySections) => ({
        ...elementWithBodySections,
        elements: (elementWithBodySections.elements ?? []).filter(
          (sectionElement) => sectionElement.element_type === type,
        ),
      }));

  const updateTemplateMutation = useUpdateDocumentTemplate();

  const getElementType = (type: ElementKey) => {
    switch (type) {
      case 'brand_voice':
        return 'brandVoice';
      case 'title':
        return 'h1TitleSection';
      case 'meta_title':
        return 'metaTitleSection';
      default:
        return 'metaDescriptionSection';
    }
  };

  const handleSelect = (
    element: DocumentElementResource & { uniqueId: string },
    value: HeadingType,
  ) => {
    if (element.element_type !== 'body') {
      const key = getElementType(element.element_type);
      setTemplateElements((prev) => ({
        ...prev,
        [key]: {
          ...element,
          values: element.values
            ? {
                ...element.values,
                subheading: {
                  title: element.values.subheading?.title ?? '',
                  type: value,
                },
              }
            : undefined,
        },
      }));
      return;
    }
    setTemplateElements((prev) => ({
      ...prev,
      bodySections: templateElements.bodySections.map((el) => {
        if (el.uniqueId === element.uniqueId) {
          return {
            ...el,
            values: el.values
              ? {
                  ...el.values,
                  subheading: {
                    title: el.values.subheading?.title ?? '',
                    type: value,
                  },
                }
              : undefined,
          };
        }
        return el;
      }),
    }));
  };

  const handleChange = (
    type: DocumentElementActionType,
    element: DocumentElementResource & { uniqueId: string },
    value: string | CustomFieldDefinitionResource,
    property?: 'subheading' | 'display_name' | 'custom_field',
  ) => {
    if (element.element_type !== 'body') {
      const key = getElementType(element.element_type);
      setTemplateElements((prev) => ({
        ...prev,
        [key]: {
          ...element,
          values: {
            prompt_instruction:
              type === 'prompt' && property !== 'custom_field'
                ? value
                : (element.values?.prompt_instruction ?? null),
            raw_markdown:
              type === 'raw' && property !== 'custom_field' ? value : null,
            display_name:
              property === 'display_name'
                ? value
                : (prev[key]?.values?.display_name ?? ''),
            subheading:
              type === 'prompt_with_subheading' && property !== 'custom_field'
                ? {
                    title:
                      property === 'subheading'
                        ? value
                        : (element.values?.subheading?.title ?? ''),
                    type: element.values?.subheading?.type ?? 'H2',
                  }
                : null,
          },
        },
      }));
      return;
    }
    setTemplateElements((prev) => ({
      ...prev,
      bodySections: templateElements.bodySections.map((el) => {
        if (el.uniqueId === element.uniqueId) {
          return {
            ...el,
            values: {
              custom_field:
                property === 'custom_field'
                  ? (value as CustomFieldDefinitionResource)
                  : element.values?.custom_field,
              prompt_instruction:
                el.action_type === 'prompt' &&
                property !== 'display_name' &&
                property !== 'custom_field'
                  ? (value as string)
                  : (element.values?.prompt_instruction ?? null),
              raw_markdown:
                type === 'raw' && property !== 'custom_field'
                  ? (value as string)
                  : (el.values?.raw_markdown ?? null),
              display_name:
                property === 'display_name'
                  ? (value as string)
                  : (el.values?.display_name ?? ''),
              subheading:
                type === 'prompt_with_subheading' && property !== 'custom_field'
                  ? {
                      title:
                        property === 'subheading'
                          ? (value as string)
                          : (el.values?.subheading?.title ?? ''),
                      type: el.values?.subheading?.type ?? 'H2',
                    }
                  : (el.values?.subheading ?? null),
            },
          };
        }
        return el;
      }),
    }));
  };

  const handleSaveTemplate = () => {
    const getElementBodyInTheRightOrder = [
      templateElements.h1TitleSection,
      ...templateElements.bodySections,
      templateElements.metaTitleSection,
      templateElements.metaDescriptionSection,
    ].filter((el) => el !== null) as DocumentElementResource[];

    updateTemplateMutation.mutate({
      pathParams: {
        documentTemplate: templateInfo.id,
        project: appState.currentProject!.id,
      },
      body: {
        description: templateInfo.description,
        name: templateInfo.name,
        brand_voice_id: brandVoiceDialogOptions.brandVoice?.id ?? null,
        content_type_id: contentTypeDialogOptions.content?.id ?? null,
        context: templateInfo.context,
        elements: getElementBodyInTheRightOrder.map((el) => ({
          id: el.id,
          custom_field_id: el.values?.custom_field?.id ?? null,
          values: el.values
            ? {
                prompt_instruction: el.values.prompt_instruction,
                raw_markdown: el.values.raw_markdown,
                display_name: el.values.display_name,
                subheading: el.values.subheading
                  ? {
                      title: el.values.subheading?.title,
                      type: el.values.subheading?.type,
                    }
                  : undefined,
              }
            : undefined,
        })),
      },
    });
  };

  useDebounce(handleSaveTemplate, [
    templateElements,
    templateInfo,
    contentTypeDialogOptions.content,
    brandVoiceDialogOptions.brandVoice,
  ]);

  const handleDelete = (
    element: DocumentElementResource & { uniqueId: string },
  ) => {
    if (element.element_type !== 'body') {
      const key = getElementType(element.element_type);
      setTemplateElements((prev) => ({
        ...prev,
        [key]: null,
      }));
      return;
    }
    setTemplateElements((prev) => ({
      ...prev,
      bodySections: prev.bodySections.filter(
        (el) => el.uniqueId !== element.uniqueId,
      ),
    }));
  };

  const handleCloseMetaFields = () => setActiveMetaFieldSlideOver(null);

  const dialogProps = {
    testPromptDialog: testPromptDialog,
    onAddPreview: (preview: string) => {
      setTestPromptDialog((prev) => ({ ...prev, preview }));
    },
    onCloseDialog: () => {
      setTestPromptDialog((prev) => ({
        ...prev,
        uniqueId: '',
        preview: '',
      }));
    },
    onOpenDialog: (uniqueId: string) => {
      setTestPromptDialog((prev) => ({
        ...prev,
        uniqueId,
      }));
    },
    onAddDocumentId: (document?: DocumentResource) => {
      setTestPromptDialog((prev) => ({
        ...prev,
        preview: '',
        document,
      }));
    },
  };

  const draggingIndex = activeElement
    ? templateElements.bodySections.findIndex(
        (el) => el.uniqueId === activeElement.data.current?.el.uniqueId,
      )
    : -1;

  const getChipProps = (
    type: ElementKey,
  ): {
    isActive?: boolean;
    variant: 'dashed' | 'solid';
    title: string;
    appendIcon: LucideIcon;
    onDelete?: () => void;
    isLoading?: boolean;
  } => {
    const key = getElementType(type);

    const element = templateElements[key];
    let value = '';
    switch (key) {
      case 'brandVoice':
        value = 'Brand Voice';
        break;
      case 'h1TitleSection':
        value = 'H1';
        break;
      case 'metaTitleSection':
        value = 'Title';
        break;
      default:
        value = 'Meta Description';
        break;
    }
    return {
      isActive: element ? true : false,
      variant: !element ? 'dashed' : 'solid',
      title: `${value}${!element ? '' : ': ' + element.display_name}`,
      appendIcon: Plus,
      onDelete: !element
        ? undefined
        : () => {
            setMetaFieldToBeDeleted(element);
          },
      isLoading,
    };
  };

  const getSelectOptions = (keyElement: ElementKey) =>
    getFilteredSections(keyElement).flatMap((element) => [
      ...element.elements.map((el) => ({
        subtitle: el.display_description,
        value: el.id,
        title: el.display_name,
      })),
    ]);

  const onChangeSelect = (id: string | undefined, keyElement: ElementKey) => {
    const value = id ? parseInt(id) : null;
    const element = getFilteredSections(keyElement)
      .flatMap((element) => [...element.elements])
      .find((v) => v.id === value);

    const key = getElementType(keyElement);

    setTemplateElements((prev) => ({
      ...prev,
      [key]: value
        ? {
            ...element,
            values: {
              ...values,
              prompt_instruction: element?.prompt,
            },
            uniqueId: createUniqueIdentifier(),
          }
        : null,
    }));
  };

  const getTitle = (keyElement: ElementKey) => {
    switch (keyElement) {
      case 'meta_title':
        return 'SEO Title';
      case 'meta_description':
        return 'Meta Description';
      default:
        return 'H1';
    }
  };

  const getMetaFields = (key: number, keyElement: ElementKey) => ({
    key,
    keyElement,
    title: getTitle(keyElement),
    isOpen: activeMetaFieldSlideOver === keyElement,
    onClose: handleCloseMetaFields,
    options: getSelectOptions(keyElement),
    onChangeSelect,
    templateElements: templateElements[getElementType(keyElement)],
    onChangePrompt: (value: string, keyElement: ElementKey) => {
      const prop = getElementType(keyElement);
      setTemplateElements((prev) => ({
        ...prev,
        [prop]: {
          ...prev[prop],
          values: {
            ...prev[prop]?.values,
            prompt_instruction: value,
          },
        },
      }));
    },
  });

  appState.pageTitle(template?.name || 'Untitled Template');

  return (
    <>
      <Dialog
        title="Content Type"
        handleClose={() =>
          setContentTypeDialogOptions((prev) => ({ ...prev, isOpen: false }))
        }
        isOpen={contentTypeDialogOptions.isOpen}
      >
        <ContentTypeSelect
          clearable
          value={contentTypeDialogOptions.content?.id}
          onChange={(v) => {
            const content =
              contentTypeQuery.data?.data.find((value) => value.id === v) ??
              null;
            setContentTypeDialogOptions((prev) => ({ ...prev, content }));
          }}
          contentTypes={contentTypeQuery.data?.data ?? []}
          isLoading={contentTypeQuery.isLoading}
        />
      </Dialog>
      <Dialog
        title="Brand Voice"
        handleClose={() =>
          setBrandVoiceDialogOptions((prev) => ({ ...prev, isOpen: false }))
        }
        isOpen={brandVoiceDialogOptions.isOpen}
      >
        <BrandVoiceSelect
          value={brandVoiceDialogOptions.brandVoice?.id.toString()}
          onChange={(_, value) => {
            setBrandVoiceDialogOptions((prev) => ({
              ...prev,
              brandVoice: value,
            }));
          }}
        />
      </Dialog>
      <Dialog
        title="Context"
        isOpen={showContextDialog}
        handleClose={() => setShowContextDialog(false)}
      >
        <InputDecoration label="Context">
          <TextArea
            rows={12}
            minHeight={5}
            maxHeight={20}
            counter
            counterMax={1000}
            resize
            placeholder="Write your context here"
            value={templateInfo.context}
            onChange={(value) =>
              setTemplateInfo((prev) => ({
                ...prev,
                context: value.slice(0, 1000),
              }))
            }
          />
        </InputDecoration>
      </Dialog>
      <TemplateMetaFieldDialog
        {...getMetaFields(1, 'meta_title')}
        {...dialogProps}
      />
      <TemplateMetaFieldDialog
        {...getMetaFields(2, 'meta_description')}
        {...dialogProps}
      />
      <TemplateMetaFieldDialog
        {...getMetaFields(3, 'title')}
        {...dialogProps}
      />
      <CreatePageContainer hasSlideover>
        <ConfirmDialog
          isOpen={!!elementToBeDeleted}
          title="Delete Element"
          onConfirm={() => {
            handleDelete(elementToBeDeleted!);
            setElementToBeDeleted(null);
          }}
          onClose={() => setElementToBeDeleted(null)}
          description={[
            `Are you sure you want to delete the element "${
              elementToBeDeleted?.values?.display_name ??
              elementToBeDeleted?.display_name
            }"?`,
          ]}
        />
        <ConfirmDialog
          isOpen={!!metaFieldToBeDeleted}
          title="Delete meta field"
          onConfirm={() => {
            handleDelete(metaFieldToBeDeleted!);
            setMetaFieldToBeDeleted(null);
          }}
          onClose={() => setMetaFieldToBeDeleted(null)}
          description={[
            `Are you sure you want to delete the element "${
              metaFieldToBeDeleted?.values?.display_name ??
              metaFieldToBeDeleted?.display_name
            }"?`,
          ]}
        />
        <DndContext
          sensors={sensors}
          modifiers={[snapCenterToCursor]}
          onDragStart={(e) => {
            setLatestDraggedElementId(e.active.id as string);
            setActiveElement(e.active);
          }}
          collisionDetection={closestCenter}
          onDragEnd={(e) => {
            const over = e.over;

            const isAdding = (e.active.id as string).startsWith('draggable');

            if (over) {
              const element = over.data.current
                ?.el as DocumentElementResource & {
                uniqueId: string;
              };
              if (over.id === 'add-new-droppable' && activeElement) {
                setTemplateElements((prev) => ({
                  ...prev,
                  bodySections: [
                    ...prev.bodySections,
                    {
                      ...element,
                      values: {
                        ...values,
                        prompt_instruction:
                          element.action_type === 'prompt'
                            ? element.prompt
                            : null,
                      },
                      uniqueId: createUniqueIdentifier(),
                    },
                  ],
                }));
              }

              if (
                !isAdding &&
                over.id.toString().includes('add-item-and-change-order-drop')
              ) {
                const elementToSwitchWith = over.data.current?.el as {
                  uniqueId: string;
                };
                const draggedElement = e.active.data.current?.el as {
                  uniqueId: string;
                };

                const draggedIndex = templateElements.bodySections.findIndex(
                  (e) => e.uniqueId === draggedElement.uniqueId,
                );

                let elementToSwitchWithIndex =
                  templateElements.bodySections.findIndex(
                    (e) => e.uniqueId === elementToSwitchWith?.uniqueId,
                  );

                if (draggedIndex > elementToSwitchWithIndex) {
                  elementToSwitchWithIndex += 1;
                }

                setTemplateElements((prev) => {
                  const newElements = [...prev.bodySections];
                  return {
                    ...prev,
                    bodySections: arrayMove(
                      newElements,
                      draggedIndex,
                      elementToSwitchWithIndex,
                    ),
                  };
                });
              }
              if (
                isAdding &&
                over.id.toString().includes('add-item-and-change-order-drop')
              ) {
                const newElement = e.active.data?.current
                  ?.el as DocumentElementResource;
                setTemplateElements((prev) => {
                  const previousElements = prev.bodySections;
                  const newElements = [...previousElements];

                  const startIndex = element
                    ? newElements.findIndex(
                        (el) => element.uniqueId === el.uniqueId,
                      )
                    : -1;
                  const start = newElements.slice(0, startIndex + 1);
                  const end = newElements.slice(
                    startIndex + 1,
                    newElements.length,
                  );
                  return {
                    ...prev,
                    bodySections: [
                      ...start,
                      {
                        ...newElement,
                        values: {
                          ...values,
                          prompt_instruction:
                            newElement.action_type === 'prompt'
                              ? newElement.prompt
                              : null,
                        },
                        uniqueId: createUniqueIdentifier(),
                      },
                      ...end,
                    ],
                  };
                });
              }
            }

            setActiveElement(null);
          }}
        >
          {template && (
            <TemplateSlideOver
              key={1}
              elements={getFilteredSections('body')}
              isLoading={listElementsQuery.isLoading}
              title="Drag elements in to include them in the template."
            />
          )}
          <div className="flex w-full flex-grow flex-col justify-start overflow-y-auto pb-6">
            <div className="flex w-full justify-center">
              <div className="w-full max-w-4xl px-16">
                <div className="flex flex-col gap-4">
                  {isLoading ? (
                    <SkeletonLoader />
                  ) : (
                    <div className="group relative overflow-hidden">
                      <input
                        placeholder="Untitled"
                        className="w-full overflow-hidden border-none bg-primary-50 px-0 text-2xl font-bold placeholder:text-primary-400 hover:bg-primary-100 focus:bg-primary-100 focus:outline-none"
                        value={templateInfo.name}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                            e.currentTarget.blur();
                          }
                        }}
                        onChange={(e) =>
                          setTemplateInfo((prev) => ({
                            ...prev,
                            name: e.target.value,
                          }))
                        }
                      />
                      <Pencil className="absolute bottom-1 right-1 top-1 my-auto font-normal text-primary-600 opacity-0 group-hover:opacity-100" />
                    </div>
                  )}

                  {isLoading ? (
                    <SkeletonLoader height="md" />
                  ) : (
                    <div className="group relative -mt-4 overflow-hidden">
                      <textarea
                        placeholder="Template description"
                        rows={3}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                            e.currentTarget.blur();
                          }
                        }}
                        className="w-full resize-none overflow-hidden border-0 bg-primary-50 px-0 placeholder:text-primary-400 hover:bg-primary-100 focus:bg-primary-100"
                        value={templateInfo.description}
                        ref={descriptionRef}
                        onChange={(e) =>
                          setTemplateInfo((prev) => ({
                            ...prev,
                            description: e.target.value,
                          }))
                        }
                      />
                      <Pencil className="absolute bottom-1 right-1 top-1 my-auto text-primary-600 opacity-0 group-hover:opacity-100" />
                    </div>
                  )}
                  <TemplateSectionTitle title="Settings & Meta fields" />
                  <div className="mb-12 flex flex-wrap gap-4">
                    <Chip
                      isActive={true}
                      isLoading={isLoading}
                      variant={
                        contentTypeDialogOptions.content ? 'solid' : 'dashed'
                      }
                      title={`Type${
                        contentTypeDialogOptions.content
                          ? `: ${contentTypeDialogOptions.content?.name}`
                          : ': Blog post'
                      }`}
                      appendIcon={Plus}
                      onClick={() => {
                        setContentTypeDialogOptions((prev) => ({
                          ...prev,
                          isOpen: true,
                        }));
                      }}
                      onDelete={
                        contentTypeDialogOptions.content
                          ? () => {
                              setContentTypeDialogOptions((prev) => ({
                                ...prev,
                                content: null,
                              }));
                            }
                          : undefined
                      }
                    />
                    <Chip
                      isActive={true}
                      isLoading={isLoading}
                      variant={
                        brandVoiceDialogOptions.brandVoice ? 'solid' : 'dashed'
                      }
                      title={
                        brandVoiceDialogOptions.brandVoice
                          ? `Voice: ${brandVoiceDialogOptions.brandVoice.name}`
                          : `Voice: ${appState.currentProject!.default_brand_voice?.name ?? 'None'}`
                      }
                      appendIcon={Plus}
                      onClick={() => {
                        setBrandVoiceDialogOptions((prev) => ({
                          ...prev,
                          isOpen: true,
                        }));
                      }}
                      onDelete={
                        brandVoiceDialogOptions.brandVoice
                          ? () => {
                              setBrandVoiceDialogOptions((prev) => ({
                                ...prev,
                                brandVoice: null,
                              }));
                            }
                          : undefined
                      }
                    />
                    <Chip
                      isLoading={isLoading}
                      isActive={!!templateInfo.context}
                      title="Context"
                      onClick={() => setShowContextDialog(true)}
                    />
                    <Chip
                      {...getChipProps('meta_title')}
                      onClick={() => {
                        setActiveMetaFieldSlideOver('meta_title');
                      }}
                    />
                    <Chip
                      {...getChipProps('title')}
                      onClick={() => {
                        setActiveMetaFieldSlideOver('title');
                      }}
                    />
                    <Chip
                      {...getChipProps('meta_description')}
                      onClick={() => {
                        setActiveMetaFieldSlideOver('meta_description');
                      }}
                    />
                  </div>
                </div>
                <TemplateSectionTitle title="Body" />
                <div>
                  {isLoading ? (
                    <div className="flex flex-col">
                      <SkeletonLoader />
                      <div className="relative flex w-full justify-center">
                        <div className="relative h-10 w-0.5 border border-primary-300 text-center">
                          <div className="absolute left-1/2 top-1/2 z-30 -translate-x-1/2 -translate-y-1/2 transform"></div>
                        </div>
                      </div>
                      <SkeletonLoader />
                      <div className="relative flex w-full justify-center">
                        <div className="relative h-10 w-0.5 border border-primary-300 text-center">
                          <div className="absolute left-1/2 top-1/2 z-30 -translate-x-1/2 -translate-y-1/2 transform"></div>
                        </div>
                      </div>
                      <SkeletonLoader />
                      <div className="relative flex w-full justify-center">
                        <div className="relative h-10 w-0.5 border border-primary-300 text-center">
                          <div className="absolute left-1/2 top-1/2 z-30 -translate-x-1/2 -translate-y-1/2 transform"></div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    templateElements.bodySections.map((el, i, array) => (
                      <div key={el.uniqueId}>
                        {i === 0 && (
                          <ElementDropzone
                            noLine
                            activeElement={
                              draggingIndex > 0
                                ? activeElement?.data.current?.el
                                : undefined
                            }
                            noIcon={false}
                            isMoving={(activeElement?.id as string)?.includes(
                              'movable',
                            )}
                          />
                        )}
                        <div
                          className={i === draggingIndex ? 'opacity-30' : ''}
                        >
                          <TemplateItem
                            {...dialogProps}
                            onSelect={handleSelect}
                            onDelete={(element) =>
                              setElementToBeDeleted(element)
                            }
                            el={el}
                            onChange={handleChange}
                          />
                        </div>
                        <ElementDropzone
                          activeElement={
                            i > draggingIndex || i < draggingIndex - 1
                              ? activeElement?.data.current?.el
                              : undefined
                          }
                          el={el}
                          isMoving={(activeElement?.id as string)?.includes(
                            'movable',
                          )}
                          noIcon={i === array.length - 1}
                        />
                      </div>
                    ))
                  )}
                  {isLoading ? (
                    <SkeletonLoader height="md" />
                  ) : (
                    <TemplateDrop
                      isEmpty={templateElements.bodySections.length === 0}
                      el={activeElement?.data.current?.el}
                    />
                  )}
                </div>
              </div>
            </div>
            {createPortal(
              <DragOverlay
                dropAnimation={
                  latestDraggedElementId.includes('draggable')
                    ? null
                    : undefined
                }
              >
                {activeElement && (
                  <ShrinkableDragOverlay
                    noShrink={(activeElement.id as string).startsWith(
                      'draggable',
                    )}
                  >
                    <div className="flex  justify-center rounded-md bg-white shadow-base-small transition-all">
                      {(activeElement.id as string).startsWith('draggable') ? (
                        <TemplateCollapsable
                          el={activeElement?.data.current?.el}
                        />
                      ) : (
                        <div className="flex w-full flex-col p-2">
                          <TemplateItemLayout
                            el={activeElement?.data.current?.el}
                            hideDescription
                          />
                        </div>
                      )}
                    </div>
                  </ShrinkableDragOverlay>
                )}
              </DragOverlay>,
              document.body,
            )}
          </div>
        </DndContext>
      </CreatePageContainer>
    </>
  );
};
