import { createRoute } from '@tanstack/react-router';
import { rootRoute } from '../Router';
import {
  guards,
  hasActiveSubscription,
  hasProject,
  isAuthenticated,
} from '../Router/guards';
import { validateTanStackSearch } from '@/utils';
import { Diff } from './Diff';

export const routes = [
  createRoute({
    getParentRoute: () => rootRoute,
    validateSearch: validateTanStackSearch,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
    },
    path: '/diff',
    component: () => <Diff />,
  }),
];
