import React from 'react';
import { CheckSquare2 } from 'lucide-react';
import { Square } from 'lucide-react';
import SimpleInput from '@/Components/SimpleInput';

type Props = {
  items: { item: string | JSX.Element; id: number }[];
  values?: number[];
  onChange: (values: number[]) => void;
  filterValue?: string;
  onFilterValueChange?: (value: string) => void;
  children?: React.ReactNode;
};

export const MultipleItemSelector = ({
  items,
  values = [],
  onChange,
  filterValue,
  onFilterValueChange,
  children,
}: Props) => {
  const handleAccept = (id: number) => {
    if (values.includes(id)) {
      onChange(values.filter((valueId) => valueId !== id));
    } else {
      onChange([...values, id]);
    }
  };

  return (
    <div className="flex flex-col gap-1 p-1">
      {filterValue !== undefined && onFilterValueChange && (
        <SimpleInput
          value={filterValue}
          className="h-8 rounded-md"
          placeholder="Filter tags..."
          onChange={(e) => onFilterValueChange(e.target.value)}
        />
      )}
      {children}
      {items.map((item) => (
        <div
          className={`cursor-pointer rounded-md px-2 py-1 text-sm font-bold text-gray-400 hover:bg-gray-100`}
          onClick={() => handleAccept(item.id)}
        >
          <p
            className={`flex w-full items-center justify-between gap-2 normal-case`}
          >
            {item.item}{' '}
            {values.includes(item.id) ? <CheckSquare2 /> : <Square />}
          </p>
        </div>
      ))}
    </div>
  );
};
