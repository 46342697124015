import { useAppStore } from '@/Pages/AppLoader/stores';
import { useKeywordInjectionStore } from '@/Pages/Document/stores';
import {
  ContentSuggestionType,
  DocumentKeywordContentSuggestionResource,
  DocumentResource,
} from '@/api/openapiSchemas';
import * as React from 'react';
import { useShallow } from 'zustand/react/shallow';
import { removeLigaturesFromMarkdown } from '../../../PlateEditor/lib/serialize';
import { DocumentSuggestionEventPayload } from '@/types';
import { useEditor } from '@/Components/Utils/v2/api';
import { useDocumentDocumentKeywordContentSuggestions } from '@/api/openapiComponents';

export const useKeywordInjectionListener = (
  document: DocumentResource,
  type: ContentSuggestionType,
) => {
  const editor = useEditor();
  const projectId = useAppStore((state) => state.currentProject!.id);
  const insertionRef = React.useRef('');
  const addRef = React.useRef('');
  const {
    keyword,
    addKeywordSuggestion,
    handleChangeUpdatedLoading,
    handleChangeAddedLoading,
    keywordSuggestions,
    newKeywordSuggestions,
    mode,
    addNewKeywordSuggestion,
    hardResetSlideOver,
    resetSlideOver,
  } = useKeywordInjectionStore(
    useShallow((state) => ({
      keyword: state.keyword,
      mode: state.mode,
      newKeywordSuggestions: state.newKeywordSuggestions,
      addKeywordSuggestion: state.addKeywordSuggestion,
      handleChangeUpdatedLoading: state.handleChangeUpdatedLoading,
      handleChangeAddedLoading: state.handleChangeAddedLoading,
      keywordSuggestions: state.keywordSuggestions,
      addNewKeywordSuggestion: state.addNewKeywordSuggestion,
      hardResetSlideOver: state.hardResetSlideOver,
      resetSlideOver: state.resetSlideOver,
    })),
  );

  const { mutateAsync: startLookingForKeywordSuggestions } =
    useDocumentDocumentKeywordContentSuggestions();

  const loadNewData = () => {
    if (mode === 'UPDATE EXISTING') {
      handleChangeUpdatedLoading(true);
    } else {
      handleChangeAddedLoading(true);
    }
  };

  const initialize = (
    data: {
      data: DocumentKeywordContentSuggestionResource;
    },
    mode: ContentSuggestionType,
  ) => {
    if (mode === 'update_existing_paragraph') {
      insertionRef.current = data.data.id;
    } else {
      addRef.current = data.data.id;
    }
    const url = `documents.${document.id}`;
    const content = editor?.string({
      anchor: { path: [0], offset: 0 },
      focus: { path: [editor.children.length - 1], offset: 0 },
    });
    window.Echo.private(url).listen(
      'DocumentContentSuggestionCompletedEvent',
      (e: DocumentSuggestionEventPayload) => {
        if (e.uuid === data.data.id && e.suggestion_type === type) {
          window.Echo.leave(url);
          if (e.suggestion_type === 'update_existing_paragraph') {
            insertionRef.current === '';
            handleChangeUpdatedLoading(false);
          } else {
            addRef.current === '';
            handleChangeAddedLoading(false);
          }
        }
      },
    );
    window.Echo.private(url).listen(
      'DocumentContentSuggestion',
      (e: DocumentSuggestionEventPayload) => {
        if (e.uuid === data.data.id && e.suggestion_type === type) {
          if (e.current.text !== '') {
            if (e.suggestion_type === 'new_paragraph') {
              addNewKeywordSuggestion(
                {
                  newMarkdownText: e.new.markdown,
                  text: removeLigaturesFromMarkdown(e.current.text),
                },
                editor!,
                content!,
              );
            } else {
              addKeywordSuggestion(
                {
                  markdownText: e.current.markdown,
                  newMarkdownText: e.new.markdown,
                  text: removeLigaturesFromMarkdown(e.current.text),
                },
                editor!,
                content!,
              );
            }
          }
        }
      },
    );
  };

  React.useEffect(() => {
    if ((document.document_report?.word_count.actual || 0) < 100) {
      resetSlideOver(keyword);
      return;
    }
    if (
      keyword !== '' &&
      ((type === 'update_existing_paragraph' &&
        keywordSuggestions.length === 0) ||
        (type === 'new_paragraph' && newKeywordSuggestions.length === 0))
    ) {
      if (type === 'update_existing_paragraph') {
        handleChangeUpdatedLoading(true);
      } else {
        handleChangeAddedLoading(true);
      }

      Promise.all([
        startLookingForKeywordSuggestions({
          pathParams: {
            document: document.id,
            project: projectId,
          },
          body: {
            keyword,
            amount: 3,
            suggestion_type: type,
          },
        }),
      ]).then((data) => {
        initialize(data[0], type);
      });
    }
    return () => {
      window.Echo.leave(`documents.${document.id}`);
      if (insertionRef.current !== '') {
        handleChangeUpdatedLoading(false);
        insertionRef.current = '';
      }
      if (addRef.current !== '') {
        handleChangeAddedLoading(false);
        addRef.current = '';
      }
    };
  }, [
    projectId,
    document.id,
    keyword,
    type,
    (document.document_report?.word_count.actual || 0) < 100,
  ]);

  React.useEffect(() => {
    return () => {
      hardResetSlideOver();
    };
  }, []);

  return { startLookingForKeywordSuggestions, initialize, loadNewData };
};
