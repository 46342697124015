import { SeoAiLogoBlack } from '@/Components/SVGs/SeoAiLogoBlack';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { ToggleGroup } from '@/Components/v2/ToggleGroup/ToggleGroup';
import { Button } from '@/Components/v2/Button';
import { useEffect, useState } from 'react';
import { Input } from '@/Components/v2/Input/Input';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { Stars, X } from 'lucide-react';
import { useNavigate } from '@tanstack/react-router';
import { AdditionalContextForm } from '@/Components/AdditionalContextForm';
import {
  useAnalyzePage,
  useListContentTypes,
  useListDocumentTemplates,
  useProjectGenerateDocument,
  useProjectsShow,
} from '@/api/openapiComponents';
import { useGetRandomPlaceHolder } from '@/data/placeholders';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { ErrorHelper } from '@/Services/ErrorHandling';
import { ErrorAlert } from '@/Components/v2/Alert';
import { tracking } from '@/Services/tracking/Tracking';

export const Create1ClickDraft = () => {
  const appState = useAppStore();
  const projectQuery = useProjectsShow({
    pathParams: { project: appState.currentProject!.id },
  });
  const placeholder = useGetRandomPlaceHolder();
  const contentTypeQuery = useListContentTypes({});
  const templatesQuery = useListDocumentTemplates({});
  const generateNewDocumentMutation = useProjectGenerateDocument();

  const templates = templatesQuery.data?.data ?? [];
  const blogPostType = contentTypeQuery.data?.data.find(
    (type) => type.name === 'Blog post',
  );

  const pcdTemplate = templates.find(
    (template) => template.name === 'Product collection description',
  );
  const pdTemplate = templates.find(
    (template) => template.name === 'Product description',
  );

  const types = [
    ...(pcdTemplate
      ? [
          {
            id: pcdTemplate.id,
            name: pcdTemplate.name,
            instruction:
              "Is there any product collection info you can provide the AI? (It's optional)",
          },
        ]
      : []),
    ...(pdTemplate
      ? [
          {
            id: pdTemplate.id,
            name: pdTemplate.name,
            instruction:
              "Is there any product info you can provide the AI? (It's optional)",
          },
        ]
      : []),
    {
      id: -1,
      name: 'Blog post',
      instruction:
        "Is there any additional context you can provide the AI? (It's optional)",
    },
  ];

  const navigate = useNavigate();

  const [type, setType] = useState<number>();
  const [mainKeyword, setMainKeyword] = useState('');

  const [importUrl, setImportUrl] = useState('');
  const [brief, setBrief] = useState('');
  const [briefFromUrl, setBriefFromUrl] = useState('');
  const [hasImportedContext, setHasImportedContext] = useState(false);
  const [selectedTab, setSelectedTab] = useState<'manual' | 'from-url'>(
    'manual',
  );

  useEffect(() => {
    if (templatesQuery.isSuccess) {
      setType(types[0].id);
    }
  }, [templatesQuery.isSuccess]);

  const submit = () => {
    generateNewDocumentMutation.mutate(
      {
        pathParams: {
          project: appState.currentProject!.id,
        },
        body: {
          document_template_id: type === -1 ? blogPostType?.id : type,
          keyword: mainKeyword,
          content_type_id:
            type === -1
              ? blogPostType?.id
              : templates.find((template) => template.id === type)?.content_type
                  ?.id,
          settings: {
            context: {
              value: (selectedTab === 'from-url' ? briefFromUrl : brief).slice(
                0,
                projectQuery.data?.data.language.brief_length ?? 0,
              ),
              type: selectedTab === 'from-url' ? 'url' : 'none',
              type_value: selectedTab === 'from-url' ? importUrl : undefined,
            },
          },
        },
      },
      {
        onSuccess: () => {
          navigate({
            to: `/created-content`,
          });
        },
      },
    );
  };

  const analyseUrlMutation = useAnalyzePage({
    onSuccess: (data) => {
      setBriefFromUrl(data.data.content);
      setHasImportedContext(true);
    },
  });

  const handleImportBrief = () => {
    analyseUrlMutation.mutate({
      body: {
        url: importUrl,
        content_format: 'text',
        page_type: 'analyze',
      },
    });
  };

  const errorHelper = new ErrorHelper(generateNewDocumentMutation.error as any);

  return (
    <div className="h-screen overflow-y-scroll py-4 text-gray-800">
      <div className="absolute right-2 top-2">
        <IconButton
          icon={X}
          onClick={() => {
            tracking.event('onboarding_cancel');
            navigate({ to: '/getting-started' });
          }}
        />
      </div>
      <div className="mx-auto flex min-h-full w-[40rem] max-w-[95vw] flex-col justify-center px-2">
        <h2 className="mb-3 text-center font-work text-[25px] font-bold leading-normal text-black">
          Create a 1-click AI draft
        </h2>

        <p className="mb-8 text-center  text-gray-400">
          Generate SEO-optimized content in minutes, not hours
        </p>
        {errorHelper.isError() && errorHelper.status() !== 422 && (
          <div className="mx-auto mt-2 w-full max-w-xl">
            <ErrorAlert title={errorHelper.message()} />
          </div>
        )}

        <form
          onSubmit={(e) => {
            e.preventDefault();
            submit();
          }}
          className="w-full animate-fade-slow"
        >
          {/* Language input */}
          <InputDecoration
            label="Type of content"
            required
            description="What type of content do you want to create?"
          >
            {templatesQuery.isLoading ? (
              <div className="flex flex-col gap-2">
                <SkeletonLoader height="sm" />
                <SkeletonLoader height={'sm'} />
                <SkeletonLoader height={'sm'} />
              </div>
            ) : (
              <ToggleGroup
                options={types.map((type) => ({
                  id: type.id,
                  title: type.name,
                }))}
                value={type}
                onChange={setType}
              />
            )}
          </InputDecoration>

          <div className="mt-16">
            <InputDecoration
              required
              label="Main Keyword"
              description="What is the primary keyword you want the content to rank for?"
            >
              <Input
                error={errorHelper.has('keyword')}
                value={mainKeyword}
                onChange={setMainKeyword}
                placeholder="Main keyword..."
              />
            </InputDecoration>
          </div>

          <div className="mt-16">
            <InputDecoration
              label="Add extra information"
              description={
                types.find((t) => t.id === type)?.instruction ??
                types[0].instruction
              }
            >
              <AdditionalContextForm
                hideImportedContext
                brief={brief}
                onBriefChange={setBrief}
                briefFromUrl={briefFromUrl}
                onBriefFromUrlChange={setBriefFromUrl}
                url={importUrl}
                onUrlChange={setImportUrl}
                isLoading={analyseUrlMutation.isPending}
                briefLimit={projectQuery.data?.data.language.brief_length ?? 0}
                isImportSuccess={hasImportedContext}
                onImport={handleImportBrief}
                placeholder={placeholder}
                onTabChange={setSelectedTab}
                selectedTab={selectedTab}
                disabled={projectQuery.isLoading}
              />
            </InputDecoration>
          </div>

          {/* Submit */}
          <div className="mx-auto mt-16 w-96">
            <Button
              fullWidth
              color="secondary"
              type="submit"
              prependIcon={Stars}
              text="Generate draft"
              isLoading={
                generateNewDocumentMutation.isPending ||
                generateNewDocumentMutation.isSuccess
              }
            />
          </div>
        </form>
      </div>
    </div>
  );
};
