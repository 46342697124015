import { useContext, useState } from 'react';
import { PopoverContext } from '@/Components/SimplePopover';
import { Button } from '@/Components/v2/Button';
import { Input } from '@/Components/v2/Input/Input';

type Props = {
  range?: {
    min?: number | null;
    max?: number | null;
  };
  values: {
    min?: number | string | null;
    max?: number | string | null;
  };
  onAccept: (values: { min?: number; max?: number }) => void;
};

export const formatRange = (values: {
  name?: string;
  min?: number | null;
  max?: number | null;
}) => {
  if (values.min === undefined && values.max === undefined) {
    return;
  }
  if (values.min !== undefined && values.max !== undefined) {
    return `${values.name ? `${values.name}: ` : ''}${values.min} - ${
      values.max
    }`;
  }
  return `${values.name ? `${values.name}: ` : ''}${
    values.min !== undefined ? `Min. ${values.min}` : ''
  } ${values.max !== undefined ? `Max. ${values.max}` : ''}`;
};

export const RangeSelector = ({ values, onAccept, range }: Props) => {
  const [min, setMin] = useState('' + (values.min ?? ''));
  const [max, setMax] = useState('' + (values.max ?? ''));

  const [minError, setMinError] = useState(false);
  const [maxError, setMaxError] = useState(false);

  const closePopover = useContext(PopoverContext);

  const handleAccept = () => {
    const minError = Number.isNaN(Number(min)) && min !== '';
    const maxError = Number.isNaN(Number(max)) && max !== '';
    setMinError(minError);
    setMaxError(maxError);

    if (!minError && !maxError) {
      closePopover();
      onAccept({
        min: min === '' ? undefined : Number(min),
        max: max === '' ? undefined : Number(max),
      });
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleAccept();
      }}
    >
      <div className="flex justify-between gap-2 px-2 py-4 pb-2">
        <div className="flex flex-col text-sm">
          <div className="w-32">
            <Input
              placeholder="From"
              fullWidth
              dense
              type="number"
              value={min}
              min={range?.min}
              max={range?.max}
              onChange={(v) => setMin(v)}
              error={minError ? 'Minimum reached' : ''}
            />
          </div>
        </div>
        <div className="flex flex-col text-sm">
          <div className="w-32">
            <Input
              placeholder="To"
              fullWidth
              dense
              type="number"
              value={max}
              min={range?.min}
              max={range?.max}
              onChange={(v) => setMax(v)}
              error={maxError ? 'Max reached' : ''}
            />
          </div>
        </div>
      </div>
      <div className="m-2 mb-4">
        <Button
          color="secondary"
          variant="outline"
          fullWidth
          size="base"
          type="submit"
          text="Accept"
        />
      </div>
    </form>
  );
};
