import { useEffect, useState } from 'react';

type Props = {
  texts: string[];
  durations: number[];
}

export const TextLoader = ({ texts, durations }: Props) => {
  const [textIndex, setTextIndex] = useState(0);
  const [show, setShow] = useState(true);

  useEffect(() => {
    const textTimer = setInterval(() => {
      setShow(false);
      setTextIndex((prev) => (prev >= texts.length - 1 ? 0 : prev + 1));
      setTimeout(() => {
        setShow(true);
      }, 500);
    }, durations[textIndex]);

    return () => {
      clearInterval(textTimer);
    };
  }, [textIndex]);

  return (
    <div className="relative h-4 w-96 animate-pulse">
      {texts.map((text, index) => (
        <p
          className={`unselectable absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center transition-all duration-1000 ${
            index === textIndex && show ? 'opacity-50' : 'scale-50 opacity-0'
          }`}
        >
          {text}
        </p>
      ))}
    </div>
  );
};
