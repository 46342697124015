import { useEffect, useState } from 'react';
import { SlideOver } from '@/Components/SlideOver';
import { TaskItem } from '@/Components/TaskItem';
import { SlideOverGeneratedContent } from '@/Components/SlideOverGeneratedContent';
import { MetadataSuggestionItem } from './components/MetadataSuggestionItem';
import { DocumentResource, ProjectResource } from '@/api/openapiSchemas';
import { useDocumentStore } from '@/Pages/Document/stores';
import { useShallow } from 'zustand/react/shallow';
import Tabs from '@/Components/Tabs';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { X } from 'lucide-react';
import { useDocumentMetaDescriptionSuggestions } from '@/api/openapiComponents';
import { NoYourElement } from '../NoYourElement';

type Props = {
  project: ProjectResource;
  document: DocumentResource;
  isOpen: boolean;
  onClose: () => void;
  hideSuggestions?: boolean;
};

export const MetadataSlideOver = ({
  isOpen,
  onClose,
  document,
  project,
  hideSuggestions,
}: Props) => {
  const [tab, setTab] = useState<'IDEAS' | 'COMPETITORS' | 'YOU'>('IDEAS');

  const [rewriteParams, setRewriteParams] = useState<{
    context: string;
    type: 'variants' | 'paraphrase' | 'default';
  }>({
    context: document.title,
    type: 'default',
  });

  const { setMetaDescription, metaDescription } = useDocumentStore(
    useShallow(({ setMetaDescription, metaDescription }) => ({
      setMetaDescription,
      metaDescription,
    })),
  );

  const metadataQuery = useDocumentMetaDescriptionSuggestions(
    {
      pathParams: {
        document: document.id,
        project: project.id,
      },
      queryParams: {
        type: rewriteParams.type,
        context: rewriteParams.context,
      },
    },
    {
      enabled: false,
      structuralSharing: (oldData: any, newData: any) => {
        if (oldData) {
          return { data: [...oldData.data, ...newData.data] };
        }
        return newData;
      },
    },
  );

  const handleSuggestionsBasedOf = (
    context: string,
    type: 'variants' | 'paraphrase',
  ) => {
    setTab('IDEAS');
    setRewriteParams({ type, context });
  };

  useEffect(() => {
    if (
      !metadataQuery.data?.data &&
      !metadataQuery.isLoading &&
      isOpen &&
      !hideSuggestions
    ) {
      metadataQuery.refetch();
    }
  }, [isOpen, hideSuggestions, rewriteParams]);

  return (
    <SlideOver
      title="Meta Description"
      subHeading="A good meta description is a brief summary aimed at enticing users to click through in search results."
      color="bg-orange-600"
      onClose={onClose}
      isOpen={isOpen}
    >
      <div className="mb-6 flex flex-col gap-1">
        <TaskItem
          completed={metaDescription
            .toLowerCase()
            .includes(document.keyword.name?.toLowerCase())}
          task="Include target keyword"
        />
        <TaskItem
          completed={
            metaDescription.length >= 100 && metaDescription.length <= 200
          }
          task="Between 100-200 characters long"
        />
      </div>
      <Tabs
        selectedTab={tab}
        onChange={setTab as (tab: string) => void}
        tabs={[
          {
            name: 'IDEAS',
          },
          {
            name: 'COMPETITORS',
          },
          {
            name: 'YOU',
          },
        ]}
        className="mb-4"
      />
      <SlideOverGeneratedContent
        loading={metadataQuery.isLoading || metadataQuery.isFetching}
        error={metadataQuery.error?.body}
        skeletonLoaders={{
          count: 3,
          height: 'lg',
        }}
        canGenerateMore={tab === 'IDEAS' && !hideSuggestions}
        onFetchItems={metadataQuery.refetch}
      >
        {tab === 'IDEAS' &&
          rewriteParams &&
          rewriteParams.type !== 'default' && (
            <p className="mb-2 flex items-center justify-between text-sm italic text-gray-600">
              {{
                variants: 'More like',
                optimised: 'Optimizing',
                paraphrase: 'Paraphrasing',
                elaborate: 'Elaborating on',
              }[rewriteParams.type] +
                ' "' +
                rewriteParams.context +
                '"'}
              <IconButton
                size="sm"
                icon={X}
                onClick={() =>
                  setRewriteParams({
                    context: document.meta_title ?? '',
                    type: 'default',
                  })
                }
              />
            </p>
          )}
        {tab === 'IDEAS' &&
          (hideSuggestions ? (
            <p className="mt-8 text-center  text-gray-600">
              In order to get suggestions, please create a title for your
              content and write at least 20 words of content
            </p>
          ) : (
            <>
              {metadataQuery.data?.data.map((item) => (
                <MetadataSuggestionItem
                  keyword={document.keyword.name}
                  value={item.content}
                  onClick={setMetaDescription}
                  disabled={hideSuggestions}
                  onMore={(value) =>
                    setRewriteParams({
                      context: value,
                      type: 'variants',
                    })
                  }
                  onParaphrase={(value) =>
                    setRewriteParams({ context: value, type: 'paraphrase' })
                  }
                />
              ))}
            </>
          ))}
        {tab === 'COMPETITORS' &&
          document.document_report?.competitors.map((competitor) => (
            <MetadataSuggestionItem
              position={competitor.position}
              source={competitor.url}
              keyword={document.keyword.name}
              disabled={hideSuggestions}
              value={competitor.description}
              onClick={setMetaDescription}
              onMore={(value) => handleSuggestionsBasedOf(value, 'variants')}
              onParaphrase={(value) =>
                handleSuggestionsBasedOf(value, 'paraphrase')
              }
            />
          ))}
        {tab === 'YOU' &&
          (document.meta_description.length ? (
            <MetadataSuggestionItem
              keyword={document.keyword.name}
              value={document.meta_description ?? ''}
              disabled={hideSuggestions}
              onClick={setMetaDescription}
              onMore={(value) => handleSuggestionsBasedOf(value, 'variants')}
              onParaphrase={(value) =>
                handleSuggestionsBasedOf(value, 'paraphrase')
              }
            />
          ) : (
            <NoYourElement
              type="a meta description"
              onClick={() => setTab('IDEAS')}
            />
          ))}
      </SlideOverGeneratedContent>
    </SlideOver>
  );
};
