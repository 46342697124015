type Props = {
  text: string | React.ReactNode;
  link?: { href: string; text: string };
};
export const Subheading = ({ text, link }: Props) => (
  <p className="mt-6 w-[36rem] text-sm">
    {text}{' '}
    {link && (
      <>
        <a
          href={link.href}
          className="underline"
          target="_blank"
          rel="noreferrer"
        >
          {link.text}
        </a>
      </>
    )}
  </p>
);
