import { useGetDraggableState } from '@/Hooks/useGetDraggableState';

type Props = {
  id: string;
}

export function Divider({ id }: Props) {
  const { isHoveredElement } = useGetDraggableState();
  return isHoveredElement(id) ? (
    <div className="h-[3px] w-full bg-gray-400" />
  ) : null;
}
