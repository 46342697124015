import { AlertCircle, AlertOctagon, AlertTriangle } from 'lucide-react';
import { Alert } from './Alert';

type Props = {
  title?: string;
  body?: string | string[] | undefined;
};
const ErrorAlert = ({ title = 'An error occured', body }: Props) => (
  <Alert title={title} color="red" body={body} />
);

ErrorAlert.displayName = 'ErrorAlert';

export { ErrorAlert };
