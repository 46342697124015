import { MoreVertical } from 'lucide-react';
import { ScoreLabel } from '@/Components/Labels/ScoreLabel';
import { Button } from '@/Components/v2/Button';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { PopoverMenu } from '@/Components/v2/Menu/ButtonMenu';

type Props = {
  position: number;
  value: string;
  tcs: number;
  url: string;
  targetTcs: number;
  onInsert: (value: string) => void;
  onMore: (value: string) => void;
  onOptimize: (value: string) => void;
  onElaborate: (value: string) => void;
  onParaphrase: (value: string) => void;
};

export const CompetitorItem = ({
  position,
  value,
  tcs,
  url,
  targetTcs,
  onInsert,
  onMore,
  onOptimize,
  onElaborate,
  onParaphrase,
}: Props) => {
  return (
    <div className="mb-4 flex animate-fade-slow rounded-lg border border-gray-300 p-4">
      <div className="flex-grow">
        <a
          className="mb-2 text-sm hover:underline"
          title={url}
          href={url}
          target="_blank"
          rel="noreferrer"
        >
          <span className={'mr-1'}>#{position}</span>{' '}
          {new URL(url).host.replace('www.', '')}
        </a>
        <p className="mb-4 mt-2 font-extrabold">{value}</p>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex items-center justify-center gap-2">
          <ScoreLabel score={tcs} color={tcs >= targetTcs ? 'green' : 'gray'} />

          {/* Actions */}
          <PopoverMenu
            items={[
              {
                title: 'More like this',
                onClick: () => onMore(value),
              },
              {
                title: 'Elaborate on topic',
                onClick: () => onElaborate(value),
              },
              {
                title: 'Paraphrase',
                onClick: () => onParaphrase(value),
              },
              {
                title: 'Optimize',
                onClick: () => onOptimize(value),
              },
            ]}
            trigger={<IconButton icon={MoreVertical} size="sm" />}
          />
        </div>
        <Button
          size="xs"
          dense
          variant="ghost"
          text="Insert"
          onClick={() => onInsert(value)}
        />
      </div>
    </div>
  );
};
