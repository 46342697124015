import * as React from 'react';
import { twMerge } from 'tailwind-merge';
import { Loader } from './Loader';

type Props = {
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  children: React.ReactNode;
  isLoading?: boolean;
  className?: React.ComponentProps<'button'>['className'];
  classNameLoader?: string;
  loadWithText?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>

export default React.forwardRef<HTMLButtonElement, Props>(function SimpleButton(
  {
    leftIcon,
    rightIcon,
    children,
    isLoading,
    className,
    loadWithText,
    disabled,
    type,
    classNameLoader,
    ...props
  },
  ref,
) {
  const getChildren = () => {
    if (isLoading && loadWithText) {
      if (rightIcon) {
        return (
          <>
            {children}
            <Loader
              className={`${classNameLoader || 'stroke-green-400'} h-5 w-5`}
            />
          </>
        );
      }
      return (
        <>
          <Loader
            className={`${classNameLoader || 'stroke-green-400'} h-5 w-5`}
          />
          {children}
        </>
      );
    }
    return (
      <>
        {isLoading && (
          <Loader
            className={`${
              classNameLoader || 'stroke-green-400'
            } absolute left-[calc(50%-0.75rem)] h-5 w-5`}
          />
        )}
        {leftIcon && isLoading ? (
          <span className={`invisible`}>{leftIcon}</span>
        ) : (
          leftIcon
        )}
        {children && isLoading ? (
          <span className={`invisible`}>{children}</span>
        ) : (
          children
        )}
        {rightIcon && isLoading ? (
          <span className={`invisible`}>{rightIcon}</span>
        ) : (
          rightIcon
        )}
      </>
    );
  };
  return (
    <button
      ref={ref}
      {...props}
      disabled={isLoading || disabled}
      type={type ?? 'button'}
      className={twMerge(
        `relative flex items-center gap-1 rounded p-3  font-semibold uppercase text-gray-500 ${
          !(isLoading || disabled)
            ? 'hover:bg-gray-100'
            : 'cursor-default opacity-50'
        } ${className}`,
      )}
    >
      {getChildren()}
    </button>
  );
});
