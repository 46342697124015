import React, { ReactNode, useState } from 'react';
import { IconButton } from '../IconButton/IconButton';
import { LucideProps, X } from 'lucide-react';
import { isComponentFunction } from '../utils';

type Props = {
  type?: 'text' | 'password' | 'number';
  value: string;
  name?: string;
  counter?: boolean | number;
  counterMax?: number;
  max?: number;
  min?: number;
  hint?: string;
  persistentHint?: boolean;
  placeholder?: string;
  disabled?: boolean;
  error?: string | string[] | null;
  dense?: boolean;
  fullWidth?: boolean;
  clearable?: boolean;
  autoComplete?: string;
  prependIcon?: React.ComponentType<LucideProps> | ReactNode;
  appendIcon?: React.ComponentType<LucideProps> | ReactNode;
  prependText?: string;
  appendText?: string;
  onChange: (value: string) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

const Input = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      type = 'text',
      onChange,
      value,
      placeholder,
      name,
      disabled,
      autoComplete,
      counter,
      counterMax,
      hint,
      dense,
      fullWidth,
      persistentHint,
      clearable,
      error,
      prependIcon,
      appendIcon,
      appendText,
      prependText,
      onKeyPress,
      max,
      min,
      onFocus,
    },
    ref,
  ) => {
    const PrependIcon = prependIcon;
    const AppendIcon = appendIcon;

    const [isFocussed, setIsFocussed] = useState(false);

    const getContainerColors = () => {
      if (disabled) return 'bg-gray-100 border-primary-300';

      if (error) {
        return `${isFocussed ? 'border-red-500' : 'border-red-300'}`;
      }

      return `bg-white ${
        isFocussed ? 'border-secondary-400' : 'border-primary-300'
      }`;
    };

    const getTextColors = () => {
      if (disabled) return 'text-primary-400';

      return 'text-primary';
    };

    return (
      <div className={fullWidth ? 'w-full' : ''}>
        <div
          className={`flex items-center rounded-lg border ${getContainerColors()} ${
            dense ? 'px-1' : 'px-2'
          }`}
        >
          {PrependIcon &&
            (isComponentFunction(PrependIcon) ? (
              <PrependIcon className={`h-4 w-4 text-primary-600`} />
            ) : (
              (PrependIcon as unknown as ReactNode)
            ))}
          {prependText && (
            <div className={`ml-1 text-primary-400`}>{prependText}</div>
          )}
          <input
            ref={ref}
            className={`flex-grow rounded-md border-none bg-transparent outline-none ring-0 placeholder:italic placeholder:text-gray-400 ${
              dense ? 'px-2 py-0.5' : 'px-2 py-1.5'
            } ${getTextColors()} ${disabled ? 'cursor-not-allowed' : ''}`}
            type={type}
            disabled={disabled}
            onChange={(e) => onChange(e.target.value)}
            onFocus={() => setIsFocussed(true)}
            onBlur={() => setIsFocussed(false)}
            onKeyDown={onKeyPress}
            onFocusCapture={onFocus}
            name={name}
            value={value}
            max={max}
            min={min}
            placeholder={placeholder}
            autoComplete={autoComplete}
          />
          {clearable && value.length > 0 && (
            <IconButton icon={X} size="sm" onClick={() => onChange('')} dense />
          )}
          {appendText && (
            <div className={`mr-1 text-primary-400`}>{appendText}</div>
          )}
          {AppendIcon &&
            (isComponentFunction(AppendIcon) ? (
              <AppendIcon className={`h-4 w-4 text-primary-600`} />
            ) : (
              (AppendIcon as unknown as ReactNode)
            ))}
        </div>
        {(hint || error || counter) && (
          <div className="w-full">
            <div
              className={`flex w-full items-start pl-3 pr-2 pt-1 text-sm ${getTextColors()}`}
            >
              <div
                className={`flex-grow break-words transition-opacity ${
                  error ? 'text-red-500' : ''
                } ${isFocussed || persistentHint || error ? '' : 'opacity-0'}`}
              >
                {error ?? hint}
              </div>
              {counter && (
                <div
                  className={`flex-shrink-0 ${
                    counterMax &&
                    (typeof counter === 'number' ? counter : value.length) >
                      counterMax
                      ? 'text-red-500'
                      : ''
                  }`}
                >
                  {counter &&
                    (typeof counter === 'number' ? counter : value.length)}
                  {counterMax && counter && `/${counterMax}`}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  },
);

Input.displayName = 'Input';
export { Input };
