import { Autocomplete } from '@/Components/v2/Select/AutoComplete';
import {
  useProjectsDomainUrlsStore,
  useProjectsUrlsIndex,
  useUpdateDocument,
} from '@/api/openapiComponents';
import { DropdownItem } from '@/Components/v2/Select/hooks';
import { useDebounce } from '@/Hooks/useDebounce';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { useState } from 'react';
import { useDocumentStore } from '@/Pages/Document/stores';
import { Info, Plus } from 'lucide-react';
import { Tooltip } from '@/Components/v2/Tooltip';
import { useQueryClient } from '@tanstack/react-query';
import { DocumentResource, ProjectUrlResource } from '@/api/openapiSchemas';
import { ErrorHelper } from '@/Services/ErrorHandling';
import { hasher } from '@/Pages/AppLoader';

type Props = {
  disabled?: boolean;
  document: DocumentResource;
};

export const UrlInput = ({ document, disabled }: Props) => {
  const appState = useAppStore();
  const client = useQueryClient();

  const projectUrl = useDocumentStore((state) => state.projectUrl);
  const setProjectUrl = useDocumentStore((state) => state.setProjectUrl);

  const [urlSearch, setUrlSearch] = useState('');
  const [debouncedUrlSearch, setDebouncedUrlSearch] = useState('');

  useDebounce(
    () => {
      setDebouncedUrlSearch(urlSearch);
    },
    [urlSearch],
    300,
  );

  const addUrlmutation = useProjectsDomainUrlsStore();
  const updateDocumentMutation = useUpdateDocument();
  const urlQuery = useProjectsUrlsIndex({
    pathParams: { project: appState.currentProject!.id },
    queryParams: {
      filters: debouncedUrlSearch ? { search: debouncedUrlSearch } : undefined,
    },
  });

  const handleSetDocumentUrl = (url?: DropdownItem) => {
    if (!url) {
      setUrlSearch('');
    }
    if (url?.value === 'add-new-url') {
      addUrlmutation.mutate(
        {
          body: {
            url: urlSearch,
          },
          pathParams: {
            project: appState.currentProject!.id,
          },
        },
        {
          onSuccess: (data) => {
            setProjectUrl(data.data);
          },
        },
      );
    } else {
      setProjectUrl(urlQuery.data?.data.find((u) => u.id === url?.value));
      updateDocumentMutation.mutate({
        pathParams: {
          project: appState.currentProject!.id,
          document: document.id,
        },
        body: {
          project_url_id: url?.value ?? null,
        },
      });

      const determineHasDocument = (u: ProjectUrlResource) => {
        if (u?.id === url?.value) {
          return true;
        }
        if (u?.id === projectUrl?.id) {
          return false;
        }
        return u.has_document;
      };
      client.setQueriesData(
        {
          predicate: (query) => query.queryKey.includes('projectsUrlsIndex'),
        },
        (oldData: any) => {
          if (oldData?.data) {
            return {
              ...oldData,
              data: oldData.data.map((u: ProjectUrlResource) => ({
                ...u,
                has_document: determineHasDocument(u),
              })),
            };
          }
          return oldData;
        },
      );
    }
  };

  const errorHelper = new ErrorHelper(
    updateDocumentMutation.error ?? addUrlmutation.error,
  );

  const hasDirectMatch = urlQuery.data?.data.some(
    (url) =>
      url.url.toLowerCase().trim() === urlSearch.toLowerCase().trim() ||
      url.path.toLowerCase().trim() === urlSearch.toLowerCase().trim(),
  );

  const addOption = {
    value: 'add-new-url',
    title: `${urlSearch.slice(0, 60)}${urlSearch.length > 60 ? '...' : ''} (Add new url)`,
    persistent: true,
  };

  return (
    <>
      <Autocomplete
        fullWidth
        preselect
        clearable
        error={errorHelper.message()}
        disabled={disabled || addUrlmutation.isPending}
        value={
          projectUrl
            ? {
                value: projectUrl?.id,
                title: projectUrl?.url.replace(/(^\w+:|^)\/\//, ''),
              }
            : undefined
        }
        onChange={handleSetDocumentUrl}
        searchValue={urlSearch}
        options={[
          ...(urlSearch.length > 0 && !hasDirectMatch ? [addOption] : []),
          ...(urlQuery.data?.data.map((url) => ({
            value: url.id,
            title: url.url.replace(/(^\w+:|^)\/\//, ''),
            disabled: url.has_document && url.id !== projectUrl?.id,
            appendIcon:
              url.has_document && url.id !== projectUrl?.id ? (
                <div className="flex flex-col justify-center">
                  <Tooltip
                    title={'This url has already been used'}
                    description={
                      'A URL can only be used for 1 content at a time. '
                    }
                    navLink={`/documents/$documentId`}
                    navLinkText="View content"
                    navLinkTarget="_blank"
                    navLinkParams={{
                      documentId: hasher.encode(url.document?.id),
                    }}
                  >
                    <Info size={20} />
                  </Tooltip>
                </div>
              ) : undefined,
          })) ?? []),
          ...(urlSearch.length > 0 && hasDirectMatch ? [addOption] : []),
        ]}
        onSearchChange={(value) => {
          setUrlSearch(value);
          addUrlmutation.reset();
          updateDocumentMutation.reset();
        }}
        placeholder="Add an existing URL to import content and get keywords gaps insights."
      />
    </>
  );
};
