import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { AiGeneratedFormState } from '../AiGenerated';
import {
  ContentTypeResource,
  DocumentTemplateResource,
} from '@/api/openapiSchemas';
import { FormEvent } from 'react';
import { TextArea } from '@/Components/v2/TextArea/TextArea';
import { useFeatureFlagging } from '@/Pages/AppLoader';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { TagEditor } from '@/Pages/Document/components/TagEditor/TagEditor';
import { Button } from '@/Components/v2/Button';
import { handleSplitOnPaste } from '@/utils';
import { ContentTypeSelect } from '@/Components/ContentTypeSelect';
import { Autocomplete } from '@/Components/v2/Select/AutoComplete';
import { DropdownItem } from '@/Components/v2/Select/hooks';
import { Tooltip } from '@/Components/v2/Tooltip';
import { setTemplateToForm } from '../utils';
import { TemplateSelect } from '@/TemplateSelect';

type Props = {
  isLoading?: boolean;
  formState: AiGeneratedFormState;
  contentTypes: ContentTypeResource[];
  onSubmit: () => void;
  setFormState: (state: AiGeneratedFormState) => void;
  keywords: string[];
  templates: (
    | DropdownItem
    | {
        group: string;
      }
  )[];
  customTemplates: DocumentTemplateResource[];
  predefinedTemplates: DocumentTemplateResource[];
};

export const BulkContentForm = ({
  formState,
  onSubmit,
  isLoading,
  setFormState,
  contentTypes,
  keywords,
  templates,
  customTemplates,
  predefinedTemplates,
}: Props) => {
  const appState = useAppStore();
  const { hasFeature } = useFeatureFlagging();

  const setBulkFormValues = (values: Partial<AiGeneratedFormState['bulk']>) => {
    setFormState({
      ...formState,
      bulk: {
        ...formState.bulk,
        ...values,
      },
    });
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-6">
      <InputDecoration label="Target keywords" required>
        <TextArea
          value={formState.bulk.keywords}
          onChange={(value) => {
            setBulkFormValues({ keywords: value });
          }}
          counterMax={100}
          counter={keywords.length}
          onPaste={async (e) => {
            const value = await handleSplitOnPaste(e, formState.bulk.keywords);
            setBulkFormValues({ keywords: value });
          }}
        />
      </InputDecoration>

      <TemplateSelect
        customTemplates={customTemplates}
        predefinedTemplates={predefinedTemplates}
        onChange={(values) =>
          setBulkFormValues({
            contentTypeId: values.contentTypeId,
            templateId: values.templateId
              ? Number(values.templateId)
              : undefined,
          })
        }
        value={formState.bulk.templateId!}
        disabled={!appState.subscription?.permissions.custom_document_templates}
        featureLocked={
          !appState.subscription?.permissions.custom_document_templates
        }
      />
      <ContentTypeSelect
        contentTypes={contentTypes}
        isLoading={isLoading}
        onChange={(value) => setBulkFormValues({ contentTypeId: value })}
        value={formState.bulk.contentTypeId}
      />

      <InputDecoration label="Add tag to content">
        <div>
          <TagEditor
            onTagUpdated={(tags) => {
              setBulkFormValues({ tags });
            }}
            organisationId={appState.organisation!.id}
            tags={formState.bulk.tags}
          />
        </div>
      </InputDecoration>
      <div className="mx-auto w-1/2">
        <Button
          fullWidth
          text={'GENERATE'}
          type="submit"
          color="secondary"
          disabled={
            !appState.subscription!.usage.credits.content.is_allowed &&
            !formState.single.optionReviewSubheadings
          }
          isLoading={isLoading}
        />
      </div>
    </form>
  );
};
