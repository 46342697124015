import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from 'lucide-react';

type Props = {
  defaultOpen?: boolean;
  title: string | React.ReactNode | ((open: boolean) => React.ReactNode);
  children: React.ReactNode;
}

const Accordion = ({ title, children, defaultOpen }: Props) => {
  const getTitle = (open: boolean) => {
    if (typeof title === 'function') {
      return title(open);
    }
    return title;
  };
  return (
    <div className="w-full">
      <div className="mx-auto w-full max-w-md rounded-2xl bg-white">
        <Disclosure defaultOpen={defaultOpen}>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full items-center justify-between rounded-lg px-1 py-2 text-base font-bold hover:bg-gray-100">
                <div className="flex">{getTitle(open)}</div>
                <ChevronDownIcon
                  size={24}
                  className={`${open ? 'rotate-180 transform' : ''}`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-1 pb-2">
                {children}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
};

export { Accordion };
