import SimpleButton from '@/Components/SimpleButton';
import { useContext } from 'react';
import { PopoverContext } from '@/Components/SimplePopover';

type Props<T> = {
  items: { value: T; name: string }[];
  value?: T;
  nameValue?: string;
  onAccept: (value: { name: string; value: T }) => void;
}

export const ItemSelector = <T,>({
  items,
  value,
  onAccept,
  nameValue,
}: Props<T>) => {
  const closePopover = useContext(PopoverContext);

  const handleAccept = (item: { value: T; name: string }) => {
    onAccept(item);
    closePopover();
  };

  return (
    <div className="flex flex-col gap-1 p-1">
      {items.map((item) => (
        <SimpleButton
          className={`py-1 text-sm ${
            (value && value === item.value) ||
            (nameValue && nameValue === item.name)
              ? 'border border-green-400'
              : ''
          }`}
          onClick={() => handleAccept(item)}
        >
          <p className="normal-case">{item.name}</p>
        </SimpleButton>
      ))}
    </div>
  );
};
