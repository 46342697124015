import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { X } from 'lucide-react';
import { IconButton } from '../v2/IconButton/IconButton';
type Props = {
  isOpen?: boolean;
  onClose?: () => void;
} & React.HtmlHTMLAttributes<HTMLDivElement>

export const Banner = ({
  children,
  isOpen,
  className: style,
  onClose,
  ...props
}: PropsWithChildren<Props>) => {
  const [className, setClassName] = useState(style);
  const [content, setContent] = useState(children);
  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!ref.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setHeight(ref.current?.clientHeight ?? 0);
    });
    resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    if (isOpen) {
      setClassName(style);
      setContent(children);
    }
  }, [children]);

  return (
    <div
      className="relative z-40 w-full flex-shrink-0 overflow-hidden transition-all"
      onResize={(e) => e.nativeEvent}
      style={
        height > 0 || !isOpen
          ? {
              height: isOpen ? height : 0,
            }
          : undefined
      }
    >
      {onClose && (
        <IconButton
          icon={X}
          onClick={(e) => {
            onClose();
            e.stopPropagation();
            e.preventDefault();
          }}
        />
      )}
      <div
        ref={ref}
        className={`flex w-full justify-center ${className}`}
        {...props}
      >
        {content}
      </div>
    </div>
  );
};
