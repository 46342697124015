import PageContainer from '@/Components/PageContainer';
import { PlaintextDiff } from './components/PlaintextDiff';
import { useState } from 'react';
import { EditableContainer } from './components/EditableContainer';
import { DiffRow } from './components/DiffRow';
import Toggle from '@/Components/Toggle';
import { TextArea } from '@/Components/v2/TextArea/TextArea';
import remarkGfm from 'remark-gfm';
import { MarkdownDiff } from './components/MarkdownDiff';
import ReactMarkdown from 'react-markdown';
import { Input } from '@/Components/v2/Input/Input';
import { Button } from '@/Components/v2/Button';
import { Menus } from '@/Components/Menus';

// This page has been archived, but it remains in case in the future it is needed again.

export const Diff = () => {
  const [inputOldTitle, setinputOldTitle] = useState('');
  const [inputNewTitle, setInputNewTitle] = useState('');

  const [inputOldMetaDescription, setInputOldMetaDescription] = useState('');
  const [inputNewMeteDescription, setInputNewMeteDescription] = useState('');

  const [title, setTitle] = useState('');
  const [actualTitle, setActualTitle] = useState('');
  const [newTitle, setNewTitle] = useState('');

  const [metaDescription, setMetaDescription] = useState('');
  const [actualMetaDescription, setActualMetaDescription] = useState('');
  const [newMetaDescription, setNewMetaDescription] = useState('');

  const body = `# This does not work

This is a work in progress

Facts about this section
- showing changes works
- accepting changes is bugged
`;

  const [actualBody, setActualBody] = useState(`# This does not work

This is a work in progress

Facts about this section
- showing changes works
- accepting changes is bugged
`);

  const [newBody, setNewBody] = useState(`# This does not work yet

This is still a work in progress

Facts about this
- showing changes functions correctly
- accepting changes is glitched
`);

  const [showChanges, setShowChanges] = useState(true);

  return (
    <Menus>
      <PageContainer>
        <div className="flex flex-col">
          <div className="mb-16 bg-primary-50 p-4">
            <p className="text-lg font-bold">Debugging tools</p>

            <DiffRow before={'Before'} after={'After'} />
            <DiffRow
              before={
                <Input
                  value={inputOldTitle}
                  onChange={(value) => setinputOldTitle(value)}
                />
              }
              after={
                <Input
                  value={inputNewTitle}
                  onChange={(value) => setInputNewTitle(value)}
                />
              }
              header={'Title'}
            />
            <DiffRow
              before={
                <TextArea
                  value={inputOldMetaDescription}
                  onChange={(value) => setInputOldMetaDescription(value)}
                />
              }
              after={
                <TextArea
                  value={inputNewMeteDescription}
                  onChange={(value) => setInputNewMeteDescription(value)}
                />
              }
              header={'Meta description'}
            />

            <div className="mt-4 flex justify-end">
              <Button
                text="Set values"
                color="secondary"
                onClick={() => {
                  setTitle(inputOldTitle);
                  setActualTitle(inputOldTitle);
                  setNewTitle(inputNewTitle);

                  setMetaDescription(inputOldMetaDescription);
                  setActualMetaDescription(inputOldMetaDescription);
                  setNewMetaDescription(inputNewMeteDescription);
                }}
              />
            </div>
          </div>

          <div className="flex justify-end gap-2">
            <div className="flex items-center gap-2">
              <Toggle value={showChanges} onChange={setShowChanges} />
              <span>Show changes</span>
            </div>
          </div>
          <DiffRow before={'Before'} after={'After'} />
          <DiffRow
            before={<div className="text-lg font-bold">{title}</div>}
            after={
              <div className="text-lg font-bold">
                <PlaintextDiff
                  newText={newTitle}
                  oldText={showChanges ? actualTitle : newTitle}
                  onChange={({ actualValue, newValue }) => {
                    if (actualValue) setActualTitle(actualValue);
                    if (newValue) setNewTitle(newValue);
                  }}
                />
              </div>
            }
            header={'Title'}
          />
          <DiffRow
            before={
              <div className="whitespace-pre-wrap">{metaDescription}</div>
            }
            after={
              <div className="whitespace-pre-wrap">
                <PlaintextDiff
                  newText={newMetaDescription}
                  oldText={
                    showChanges ? actualMetaDescription : newMetaDescription
                  }
                  onChange={({ actualValue, newValue }) => {
                    if (actualValue) setActualMetaDescription(actualValue);
                    if (newValue) setNewMetaDescription(newValue);
                  }}
                />
              </div>
            }
            header={'Meta description'}
          />
          <DiffRow
            header="Body"
            before={
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                className="prose text-base leading-tight"
              >
                {body}
              </ReactMarkdown>
            }
            after={
              <EditableContainer
                preview={
                  <MarkdownDiff
                    oldMarkdown={showChanges ? actualBody : newBody}
                    newMarkdown={newBody}
                    onChange={({ actualValue, newValue }) => {
                      if (actualValue) setActualBody(actualValue);
                      if (newValue) setNewBody(newValue);
                    }}
                  />
                }
                editor={
                  <TextArea
                    fullHeight
                    value={newBody}
                    onChange={(value) => setNewBody(value)}
                  />
                }
              />
            }
          />
        </div>
      </PageContainer>
    </Menus>
  );
};
