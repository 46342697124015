import { Album, SendHorizontal } from 'lucide-react';
import { PromptInfo } from '@/types';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { PopoverMenu } from '@/Components/v2/Menu/ButtonMenu';

type Props = {
  onPromptClick: (
    prompt: Omit<PromptInfo, 'Icon'> & { content?: string },
  ) => void;
  onSendMessage: () => void;
  prompts: (PromptInfo & { content: string })[];
  disablePrompts: boolean;
  disableButton: boolean;
  commentOutPromptIcon?: boolean;
}
const ChatInputIcons = ({
  disableButton,
  onSendMessage,
  prompts,
  disablePrompts,
  onPromptClick,
  commentOutPromptIcon,
}: Props) => {
  return (
    <div className="absolute bottom-0 right-1.5 top-0 flex items-center justify-center">
      {!commentOutPromptIcon && (
        <PopoverMenu
          items={prompts.map((prompt) => ({
            title: prompt.title,
            disabled: disablePrompts,
            onClick: () =>
              onPromptClick({ ...prompt, content: prompt.content }),
          }))}
          trigger={
            <IconButton
              onClick={(e) => {
                e.preventDefault();
              }}
              disabled={disablePrompts}
              icon={Album}
            />
          }
        />
      )}
      <IconButton
        disabled={disableButton}
        onClick={(e) => {
          onSendMessage();
        }}
        icon={SendHorizontal}
      />
    </div>
  );
};

ChatInputIcons.displayName = 'ChatInputIcons';

export { ChatInputIcons };
