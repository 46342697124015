import { DocumentElementResource } from '@/api/openapiSchemas';
import { useDraggable } from '@dnd-kit/core';
import { TemplateCollapsable } from './TemplateCollapsable';
import { useState } from 'react';

type Props = {
  el: DocumentElementResource;
};

export const TemplateSlideoverElement = ({ el }: Props) => {
  const [isHovered, setIsHovered] = useState(false);
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: 'draggable' + el.id,
    data: { el },
  });

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="flex rounded-md shadow-base-small"
      ref={setNodeRef}
      {...listeners}
      {...attributes}
    >
      <div className="w-full">
        <TemplateCollapsable el={el} isHovered={isHovered} />
      </div>
    </div>
  );
};
