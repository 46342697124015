import {
  useLinkToolbarButton,
  useLinkToolbarButtonState,
} from '@udecode/plate-link';

import { Icons } from './icons';

import { ToolbarButton } from '../components';

type Props = {
  disabled?: boolean;
}

export function LinkToolbarButton({ disabled }: Props) {
  const state = useLinkToolbarButtonState();
  const { props } = useLinkToolbarButton(state);

  return (
    <ToolbarButton tooltip="Link" disabled={disabled} {...props}>
      <Icons.link />
    </ToolbarButton>
  );
}
