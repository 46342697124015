import { LucideProps } from 'lucide-react';
import React, { MouseEvent, ReactNode } from 'react';
import { isComponentFunction } from '../utils';

export type ListItemProps = {
  /**
   * The title of the list item
   */
  title?: string | ReactNode;

  /**
   * Optional subtitle
   */
  subtitle?: string;

  /**
   * Limits the number of lines for the subtitle to 2
   */
  limitSubtitle?: boolean;

  /**
   * Prepends an icon
   */
  prependIcon?: React.ComponentType<LucideProps> | ReactNode;

  /**
   * Appends an icon
   */
  appendIcon?: React.ComponentType<LucideProps> | ReactNode;

  /**
   * Optional click handler
   */
  onClick?: () => void;

  /**
   * Optional mouse down handler
   */
  onMouseDown?: (e: MouseEvent) => void;

  /**
   * If true, the list item will have a smaller padding
   */
  dense?: boolean;

  /**
   * If true, the list item will be disabled
   */
  disabled?: boolean;

  /**
   * If true the item will be highlighted as if it was hovered
   */
  isFocused?: boolean;

  /**
   * Adds a border underneath the list item
   */
  underlined?: boolean;

  /**
   * If true, the list item will be at a slight opacity
   */
  faded?: boolean;
};

export const ListItem = ({
  title,
  subtitle,
  prependIcon,
  appendIcon,
  dense,
  disabled,
  isFocused,
  underlined,
  onClick,
  onMouseDown,
  faded,
  limitSubtitle = true,
}: ListItemProps) => {
  const PrependIcon = prependIcon;
  const AppendIcon = appendIcon;

  const getLeftIconMargin = () => {
    if (dense) {
      return 'ml-2';
    }

    return 'ml-4';
  };

  const getRightIconMargin = () => {
    if (dense) {
      return 'mr-2';
    }

    return 'mr-4';
  };

  return (
    <div
      className={`flex items-center ${
        onClick && !disabled ? 'cursor-pointer hover:bg-primary-100' : ''
      } ${dense ? 'py-0.5' : 'py-1'} ${
        onClick && isFocused ? 'bg-secondary-100 hover:bg-secondary-100' : ''
      } ${disabled ? 'cursor-not-allowed opacity-50' : ''} ${
        underlined ? 'border-b border-primary-100' : ''
      } ${faded ? 'opacity-50' : ''}`}
      onClick={!disabled ? onClick : undefined}
      onMouseDown={!disabled ? onMouseDown : undefined}
    >
      {PrependIcon &&
        (isComponentFunction(PrependIcon) ? (
          <PrependIcon
            className={`${getLeftIconMargin()} h-5 w-5 flex-shrink-0 text-primary`}
          />
        ) : (
          <div className={`${getLeftIconMargin()} flex shrink-0`}>
            {PrependIcon as ReactNode}
          </div>
        ))}

      <div
        className={`flex flex-grow flex-col justify-center overflow-hidden ${
          dense ? 'mx-2' : 'mx-4'
        }`}
      >
        <p className="line-clamp-2 truncate text-primary">{title}</p>
        {subtitle && (
          <p
            className={`break-words text-primary-400 ${
              limitSubtitle ? 'line-clamp-2' : ''
            }`}
          >
            {subtitle}
          </p>
        )}
      </div>

      {AppendIcon &&
        (isComponentFunction(AppendIcon) ? (
          <AppendIcon
            className={`${getRightIconMargin()} h-5 w-5 flex-shrink-0 text-primary`}
          />
        ) : (
          <div className={`${getRightIconMargin()} flex shrink-0`}>
            {AppendIcon as ReactNode}
          </div>
        ))}
    </div>
  );
};
