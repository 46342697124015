import Google from '../../../../../public/svg/google.png';
import GoogleCam from '../../../../../public/svg/google-camera.svg';
import GoogleMicrophone from '../../../../../public/svg/google-microphone.svg';
import { GoogleGlass } from './GoogleGlass';

type Props = {
  keyword: string;
}

export const GoogleMobile = ({ keyword }: Props) => {
  return (
    <div className="mb-2 flex w-[410px] flex-col items-center gap-2 rounded-t-md border border-gray-50 bg-white px-2 py-1.5">
      <img className="h-7 w-[5rem]" src={Google} />
      <div className="relative w-full">
        <div className="absolute left-3 translate-y-[0.65rem]">
          <GoogleGlass gray />
        </div>
        <div className="absolute right-3 flex translate-y-[0.65rem] gap-5">
          <img src={GoogleMicrophone} className="h-6 w-6" />
          <img src={GoogleCam} className="h-6 w-6" />
        </div>
        <input
          className="w-full rounded-3xl border-[1px] border-gray-300 py-[0.6rem] pl-11 font-google shadow-md ring-0"
          disabled={true}
          value={keyword}
        />
      </div>
    </div>
  );
};
