import { TabBarSkeleton } from './TabBarSkeleton';
import { TabItem, TabItemProps } from './TabItem';

type Props = {
  tabs: Omit<TabItemProps, 'grow' | 'active'>[];
  type?: 'center' | 'left' | 'right' | 'grow';
  onChange: (index: number) => void;
  disabled?: boolean;
  value: number;
  color?:
    | 'green'
    | 'red'
    | 'blue'
    | 'gray'
    | 'yellow'
    | 'primary'
    | 'secondary'
    | 'white';
  noBorder?: boolean;
};

export const Tabs = ({
  tabs,
  type = 'left',
  onChange,
  value,
  disabled,
  color,
  noBorder,
}: Props) => {
  return (
    <TabBarSkeleton
      color={color}
      alignment={type}
      noBorder={tabs.length === 0 || noBorder}
    >
      {tabs.map((tab, index) => (
        <TabItem
          {...tab}
          disabled={disabled || tab.disabled}
          grow={type === 'grow'}
          active={value === index}
          onClick={tab.onClick ? tab.onClick : () => onChange(index)}
        />
      ))}
    </TabBarSkeleton>
  );
};
