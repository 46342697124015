import SimpleTitle from '@/Components/SimpleTitle';
import * as Table from '@/Components/Table';
import { RankLabel } from '@/Components/Labels/RankLabel';
import { GradualScoreVariant } from '@/Components/Labels/AppLabel';
import { X } from 'lucide-react';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { useProjectsTrackedKeywordsShow } from '@/api/openapiComponents';
import { KeywordGraph } from './KeywordGraph';
import ToggleButton from '@/Components/ToggleButton';
import { TrackedKeywordSerpOverview } from './TrackedKeywordSerpOverview';
import dayjs from 'dayjs';
import {
  TrackedKeywordUpdateResource,
  TrackedKeywordUpdateResultResource,
} from '@/api/openapiSchemas';
import Pagination from '@/Components/ProjectKeywords/Pagination';
import { useTable } from '@/Components/Table/hooks';

type Props = {
  projectId: number;
  title: string;
  trackedKeywordId?: number;
  tab: 'positions' | 'serp-overview';
  setTab: (tab: 'positions' | 'serp-overview') => void;
  onClose: () => void;
};

export const KeywordRankTrackingSlideOverContent = ({
  trackedKeywordId,
  projectId,
  title,
  onClose,
  setTab,
  tab,
}: Props) => {
  const { tableState, setPage } = useTable<{}, {}>();

  const keywordQuery = useProjectsTrackedKeywordsShow(
    {
      pathParams: {
        project: projectId,
        trackedKeyword: trackedKeywordId!,
      },
      queryParams: {
        page: tableState.page,
        limit: 25,
      },
    },
    {
      enabled: !!trackedKeywordId,
    },
  );

  const trackedKeyword = keywordQuery.data?.data;

  const getRankTrackingVariant = (
    position: number,
  ): keyof typeof GradualScoreVariant => {
    if (position <= 3) {
      return 'best';
    } else if (position <= 10) {
      return 'great';
    } else if (position <= 20) {
      return 'good';
    } else if (position <= 50) {
      return 'ok';
    } else {
      return 'remaining';
    }
  };

  return (
    <div className="w-full overflow-hidden">
      <div className="mb-6 flex justify-between">
        <SimpleTitle>{title}</SimpleTitle>
        <IconButton icon={X} onClick={onClose} color="gray" />
      </div>
      <div className="relative min-h-[4rem]">
        <ToggleButton
          className="absolute left-0 top-0 h-10"
          options={[
            { displayName: 'POSITIONS', value: 'positions' },
            { displayName: 'SERP OVERVIEW', value: 'serp-overview' },
          ]}
          value={tab}
          onChange={setTab}
        />
        {tab === 'positions' && <KeywordGraph keywordId={trackedKeywordId!} />}
      </div>
      {tab === 'positions' && (
        <Table.Root
          skeletonLoaders={5}
          isLoading={keywordQuery.isLoading}
          items={trackedKeyword ?? []}
          meta={keywordQuery.data?.meta}
          subRows={{
            columns: [
              {
                render: (_, row) => (
                  <div className="text-primary-500">
                    {dayjs(row.date).format('DD MMM YYYY')}
                  </div>
                ),
              },
              {
                rightAlign: true,
                render: (item) => (
                  <div className="flex flex-col gap-1 pr-5">
                    <div key={item.position + item.url} className="h-6">
                      <RankLabel
                        rank={item.position}
                        variant={getRankTrackingVariant(item.position)}
                      />
                    </div>
                  </div>
                ),
              },
              {
                rightAlign: true,
                render: (item) => (
                  <div className="flex flex-col gap-1 pr-5">
                    <div
                      key={item.position + item.url}
                      className={`flex h-6 items-center ${
                        item.change > 0 ? 'text-green-500' : ''
                      } ${item.change < 0 ? 'text-red-500' : ''}`}
                    >
                      {item.change > 0 && '+'}
                      {item.change ?? '-'}
                    </div>
                  </div>
                ),
              },
              {
                render: (item, row) => (
                  <div className="flex max-w-xs gap-1 overflow-hidden">
                    <a
                      key={row.position + row.url}
                      title={item.url}
                      className="h-6 truncate hover:opacity-60"
                      href={item.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.path}
                    </a>
                  </div>
                ),
              },
            ],
            rows: ((item) => item.results ?? []) as (
              item: TrackedKeywordUpdateResource,
            ) => TrackedKeywordUpdateResultResource[],
          }}
          columns={[
            {
              heading: 'Date',
              render: (item) => (
                <div>{dayjs(item.date).format('DD MMM YYYY')}</div>
              ),
            },
            {
              rightAlign: true,
              heading: 'Position',
              render: (item) => (
                <div className="flex flex-col gap-1 pr-5">
                  <div className="h-6" key={item.position + item.results?.url}>
                    {item?.position ? (
                      <RankLabel
                        rank={item?.position}
                        variant={getRankTrackingVariant(item?.position)}
                      />
                    ) : (
                      '-'
                    )}
                  </div>
                </div>
              ),
            },
            {
              rightAlign: true,
              heading: 'Change',
              render: (item) => (
                <div className="flex flex-col gap-1 pr-5">
                  <div
                    key={item.position + item.url}
                    className={`flex h-6 items-center ${
                      item.change > 0 ? 'text-green-500' : ''
                    } ${item.change < 0 ? 'text-red-500' : ''}`}
                  >
                    {item.change > 0 && '+'}
                    {item.change ?? '-'}
                  </div>
                </div>
              ),
            },
            {
              heading: 'Link',
              render: (item, _, { subrowCount, toggleSubRows }) => (
                <div className="flex max-w-xs items-center gap-1 overflow-hidden">
                  <a
                    key={item.position + item?.url}
                    title={item?.url}
                    className="h-6 truncate hover:opacity-75"
                    href={item.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.url}
                  </a>

                  {subrowCount > 1 ? (
                    <div
                      className="cursor-pointer text-sm text-primary-600 hover:opacity-50"
                      onClick={toggleSubRows}
                    >
                      (+{item.results?.length! - 1})
                    </div>
                  ) : null}
                </div>
              ),
            },
          ]}
        >
          <Table.Footer>
            <Pagination
              currentPage={keywordQuery.data?.meta.current_page ?? 1}
              setCurrentPage={setPage}
              lastPage={keywordQuery.data?.meta.last_page ?? 1}
            />{' '}
          </Table.Footer>
        </Table.Root>
      )}
      {tab === 'serp-overview' && (
        <TrackedKeywordSerpOverview trackedKeywordId={trackedKeywordId!} />
      )}
    </div>
  );
};
