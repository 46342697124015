import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = {
  className?: string;
  containerClassname?: string;
  hint?: string;
  icon?: JSX.Element;
  removeBoxShadow?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>

export default React.forwardRef<HTMLDivElement, Props>(function SimpleInput(
  {
    className,
    containerClassname,
    hint,
    disabled,
    icon,
    removeBoxShadow,
    ...props
  },
  ref,
) {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className={twMerge(`relative mt-2`, containerClassname)}>
      <div ref={ref}>
        {icon && (
          <div className="pointer-events-none absolute inset-0 flex items-center pl-3">
            {icon}
          </div>
        )}
        <input
          {...props}
          disabled={disabled}
          onFocus={(e) => {
            setIsFocused(true);
            if (props.onFocus) props.onFocus(e);
          }}
          onBlur={(e) => {
            setIsFocused(false);
            if (props.onBlur) props.onBlur(e);
          }}
          className={twMerge(
            `block rounded-2xl border-0 ${
              icon ? 'pl-10 pr-5' : 'px-5'
            } py-3 font-work text-black shadow-inner ring-1 ring-inset ring-gray-200 placeholder:text-zinc-400 focus:border-green-400 focus:ring-2 focus:ring-inset focus:ring-green-400 sm:leading-6 ${
              !removeBoxShadow ? 'shadow-input-shadow focus:shadow-none' : ''
            } ${disabled ? 'bg-gray-50 text-gray-400' : ''} ${className}`,
          )}
        />
      </div>
      {hint && (
        <p
          className={`absolute -bottom-5 left-5 z-50 text-sm text-gray-400 ${
            isFocused ? 'opacity-75' : 'opacity-0'
          } transition-opacity`}
        >
          {hint}
        </p>
      )}
    </div>
  );
});
