import { Destination } from '../Destinations';

export const googleTagManager: Destination = {
  name: 'googleTagManager',
  enabledByEnvKey: 'VITE_GOOGLE_TAG_MANAGER_ENABLED',
  initialize: () => {
    const disableTracking = ['/signup/external'].some((path) =>
      window.location.pathname.startsWith(path),
    );
    if (!disableTracking) {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        const f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-ND5JMPK');
    }
  },
  identify: () => {
    // TODO: implement Google Tag Manager user identification
  },
  reset: () => {
    // TODO: implement Google Tag Manager user reset
  },
  trackEvent: (event) => {
    window.dataLayer.push({
      event: event.name,
      ...event.payload,
    });
  },
  onlyAllow: ['subscription_started', 'onboarding_create_project'],
};
