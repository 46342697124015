import React, { PropsWithChildren, useEffect } from 'react';
import { Loader } from '@/Components/Loader';
import { Plus } from 'lucide-react';
import { Button } from './v2/Button';
import { SkeletonLoader } from './v2/SkeletonLoader/SkeletonLoader';

type Props = {
  loading: boolean;
  topContent?: React.ReactNode;
  error: any;
  canGenerateMore?: boolean;
  skeletonLoaders?: {
    count?: number;
    height?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';
  };
  onFetchItems: () => void;
};

export const SlideOverGeneratedContent = ({
  onFetchItems,
  loading,
  topContent,
  error,
  children,
  canGenerateMore = true,
  skeletonLoaders,
}: PropsWithChildren<Props>) => {
  useEffect(() => {
    if (!children && !loading) {
      onFetchItems();
    }
  }, []);

  const skeletonLoaderConfig = {
    count: 5,
    height: 'md',
    ...skeletonLoaders,
  };

  if (error) {
    return (
      <div className="flex w-full flex-col items-center gap-2">
        An error occured
        <Button
          variant="outline"
          color="gray"
          text="Try again"
          onClick={onFetchItems}
        />
      </div>
    );
  }

  if (!loading && Array.isArray(children) && children.length === 0) {
    return (
      <div className="flex w-full flex-col items-center gap-2">
        There were no items found
        <Button
          variant="outline"
          color="gray"
          text="Try again"
          onClick={onFetchItems}
        />
      </div>
    );
  }

  return (
    <div>
      {topContent}
      {children &&
      (!Array.isArray(children) || children.filter(Boolean).length !== 0) ? (
        <>
          {children}
          {canGenerateMore && (
            <>
              {loading ? (
                <div className="mb-4 flex w-full justify-center">
                  <Loader className="h-8 w-8 stroke-green-400" />
                </div>
              ) : (
                <div className="mb-4 flex w-full">
                  <Button
                    prependIcon={Plus}
                    text="Generate more"
                    onClick={onFetchItems}
                    variant="ghost"
                    color="primary"
                    dense
                    size="sm"
                  />
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <div className="flex flex-col gap-2">
          {Array.from({ length: skeletonLoaderConfig.count }).map(() => (
            <SkeletonLoader height={skeletonLoaderConfig.height as any} />
          ))}
        </div>
      )}
    </div>
  );
};
