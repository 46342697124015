import {
  useCmsContentTypes,
  useExportDocumentsToShopifyCms,
  useExportDocumentToCms,
} from '@/api/openapiComponents';
import {
  CmsConnectionResource,
  CmsContentTypeResource,
  DocumentResource,
  SlimDocumentResource,
} from '@/api/openapiSchemas';
import { Dialog } from '@/Components/Dialog';
import Toggle from '@/Components/Toggle';
import { ErrorAlert, WarningAlert } from '@/Components/v2/Alert';
import { Button } from '@/Components/v2/Button';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { Select } from '@/Components/v2/Select';
import { AutocompleteTextInput } from '@/Components/v2/Select/AutoCompleteTextInput';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { ErrorHelper } from '@/Services/ErrorHandling';
import { Check, ExternalLink, Info, Plus, X } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useNavigate } from '@tanstack/react-router';
import { Input } from '@/Components/v2/Input/Input';
import { LoadingSpinner } from '@/Components/v2/SvgIcon';
import { Tooltip } from '@/Components/v2/Tooltip';
import { IconButton } from '@/Components/v2/IconButton/IconButton.tsx';
import { faShopify } from '@fortawesome/free-brands-svg-icons';
import { Icon } from '@/Components/v2/Icon';

type Props = {
  cmsConnection?: CmsConnectionResource;
  documents: DocumentResource[];
  onClose: () => void;
  onSuccess?: () => void;
  isOpen: boolean;
};

export const ShopifyExportDialog = ({
  onClose,
  onSuccess,
  isOpen,
  documents,
  cmsConnection,
}: Props) => {
  const appState = useAppStore();
  const navigate = useNavigate();

  const allDocumentsHaveRefs = documents.every(
    (document) => document.project_url?.cms.has_reference,
  );
  const documentsWithoutRefs = documents.filter(
    (document) =>
      document.project_url && !document.project_url.cms.has_reference,
  );
  const documentsForExport = documents.filter(
    (doc) => !doc.project_url || doc.project_url.cms.has_reference,
  );

  const storedValues = {
    arthur: localStorage.getItem('cms_export_shopify_arthur'),
    blogId: localStorage.getItem('cms_export_shopify_blog_id'),
  };

  const [isAutomated, setIsAutomated] = useState(false);
  const [authorInput, setAuthorInput] = useState(storedValues.arthur ?? '');
  const [blogInput, setBlogInput] = useState('');
  const [selectedBlogId, setSelectedBlogId] = useState(
    cmsConnection?.settings?.blogs.find(
      (blog) => blog.id === storedValues.blogId,
    )?.id ?? -1,
  );
  const [isDraft, setIsDraft] = useState(false);
  const [selectedContentType, setSelectedContentType] =
    useState<CmsContentTypeResource>();

  const [exportingState, setExportingState] = useState<
    {
      name: string;
      id: number;
      exported: boolean;
      error?: string;
      url?: string;
      admin_url?: string;
    }[]
  >([]);

  const bulkExportMutation = useExportDocumentsToShopifyCms();
  const contentTypesQuery = useCmsContentTypes(
    {
      pathParams: {
        project: appState.currentProject!.id,
      },
    },
    {
      enabled: isOpen,
    },
  );

  useEffect(() => {
    if (contentTypesQuery.isSuccess) {
      setSelectedContentType(contentTypesQuery.data?.data[0]);
    }
  }, [contentTypesQuery.isSuccess]);

  type ExportType = {
    success: boolean;
    data: {
      document: SlimDocumentResource;
      cms?: {
        admin_link?: string;
        public_link?: string;
      };
      error?: {
        message: string;
        reference_deleted: boolean;
      };
    };
  };

  const handleSubmit = () => {
    bulkExportMutation.mutate(
      {
        pathParams: {
          project: appState.currentProject!.id,
        },
        body: {
          cms_content_type_id: selectedContentType!.id,
          documents: documentsForExport.map((document) => document.id),
          additional_fields: !allDocumentsHaveRefs
            ? {
                ...(selectedContentType?.name === 'article'
                  ? {
                      author: authorInput,
                      blog_id:
                        selectedBlogId === -1
                          ? blogInput
                          : selectedBlogId.toString(),
                    }
                  : {}),
                is_automated: isAutomated,
                draft: isDraft,
              }
            : {
                author: 'asd',
                blog_id: 'asd',
                is_automated: true,
              },
        },
      },
      {
        onSuccess: () => {
          setExportingState(
            documentsForExport.map((doc) => ({
              id: doc.id,
              name: doc.title || doc.meta_title || 'Untitled',
              exported: false,
              failed: false,
              url: doc.url ?? undefined,
              admin_url: undefined,
            })),
          );
          onSuccess?.();
        },
      },
    );
  };

  useEffect(() => {
    const url = `projects.${appState.currentProject!.id}`;
    const channel = window.Echo.private(url).listen(
      'DocumentExportedEvent',
      (event: ExportType) => {
        setExportingState((prevState) =>
          prevState.map((doc) =>
            doc.id === event.data.document.id
              ? {
                  ...doc,
                  exported: event.success,
                  error: event.data.error?.message ?? undefined,
                  admin_url: event.data.cms?.admin_link ?? undefined,
                  url: doc.url ?? event.data.cms?.public_link ?? undefined,
                }
              : doc,
          ),
        );
      },
    );

    return () => {
      channel.stopListening('DocumentExportedEvent');
    };
  }, []);

  const handleClose = () => {
    bulkExportMutation.reset();
    setExportingState([]);
    onClose();
  };

  const errorHelper = new ErrorHelper(bulkExportMutation.error);
  const exportedDocumentCount = exportingState.filter(
    (doc) => doc.exported,
  ).length;
  const failedDocumentCount = exportingState.filter((doc) => doc.error).length;

  if (bulkExportMutation.isSuccess) {
    return (
      <Dialog
        isOpen={isOpen}
        title="Export to shopify"
        handleClose={handleClose}
      >
        <div className=" flex flex-col items-center text-lg font-bold">
          {exportedDocumentCount + failedDocumentCount >= exportingState.length
            ? 'All documents exported'
            : `Exporting ${exportedDocumentCount}/${exportingState.length} documents`}
        </div>
        {exportedDocumentCount + failedDocumentCount >=
          exportingState.length && (
          <div className="px-6 text-center text-sm">
            Shopify may take a few minutes before the content is fully updated
            on the url. You can view the changes in the Shopify admin.
          </div>
        )}
        {failedDocumentCount > 0 && (
          <div className="flex justify-center text-sm text-red-400">
            {failedDocumentCount} failed. See errors below.
          </div>
        )}

        <div className="mt-6 flex h-1 flex-shrink-0 bg-gray-100">
          <div
            style={{
              width: `${(exportedDocumentCount / exportingState.length) * 100}%`,
            }}
            className="h-full bg-green transition-all"
          />
          <div
            style={{
              width: `${(failedDocumentCount / exportingState.length) * 100}%`,
            }}
            className="h-full bg-red transition-all"
          />
        </div>
        <div className="mt-2 flex max-h-96 flex-col overflow-y-auto">
          {exportingState.map((doc) => (
            <div
              key={doc.id}
              className={`flex items-center justify-between border-b border-primary-100 py-0.5 `}
            >
              <div
                className={`flex flex-grow items-center gap-2 truncate ${doc.name === 'Untitled' ? 'text-primary' : ''}`}
              >
                {doc.name || 'Untitled'}
              </div>
              <div className="flex flex-shrink-0 items-center gap-1">
                {doc.error && (
                  <Tooltip title={doc.error}>
                    <Info className="mt-1 text-primary" size={18} />
                  </Tooltip>
                )}

                {doc.exported ? (
                  <>
                    {doc.url && (
                      <IconButton
                        dense
                        icon={ExternalLink}
                        onClick={() => window.open(doc.url)}
                      />
                    )}
                    {doc.admin_url && (
                      <IconButton
                        dense
                        icon={
                          <Icon
                            icon={faShopify}
                            className="h-[18px] w-[18px] text-primary"
                          />
                        }
                        onClick={() => window.open(doc.admin_url)}
                      />
                    )}
                    <Check className="text-green" />
                  </>
                ) : doc.error ? (
                  <X className="text-red" />
                ) : (
                  <div>
                    <LoadingSpinner color="primary" />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </Dialog>
    );
  }

  if (allDocumentsHaveRefs) {
    return (
      <Dialog
        isOpen={isOpen}
        title="Export to Shopify"
        handleClose={handleClose}
      >
        <p>Are you sure you want to export to shopify?</p>
        {errorHelper.isError() && <ErrorAlert title={errorHelper.message()} />}
        {documentsWithoutRefs.length > 0 && (
          <WarningAlert
            title="The following documents has a URL that has no reference to shopify and will not be exported"
            body={[
              ...documentsWithoutRefs
                .slice(0, 10)
                .map((doc) => doc.title || 'Untitled'),
              ...(documentsWithoutRefs.length > 10
                ? [`and ${documentsWithoutRefs.length - 10} more`]
                : []),
            ]}
          />
        )}
        <div className="flex justify-end">
          <Button
            text="Export"
            color="secondary"
            onClick={handleSubmit}
            isLoading={bulkExportMutation.isPending}
          />
        </div>
      </Dialog>
    );
  }

  return (
    <Dialog isOpen={isOpen} title="Export to Shopify" handleClose={handleClose}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
        className="flex flex-col gap-4"
      >
        <InputDecoration label="Content type" required>
          <Select
            value={selectedContentType?.id ?? ''}
            options={
              contentTypesQuery.data?.data.map((contentType) => ({
                value: contentType.id,
                title: contentType.name,
              })) ?? []
            }
            onChange={(value) =>
              setSelectedContentType(
                contentTypesQuery.data?.data.find(
                  (contentType) => contentType.id === value,
                ),
              )
            }
          />
        </InputDecoration>
        {selectedContentType?.name === 'article' && (
          <>
            <InputDecoration label="Author" required>
              <AutocompleteTextInput
                error={errorHelper.has('additional_fields.author')}
                placeholder="Type a name or select an existing author"
                value={authorInput}
                onChange={(value) => {
                  setAuthorInput(value);
                  localStorage.setItem('cms_export_shopify_arthur', value);
                }}
                options={
                  cmsConnection?.settings.authors.map((author) => ({
                    value: author,
                  })) ?? []
                }
              />
            </InputDecoration>
            <InputDecoration label="Blog" required>
              <Select
                error={
                  selectedBlogId > -1
                    ? errorHelper.has('additional_fields.blog_id')
                    : undefined
                }
                value={selectedBlogId}
                onChange={(value) => {
                  setSelectedBlogId(value);
                  localStorage.setItem('cms_export_shopify_blog_id', value);
                }}
                options={[
                  {
                    title: 'Create new blog',
                    value: -1,
                    prependIcon: Plus,
                  },
                  ...(cmsConnection?.settings.blogs.map((blog) => ({
                    value: blog.id,
                    title: blog.title,
                  })) ?? []),
                ]}
              />
            </InputDecoration>
            {selectedBlogId === -1 && (
              <InputDecoration label="New blog name" required>
                <Input
                  error={
                    selectedBlogId === -1
                      ? errorHelper.has('additional_fields.blog_id')
                      : undefined
                  }
                  value={blogInput}
                  onChange={setBlogInput}
                  counter
                  counterMax={32}
                />
              </InputDecoration>
            )}
          </>
        )}
        <div className="flex items-center gap-2">
          Draft
          <Toggle onChange={setIsDraft} value={isDraft} />
          {selectedContentType?.name === 'collection' && (
            <>
              Is automated
              <Toggle value={isAutomated} onChange={setIsAutomated} />
            </>
          )}
        </div>
        {errorHelper.isError() && <ErrorAlert title={errorHelper.message()} />}
        {documentsWithoutRefs.length > 0 && (
          <WarningAlert
            title="The following documents has a URL that has no reference to shopify and will not be exported"
            body={[
              ...documentsWithoutRefs
                .slice(0, 10)
                .map((doc) => doc.title || 'Untitled'),
              ...(documentsWithoutRefs.length > 10
                ? [`and ${documentsWithoutRefs.length - 10} more`]
                : []),
            ]}
          />
        )}
        <div className="flex justify-end gap-2">
          <Button
            text="View field mappings"
            variant="ghost"
            onClick={() => navigate({ to: '/settings/custom-fields/mapping' })}
          />
          <Button
            text="Export"
            color="secondary"
            type="submit"
            disabled={documentsForExport.length === 0}
            isLoading={bulkExportMutation.isPending}
          />
        </div>
      </form>
    </Dialog>
  );
};
