import { Button, ButtonProps } from '@/Components/v2/Button';
import { Card } from '@/Components/v2/Card';
import { CardBody } from '@/Components/v2/Card/components/CardBody';
import { LucideProps } from 'lucide-react';

type Props = {
  icon: React.ComponentType<LucideProps>;
  title: string;
  description: string;
  action: Omit<ButtonProps, 'fullWidth' | 'color'>;
};

export const ActionCard = ({ action, description, icon, title }: Props) => {
  const Icon = icon;

  return (
    <Card elevated>
      <CardBody>
        <div className="flex max-w-[16rem] flex-col items-center gap-4 p-2">
          <Icon className="h-24 w-24 text-primary-300" />
          <p className=" text-center text-lg font-bold text-primary">{title}</p>
          <p className="text-center text-sm text-primary">{description}</p>
          <Button {...action} fullWidth color="secondary" />
        </div>
      </CardBody>
    </Card>
  );
};
