import { X } from 'lucide-react';
import Google from '../../../../../public/svg/google.png';
import GoogleCam from '../../../../../public/svg/google-camera.svg';
import GoogleMicrophone from '../../../../../public/svg/google-microphone.svg';
import GoogleKeyboard from '../../../../../public/svg/google-keyboard.svg';
import { GoogleGlass } from './GoogleGlass';

type Props = {
  keyword: string;
}

export const GoogleDesktop = ({ keyword }: Props) => {
  return (
    <div className="mb-4 flex w-full items-center gap-10 rounded-t-md border border-gray-100 px-2 py-1.5 shadow-serp-bottom">
      <img className="ml-10 h-7 w-[5rem]" src={Google} />
      <div className="relative w-full">
        <div className="absolute right-3 flex translate-y-3 gap-3">
          <X className="stroke-gray-500" size={20} />
          <div className="y-2 h-5 w-[1px] bg-gray-200" />
          <img src={GoogleKeyboard} className="h-5 w-5" />
          <img src={GoogleMicrophone} className="h-5 w-5" />
          <img src={GoogleCam} className="h-5 w-5" />
          <GoogleGlass />
        </div>
        <input
          className="w-full rounded-3xl border-[1px] border-gray-300 py-[0.6rem] pl-4 font-google ring-0"
          disabled={true}
          value={keyword}
        />
      </div>
    </div>
  );
};
