import { SlideOver } from '@/Components/SlideOver';
import { useKeywordInjectionStore } from '@/Pages/Document/stores';
import { DocumentResource, ProjectResource } from '@/api/openapiSchemas';
import { useShallow } from 'zustand/react/shallow';
import { KeywordInjectionContent } from './components/KeywordInjectionContent';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  project: ProjectResource;
  document: DocumentResource;
};
const KeywordInjectionSlideOver = ({
  isOpen,
  onClose,
  project,
  document,
}: Props) => {
  const { keyword } = useKeywordInjectionStore(
    useShallow((state) => ({
      keyword: state.keyword,
    })),
  );

  return (
    <SlideOver
      isOpen={isOpen}
      onClose={onClose}
      title={`INCLUDE "${keyword}"`}
      subHeading="These are the places in your text that we suggest you to insert the selected keyword."
      color={'bg-emerald-500'}
    >
      <KeywordInjectionContent document={document} project={project} />
    </SlideOver>
  );
};

KeywordInjectionSlideOver.displayName = 'KeywordInjectionSlideOver';

export { KeywordInjectionSlideOver };
