import * as React from 'react';
import { TooltipPopover } from './TooltipPopover';

export type TooltipProps = {
  title?: string;
  description?: string | React.ReactNode;
  link?: { text: string; url: string };
  navLink?: string;
  navLinkText?: string;
  navLinkParams?: any;
  navLinkTarget?: string;
  children: React.ReactNode | React.ReactNode[];
  side?: 'top' | 'right' | 'bottom' | 'left';
  align?: 'start' | 'center' | 'end';
  disabled?: boolean | null;
  asChild?: boolean;
};

const Tooltip = ({ disabled = null, children, ...props }: TooltipProps) => {
  if (disabled !== null) {
    if (!disabled) {
      return <TooltipPopover {...props} children={children} />;
    } else {
      return children;
    }
  }
  return <TooltipPopover {...props} children={children} />;
};

Tooltip.displayName = 'Tooltip';

export { Tooltip };
