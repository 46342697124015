import { ReactNode } from 'react';

export enum GradualScoreVariant {
  best = 'bg-green-500 text-green-800 bg-opacity-50 border border-green-550',
  great = 'bg-green-400 text-green-800 bg-opacity-50 border border-green-450',
  good = 'bg-green-300 text-green-800 bg-opacity-50 border border-green-350',
  ok = 'bg-primary-200 text-primary-600 bg-opacity-50 border border-primary-300',
  remaining = 'bg-primary-150 text-primary-600 bg-opacity-50 border border-primary-200',
}

export enum TagVariant {
  gray = 'bg-gray-100 text-gray-800',
  brown = 'bg-brown-100 text-brown-800',
  orange = 'bg-orange-100 text-orange-800',
  yellow = 'bg-yellow-100 text-yellow-800',
  green = 'bg-green-100 text-green-800',
  blue = 'bg-blue-100 text-blue-800',
  purple = 'bg-purple-100 text-purple-800',
  pink = 'bg-fuchsia-100 text-fuchsia-600',
  red = 'bg-red-100 text-red-800',
}
export enum Variant {
  blue = 'bg-blue-200 text-blue-800',
  cyan = 'bg-cyan-300 text-cyan-800',
  green = 'bg-green-100 text-green-800',
  yellow = 'bg-yellow-100 text-yellow-800',
  orange = 'bg-amber-400 text-amber-800',
  red = 'bg-red-100 text-red-800',
  gray = 'bg-gray-200 text-gray-800',
  pink = 'bg-red-50 text-red-300',
  purple = 'bg-purple-200 text-purple-800',
  teal = 'bg-teal-200 text-teal-800',
  white = 'bg-white text-primary border border-gray-200',
}
type Props = {
  children: ReactNode;
  variant: Variant | TagVariant | GradualScoreVariant;
  onClick?: () => void;
  disabled?: boolean;
};

export default function AppLabel({
  children,
  variant,
  onClick,
  disabled,
}: Props) {
  return (
    <div
      className={`flex justify-start ${
        onClick ? (disabled ? '' : 'cursor-pointer') : ''
      }`}
      onClick={onClick}
    >
      <div
        className={`items-center truncate rounded px-1.5 py-1 text-sm font-semibold ${variant}`}
      >
        {children}
      </div>
    </div>
  );
}
