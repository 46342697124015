type Props = {
  index: number;
  serp: {
    favicon: string;
    title: string;
    url: string;
    is_ours?: boolean;
  };
  green?: boolean;
};
export const SerpOverviewLines = ({ index, serp, green }: Props) => {
  const getBackgroundColor = () => {
    if (green) {
      return 'bg-green-100';
    }
    return '';
  };
  return (
    <div
      key={index}
      className={`flex flex-grow items-center overflow-hidden border-b border-primary-200 ${getBackgroundColor()}`}
    >
      <div className="w-12 flex-shrink-0 text-end">{index + 1}</div>
      <div className="flex flex-grow items-center gap-4 overflow-hidden py-2 pl-4">
        <img
          src={serp.favicon}
          className="h-8 w-8 flex-shrink-0 overflow-hidden rounded-full border border-primary-200"
        />
        <div className="flex flex-grow flex-col overflow-hidden">
          <div className="truncate text-blue-500">{serp.title}</div>
          <a
            href={serp.url}
            target="_blank"
            className="truncate hover:opacity-50"
            rel="noreferrer"
          >
            {serp.url}
          </a>
        </div>
        {serp.is_ours && <div className="pr-4 text-secondary-500">YOU</div>}
      </div>
    </div>
  );
};
