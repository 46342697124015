import { useTrackCustomBrandVoices } from '@/Pages/Settings/BrandVoice/hooks/useTrackCustomBrandVoices';
import { InputDecoration } from './v2/Input/InputDecoration';
import { Select } from './v2/Select';
import { useBrandVoiceIndex } from '@/api/openapiComponents';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { BrandVoiceResource } from '@/api/openapiSchemas';

type Props = {
  onChange: (
    value: string | undefined,
    valueObject: BrandVoiceResource,
  ) => void;
  value?: string;
};

export const BrandVoiceSelect = ({ onChange, value }: Props) => {
  const appState = useAppStore();
  const { data: defaultBrandVoices } = useBrandVoiceIndex({
    queryParams: { sort_direction: 'ASC', limit: 100 },
  });

  const { data: customBrandVoices } = useTrackCustomBrandVoices();

  const getDefaultBrandVoice = () => {
    const allBrandVoices =
      customBrandVoices && defaultBrandVoices
        ? [...customBrandVoices.data, ...defaultBrandVoices.data]
        : [];
    if (allBrandVoices.length > 0) {
      const selectedBrandVoice = allBrandVoices.find(
        (brandVoice) =>
          brandVoice.id === appState.currentProject!.default_brand_voice?.id,
      );
      return selectedBrandVoice;
    }
    return null;
  };
  return (
    <InputDecoration
      label="Brand voice"
      tooltip="Changes the tone of voice the AI uses to write content."
    >
      <Select
        clearable
        fullWidth
        placeholder={getDefaultBrandVoice()?.name ?? 'Professional'}
        onChange={(value) =>
          onChange(
            value,
            [
              ...(customBrandVoices?.data ?? []),
              ...(defaultBrandVoices?.data ?? []),
            ].find((brandVoice) => brandVoice.id.toString() === value)!,
          )
        }
        options={[
          {
            group: 'Pre-defined',
          },

          ...(defaultBrandVoices?.data
            .filter((brandVoice) => brandVoice.status === 'active')
            .map((brandVoice) => ({
              value: brandVoice.id.toString(),
              title: brandVoice.name,
            })) ?? []),
          {
            group: 'Custom',
          },
          ...(customBrandVoices?.data
            .filter((brandVoice) => brandVoice.status === 'active')
            .map((brandVoice) => ({
              value: brandVoice.id.toString(),
              title: brandVoice.name,
            })) ?? []),
        ]}
        value={value}
      />
    </InputDecoration>
  );
};
