import { Button } from '@/Components/v2/Button';
import { useNavigate, useRouter } from '@tanstack/react-router';
import { ArrowLeft, LucideProps, X } from 'lucide-react';
import { PropsWithChildren } from 'react';

type Props = {
  title?: string;
  canGoBack?: boolean;
  hasSlideover?: boolean;
  onClose?: () => void;
  closeText?: string;
  closeIcon?: React.ComponentType<LucideProps>;
};

export const CreatePageContainer = ({
  onClose,
  canGoBack = true,
  title,
  children,
  hasSlideover,
  closeText,
  closeIcon,
}: PropsWithChildren<Props>) => {
  const { history } = useRouter();
  const navigate = useNavigate();

  return (
    <div className="flex h-full w-full flex-col items-center bg-primary-50">
      <div
        className={`flex w-full justify-between ${
          hasSlideover ? 'pr-[28rem]' : ''
        }`}
      >
        <div className="ml-1 mt-1">
          {canGoBack && (
            <Button
              text="back"
              dense
              prependIcon={ArrowLeft}
              onClick={() => {
                {
                  if (history.location.state.key) {
                    history.back();
                  } else {
                    navigate({ to: '/' });
                  }
                }
              }}
              variant="ghost"
            />
          )}
        </div>
        {onClose && (
          <div className="mr-1 mt-1">
            <Button
              text={closeText ?? 'Close'}
              dense
              onClick={onClose}
              appendIcon={closeIcon ?? X}
              variant="ghost"
            />
          </div>
        )}
      </div>
      <div
        className={`flex h-full w-full flex-col items-center justify-center gap-4 overflow-hidden ${
          hasSlideover ? 'pr-[28rem]' : ''
        }`}
      >
        <p className={`text-center text-4xl font-bold`}>{title}</p>
        {children}
      </div>
    </div>
  );
};
