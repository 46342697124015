import { useNavigate } from '@tanstack/react-router';
import { CreatePageContainer } from '../components';
import {
  ExternalLink,
  FilePlus,
  Globe,
  Import,
  Plus,
  Stars,
} from 'lucide-react';
import { ActionCard } from './components/ActionCard';

export const ChooseCreationMethod = () => {
  const navigate = useNavigate();

  return (
    <CreatePageContainer
      title="Select an action"
      onClose={() => navigate({ to: '/' })}
    >
      <div className="mt-4 flex flex-wrap justify-center gap-12 px-4 pb-2">
        <div>
          <ActionCard
            icon={FilePlus}
            title="New content"
            description="Create new content from scratch in the AI SEO Editor"
            action={{
              prependIcon: ExternalLink,
              text: 'Open',
              onClick: () => navigate({ to: '/create-content/blank' }),
            }}
          />
        </div>
        <div>
          <ActionCard
            icon={Globe}
            title="Optimize existing"
            description="Import existing URL and benchmark against competitors"
            action={{
              prependIcon: Import,
              text: 'Select url',
              onClick: () => navigate({ to: '/create-content/import' }),
            }}
          />
        </div>
        <div>
          <ActionCard
            icon={Stars}
            title="AI draft"
            description="Generate draft of a blog post, product category etc. with AI"
            action={{
              prependIcon: Plus,
              text: 'Generate',
              onClick: () =>
                navigate({
                  to: '/create-content/ai-generated',
                  search: { is_new: true },
                }),
            }}
          />
        </div>
      </div>
    </CreatePageContainer>
  );
};
