import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { Input } from '@/Components/v2/Input/Input';
import { Popover } from '@/Components/v2/Menu/Popover';
import { ContentListResource } from '@/api/openapiSchemas';
import { isContentListItem } from '@/types';
import { AlignJustify, Check, Search } from 'lucide-react';
import { useState } from 'react';

type Props = {
  items: ContentListResource[];
  selectedList: ContentListResource | undefined;
  onChange: (index: number) => void;
};

export const ListMenu = ({ items, selectedList, onChange }: Props) => {
  const [search, setSearch] = useState('');
  const filteredItems = [{ name: 'All' }, ...items].filter((item) =>
    item.name.toLowerCase().includes(search.toLowerCase()),
  );

  const isSelectedItem = (
    item:
      | ContentListResource
      | {
          name: string;
        },
  ) => {
    if (item.name === 'All' && selectedList === undefined) {
      return true;
    }
    if (isContentListItem(item) && item.id === selectedList?.id) {
      return true;
    }
    return false;
  };
  return (
    <Popover trigger={<IconButton icon={AlignJustify} />}>
      <div className="flex w-80 flex-col px-3 py-4">
        <Input
          ref={(ref) => ref?.focus()}
          placeholder="Search.."
          dense
          appendIcon={Search}
          value={search}
          onChange={(value) => {
            setSearch(value);
          }}
          clearable
        />
        <ul className="relative mt-4 h-60 overflow-y-auto">
          {filteredItems.map((item) => (
            <>
              <li
                className="flex cursor-pointer px-2 py-1 hover:bg-primary-100"
                onClick={() => {
                  const currentId = isContentListItem(item)
                    ? item.id
                    : undefined;

                  const itemIndex = items.findIndex(
                    (item) => item.id === currentId,
                  );

                  onChange(itemIndex === -1 ? 0 : itemIndex + 1);
                }}
              >
                <div className="mr-2 h-5 w-5 text-primary">
                  {isSelectedItem(item) && (
                    <Check className="h-5 w-5 flex-shrink-0 text-primary" />
                  )}
                </div>
                <p className="line-clamp-2 truncate text-primary">
                  {item.name}
                </p>
              </li>
            </>
          ))}
        </ul>
      </div>
    </Popover>
  );
};
