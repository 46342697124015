import { Dialog } from '@/Components/Dialog';
import ValidationErrors from '@/Components/ValidationErrors';
import { Alert } from '@/Components/v2/Alert';
import { Button } from '@/Components/v2/Button';
import { LoadingSpinner } from '@/Components/v2/SvgIcon';
import { DocumentExportResource, DocumentResource } from '@/api/openapiSchemas';
import { ExportOptions } from '@/types';
import { AlertTriangle, Check } from 'lucide-react';
import React from 'react';

type Props = {
  publishDocumentDialog: ExportOptions;
  setPublishDocumentDialog: React.Dispatch<React.SetStateAction<ExportOptions>>;
  publishingDocument: boolean;
  documentIsPublished: boolean;
  publishedData:
  | {
    data: DocumentExportResource;
  }
  | undefined;
  document: DocumentResource;
  isPublishingError: boolean;
  errors:
  | {
    error: unknown;
    hasError: true;
  }
  | {
    error: null;
    hasError: boolean;
  };
  handleErrorAction: () => void;
};
export const PublishDocumentDialog = ({
  documentIsPublished,
  errors,
  handleErrorAction,
  isPublishingError,
  publishDocumentDialog,
  publishedData,
  publishingDocument,
  setPublishDocumentDialog,
  document,
}: Props) => {
  return (
    <Dialog
      isOpen={publishDocumentDialog.isOpen}
      handleClose={() => {
        setPublishDocumentDialog((prev) => ({ ...prev, isOpen: false }));
      }}
      title={`Exporting to ${publishDocumentDialog.type === 'wordpress' ? 'WordPress' : 'Google Docs'
        }`}
      size={'sm'}
    >
      <div className="flex flex-col text-2xl">
        <p className="flex flex-col gap-6">
          {(publishingDocument || documentIsPublished) && (
            <>
              {publishingDocument && (
                <LoadingSpinner size="base" color="green" />
              )}
              {documentIsPublished && (
                <Alert
                  color={'green'}
                  icon={Check}
                  body={
                    publishingDocument ? 'Exporting' : 'Successfully exported'
                  }
                />
              )}

              {documentIsPublished &&
                publishDocumentDialog.type === 'google_docs' &&
                (document.text.includes('&') ||
                  document.title.includes('&')) && (
                  <Alert
                    color={'yellow'}
                    icon={AlertTriangle}
                    body={
                      'The text contains "&" characters, which causes issues with the export. All "&" characters have been replaced with "-AND-" so you can easily replace them.'
                    }
                  />
                )}
              <Button
                disabled={publishingDocument && !documentIsPublished}
                fullWidth
                color="green"
                text="View"
                onClick={() => {
                  window.open(publishedData?.data.link || '', '_blank');
                }}
              />
            </>
          )}
          {isPublishingError && (
            <>
              <ValidationErrors
                apiError={errors.error}
                errors={errors.error?.errors}
              />
              <Button
                fullWidth
                color="green"
                text="Edit connection"
                onClick={() => {
                  setPublishDocumentDialog((prev) => ({
                    ...prev,
                    isOpen: false,
                  }));
                  handleErrorAction();
                }}
              />
            </>
          )}
        </p>
      </div>
    </Dialog>
  );
};
