import { AnimatedCollapsibleContainer } from '@/Components/AnimatedCollapsibleContainer';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { DocumentReportCompetitorResource } from '@/api/openapiSchemas';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { OutlineSuggestionItem } from './OutlineSuggestionItem';

type Props = {
  competitor: DocumentReportCompetitorResource;
  isOpen: boolean;
  disabled?: boolean;
  setIsCollapsed: (value: boolean) => void;
  onElaborate: (value: string) => void;
  onInsert: (value: string, type: string) => void;
  onMore: (value: string) => void;
  onParaphrase: (value: string) => void;
};

export const OutlineCompetitorItem = ({
  competitor,
  disabled,
  isOpen,
  onParaphrase,
  setIsCollapsed,
  onElaborate,
  onInsert,
  onMore,
}: Props) => {
  return (
    <div
      className={`border-gray-200 pt-2 text-gray-800 ${
        competitor.position !== 10 ? 'border-b' : ''
      }`}
    >
      <div className="flex items-center justify-between text-sm">
        <div
          className={`flex w-full  items-center gap-2 ${
            competitor.outline.length === 0
              ? 'cursor-not-allowed'
              : 'cursor-pointer'
          }`}
          onClick={() =>
            competitor.outline.length !== 0
              ? setIsCollapsed(!isOpen)
              : undefined
          }
        >
          <p>#{competitor.position}</p>
          <div className="flex-grow truncate">
            <a
              href={competitor.url}
              onClick={(e) => e.stopPropagation()}
              target="_blank"
              className="w-full cursor-pointer truncate hover:underline"
              title={competitor.title}
              rel="noreferrer"
            >
              {competitor.domain}
            </a>
          </div>
          <div className="flex flex-shrink-0 items-center">
            {competitor.outline.length === 0 ? (
              <p className="text-gray-400">No subheadings</p>
            ) : (
              <p>{competitor.outline.length} subheadings</p>
            )}
            <IconButton
              size="sm"
              disabled={competitor.outline.length === 0}
              icon={isOpen ? ChevronUp : ChevronDown}
              onClick={() => setIsCollapsed(!isOpen)}
            />
          </div>
        </div>
      </div>
      <p className="text-md mb-4 font-bold">{competitor.title}</p>
      <AnimatedCollapsibleContainer isOpen={isOpen}>
        <div className="flex flex-col gap-2 pb-4">
          {competitor.outline.map((heading) => (
            <OutlineSuggestionItem
              disabled={disabled}
              type={heading.type}
              suggestion={heading.title}
              onElaborate={(value) => onElaborate(value)}
              onInsert={(value) => onInsert(value, heading.type)}
              onMore={(value) => onMore(value)}
              onParaphrase={(value) => onParaphrase(value)}
            />
          ))}
        </div>
      </AnimatedCollapsibleContainer>
    </div>
  );
};
