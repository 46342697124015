type Props = {
  total?: number;
  isLoading?: boolean;
  title: string;
};

export const ResultsTotal = ({ total, isLoading, title }: Props) => {
  return (
    <>
      <div
        className={`min-w-24 mr-2 flex min-h-[2rem] items-center text-base font-semibold text-primary`}
      >
        {isLoading ? (
          <div className="animate-pulse">
            <div className="h-5 w-24 rounded-md bg-gray-200" />
          </div>
        ) : (
          <p className="opacity-90">
            {total ?? 0} {title}{' '}
          </p>
        )}
      </div>
    </>
  );
};
