type Props = {
  score: number;
  onClick: () => void;
};

export const ScoreBadge = ({ onClick, score }: Props) => {
  const getColor = () => {
    if (score < 40) {
      return 'bg-gradient-to-tr from-[#AA9944] to-[#EE4444]';
    }
    if (score < 70) {
      return 'bg-gradient-to-tr from-[#B7CD4E] to-[#BBAA33]';
    }
    return 'bg-gradient-to-tr from-[#B7CD4E] to-[#0FC75F]';
  };

  return (
    <div
      className={`absolute bottom-4 right-8 z-[60] aspect-square cursor-pointer rounded-full border border-primary-100 bg-white p-1 text-xl font-bold text-white shadow-xl `}
      onClick={onClick}
    >
      <div
        className={`flex h-full w-full items-center justify-center rounded-full p-2 ${getColor()}`}
      >
        {score} <span className="text-lg">%</span>
      </div>
    </div>
  );
};
