import { ConfirmDialog } from '@/Components/ConfirmDialog';
import { ContentListResource } from '@/api/openapiSchemas';

type Props = {
  deletedList: ContentListResource | undefined;
  onClose: () => void;
  isLoading: boolean;
  handleDeleteSubmit: () => void;
};

export const RemoveListDialog = ({
  onClose,
  isLoading,
  deletedList,
  handleDeleteSubmit,
}: Props) => {
  return (
    <ConfirmDialog
      title="Delete list"
      confirmText="Delete"
      content={
        <>
          Are you sure you want to delete the list <b>{deletedList?.name}</b>?
        </>
      }
      onClose={onClose}
      isOpen={!!deletedList}
      confirmColor="red"
      isLoading={isLoading}
      onConfirm={handleDeleteSubmit}
    />
  );
};
