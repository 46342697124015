import { BlockType, LeafType, isLeafNode } from '@/types';
import { getIndexRangesOfKeyword } from '../../utils';

export const findKeywordAndGetAnchorPos = (
  children: (BlockType | LeafType)[],
  pattern: string,
  pos: {
    path: number[];
    location: {
      anchor: { path: number[]; offset: number };
      focus: { path: number[]; offset: number };
    } | null;
  } = { path: [], location: null },
): {
  path: number[];
  location: {
    anchor: { path: number[]; offset: number };
    focus: { path: number[]; offset: number };
  } | null;
} | null => {
  for (const [index, child] of children.entries()) {
    const newPos = [...pos.path, index];
    if (isLeafNode(child) && child.text.includes(pattern)) {
      const { startIndex, endIndex } = getIndexRangesOfKeyword(
        pattern,
        child.text,
      );

      return {
        path: newPos,
        location: {
          anchor: { path: newPos, offset: startIndex },
          focus: { path: newPos, offset: endIndex },
        },
      };
    }

    if (!isLeafNode(child)) {
      const path = [...pos.path, index];
      const result = findKeywordAndGetAnchorPos(child.children, pattern, {
        path,
        location: null,
      });

      if (result && result.location) {
        return { path: result.path, location: result.location };
      }
    }
  }

  return null;
};

export const trimPunctuation = /[\,\.\!\?]+$/;

export const getKeywordIndexes = (sentence: string, keyword: string) => {
  const keywordStart = sentence.indexOf(keyword);
  const startOfKeyword = sentence.slice(0, keywordStart).split(' ').length - 1;
  const endOfKeyword = startOfKeyword + keyword.split(' ').length - 1;

  if (startOfKeyword === -1 || endOfKeyword === -1) {
    return [null, null];
  }

  return [startOfKeyword, endOfKeyword];
};
