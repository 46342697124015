import { Button } from '@/Components/v2/Button';
import { ThumbsDown, ThumbsUp } from 'lucide-react';

type Props = {
  onReject: () => void;
  onAccept: () => void;
  isDisabled?: boolean;
}

const SuggestedActions = ({ onReject, onAccept, isDisabled }: Props) => {
  return (
    <div className="mt-4 flex justify-center gap-4">
      <Button
        variant="outline"
        onClick={onReject}
        prependIcon={ThumbsDown}
        text="REJECT"
        size={'xs'}
      />
      <Button
        color="secondary"
        onClick={onAccept}
        prependIcon={ThumbsUp}
        text="ACCEPT"
        disabled={isDisabled}
        size={'xs'}
      />
    </div>
  );
};

SuggestedActions.displayName = 'SuggestedActions';

export { SuggestedActions };
