import {
  ScannedKeywordGroupResource,
  TrackedKeywordResource,
} from '@/api/openapiSchemas';
import { SimilarityDialog } from '@/Pages/KeywordScanner/components/SimilarityDialog';
import { useState } from 'react';

type Props = {
  mainKeyword: ScannedKeywordGroupResource;
  keyword: TrackedKeywordResource;
};

export const SimilarityIndicator = ({ mainKeyword, keyword }: Props) => {
  const [showCannibalizationDialog, setShowCannibalizationDialog] =
    useState(false);

  return (
    <>
      <SimilarityDialog
        isOpen={showCannibalizationDialog}
        onClose={() => setShowCannibalizationDialog(false)}
        leftLines={{
          keyword: mainKeyword.main_keyword.keyword?.name ?? '',
          items:
            mainKeyword.main_keyword.keyword?.search_results?.map((result) => ({
              image: result.favicon,
              title: result.title,
              url: result.url,
            })) ?? [],
        }}
        rightLines={{
          keyword: keyword.keyword?.name ?? '',
          items:
            keyword.keyword?.search_results?.map((result) => ({
              image: result.favicon,
              title: result.title,
              url: result.url,
            })) ?? [],
        }}
      />

      <div
        className="cursor-pointer text-sm text-primary-600 hover:opacity-50"
        onClick={() => setShowCannibalizationDialog(true)}
      >
        (
        {(mainKeyword.main_keyword.keyword?.search_results?.filter(
          (result) =>
            keyword.keyword?.search_results?.some(
              (secondaryResult) => secondaryResult.url === result.url,
            ),
        ).length ?? 0) * 10}
        %)
      </div>
    </>
  );
};
