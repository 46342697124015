import { CustomFieldDefinitionResource } from '@/api/openapiSchemas';
import { CustomFieldDropZone } from './CustomFieldDropzone';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { CustomFieldDraggableContainer } from './CustomFieldDraggableContainer';

type Props = {
  customField: CustomFieldDefinitionResource;
  value?: string;
  isActive: boolean;
};

export const CustomFieldDraggable = ({
  customField,
  isActive,
  value,
}: Props) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: 'movable ' + customField.id,
      data: { customField },
    });

  return (
    <>
      <div
        ref={setNodeRef}
        style={{
          transform: CSS.Transform.toString(transform),
          transition,
        }}
        {...attributes}
        {...listeners}
        className="cursor-grab"
      >
        <CustomFieldDraggableContainer
          customField={customField}
          value={value}
          hideTooltip
        />
      </div>
      <CustomFieldDropZone
        key={customField.id}
        customField={customField}
        isActive={isActive}
      />
    </>
  );
};
