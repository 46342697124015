import { Dialog } from '../Dialog';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { UsageItem } from '@/Pages/Account/components/UsageItem';
import { useEffect, useState } from 'react';
import * as Table from '@/Components/Table';
import { Button } from '../v2/Button';
import { ProjectCreditsInput } from './components/ProjectCreditsInput';
import { useNavigate } from '@tanstack/react-router';
import ReactCountryFlag from 'react-country-flag';
import { CreditsInfoBox } from './components/CreditsInfoBox';
import { formatThousandSeperator } from '@/utils';
import { HelpCircle } from 'lucide-react';
import { Tooltip } from '../v2/Tooltip';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const UrlCreditsManagementDialog = ({ isOpen, onClose }: Props) => {
  const appState = useAppStore();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const [extraCredits, setExtraCredits] = useState(
    appState.projects!.map((project) => '' + project.url_credits.addons),
  );

  useEffect(() => {
    if (!isOpen) return;
    const func = async () => {
      await appState.refreshProjects();
      await appState.refreshSubscription();
      setIsLoading(false);
    };
    func();
  }, [isOpen]);

  useEffect(() => {
    if (!isLoading) {
      setExtraCredits(
        appState.projects!.map((project) => '' + project.url_credits.addons),
      );
    }
  }, [isLoading]);

  const totalExtraCredits = extraCredits.reduce(
    (prev, cur) => prev + Number(cur),
    0,
  );

  const handleClose = () => {
    appState.refreshProjects();
    appState.refreshSubscription();
    setIsLoading(true);
    onClose();
  };

  return (
    <Dialog
      title={
        <div className="flex items-center gap-2">
          URL credits per website
          <Tooltip
            title="URL credits"
            description={`Your account plan includes ${appState.subscription?.usage.credits.urls.base_project_urls} default URLs per domain that we crawl and analyze. You have purchased ${appState.subscription?.usage.credits.urls.limit} extra URL credits that you can distribute among your domains.`}
            link={{
              text: 'Read more',
              url: 'https://docs.seo.ai/a-41-url-credits',
            }}
          >
            <HelpCircle size={18} />
          </Tooltip>
        </div>
      }
      isOpen={isOpen}
      size="lg"
      handleClose={handleClose}
    >
      <div className="mb-6 flex justify-around gap-6">
        <CreditsInfoBox
          name="Default website credits"
          highlighted
          amount={
            appState.subscription?.usage.credits.urls.base_project_urls ?? 0
          }
        />
        <CreditsInfoBox
          highlighted
          name={`Extra credits (${appState.subscription?.products.find((product) => product.type === 'plan')?.name.toUpperCase()})`}
          amount={appState.subscription?.usage.credits.urls.limit ?? 0}
        />
      </div>
      <UsageItem
        allowMaxedOut
        name={`Used extra credits (${formatThousandSeperator(appState.subscription?.usage.credits.urls.limit - totalExtraCredits)} remaining)`}
        usage={{
          used: totalExtraCredits,
          limit: appState.subscription?.usage.credits.urls.limit ?? 0,
        }}
      />

      <p className="mt-10 text-lg font-bold">Allocation of credits</p>
      <Table.Root
        items={appState.projects}
        isLoading={isLoading}
        skeletonLoaders={3}
        columns={[
          {
            heading: 'WEBSITE',
            render: (item) => (
              <div className="flex items-center gap-2" title={item.domain}>
                <ReactCountryFlag
                  countryCode={item.language.country_code ?? ''}
                  svg
                  style={{
                    height: '18px',
                    width: '23px',
                  }}
                />
                {item.name}
              </div>
            ),
          },
          {
            heading: (
              <div className="flex items-center gap-1">
                EXTRA{' '}
                <Tooltip title="Extra credits will increase the limit of URL credits for the specific website.">
                  <HelpCircle size={14} className="mt-1" />
                </Tooltip>
              </div>
            ),
            render: (item, _, __, index) => (
              <ProjectCreditsInput
                project={item}
                value={extraCredits?.[index]}
                onChange={(value) =>
                  setExtraCredits((p) => {
                    const newValues = [...p];
                    newValues[index] = value;
                    return newValues;
                  })
                }
              />
            ),
          },
          {
            heading: (
              <div className="flex items-center gap-1">
                USED{' '}
                <Tooltip title="How many URLS have already been stored for the website. This is visible on 'All pages'.">
                  <HelpCircle size={14} className="mt-1" />
                </Tooltip>
              </div>
            ),
            render: (item) => formatThousandSeperator(item.url_credits.used),
          },
          {
            heading: (
              <div className="flex items-center gap-1">
                TOTAL{' '}
                <Tooltip title="Total amount of URLS a website can store, when the limit is reached no more URLS can be saved.">
                  <HelpCircle size={14} className="mt-1" />
                </Tooltip>
              </div>
            ),
            render: (item, _, __, index) => (
              <>
                {formatThousandSeperator(
                  item.url_credits.base + Number(extraCredits[index]),
                )}
              </>
            ),
          },
          {
            heading: (
              <div className="flex items-center gap-1">
                UNUSED{' '}
                <Tooltip title="Unused credits is how many more URLS a website can have saved. If it reaches 0, no more URLS will get saved.">
                  <HelpCircle size={14} className="mt-1" />
                </Tooltip>
              </div>
            ),
            render: (item, _, __, index) => (
              <>
                {formatThousandSeperator(
                  item.url_credits.base +
                    Number(extraCredits[index]) -
                    item.url_credits.used,
                )}
              </>
            ),
          },
        ]}
      />

      <div className="flex justify-end">
        <Button
          text="buy more credits"
          variant="outline"
          color="secondary"
          onClick={() => navigate({ to: '/account/subscription' })}
        />
      </div>
    </Dialog>
  );
};
