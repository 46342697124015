import { getKeywordPosInsideSentence } from '@/Pages/Document/utils';
import {
  BlockType,
  ExtendedKeywordSuggestion,
  ExtendedNewKeywordSuggestion,
  InternalLinkingResource,
  LeafType,
  NodeTypesEnum,
  isBaseElement,
  isExtendedKeywordSuggestion,
  isInternalLinkingResource,
  isLeafNode,
} from '@/types';
import { Value } from '@udecode/plate-common';
import { PlateEditor } from '@udecode/plate-core';
import { type ClassValue, clsx } from 'clsx';
import { Element, Node, Point } from 'slate';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const getDocumentText = (nodes: BlockType[]): string => {
  if (!nodes) return '';
  return (nodes)
    .map((node) => {
      if (
        node.type === NodeTypesEnum.P ||
        node.type === NodeTypesEnum.LIC ||
        node.type === NodeTypesEnum.BLOCKQUOTE
      ) {
        return node.children.reduce((prev, curr) => {
          if (
            (curr as BlockType).type === NodeTypesEnum.A ||
            (curr as BlockType).type === NodeTypesEnum.BLOCKQUOTE
          ) {
            return (
              prev +
              (curr as BlockType).children.reduce((prev, curr) => {
                return prev + (curr as LeafType).text;
              }, '')
            );
          }
          return prev + (curr as LeafType).text;
        }, '');
      } else {
        return getDocumentText(node.children as BlockType[]);
      }
    })
    .join('\n');
};

export const isInsideTableCell = (editor: PlateEditor) => {
  const { selection } = editor;

  if (selection) {
    const [cellEntry] = editor.nodes({
      match: (node) =>
        Element.isElement(node) &&
        ((node as BlockType).type === NodeTypesEnum.TD ||
          (node as BlockType).type === NodeTypesEnum.TH),
    });

    return !!cellEntry;
  }

  return false;
};

export const isParagraphNode = <BlockType extends Node>(node: BlockType) =>
  isBaseElement(node) &&
  (node.type === NodeTypesEnum.P || node.type === NodeTypesEnum.LIC);

const getAnchorPosForLink = (node: BlockType, endOffset: number) => {
  let path = 0;
  let count = 0;
  let offset = 0;
  let foundValue = false;
  node.children.forEach((child) => {
    if (!foundValue) {
      const text = getLeafText(child);
      const textLength = text.length;
      if (count + textLength >= endOffset) {
        const index = endOffset - count;
        offset = index;
        foundValue = true;
      } else {
        path = path + 1;
        count = count + textLength;
      }
    }
  });
  return { path, offset };
};
export const getAnchorAndFocusPos = (
  textNodes: (BlockType | LeafType)[],
  startIndex: number,
  endIndex: number,
  path: any[],
  skipFirst?: boolean,
) => {
  const positions: { anchor: Point | null; focus: Point | null } = {
    anchor: null,
    focus: null,
  };
  let count = 0;
  let point = 0;
  let foundValue = false;
  let lookFor: 'start' | 'end' = 'start';
  textNodes.forEach((node: BlockType | LeafType, index) => {
    if (skipFirst && index === 0) {
      point++;
      return;
    }
    if (!foundValue) {
      const text = getLeafText(node);
      const textLength = text.length;
      if (lookFor === 'start') {
        if (count + textLength >= startIndex) {
          const startOffset = startIndex - count;
          if (node.type === NodeTypesEnum.A) {
            const values = getAnchorPosForLink(node as BlockType, startOffset);
            positions.anchor = {
              path: [...path, point, values.path],
              offset: values.offset,
            };
          } else {
            positions.anchor = { path: [...path, point], offset: startOffset };
          }

          lookFor = 'end';
        } else {
          count = count + textLength;
          point++;
        }
      }
      if (lookFor === 'end') {
        if (count + textLength >= endIndex) {
          const endOffset = endIndex - count;
          if (node.type === NodeTypesEnum.A) {
            const values = getAnchorPosForLink(node as BlockType, endOffset);
            positions.focus = {
              path: [...path, point, values.path],
              offset: values.offset,
            };
          } else {
            positions.focus = {
              path: [...path, point],
              offset: endOffset,
            };
          }
          foundValue = true;
        } else {
          count = count + textLength;
          point++;
        }
      }
    }
  });

  return positions;
};

export const getParagraphText = (children: (BlockType | LeafType)[]) =>
  children.reduce((prev: string, node) => {
    return prev + getLeafText(node);
  }, '');

export const getLeafText = (node: BlockType | LeafType) =>
  isLeafNode(node)
    ? node.text
    : node.children.reduce((prev, curr) => {
        return prev + (curr as LeafType).text;
      }, '');

export const getKeywordPositionInParagraph = (
  editor: PlateEditor | null,
  lr: InternalLinkingResource,
) => {
  const result = editor
    ?.nodes<BlockType | LeafType>({
      at: {
        anchor: { path: [0], offset: 0 },
        focus: { path: [editor.children.length - 1], offset: 0 },
      },
      match: (node) =>
        (isParagraphNode(node) || node.type === NodeTypesEnum.BLOCKQUOTE) &&
        getParagraphText((node as BlockType).children).includes(
          lr.linkResource.sentence,
        ),
    })
    .next();
  const path = [...result?.value[1]];
  const paragraphText = getParagraphText(result?.value[0].children);
  const textNodes: (BlockType | LeafType)[] = result?.value[0].children;

  const positions = getKeywordPosInsideSentence(
    lr,
    paragraphText,
    textNodes,
    path,
  );
  return positions;
};

export const isEmptyEditor = (editor: PlateEditor<Value>) =>
  editor.children.length === 1 &&
  editor.children[0].children.length === 1 &&
  editor.children[0].type === NodeTypesEnum.P &&
  editor.children[0].children[0].text === '';

export const getHoverColorForLinks = (
  resource:
    | InternalLinkingResource
    | ExtendedKeywordSuggestion
    | ExtendedNewKeywordSuggestion,
) => {
  if (isInternalLinkingResource(resource)) {
    return resource.isHoveredFromTab || resource?.isHoveredFromEditor
      ? 'bg-green-200'
      : 'bg-purple-200';
  }
  if (isExtendedKeywordSuggestion(resource)) {
    return resource.hoveredFromTab || resource.hoveredFromEditor
      ? 'bg-green-200'
      : 'bg-green-100';
  }
  return '';
};
