import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Button } from '../Button';
import { IconButton } from '../IconButton/IconButton';
import { X } from 'lucide-react';

export type SnackbarColors =
  | 'green'
  | 'red'
  | 'blue'
  | 'gray'
  | 'yellow'
  | 'primary'
  | 'secondary'
  | 'white';

export type SnackbarAlignment =
  | 'top'
  | 'top-right'
  | 'top-left'
  | 'bottom'
  | 'bottom-right'
  | 'bottom-left';

type Props = {
  isOpen: boolean;
  message: string;
  color?: SnackbarColors;
  action?: { text: string; onClick: () => void };
  closeable: boolean;
  duration?: number | 'infinite';
  onClose: () => void;
  alignment?: SnackbarAlignment;
};

export const Snackbar = ({
  isOpen,
  message,
  alignment = 'bottom',
  closeable,
  color,
  action,
  duration,
  onClose,
}: Props) => {
  useEffect(() => {
    if (isOpen && duration !== 'infinite') {
      const timer = setTimeout(() => {
        onClose();
      }, duration ?? 3000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [isOpen]);

  const getPosition = () => {
    let classNames = alignment?.includes('top') ? 'top-4' : 'bottom-4';

    if (alignment?.includes('right')) {
      classNames += ' right-4';
    } else if (alignment?.includes('left')) {
      classNames += ' left-4';
    } else {
      classNames += ' left-1/2 transform -translate-x-1/2';
    }

    return classNames;
  };

  const getAnimation = () => {
    return isOpen ? 'animate-fade-fast' : 'animate-fade-out-fast';
  };

  const getColor = () => {
    switch (color) {
      case 'green':
        return 'bg-green text-white';
      case 'red':
        return 'bg-red text-white';
      case 'blue':
        return 'bg-blue text-white';
      case 'gray':
        return 'bg-gray text-white';
      case 'yellow':
        return 'bg-yellow text-white';
      case 'primary':
        return 'bg-primary text-white';
      case 'secondary':
        return 'bg-secondary text-white';
      case 'white':
        return 'bg-white text-primary-400';
    }
  };

  return createPortal(
    <div className={`absolute z-[10000] ${getPosition()}`}>
      <div
        className={`flex items-center gap-2 rounded py-2 pl-3 ${
          !closeable && !action ? 'pr-3' : 'pr-2'
        } shadow-md ${getAnimation()} ${getColor()}`}
      >
        {message}
        {action && (
          <Button
            onClick={action.onClick}
            size="xs"
            variant="ghost"
            dense
            color={color === 'white' ? 'primary' : 'white'}
            text={action.text}
          />
        )}
        {closeable && (
          <IconButton
            color={color === 'white' ? 'primary' : 'white'}
            icon={X}
            dense
            onClick={onClose}
          />
        )}
      </div>
    </div>,
    document.body,
  );
};
