type Props = {
  checked: boolean;
  label?: string;
  color?: keyof typeof colors;
  onChange: (value: boolean) => void;
  disabled?: boolean;
};

const colors = {
  default:
    'border-primary text-secondary checked:bg-secondary checked:before:bg-secondary focus:bg-transparent hover:bg-transparent before:bg-secondary checked:focus:bg-secondary checked:hover:bg-secondary',
  top3: 'border-secondary-500 text-secondary-500 checked:bg-secondary-500 checked:before:bg-secondary-500 focus:bg-transparent hover:bg-trasparent before:bg-secondary-500 checked:focus:bg-secondary-500 checked:hover:bg-secondary-500',
  top10:
    'border-secondary-400 text-secondary-400 checked:bg-secondary-400 checked:before:bg-secondary-400 checked:focus:bg-secondary-400 focus:bg-transparent hover:bg-transparent before:bg-secondary-400 checked:hover:bg-secondary-400',
  top20:
    'border-secondary-300 text-secondary-300 checked:bg-secondary-300 checked:before:bg-secondary-300 focus:bg-transparent hover:bg-transparent before:bg-secondary-300 checked:focus:bg-secondary-300 checked:hover:bg-secondary-300',
  top50:
    'border-primary-400 text-primary-400 checked:bg-primary-400 checked:before:bg-primary-400 focus:bg-transparent hover:bg-transparent before:bg-primary-400 checked:focus:bg-primary-400 checked:hover:bg-primary-400',
  remaining:
    'border-primary-300 text-primary-300 checked:bg-primary-300 checked:before:bg-primary-300 focus:bg-transparent hover:bg-transparent before:bg-primary-300 checked:focus:bg-primary-300 checked:hover:bg-primary-300',
};

export function SimpleCheckbox({
  checked,
  onChange,
  label,
  color = 'default',
  disabled,
}: Props) {
  return (
    <label className="relative flex cursor-pointer items-center gap-2 rounded-full p-2 text-gray-600">
      <input
        type="checkbox"
        className={`relative h-5 w-5 cursor-pointer appearance-none rounded border border-primary-400 text-secondary-500 transition-all before:absolute before:left-2/4 before:top-2/4 before:block before:h-12 before:w-12 before:-translate-x-2/4 before:-translate-y-2/4 before:rounded-full before:opacity-0 before:transition-opacity hover:before:opacity-10  focus:ring-0 focus:ring-offset-0 ${colors[color]}`}
        onChange={onChange ? (e) => onChange(e.target.checked) : undefined}
        onClick={(e) => e.stopPropagation()}
        checked={checked}
        disabled={disabled}
      />
      {label}
    </label>
  );
}
