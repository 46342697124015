import { MarkdownNode } from '@/types';
import transform from './transform';

export default function plugin() {
  const compiler = (node: { children: MarkdownNode[] }) => {
    return node.children.map((c) => transform(c)).filter((c) => c !== null);
  };

  // @ts-ignore
  this.Compiler = compiler;
}
