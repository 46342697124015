import { ListItem, ListItemProps } from '../Listitem';

type Props = {
  options: (ListItemProps & { id: number })[];
  value: number;
  onChange: (value: number) => void;
};

export const ToggleGroup = ({ options, onChange, value }: Props) => {
  return (
    <div className="flex w-full flex-col gap-2">
      {options.map((option) => {
        const isActive = option.id === value;

        return (
          <div
            key={option.id}
            className={`group flex w-full cursor-pointer items-center rounded-lg p-2 ${
              isActive
                ? 'border-secondary bg-secondary-50'
                : 'border-primary-300'
            } border`}
            onClick={() => onChange(option.id)}
          >
            <div
              className={`${
                isActive
                  ? 'border-4 border-secondary-300 bg-secondary-50 '
                  : 'border border-primary group-hover:border-primary-300'
              } ml-2 box-border h-3 w-3 rounded-full`}
            />

            <ListItem {...option} />
          </div>
        );
      })}
    </div>
  );
};
