import { createPortal } from 'react-dom';
import { Card, CardProps } from '../Card';
import { IconButton } from '../IconButton/IconButton';
import { X } from 'lucide-react';

export type PushNotificationProps = CardProps & { onClose?: () => void };

const PushNotification = ({ onClose, ...props }: PushNotificationProps) => {
  return (
    <>
      {createPortal(
        <div className="absolute bottom-8 left-8 z-[10000] max-w-xs shadow-base-small rounded-xl">
          {onClose && (<div className='absolute top-2 right-2'><IconButton onClick={onClose} size='lg' icon={X} /></div>)}
          <Card {...props}>{props.children}</Card>
        </div>,
        document.body,
      )}
    </>
  );
};

export { PushNotification };
