import { ScoreLabel } from '@/Components/Labels/ScoreLabel';
import { MoreVertical } from 'lucide-react';
import { Button } from '@/Components/v2/Button';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { PopoverMenu } from '@/Components/v2/Menu/ButtonMenu';

type Props = {
  value: { title: string; score?: string };
  targetTcs?: number;
  onInsert?: (value: string) => void;
  onMore: (value: string) => void;
  onElaborate: (value: string) => void;
  onParaphrase: (value: string) => void;
  onOptimize: (value: string) => void;
};

export const SuggestionItem = ({
  value,
  onInsert,
  targetTcs,
  onOptimize,
  onElaborate,
  onMore,
  onParaphrase,
}: Props) => {
  return (
    <div className="mb-4 flex animate-fade-slow items-center rounded-lg border border-gray-300 px-4 py-2">
      <div className="flex-grow">
        <p className="mb-4 mt-2 font-extrabold">{value.title}</p>
      </div>
      <div className="ml-4 flex flex-col gap-2">
        <div className="flex justify-end gap-2">
          {value.score && targetTcs && (
            <ScoreLabel
              score={value.score}
              color={parseInt(value.score) >= targetTcs ? 'green' : 'gray'}
            />
          )}

          {/* Actions */}
          <PopoverMenu
            items={[
              {
                title: 'More like this',
                onClick: () => onMore(value.title),
              },
              {
                title: 'Elaborate on topic',
                onClick: () => onElaborate(value.title),
              },
              {
                title: 'Paraphrase',
                onClick: () => onParaphrase(value.title),
              },
              {
                title: 'Optimize',
                onClick: () => onOptimize(value.title),
              },
            ]}
            trigger={<IconButton icon={MoreVertical} size="sm" />}
          />
        </div>
        {onInsert && (
          <Button
            size="xs"
            dense
            variant="ghost"
            text="Insert"
            onClick={() => onInsert(value.title)}
          />
        )}
      </div>
    </div>
  );
};
