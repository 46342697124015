import * as React from 'react';
import BeatLoader from '@/Components/BeatLoader';
import { Pencil, SendHorizontal } from 'lucide-react';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { Button } from '@/Components/v2/Button';
import { TextArea } from '@/Components/v2/TextArea/TextArea';

type Props = {
  text: string;
  id: number;
  isLoading?: boolean;
  disabled?: boolean;
  onSubmitEdit: (messageId: number, text: string) => void;
};

export function HumanMessage({
  text,
  id,
  isLoading,
  disabled,
  onSubmitEdit,
}: Props) {
  const [isEditing, setIsEditing] = React.useState(false);
  const [editedMessageValue, setEditedMessageValue] = React.useState('');

  const handleEdit = () => {
    setIsEditing(true);
    setEditedMessageValue(text);
  };

  const handleSubmitEdit = () => {
    onSubmitEdit(id, editedMessageValue);
    setIsEditing(false);
  };

  return (
    <div className=" border-b-2 bg-zinc-100">
      {isEditing ? (
        <div className="relative p-2">
          <TextArea
            value={editedMessageValue}
            rows={5}
            maxHeight={15}
            resize
            onChange={(value) => setEditedMessageValue(value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                if (e.shiftKey) {
                  return;
                }
                e.preventDefault();

                handleSubmitEdit();
              }
            }}
            appendIcon={
              <IconButton
                icon={SendHorizontal}
                onClick={handleSubmitEdit}
                color="secondary"
              />
            }
          />
          <div className="absolute right-4 top-4">
            {/* TODO: Replace with iconbutton */}
          </div>
        </div>
      ) : (
        <p className="whitespace-pre-wrap px-6 pt-6">
          {text}
          {isLoading && (
            <BeatLoader
              className="mt-6"
              size={'0.5rem'}
              color="rgb(74 222 128 / 1)"
            />
          )}
        </p>
      )}
      <div className="flex justify-end pb-2 pr-2">
        <Button
          size="sm"
          color="primary"
          onClick={handleEdit}
          disabled={disabled}
          text="Edit"
          prependIcon={Pencil}
          variant="ghost"
          dense
        />
      </div>
    </div>
  );
}
