import SimpleTitle from '@/Components/SimpleTitle';
import { Button, ButtonProps } from '@/Components/v2/Button';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { Tooltip } from '@/Components/v2/Tooltip';
import { Plus } from 'lucide-react';
import * as React from 'react';
import BrandVoiceEmptyIcon from '../../../../public/svg/brand-voice-empty-icon.png';
import Pagination from '@/Components/ProjectKeywords/Pagination';
import { SectionHeader } from './SectionHeader';

type Props = {
  title: string;
  buttonProps?: {
    toolTip?: {
      title: string;
      description: string;
    };
  } & ButtonProps;
  children: React.ReactNode;
  isLoading?: boolean;
  isEmptyProps?: {
    isEmpty: boolean;
    text: string;
    onClick: () => void;
    buttonText: string;
  };
  paginationProps?: {
    currentPage: number;
    setCurrentPage: (currentPage: number) => void;
    lastPage?: number;
  };
  gap?: number;
};

export const CardSection = ({
  title,
  buttonProps,
  children,
  isLoading,
  isEmptyProps,
  paginationProps,
  gap = 2,
}: Props) => {
  return (
    <>
      <div className="my-12">
        <SectionHeader title={title} buttonProps={buttonProps} />
        <div
          style={{ gap: gap ? `${gap * 0.25}rem` : undefined }}
          className="grid grid-cols-3 pt-10"
        >
          {isLoading ? (
            <>
              <>
                <span>
                  <SkeletonLoader height="xl" />
                </span>
                <span>
                  <SkeletonLoader height="xl" />
                </span>
                <span>
                  <SkeletonLoader height="xl" />
                </span>
              </>
            </>
          ) : !isEmptyProps ? (
            children
          ) : isEmptyProps.isEmpty ? (
            <>
              <div />
              <div className="flex w-full flex-col items-center justify-center gap-4">
                <img className="h-40" src={BrandVoiceEmptyIcon} />
                <h1 className="italic text-gray-400">{isEmptyProps.text}</h1>
                <div>
                  <Button
                    variant="outline"
                    color="secondary"
                    prependIcon={Plus}
                    text={isEmptyProps.buttonText}
                    onClick={isEmptyProps.onClick}
                  />
                </div>
              </div>
              <div />
            </>
          ) : (
            children
          )}
        </div>
      </div>
      {paginationProps && <Pagination {...paginationProps} />}
    </>
  );
};
