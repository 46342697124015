import SimpleButton from '@/Components/SimpleButton';
import { useNavigate } from '@tanstack/react-router';
import { ArrowLeft } from 'lucide-react';

type Props = {
  errorBoundary?: boolean;
}
const DocumentLoading = ({ errorBoundary }: Props) => {
  const navigate = useNavigate();
  return (
    <div className={`bg-slate-50 ${errorBoundary ? 'w-full' : ''}`}>
      <div className="flex h-screen w-full flex-col items-center overflow-hidden">
        <div className="flex w-full flex-grow overflow-hidden">
          <div className="flex h-[53px] flex-grow items-center border-b border-gray-200">
            {!errorBoundary && (
              <SimpleButton
                onClick={() => {
                  navigate({ to: '/created-content' });
                }}
                className="ml-3 block h-8 w-8 rounded-2xl bg-slate-200 p-1"
              >
                <ArrowLeft color="black" />
              </SimpleButton>
            )}
          </div>
          <div className="flex h-full w-full  max-w-5xl flex-col overflow-hidden">
            {/* Toolbar */}
            <div className="flex h-[53px] w-full flex-shrink-0 items-center justify-between gap-4 border-b border-gray-200 px-6">
              <div className="flex gap-4">
                <div className="flex gap-1">
                  <div className="h-8 w-8 animate-pulse rounded-md bg-gray-200" />
                  <div className="h-8 w-8 animate-pulse rounded-md bg-gray-200" />
                </div>
                <div className="flex gap-1">
                  <div className="h-8 w-8 animate-pulse rounded-md bg-gray-200" />
                  <div className="h-8 w-8 animate-pulse rounded-md bg-gray-200" />
                </div>
                <div className="flex gap-1">
                  <div className="h-8 w-8 animate-pulse rounded-md bg-gray-200" />
                  <div className="h-8 w-8 animate-pulse rounded-md bg-gray-200" />
                </div>
                <div className="flex gap-1">
                  <div className="h-8 w-8 animate-pulse rounded-md bg-gray-200" />
                  <div className="h-8 w-8 animate-pulse rounded-md bg-gray-200" />
                </div>
              </div>
              <div className="flex gap-1">
                <div className="h-8 w-8 animate-pulse rounded-md bg-gray-200" />
                <div className="h-8 w-8 animate-pulse rounded-md bg-gray-200" />
              </div>
            </div>

            {/* Content */}
            <div className="flex w-full flex-grow overflow-hidden pt-6">
              <div
                className={`flex w-full flex-grow justify-center overflow-hidden`}
              >
                <div className="mx-6 h-full w-full rounded-2xl bg-white p-2">
                  <div className="h-full w-full max-w-5xl overflow-hidden px-8">
                    <div className="mb-4 mt-8 h-20 w-full rounded-lg bg-gray-100" />
                    <div className="mb-4 mt-8 h-10 w-full rounded-lg bg-gray-100" />
                    {Array.from({ length: 10 }).map(() => (
                      <div className="my-1 h-6 w-full rounded bg-gray-100" />
                    ))}
                    <div className="mb-4 mt-10 h-10 w-full rounded-lg bg-gray-100" />
                    {Array.from({ length: 10 }).map(() => (
                      <div className="my-1 h-6 w-full rounded bg-gray-100" />
                    ))}
                    <div className="mb-4 mt-10 h-10 w-full rounded-lg bg-gray-100" />
                    {Array.from({ length: 10 }).map(() => (
                      <div className="my-1 h-6 w-full rounded bg-gray-100" />
                    ))}
                    <div className="mb-4 mt-10 h-10 w-full rounded-lg bg-gray-100" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-grow flex-col overflow-hidden">
            <div className="h-[53px] w-full border-b border-gray-200" />
          </div>
          {!errorBoundary && (
            <div className="flex h-full w-96 flex-col overflow-hidden">
              <div className="h-[53px] flex-shrink-0 border-b border-gray-200" />
              {/* Sidebar */}
              <div className="flex w-full flex-grow flex-col gap-4 pr-12 pt-6">
                <div className="h-48 w-full rounded-lg bg-gray-100" />
                <div className="mt-4 h-10 w-full rounded-lg bg-gray-100" />
                <div className="flex flex-wrap gap-2">
                  {Array.from({ length: 12 }).map(() => (
                    <div className="h-6 w-16 animate-pulse rounded bg-gray-100" />
                  ))}
                </div>
                <div className="mt-4 h-10 w-full rounded-lg bg-gray-100" />
                <div className="flex flex-wrap gap-2">
                  {Array.from({ length: 12 }).map(() => (
                    <div className="h-6 w-16 animate-pulse rounded bg-gray-100" />
                  ))}
                </div>
                <div className="mt-6 h-12 w-full rounded-lg bg-gray-100" />
                <div className="h-12 w-full rounded-lg bg-gray-100" />
                <div className="h-12 w-full rounded-lg bg-gray-100" />
                <div className="h-12 w-full rounded-lg bg-gray-100" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

DocumentLoading.displayName = 'DocumentLoading';

export { DocumentLoading };
