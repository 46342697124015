import { AlertCircle } from 'lucide-react';
import { Alert } from './v2/Alert';

type Props = {
  error?:
    | {
        body?: Record<string, any>;
      }
    | Record<string, any>
    | null;
  maxErrors?: number;
}

export const Errors = ({ error, maxErrors = 10 }: Props) => {
  if (!error) {
    return null;
  }

  const errorBody = error.body ?? error;

  return (
    <Alert
      title={errorBody?.message}
      body={[
        ...Object.keys(errorBody.errors ?? {})
          .filter((key) => !errorBody.errors[key].includes(errorBody.message))
          .slice(0, maxErrors)
          .map((key, index) =>
            errorBody.errors[key].map((error, index) => error),
          ),
        ...(Object.keys(errorBody.errors ?? {}).length > maxErrors
          ? [
              `And ${
                Object.keys(errorBody.errors ?? {}).length - maxErrors
              } more`,
            ]
          : []),
      ]}
      color={'red'}
      icon={AlertCircle}
    />
  );
};
