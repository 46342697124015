import { useKeywordInjectionStore } from '@/Pages/Document/stores';
import { ExtendedNewKeywordSuggestion } from '@/types';
import { useShallow } from 'zustand/react/shallow';

type Props = {
  resource: ExtendedNewKeywordSuggestion;
}

const InsertHereLine = ({ resource }: Props) => {
  const handleHoverNewKeywordPositionFromEditor = useKeywordInjectionStore(
    useShallow((state) => state.handleHoverNewKeywordPositionFromEditor),
  );
  return !resource.accepted ? (
    <div
      className={`my-4 h-1 w-full ${
        resource.hoveredFromTab || resource.hoveredFromEditor
          ? 'bg-green-500'
          : 'bg-green-200'
      }`}
      onMouseEnter={() => {
        handleHoverNewKeywordPositionFromEditor(resource, true);
      }}
      onMouseLeave={() => {
        handleHoverNewKeywordPositionFromEditor(resource, false);
      }}
    />
  ) : null;
};

InsertHereLine.displayName = 'InsertHereLine';

export { InsertHereLine };
