import { Button } from '@/Components/v2/Button';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { PopoverMenu } from '@/Components/v2/Menu/ButtonMenu';
import { DocumentReportCompetitorResource } from '@/api/openapiSchemas';
import { MoreVertical } from 'lucide-react';

type Props = {
  competitor: DocumentReportCompetitorResource;
  onMore: (value: string) => void;
  onOptimize: (value: string) => void;
  onElaborate: (value: string) => void;
  onParaphrase: (value: string) => void;
  onInsert: (value: string) => void;
};

export const CompetitorItem = ({
  competitor,
  onElaborate,
  onMore,
  onOptimize,
  onParaphrase,
  onInsert,
}: Props) => {
  const hasH1 = competitor.outline.some((item) => item.type === 'H1');

  return (
    <div className="mb-4 flex animate-fade-slow rounded-lg border border-gray-300 px-4 py-2">
      <div className="flex-grow">
        <a
          className="mb-2 text-sm hover:underline"
          title={competitor.url}
          href={competitor.url}
          target="_blank"
          rel="noreferrer"
        >
          <span className={'mr-1'}>#{competitor.position}</span>{' '}
          {competitor.domain.replace('www.', '')}
        </a>
        {hasH1 && (
          <p className="mb-4 mt-2 font-extrabold">
            {competitor.outline.find((heading) => heading.type === 'H1')?.title}
          </p>
        )}
      </div>
      {hasH1 ? (
        <div className="ml-4 flex flex-col items-end gap-2">
          {/* Actions */}
          <PopoverMenu
            items={[
              {
                title: 'More like this',
                onClick: () => onMore(competitor.site_name),
              },
              {
                title: 'Elaborate on topic',
                onClick: () => onElaborate(competitor.site_name),
              },
              {
                title: 'Paraphrase',
                onClick: () => onParaphrase(competitor.site_name),
              },
              {
                title: 'Optimize',
                onClick: () => onOptimize(competitor.site_name),
              },
            ]}
            trigger={<IconButton icon={MoreVertical} size="sm" />}
          />
          <Button
            size="xs"
            dense
            variant="ghost"
            text="Insert"
            onClick={() => onInsert(competitor.site_name)}
          />
        </div>
      ) : (
        <p className="text-primary-400">No heading</p>
      )}
    </div>
  );
};
