import { PropsWithChildren } from 'react';

type Props = {
  className?: string;
}

export const SlideOverHeading = ({
  children,
  className,
}: PropsWithChildren<Props>) => {
  return (
    <div
      className={`mt-8 w-full border-b-2 border-gray-300 pb-2 text-sm font-bold text-gray-500 ${className}`}
    >
      {children}
    </div>
  );
};
