import { CustomFieldDefinitionResource } from '@/api/openapiSchemas';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';

type Props = {
  customField: CustomFieldDefinitionResource;
  value?: string;
  hideTooltip?: boolean;
};

export const CustomFieldDraggableContainer = ({
  customField,
  value,
  hideTooltip,
}: Props) => {
  return (
    <div
      className={`rounded-xl border border-primary-300 ${customField.id === 'body' ? 'bg-secondary-75' : 'bg-white'} p-4 shadow-base-small`}
    >
      <InputDecoration
        label={customField.name}
        tooltip={!hideTooltip ? customField.description : undefined}
      >
        <div>{value || customField.description}</div>
      </InputDecoration>
    </div>
  );
};
