import { TitleSection } from '@/Components/CreateContent/components';
import { SimpleTextArea } from '@/Components/SimpleTextArea';
import Toggle from '@/Components/Toggle';
import { Button } from '@/Components/v2/Button';
import { DocumentResource } from '@/api/openapiSchemas';
import { Sparkles } from 'lucide-react';
import React from 'react';

type Props = {
  handleChangeTitle: (e: React.ChangeEvent<HTMLInputElement>) => void;
  illustration: {
    title: string;
    titleInPixels: string;
    titleWidth: number;
    description: string;
    descriptionWidth: number;
  };
  data?: DocumentResource;
  handleChangeDescription:
    | React.ChangeEventHandler<HTMLTextAreaElement>
    | undefined;
  handleGenerateDescription: () => void;
  isLoadingMetaDescriptionSuggestions: boolean;
  mode: 'Desktop' | 'Mobile';
  handleChangeMode: (mode: 'Desktop' | 'Mobile') => void;
  setToggles: React.Dispatch<
    React.SetStateAction<{
      position: number;
      showCompetitors: boolean;
      showDate: boolean;
      heatMap: boolean;
      showMetaDescription: boolean;
    }>
  >;
  handleChangePosition: (e: React.ChangeEvent<HTMLInputElement>) => void;
  toggles: {
    showDate: boolean;
    showCompetitors: boolean;
    position: number;
    showMetaDescription: boolean;
  };
};

const DocumentGenerationController = ({
  handleChangeTitle,
  illustration,
  data,
  handleChangeDescription,
  handleGenerateDescription,
  isLoadingMetaDescriptionSuggestions,
  mode,
  handleChangeMode,
  setToggles,
  handleChangePosition,
  toggles,
}: Props) => {
  return (
    <form className="flex flex-col gap-6 rounded-md bg-white p-4 shadow-serp">
      <TitleSection
        onChange={handleChangeTitle}
        illustration={{
          title: illustration.title,
          titleInPixels: illustration.titleInPixels,
          titleWidth: illustration.titleWidth,
        }}
        documentData={data}
      />

      <div className="flex flex-col gap-2 text-gray-500">
        <div className="mb-8 flex flex-col">
          <p className="flex w-full justify-center">Simulated position</p>
          <input
            type="range"
            className="mt-2 h-2 w-full cursor-pointer appearance-none rounded-lg bg-gray-200 accent-green-500"
            step={1}
            min={1}
            max={10}
            value={toggles.position}
            onChange={handleChangePosition}
          />
          <div className="mt-1.5 flex justify-between pl-1.5">
            {Array.from({ length: 10 }).map((_, i) => (
              <p>{i + 1}</p>
            ))}
          </div>
        </div>

        <div className="mt-5 flex justify-between ">
          Mobile view
          <Toggle
            value={mode === 'Mobile'}
            onChange={(value) => handleChangeMode(value ? 'Mobile' : 'Desktop')}
          />
        </div>
        <div className="flex justify-between ">
          Date (day and month)
          <Toggle
            onChange={(showDate) => {
              setToggles((prev) => ({ ...prev, showDate }));
            }}
            value={toggles.showDate}
          />
        </div>
        <div className="flex justify-between ">
          Show competitors
          <Toggle
            onChange={(showCompetitors) => {
              setToggles((prev) => ({ ...prev, showCompetitors }));
            }}
            value={toggles.showCompetitors}
          />
        </div>
        <div className="flex justify-between ">
          Edit meta-description
          <Toggle
            onChange={(showMetaDescription) => {
              setToggles((prev) => ({ ...prev, showMetaDescription }));
            }}
            value={toggles.showMetaDescription}
          />
        </div>
      </div>

      {toggles.showMetaDescription && (
        <div className="mt-8 flex flex-col gap-2">
          <div className="flex items-center justify-between">
            <h1 className="text-base font-bold text-gray-600">
              Meta description
            </h1>
          </div>
          <SimpleTextArea
            container="w-full border border-green-400 placeholder:text-gray-400"
            onChange={handleChangeDescription}
            value={illustration.description}
            placeholder="eg. Planning your 2024 wedding? Our guide covers all you need to know, from the latest trends to timeless advice, ensuring your celebration is stress-free."
            count={illustration.description.length}
            max={200}
          />
          <div className="flex w-full justify-end">
            <Button
              onClick={handleGenerateDescription}
              prependIcon={Sparkles}
              isLoading={isLoadingMetaDescriptionSuggestions}
              text="Generate"
              variant="ghost"
              size={'xs'}
              dense
            />
          </div>
        </div>
      )}
    </form>
  );
};

DocumentGenerationController.displayName = 'DocumentGenerationController';

export { DocumentGenerationController };
