import { HelpCircle } from 'lucide-react';
import React from 'react';
import { Tooltip } from '../Tooltip';

type Props = {
  children: React.ReactNode;
  label: string;
  required?: boolean;
  description?: string;
  hint?: string;
  tooltip?: string;
};

export const InputDecoration = ({
  children,
  label,
  description,
  required,
  tooltip,
  hint,
}: Props) => {
  return (
    <div className="w-full">
      <p className=" mb-1 flex items-end gap-1 font-bold text-primary-800">
        {label} {required ? <span className="text-red-400">*</span> : undefined}{' '}
        {hint && <span className="text-sm text-primary-400">{hint}</span>}
        {tooltip && (
          <div className="-mb-0.5">
            <Tooltip description={tooltip}>
              <HelpCircle size={14} className="stroke-black" />
            </Tooltip>
          </div>
        )}
      </p>
      {description ? (
        <p className=" mb-3 text-primary-600">{description}</p>
      ) : undefined}
      {children}
    </div>
  );
};
