import { useListCustomFieldDefinitions } from '@/api/openapiComponents';
import {
  CustomFieldDefinitionResource,
  DocumentResource,
} from '@/api/openapiSchemas';
import { Dialog } from '@/Components/Dialog';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { TextArea } from '@/Components/v2/TextArea/TextArea';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { useDocumentStore } from '@/Pages/Document/stores';
import { useEffect } from 'react';

type Props = {
  document: DocumentResource;
  isOpen: boolean;
  onClose: () => void;
};

export const CustomFieldsDialog = ({ isOpen, onClose, document }: Props) => {
  const appState = useAppStore();

  const customFieldValues = useDocumentStore(
    (state) => state.customFieldValues,
  );
  const setCustomFieldValues = useDocumentStore(
    (state) => state.setCustomFieldValues,
  );

  const customFieldsQuery = useListCustomFieldDefinitions(
    {
      pathParams: {
        project: appState.currentProject!.id,
      },
    },
    {
      enabled: isOpen,
    },
  );

  useEffect(() => {
    if (customFieldsQuery.isSuccess) {
      const data = customFieldsQuery.data?.data.reduce(
        (acc, item) => {
          acc[item.id] =
            document.custom_fields?.find((field) => field.id === item.id)
              ?.value ?? item.value;
          return acc;
        },
        {} as Record<string, string>,
      );
      setCustomFieldValues(data);
    }
  }, [customFieldsQuery.isSuccess]);

  const handleChange = (value: string, item: CustomFieldDefinitionResource) => {
    setCustomFieldValues({
      ...customFieldValues,
      [item.id]: value,
    });
  };

  return (
    <Dialog title="Custom fields" isOpen={isOpen} handleClose={onClose}>
      <div className="flex flex-col">
        {customFieldsQuery.isLoading ? (
          <SkeletonLoader height="lg" />
        ) : (
          customFieldsQuery.data?.data.map((customField) => (
            <div key={customField.id}>
              <InputDecoration
                label={customField.name}
                tooltip={customField.description}
              >
                <TextArea
                  resize
                  counter
                  placeholder={customField.description}
                  value={customFieldValues[customField.id]}
                  onChange={(value) => handleChange(value, customField)}
                />
              </InputDecoration>
            </div>
          ))
        )}
      </div>
    </Dialog>
  );
};
