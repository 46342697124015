import SimpleButton from '@/Components/SimpleButton';
import SimpleInput from '@/Components/SimpleInput';
import { promptsForAiInput } from '@/data/prompts';
import { PlateEditor, Value } from '@udecode/plate-common';
import * as React from 'react';
import { ReactEditor } from 'slate-react';
import { WriteMorePromptType } from '@/api/openapiSchemas';
import { LucideIcon, SendHorizonal } from 'lucide-react';
import { PromptInfo } from '@/types';
import { VerticalMenu } from '@/Components/v2/Menu/VerticalMenu';

type Props = {
  editor: PlateEditor<Value>;
  isOpen: boolean;
  handleClose: (shouldReselect?: boolean) => void;
  handlePrompt: (prompt: Omit<PromptInfo, 'Icon'>) => void;
};
export default React.forwardRef<HTMLDivElement, Props>(function FloatingAIInput(
  { editor, isOpen, handleClose, handlePrompt },
  ref,
) {
  const selectRef = React.useRef<HTMLDivElement>(null);
  const [prompt, setPrompt] = React.useState('');
  const [filteredPrompts, setFilteredPrompts] = React.useState<
    {
      Icon: LucideIcon;
      isFocussed: boolean;
      title: string;
      type: WriteMorePromptType;
    }[]
  >(
    promptsForAiInput.map((prompt) => ({
      ...prompt,
      isFocussed: false,
    })),
  );

  const handleChange = (value: string) => {
    setFilteredPrompts(() => {
      if (value === '') {
        return [
          ...promptsForAiInput.map((prompt) => ({
            ...prompt,
            isFocussed: false,
          })),
        ];
      } else {
        return promptsForAiInput
          .map((prompt) => ({ ...prompt, isFocussed: false }))
          .filter((prompt) =>
            prompt.title.toLowerCase().includes(value.toLowerCase()),
          );
      }
    });
    setPrompt(value);
  };

  const handleTabDownSelection = () => {
    const currentIndex = filteredPrompts.findIndex(
      (prompt) => prompt.isFocussed,
    );
    if (currentIndex === -1 || currentIndex === filteredPrompts.length - 1) {
      setFilteredPrompts((prev) =>
        prev.map((prompt, index) =>
          index === 0
            ? { ...prompt, isFocussed: true }
            : { ...prompt, isFocussed: false },
        ),
      );
    } else {
      setFilteredPrompts((prev) =>
        prev.map((prompt, index) =>
          index === currentIndex + 1
            ? { ...prompt, isFocussed: true }
            : { ...prompt, isFocussed: false },
        ),
      );
    }
  };

  const handleTabUpSelection = () => {
    const currentIndex = filteredPrompts.findIndex(
      (prompt) => prompt.isFocussed,
    );
    if (currentIndex === -1) return;
    if (currentIndex > 0 && currentIndex <= filteredPrompts.length - 1) {
      setFilteredPrompts((prev) =>
        prev.map((prompt, index) =>
          index === currentIndex - 1
            ? { ...prompt, isFocussed: true }
            : { ...prompt, isFocussed: false },
        ),
      );
    } else {
      setFilteredPrompts((prev) =>
        prev.map((prompt, index) => {
          if (currentIndex === 0 && index === filteredPrompts.length - 1) {
            return { ...prompt, isFocussed: true };
          }
          return { ...prompt, isFocussed: false };
        }),
      );
    }
  };

  React.useEffect(() => {
    if (selectRef.current && isOpen) {
      selectRef.current.scrollIntoView({
        behavior: 'instant',
        block: 'center',
      });
    }
  }, [selectRef, isOpen]);

  return isOpen ? (
    <div
      ref={selectRef}
      onSubmit={(e) => {
        e.preventDefault();
      }}
      onKeyDown={(e) => {
        if (e.code === 'Escape') {
          handleClose(true);
          ReactEditor.focus(editor as ReactEditor);
        }
        if (
          e.code === 'Tab' ||
          e.code === 'ArrowDown' ||
          e.code === 'ArrowUp'
        ) {
          if (e.code === 'Tab' || e.code === 'ArrowDown') {
            handleTabDownSelection();
          }
          if (e.code === 'ArrowUp') {
            handleTabUpSelection();
          }
          e.preventDefault();
        }

        if (e.code === 'Enter') {
          const definedPrompt = filteredPrompts.find(
            (prompt) => prompt.isFocussed,
          );
          if (definedPrompt) {
            handlePrompt({
              title: definedPrompt.title,
              type: definedPrompt.type,
            });
            handleClose(true);
          } else {
            if (prompt !== '') {
              handlePrompt({ title: prompt, type: 'custom' });
              handleClose(true);
            }
          }
        }
      }}
      onBlur={() => {
        setTimeout(() => {
          handleClose(false);
        }, 1);
      }}
      className="w-[calc(100%-1.25rem)]"
    >
      <div className="relative -mt-2.5 mr-3.5 rounded-md bg-white shadow-base-small">
        <SimpleInput
          removeBoxShadow
          containerClassname="mt-0 mb-2"
          autoFocus
          placeholder="Message AI assistant..."
          className="mx-2 w-[calc(100%-1rem)] rounded-b-none rounded-t-none border-b-2 bg-white px-0 py-2.5 pr-10 normal-case shadow-none ring-0 placeholder:text-[1rem] placeholder:font-bold placeholder:font-normal focus:border-none focus:ring-0"
          value={prompt}
          onChange={(e) => handleChange(e.target.value)}
        />
        <SimpleButton
          className="absolute right-3 top-1.5 z-10 p-0 px-1 py-1.5"
          onMouseDown={(e) => {
            e.stopPropagation();
            if (prompt !== '') {
              handlePrompt({ title: prompt, type: 'custom' });
              handleClose(false);
            }
          }}
        >
          <SendHorizonal className="h-5 w-5 stroke-gray-400" />
        </SimpleButton>
      </div>

      {filteredPrompts.length >= 1 && (
        <div className="relative z-10 my-1.5 w-3/5 rounded-md shadow-base-small">
          <VerticalMenu
            size="full"
            items={filteredPrompts.map((prompt) => ({
              title: prompt.title,
              prependIcon: prompt.Icon,
              isFocused: prompt.isFocussed,
              onClick: () => {
                handlePrompt({
                  title: prompt.title,
                  type: prompt.type,
                });
                handleClose(true);
              },
              onMouseDown: (e) => {
                e.preventDefault();
              },
            }))}
          />
        </div>
      )}
    </div>
  ) : null;
});
