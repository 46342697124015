import { useDocumentDocumentKeywordQuestions } from '@/api/openapiComponents';
import { DocumentKeywordQuestionResource } from '@/api/openapiSchemas';
import { useQueryClient, useQuery } from '@tanstack/react-query';

export const useAiGeneratedQuestions = (documentId: number) => {
  const client = useQueryClient();

  const questionsLoadingQuery = useQuery({
    queryKey: [`ai-questions-loading-${documentId}`],
    initialData: false,
  });

  const questions = useQuery<DocumentKeywordQuestionResource[] | undefined>({
    queryKey: [`ai-questions-${documentId}`],
    initialData: undefined,
  });

  const questionsMutation = useDocumentDocumentKeywordQuestions({
    onMutate: () => {
      client.setQueryData<boolean>(
        [`ai-questions-loading-${documentId}`],
        true,
      );
    },
    onSuccess: (data) => {
      client.setQueryData<DocumentKeywordQuestionResource[]>(
        [`ai-questions-${documentId}`],
        (prev) => {
          if (!data) {
            return;
          }
          if (prev) {
            return [...prev, ...data.data];
          }
          return data.data;
        },
      );
      client.setQueryData<boolean>(
        [`ai-questions-loading-${documentId}`],
        false,
      );
    },
  });

  return {
    loading: questionsLoadingQuery.data,
    questions: questions.data,
    fetchQuestions: questionsMutation.mutate,
    error: questionsMutation.error,
  };
};
