import * as React from 'react';
import { useHeadingState, useMetaTitleState } from '@/Pages/Document/hooks';
import { Generating } from '@/types';
import { Copy } from 'lucide-react';
import { useEditorState } from '@udecode/plate-common';
import { ReactEditor } from 'slate-react';
import { IconButton } from '@/Components/v2/IconButton/IconButton';

type Props = {
  isLoading: Generating;
  handleTitleFocus: () => void;
};

const EditorTitle = ({ isLoading, handleTitleFocus }: Props) => {
  const editor = useEditorState();
  const [heading, setHeading] = useHeadingState();
  const [metaTitle, setMetaTitle] = useMetaTitleState();
  const [copied, setCopied] = React.useState(false);
  const titleRef = React.useRef<HTMLTextAreaElement | null>(null);

  React.useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    if (copied) {
      timer = setTimeout(() => {
        setCopied(false);
      }, 1000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [copied]);

  React.useEffect(() => {
    if (titleRef.current) {
      titleRef.current.style.height = '1px';
      titleRef.current.style.height =
        (titleRef.current?.scrollHeight ?? 42) + 'px';
    }
  });

  return (
    <div className="h=[25vh] p-5 pt-2">
      <div className="relative flex bg-white">
        <p className="-ml-[3.25rem] mt-7 w-9 text-end text-sm">H1</p>
        <textarea
          disabled={!!isLoading}
          ref={titleRef}
          placeholder="Click here to get title ideas"
          className="intro-step-3 ml-4 mt-2 w-full cursor-pointer resize-none overflow-y-hidden border-0 p-0 text-6xl font-bold"
          value={heading}
          onFocus={handleTitleFocus}
          onChange={(e) => {
            if (heading === metaTitle) {
              setMetaTitle(e.target.value);
            }
            setHeading(e.target.value);
          }}
          onKeyDown={(e) => {
            const shiftPressed = e.shiftKey;
            const key = e.key;
            if (key === 'Enter' && !shiftPressed) {
              const initialPath = { offset: 0, path: [0, 0] };
              editor.select({
                anchor: initialPath,
                focus: initialPath,
              });
              ReactEditor.focus(editor as ReactEditor);
              e.preventDefault();
            }
          }}
        />
        <div className="absolute left-full top-[1.25rem]">
          <div className="relative flex justify-center">
            <IconButton
              size="sm"
              icon={Copy}
              isLoading={!!isLoading}
              onClick={() => {
                setCopied(true);
                navigator.clipboard.writeText(heading);
              }}
            />
            {copied && (
              <p className="absolute -top-5 bg-white  text-green-500">
                Copied!
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

EditorTitle.displayName = 'EditorTitle';

export { EditorTitle };
