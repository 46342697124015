import {
  useDeleteCustomFieldDefinition,
  useGetCmsConnection,
  useListCustomFieldDefinitions,
  useUpdateCustomFieldDefinition,
} from '@/api/openapiComponents';
import { CustomFieldDefinitionResource } from '@/api/openapiSchemas';
import { ConfirmDialog } from '@/Components/ConfirmDialog';
import { EditableText } from '@/Components/EditableText';
import Pagination from '@/Components/ProjectKeywords/Pagination';
import * as Table from '@/Components/Table';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate, useSearch } from '@tanstack/react-router';
import { ArrowUpDown, Plus, Trash2, Waypoints } from 'lucide-react';
import { useState } from 'react';
import { AddCustomFieldDialog } from './components/EditCustomFieldDialog';
import { Button } from '@/Components/v2/Button';
import { Tooltip } from '@/Components/v2/Tooltip';

export const CustomFieldsTable = () => {
  const client = useQueryClient();
  const appState = useAppStore();
  const navigate = useNavigate({ from: '/settings/custom-fields' });
  const search = useSearch({ from: '/settings/custom-fields' });

  const [showAddNewDialog, setShowAddNewDialog] = useState(false);
  const [customFieldToBeDeleted, setCustomFieldToBeDeleted] =
    useState<CustomFieldDefinitionResource>();

  const updateCustomFieldMutation = useUpdateCustomFieldDefinition();
  const deleteCustomFieldMutation = useDeleteCustomFieldDefinition();
  const cmsConnectionQuery = useGetCmsConnection(
    {
      pathParams: { project: appState.currentProject!.id },
    },
    {
      retry: false,
    },
  );
  const customFieldsQuery = useListCustomFieldDefinitions({
    pathParams: {
      project: appState.currentProject!.id,
    },
    queryParams: {
      limit: 100,
      page: search.page,
      sort_by: 'position',
    },
  });

  const handleChange = (
    value: string,
    property: 'name' | 'description',
    item: CustomFieldDefinitionResource,
  ) => {
    client.setQueriesData<any>(
      {
        predicate: (query) =>
          query.queryKey.includes('listCustomFieldDefinitions'),
      },
      (prev: { data: CustomFieldDefinitionResource[] }) => {
        if (!prev) return prev;
        return {
          ...prev,
          data: prev.data.map((currentItem) => {
            if (item.id === currentItem.id) {
              return { ...currentItem, [property]: value };
            }
            return currentItem;
          }),
        };
      },
    );
  };

  const handleSaveChanges = async (
    value: string,
    property: 'name' | 'description',
    item: CustomFieldDefinitionResource,
  ) => {
    return updateCustomFieldMutation.mutateAsync({
      pathParams: {
        project: appState.currentProject!.id,
        customField: item.id,
      },
      body: { [property]: value },
    });
  };

  const handleDelete = (item: CustomFieldDefinitionResource) => {
    deleteCustomFieldMutation.mutate(
      {
        pathParams: {
          project: appState.currentProject!.id,
          customField: item.id,
        },
      },
      {
        onSuccess: () => {
          customFieldsQuery.refetch();
          setCustomFieldToBeDeleted(undefined);
        },
      },
    );
  };

  const customFields = [
    ...(customFieldsQuery.data?.data ?? []).filter(
      (field) => field.position < 0,
    ),
    {
      id: 'body',
      name: 'Body',
      description: 'The content body',
      position: 0,
      value: '',
    },
    ...(customFieldsQuery.data?.data ?? []).filter(
      (field) => field.position >= 0,
    ),
  ];

  return (
    <>
      <AddCustomFieldDialog
        key={`${showAddNewDialog}`}
        isOpen={showAddNewDialog}
        onClose={() => setShowAddNewDialog(false)}
        onSuccess={() => customFieldsQuery.refetch()}
      />
      <ConfirmDialog
        isOpen={!!customFieldToBeDeleted}
        onClose={() => setCustomFieldToBeDeleted(undefined)}
        onConfirm={() => handleDelete(customFieldToBeDeleted!)}
        title="Delete custom field"
        description={[
          `Are you sure you want to delete this custom field (${customFieldToBeDeleted?.name})?`,
        ]}
        error={deleteCustomFieldMutation.error}
        isLoading={deleteCustomFieldMutation.isPending}
      />

      <div className="flex items-center justify-end gap-2">
        <Tooltip
          title="You need to connect to a CMS to map custom fields"
          navLink="/settings/connections"
          navLinkText="Connect to a CMS"
          disabled={
            cmsConnectionQuery.isLoading ||
            cmsConnectionQuery.data?.data.state === 'active'
          }
        >
          <Button
            disabled={cmsConnectionQuery.data?.data.state !== 'active'}
            text="Map to CMS fields"
            color="secondary"
            prependIcon={Waypoints}
            onClick={() =>
              navigate({
                to: '/settings/custom-fields/mapping',
                search: { page: 1 },
              })
            }
          />
        </Tooltip>
        <Button
          text="Reorder"
          color="secondary"
          prependIcon={ArrowUpDown}
          onClick={() => navigate({ to: '/settings/custom-fields/reorder' })}
        />
        <Button
          prependIcon={Plus}
          text="Add new custom field"
          color="secondary"
          onClick={() => setShowAddNewDialog(true)}
        />
      </div>

      <Table.Root
        isLoading={customFieldsQuery.isLoading}
        items={customFields}
        renderRow={({ cells, classNames, item }) => (
          <tr
            className={`${classNames} ${item.id === 'body' ? 'bg-white' : ''}`}
          >
            {cells}
          </tr>
        )}
        columns={[
          {
            heading: 'Name',
            width: 20,
            render: (customField) =>
              customField.id === 'body' ? (
                <div className="my-2 font-bold">{customField.name}</div>
              ) : (
                <div className="font-bold">
                  <EditableText
                    text={customField.name}
                    onChange={(value) =>
                      handleChange(value, 'name', customField)
                    }
                    onDebounceChange={(value) =>
                      handleSaveChanges(value, 'name', customField)
                    }
                  />
                </div>
              ),
          },
          {
            heading: 'Description',
            render: (customField) =>
              customField.id === 'body' ? (
                <div>{customField.description}</div>
              ) : (
                <EditableText
                  text={customField.description}
                  onChange={(value) =>
                    handleChange(value, 'description', customField)
                  }
                  onDebounceChange={(value) =>
                    handleSaveChanges(value, 'description', customField)
                  }
                />
              ),
          },
          {
            width: 5,
            render: (customField) =>
              customField.id === 'body' ? null : (
                <IconButton
                  icon={Trash2}
                  onClick={() => setCustomFieldToBeDeleted(customField)}
                />
              ),
          },
        ]}
      >
        <Table.Footer>
          <Pagination
            currentPage={customFieldsQuery.data?.meta.current_page ?? 0}
            lastPage={customFieldsQuery.data?.meta.last_page ?? 0}
            setCurrentPage={(page) => navigate({ search: { page } })}
          />
        </Table.Footer>
      </Table.Root>
    </>
  );
};
