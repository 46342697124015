import { OutlineSlideOver } from '@/Pages/Document/components/DocumentSlideOvers/components/OutlineSliderOver/OutlineSlideOver';
import { CreatePageContainer } from '../../../components';
import { Card } from '@/Components/v2/Card';
import { CardBody } from '@/Components/v2/Card/components/CardBody';
import { Button } from '@/Components/v2/Button';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { useNavigate, useParams, useSearch } from '@tanstack/react-router';
import {
  useGetDocument,
  useProjectGenerateOutline,
  useUpdateDocument,
} from '@/api/openapiComponents';
import { DndHandle } from '@/Components/DocumentForm/components/DndHandle';
import { DndItemInput } from '@/Components/DocumentForm/components/DndItemInput';
import { DndHeading, OutlineItem } from '@/Components/DocumentForm/hooks';
import SimpleButton from '@/Components/SimpleButton';

import { DndContext, closestCorners, DragOverlay } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { ArrowRight, RefreshCcw } from 'lucide-react';
import { useGenerateDocumentV2, useOutlineState } from '../../hooks';
import { useLoadingEllipsis } from '@/Hooks/useLoadingEllipsis';
import { createUniqueIdentifier } from '@/Pages/Document/components/PlateEditor/lib/transform';
import { useEffect, useRef, useState } from 'react';
import { hasher } from '@/Pages/AppLoader';
import { useDebounce } from '@/Hooks/useDebounce';
import { useQueryClient } from '@tanstack/react-query';

export const OutlineEditor = () => {
  const params = useParams({
    from: '/create-content/outline-editor/$documentId',
  });
  const search = useSearch({
    from: '/create-content/outline-editor/$documentId',
  });
  const documentId = Number(params.documentId);
  const appState = useAppStore();
  const navigate = useNavigate();

  const titleRef = useRef<HTMLTextAreaElement | null>(null);
  const [title, setTitle] = useState('');

  const client = useQueryClient();

  const updateDocumentMutation = useUpdateDocument({
    onSuccess: (data) => {
      const queryKey =
        client.getQueryCache().find({
          predicate: (query) => query.queryKey.includes('getDocument'),
        })?.queryKey ?? [];
      client.setQueryData(queryKey, data);
    },
  });
  const { handleGenerateDocument, isLoading } = useGenerateDocumentV2();
  const generateOutlineMutation = useProjectGenerateOutline();
  const documentQuery = useGetDocument({
    pathParams: {
      project: appState.currentProject!.id,
      document: documentId,
    },
  });

  const ellipsis = useLoadingEllipsis(generateOutlineMutation.isPending);

  const getSubHeadingCount = () => {
    let amount = 0;

    if (!outline) {
      return 0;
    }

    for (const item of outline) {
      amount++;
      for (const _ of item.subHeadings) {
        amount++;
      }
    }

    return amount;
  };

  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.style.height = '1px';
      titleRef.current.style.height =
        (titleRef.current?.scrollHeight ?? 42) + 'px';
    }
  });

  const {
    activeHeading,
    deleteItem,
    handleDragEnd,
    handleDragOver,
    handleDragStart,
    newItem,
    outline,
    sensors,
    setText,
    toggleType,
    setOutline,
  } = useOutlineState();

  const document = documentQuery.data?.data;

  const handleRefresh = () => {
    generateOutlineMutation.mutate(
      {
        pathParams: {
          project: appState.currentProject!.id,
        },
        body: {
          content_type_id: document?.content_type?.id,
          keyword: document?.keyword.name,
          title: document?.title,
          brief: document?.settings.brief,
        },
      },
      {
        onSuccess: (data) => {
          const dndItems: DndHeading[] = [];
          if (!data?.data) {
            return;
          }
          for (const h2 of data.data.outline) {
            dndItems.push({
              id: createUniqueIdentifier(),
              title: h2.title,
              subHeadings:
                h2.items?.map((h3) => ({
                  id: createUniqueIdentifier(),
                  title: h3.title,
                })) ?? [],
            });
          }

          setOutline(dndItems);
          if (title === '') {
            setTitle(data.data.title);
          }
        },
      },
    );
  };

  useEffect(() => {
    if (documentQuery.isSuccess) {
      if (!document?.subheadings || document.subheadings.length === 0) {
        handleRefresh();
      } else {
        const newOutline: DndHeading[] = [];

        for (let i = 0; i < document.subheadings.length; i++) {
          if (i === 0) {
            newOutline.push({
              id: createUniqueIdentifier(),
              title: document.subheadings[i].title,
              subHeadings: [],
            });
          } else {
            if (document.subheadings[i].type === 'H2') {
              newOutline.push({
                id: createUniqueIdentifier(),
                title: document.subheadings[i].title,
                subHeadings: [],
              });
            } else {
              newOutline[newOutline.length - 1].subHeadings.push({
                id: createUniqueIdentifier(),
                title: document.subheadings[i].title,
              });
            }
          }
        }

        setOutline(newOutline);
      }
      setTitle(document!.title);
    }
  }, [documentQuery.isSuccess]);

  useDebounce(
    () => {
      updateDocumentMutation.mutate({
        pathParams: {
          document: documentId,
          project: appState.currentProject!.id,
        },
        body: {
          title: title,
          body: outline
            .map(
              (item) =>
                `## ${item.title}\n${item.subHeadings
                  .map((subHeading) => `### ${subHeading.title}\n`)
                  .join('')}`,
            )
            .join(''),
        },
      });
    },
    [title, outline],
    500,
  );

  const handleInsertHeading = (heading: string) => {
    setOutline([
      ...outline,
      {
        id: createUniqueIdentifier(),
        title: heading,
        subHeadings: [],
      },
    ]);
  };

  const handleinsertOutline = (generatedOutline: OutlineItem[]) => {
    setOutline([
      ...outline,
      ...generatedOutline.map((item) => ({
        id: createUniqueIdentifier(),
        title: item.title,
        subHeadings: (item.items ?? []).map((subItem) => ({
          id: createUniqueIdentifier(),
          title: subItem.title,
        })),
      })),
    ]);
  };

  const handleSubmit = () => {
    updateDocumentMutation.mutate(
      {
        pathParams: {
          document: documentId,
          project: appState.currentProject!.id,
        },
        body: {
          body: '',
        },
      },
      {
        onSuccess: () =>
          handleGenerateDocument({
            document: document!,
            liveGenerate: search.live_generate === true,
            projectId: appState.currentProject!.id,
            outline: outline,
            onError: () => {},
            onSuccess: () => {
              if (search.live_generate) {
                navigate({
                  to: '/documents/$documentId',
                  params: { documentId: hasher.encode(documentId) },
                  search: { generate: true },
                });
              } else {
                navigate({ to: '/created-content' });
              }
            },
          }),
      },
    );
  };

  const handleClose = () => {
    navigate({
      to: '/documents/$documentId',
      params: { documentId: hasher.encode(documentId) },
    });
  };

  appState.pageTitle('Outline Editor');

  return (
    <CreatePageContainer
      hasSlideover
      canGoBack={!!search.review_title}
      onClose={handleClose}
      closeText={search.is_new ? 'Go to editor' : 'Return to editor'}
      closeIcon={ArrowRight}
    >
      {document && (
        <OutlineSlideOver
          disabled={generateOutlineMutation.isPending}
          document={document}
          onGenerateOutline={handleinsertOutline}
          onInsert={handleInsertHeading}
          subHeadings={
            document?.subheadings.map((heading) => heading.title) ?? []
          }
          isOpen
        />
      )}
      <div className="flex w-full flex-col justify-start overflow-y-auto">
        <div className="flex max-w-full flex-col items-center pb-12">
          <div className="flex w-full max-w-5xl flex-col">
            <Card fullHeight>
              <CardBody>
                <div className="flex w-full flex-col">
                  <div className="mb-2 flex flex-col">
                    <div className="flex justify-between">
                      <p className="ml-4">H1</p>
                      <div>
                        Target keyword: <b>{document?.keyword.name}</b>
                      </div>
                    </div>
                    <textarea
                      disabled={documentQuery.isLoading}
                      placeholder="Title of your document..."
                      ref={titleRef}
                      className="w-full resize-none overflow-hidden border-none text-4xl font-bold leading-10 placeholder:text-primary-400 hover:bg-primary-50 focus:bg-primary-50"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                  <p
                    className={`mb-2 text-sm text-gray-400 ${
                      generateOutlineMutation.isPending
                        ? 'self-start'
                        : 'self-end'
                    }`}
                  >
                    {!generateOutlineMutation.isPending && outline
                      ? `Subheadings: ${getSubHeadingCount()}`
                      : `Generating outline${ellipsis}`}
                  </p>
                </div>
                <div className="flex w-full flex-grow animate-fade-slow flex-col overflow-y-scroll">
                  {generateOutlineMutation.isError ? (
                    <div className="flex w-full grow items-center justify-center">
                      <SimpleButton
                        className="rounded-full border-2 border-gray-300 px-3 py-1"
                        disabled={generateOutlineMutation.isPending}
                        onClick={handleRefresh}
                      >
                        <RefreshCcw /> &nbsp; REFRESH OUTLINE
                      </SimpleButton>
                    </div>
                  ) : (
                    <>
                      <div className="flex h-full flex-col">
                        {generateOutlineMutation.isPending ? (
                          <div className="flex w-full flex-shrink-0 flex-col overflow-hidden">
                            <div className="mb-2 flex h-12 animate-pulse rounded-full bg-gray-200" />
                            <div className="mb-2 ml-10 h-12 animate-pulse rounded-full bg-gray-200" />
                            <div className="mb-2 ml-10 h-12 animate-pulse rounded-full bg-gray-200" />
                            <div className="mb-2 h-12 animate-pulse rounded-full bg-gray-200" />
                            <div className="mb-2 ml-10 h-12 animate-pulse rounded-full bg-gray-200" />
                            <div className="mb-2 ml-10 h-12 animate-pulse rounded-full bg-gray-200" />
                          </div>
                        ) : (
                          <DndContext
                            sensors={sensors}
                            collisionDetection={closestCorners}
                            onDragOver={handleDragOver}
                            onDragStart={handleDragStart}
                            onDragEnd={handleDragEnd}
                          >
                            <div className="unselectable w-full animate-fade-slow font-work ">
                              {outline && (
                                <SortableContext
                                  items={outline.map((item) => item.id)}
                                  strategy={verticalListSortingStrategy}
                                >
                                  {outline.map((item, i) => (
                                    <DndHandle
                                      key={item.id}
                                      id={item.id}
                                      type="H2"
                                    >
                                      <div className="flex flex-grow flex-col">
                                        <DndItemInput
                                          type="H2"
                                          value={item.title}
                                          typeDisabled={i === 0}
                                          onTypeChange={() =>
                                            toggleType(item.id)
                                          }
                                          onDelete={() => deleteItem(item.id)}
                                          onChange={(value) =>
                                            setText(item.id, value)
                                          }
                                        />
                                        <SortableContext
                                          items={item.subHeadings.map(
                                            (item) => item.id,
                                          )}
                                        >
                                          {item.subHeadings.map((subItem) => (
                                            <DndHandle
                                              key={subItem.id}
                                              id={subItem.id}
                                              type="H3"
                                            >
                                              <DndItemInput
                                                type="H3"
                                                value={subItem.title}
                                                onTypeChange={() =>
                                                  toggleType(subItem.id)
                                                }
                                                onChange={(value) =>
                                                  setText(subItem.id, value)
                                                }
                                                onDelete={() =>
                                                  deleteItem(subItem.id)
                                                }
                                              />
                                            </DndHandle>
                                          ))}
                                        </SortableContext>
                                      </div>
                                    </DndHandle>
                                  ))}
                                </SortableContext>
                              )}
                            </div>
                            <DragOverlay>
                              {activeHeading && (
                                <div className={`w-96 pl-6`}>
                                  <DndItemInput
                                    value={activeHeading.title}
                                    type={
                                      (activeHeading as DndHeading).subHeadings
                                        ? 'H2'
                                        : 'H3'
                                    }
                                  />
                                  {(activeHeading as DndHeading)
                                    .subHeadings && (
                                    <div className="pl-6">
                                      {(
                                        activeHeading as DndHeading
                                      ).subHeadings.map((subHeading) => (
                                        <DndItemInput
                                          value={subHeading.title}
                                          type="H3"
                                        />
                                      ))}
                                    </div>
                                  )}
                                </div>
                              )}
                            </DragOverlay>
                          </DndContext>
                        )}
                      </div>
                    </>
                  )}
                </div>
                <div className="flex justify-between pt-2">
                  <Button
                    text="Refresh outline"
                    variant="outline"
                    onClick={handleRefresh}
                  />
                  <Button
                    text="Insert heading"
                    variant="outline"
                    onClick={() => handleInsertHeading('')}
                  />
                </div>
              </CardBody>
            </Card>
            <div className="mx-auto mt-4 flex w-full max-w-xs justify-center">
              <Button
                isLoading={isLoading}
                text={'Generate'}
                color="secondary"
                fullWidth
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </CreatePageContainer>
  );
};
