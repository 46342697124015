import * as React from 'react';
import { twMerge } from 'tailwind-merge';
import AppLabel, { Variant } from './Labels/AppLabel';
import { Button, ButtonSizes } from './v2/Button';
import { LucideProps } from 'lucide-react';
type SegmentOption<T> = {
  value: T;
  displayName: string;
  label?: string;
  icon?: React.ComponentType<LucideProps>;
}

type Props<T> = {
  value: T;
  onChange: (value: T) => void;
  options: SegmentOption<T>[];
  className?: string;
  isLoading?: boolean;
  disabled?: boolean;
  buttonClassName?: string;
  labelColor?: Variant;
  size?: ButtonSizes;
}

export default function ToggleButton<T>({
  onChange,
  options,
  value,
  className,
  isLoading,
  disabled,
  labelColor,
  size,
}: Props<T>) {
  return (
    <div
      className={twMerge(`flex gap-1 rounded-md bg-gray-100 p-1 ${className}`)}
    >
      {options.map((option) => (
        <Button
          key={option.value as string}
          onClick={(e) => {
            e.stopPropagation();
            onChange(option.value);
          }}
          fullWidth
          prependIcon={option.icon}
          color={value === option.value ? 'white' : 'primary'}
          variant={value === option.value ? 'fill' : 'ghost'}
          appendIcon={
            option.label ? (
              <AppLabel variant={Variant.yellow}>{option.label}</AppLabel>
            ) : undefined
          }
          labelColor={labelColor}
          disabled={disabled || isLoading}
          text={option.displayName}
          size={size}
          dense
        />
      ))}
    </div>
  );
}
