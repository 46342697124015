import { DropdownMenuProps } from '@radix-ui/react-dropdown-menu';
import {
  collapseSelection,
  findNode,
  focusEditor,
  isBlock,
  isCollapsed,
  TElement,
  toggleNodeType,
  useEditorState,
} from '@udecode/plate-common';
import { ELEMENT_H2, ELEMENT_H3, ELEMENT_H4 } from '@udecode/plate-heading';
import { ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph';

import { Icons } from './icons';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
  useOpenState,
  ToolbarButton,
} from '../components';
import { useQuery } from '@tanstack/react-query';
import { Generating } from '@/types';
import { isInsideTableCell } from '../lib/utils';

const items = [
  {
    value: ELEMENT_PARAGRAPH,
    label: 'Paragraph',
    description: 'Paragraph',
    icon: Icons.paragraph,
  },
  {
    value: ELEMENT_H2,
    label: 'Heading 2',
    description: 'Heading 2',
    icon: Icons.h2,
  },
  {
    value: ELEMENT_H3,
    label: 'Heading 3',
    description: 'Heading 3',
    icon: Icons.h3,
  },
  {
    value: ELEMENT_H4,
    label: 'Heading 4',
    description: 'Heading 4',
    icon: Icons.h4,
  },
];

const defaultItem = items.find((item) => item.value === ELEMENT_PARAGRAPH)!;
export function TurnIntoDropdownMenu(props: DropdownMenuProps) {
  const editor = useEditorState();
  const openState = useOpenState();
  const { data: isGenerating } = useQuery<Generating>({
    queryKey: ['autoGenerationStream'],
  });

  const getType = (value: string) => {
    const entry = findNode<TElement>(editor, {
      match: (n) => isBlock(editor, n),
    });
    if (entry) {
      value =
        items.find((item) => item.value === entry[0].type)?.value ??
        ELEMENT_PARAGRAPH;
    }
    return value;
  };
  let value: string = ELEMENT_PARAGRAPH;
  if (isCollapsed(editor.selection)) {
    value = getType(value);
  } else {
    const children = editor.getFragment();
    if (children.length > 1) {
      value = ELEMENT_PARAGRAPH;
    } else {
      value = getType(value);
    }
  }

  const selectedItem =
    items.find((item) => item.value === value) ?? defaultItem;
  const { icon: SelectedItemIcon, label: selectedItemLabel } = selectedItem;

  return (
    <DropdownMenu modal={false} {...openState} {...props}>
      <DropdownMenuTrigger asChild>
        <ToolbarButton
          pressed={openState.open}
          tooltip="Turn into"
          isDropdown
          disabled={!!isGenerating || isInsideTableCell(editor)}
          className="2xl:min-w-[130px]"
        >
          <SelectedItemIcon className="h-5 w-5 2xl:hidden" />
          <span className="max-2xl:hidden">{selectedItemLabel}</span>
        </ToolbarButton>
      </DropdownMenuTrigger>

      <DropdownMenuContent align="start" className="min-w-0">
        <DropdownMenuLabel>Turn into</DropdownMenuLabel>

        <DropdownMenuRadioGroup
          className="flex flex-col gap-0.5"
          value={value}
          onValueChange={(type) => {
            // if (type === 'ul' || type === 'ol') {
            //   if (settingsStore.get.checkedId(KEY_LIST_STYLE_TYPE)) {
            //     toggleIndentList(editor, {
            //       listStyleType: type === 'ul' ? 'disc' : 'decimal',
            //     });
            //   } else if (settingsStore.get.checkedId('list')) {
            //     toggleList(editor, { type });
            //   }
            // } else {
            //   unwrapList(editor);
            toggleNodeType(editor, { activeType: type });
            // }

            collapseSelection(editor);
            focusEditor(editor);
          }}
        >
          {items.map(({ value: itemValue, label, icon: Icon }) => (
            <DropdownMenuRadioItem
              key={itemValue}
              value={itemValue}
              className="min-w-[180px]"
            >
              <Icon className="mr-2 h-5 w-5" />
              {label}
            </DropdownMenuRadioItem>
          ))}
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
