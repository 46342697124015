import {
  useDocumentChatClear,
  useDocumentsChatMessagesDestroy,
  useDocumentsChatMessagesIndex,
  useDocumentsChatMessagesStore,
} from '@/api/openapiComponents';
import { DocumentResource } from '@/api/openapiSchemas';
import { BlockSelection, isHumanMessage, Message } from '@/types';
import { useQueryClient } from '@tanstack/react-query';
import { useStartMessageStreamMutation } from '@/Components/Utils/v2/api';
import { queryKeyFn } from '@/api/openapiContext';
import { useScrollIntoView } from '../ChatTab/hooks/useScrollIntoView';
import { useSnackbar } from '@/Components/v2/Snackbar';
import { DragOverlay } from '@dnd-kit/core';
import { createPortal } from 'react-dom';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useGetDraggableState } from '@/Hooks/useGetDraggableState';
import { HumanMessageV2 } from '../ChatTab/components/HumanMessageV2';
import {
  AIMessageOverlayV2,
  AIMessageV2,
} from '../ChatTab/components/AIMessageV2';
import remarkGfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { ArrowDown, Trash, Trash2 } from 'lucide-react';
import { GetStartedChatBubbleText } from '../ChatTab/components';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';

type Props = {
  document: DocumentResource;
  selection: BlockSelection | null;
  deselectSelection: () => void;
  disabled?: boolean;
  initialMessage?: ReactNode;
};

export const Chat = ({
  document,
  deselectSelection,
  selection,
  disabled,
  initialMessage,
}: Props) => {
  const client = useQueryClient();

  const { showSnackbar } = useSnackbar();
  const { draggableState } = useGetDraggableState();

  const [text, setText] = useState('');

  const [scrollBarWidth, setScrollBarWidth] = useState(10);

  const variables = {
    pathParams: { document: document.id },
  };

  const queryKey = queryKeyFn({
    path: '/documents/{document}/chat-messages',
    operationId: 'documentsChatMessagesIndex',
    variables,
  });

  const { mutateAsync: sendMessageAsync, isPending: isSendingMessage } =
    useDocumentsChatMessagesStore();
  const { data: chatMessages, isLoading } =
    useDocumentsChatMessagesIndex(variables);
  const deleteChatMessageMutation = useDocumentsChatMessagesDestroy();
  const { mutate: clearChat, isPending: isClearingChat } = useDocumentChatClear(
    {
      onMutate: () => {
        client.setQueryData(queryKey, () => []);
      },
      onSuccess: () => {
        client.invalidateQueries({ queryKey });
      },
    },
  );

  const {
    handleStartStream,
    data: { isLoadingMessage, content, isStreamingMessage },
    handleSetLoadingMessage,
  } = useStartMessageStreamMutation(document.id, queryKey);

  const handleRefreshMessage = (id: number) => {
    handleSetLoadingMessage(true);
    deleteChatMessageMutation.mutate(
      {
        pathParams: {
          chatMessage: id,
          document: document.id,
        },
      },
      {
        onSuccess: () => {
          client.invalidateQueries({ queryKey: queryKey }).then(() => {
            handleStartStream();
          });
        },
      },
    );
  };

  const scrollRef = useRef<HTMLDivElement>(null);
  const { handleScrollIntoView, isInView, scrollToDiv } =
    useScrollIntoView(true);

  useEffect(() => {
    if (scrollRef.current) {
      const scrollBarWidth =
        scrollRef.current.offsetWidth - scrollRef.current.clientWidth;
      setScrollBarWidth(scrollBarWidth);
    }
  }, [scrollRef.current]);

  const handleSendMessage = async (predefinedText?: string, insert = true) => {
    handleScrollIntoView();
    if (insert) {
      if (selection && selection.selected.showInChat) {
        client.setQueriesData({ queryKey }, (prev) => {
          return {
            ...prev,
            data: [
              {
                content: selection.selected.text,
                display_type: 'SELECTION',
                is_moderated: false,
                id: -1,
                role: 'user',
              },
              ...prev.data,
            ],
          };
        });
      }
      client.setQueriesData({ queryKey }, (prev) => {
        return {
          ...prev,
          data: [
            {
              content: predefinedText || text,
              display_type: 'MESSAGE',
              is_moderated: false,
              id: 0,
              role: 'user',
            },
            ...prev.data,
          ],
        };
      });
    }
    if (selection && selection.selected.showInChat) {
      try {
        await sendMessageAsync({
          pathParams: { document: document.id },
          body: {
            content: selection.selected.text,
            display_type: 'SELECTION',
          },
        });
        if (!predefinedText) {
          if (!text) return;
          setText('');
        }
        deselectSelection();
      } catch (error: any) {
        showSnackbar({
          alignment: 'bottom',
          message: error.message,
          color: 'red',
        });
        return;
      }
    }
    handleSetLoadingMessage(true);

    try {
      await sendMessageAsync({
        pathParams: { document: document.id },
        body: {
          content: predefinedText || text,
          display_type: 'MESSAGE',
        },
      });
      if (!predefinedText) {
        if (!text) return;
        setText('');
      }
      handleStartStream();
    } catch (error: any) {
      showSnackbar({
        alignment: 'top',
        message: error.message,
        color: 'red',
      });
      handleSetLoadingMessage(false);
    }
  };

  const handleSubmitEditedMessage = (id: number, text: string) => {
    client.setQueriesData({ queryKey }, (prev) => {
      const messageIndex = prev.data.findIndex((message) => message.id === id);

      return {
        ...prev,
        data: [
          {
            ...prev.data[messageIndex],
            content: text,
          },
          ...prev.data.slice(messageIndex + 1),
        ],
      };
    });

    deleteChatMessageMutation.mutate(
      {
        pathParams: {
          chatMessage: id,
          document: document.id,
        },
      },
      {
        onSuccess: () => {
          handleSendMessage(text, false);
        },
      },
    );
  };

  const displayMessages = () => {
    let messages: (string | Message)[] = [];
    if (!selection && !chatMessages?.data) {
      return;
    }

    if (chatMessages?.data) {
      messages = [...messages, ...(chatMessages?.data ?? [])];
    }

    const components: ReactNode[] = [];

    for (let i = 0; i < messages.length; i++) {
      const message = messages[i];
      if (typeof message === 'string' || message.display_type === 'SELECTION') {
        continue;
      }
      if (isHumanMessage(message)) {
        let selectedText: string | undefined = undefined;

        if (
          typeof messages[i + 1] === 'string' ||
          messages[i + 1]?.display_type === 'SELECTION'
        ) {
          selectedText = messages[i + 1]?.content ?? messages[i + 1];
        }

        components.push(
          <HumanMessageV2
            key={message.id}
            id={message.id}
            isLoading={
              message.id === 0 &&
              isLoadingMessage &&
              deleteChatMessageMutation.isPending
            }
            selectedText={selectedText}
            disabled={
              isStreamingMessage ||
              !!disabled ||
              deleteChatMessageMutation.isPending
            }
            text={message.content}
            onSubmitEdit={handleSubmitEditedMessage}
          />,
        );
      } else {
        components.push(
          <AIMessageV2
            isLastAIMessage={i === 0}
            key={message.id}
            text={message.content}
            id={message.id}
            handleRefreshMessage={handleRefreshMessage}
          />,
        );
      }
    }
    return components;
  };

  return (
    <>
      <div className="absolute left-2 top-2 z-[60]">
        <IconButton
          icon={Trash2}
          onClick={() => clearChat({ pathParams: { document: document.id } })}
        />
      </div>
      <div className="relative flex h-full w-full flex-col justify-center">
        {chatMessages?.data.length === 0 && <GetStartedChatBubbleText />}
        <div
          ref={scrollRef}
          className="flex w-full flex-grow flex-col-reverse items-center overflow-y-scroll p-4"
        >
          <div ref={scrollToDiv} />
          <div className="flex w-full max-w-2xl flex-col-reverse gap-4 ">
            {isLoading && (
              <div className="flex flex-col gap-2">
                <SkeletonLoader height="md" />
                <SkeletonLoader height="md" />
                <SkeletonLoader height="md" />
                <SkeletonLoader height="md" />
                <SkeletonLoader height="md" />
                <SkeletonLoader height="md" />
                <SkeletonLoader height="md" />
              </div>
            )}
            {(!!content || isLoadingMessage) && (
              <AIMessageOverlayV2 text={content ?? ''} id={0} />
            )}
            {displayMessages()}
            {initialMessage && (
              <div className="mb-8 w-full">{initialMessage}</div>
            )}
          </div>
        </div>
        <div className="relative h-0 w-full">
          <div
            className={`absolute bottom-4 right-4 z-40 animate-bounce rounded-3xl ${
              isInView ? 'hidden' : 'block'
            }`}
          >
            <IconButton
              icon={ArrowDown}
              onClick={handleScrollIntoView}
              color="primary"
            />
          </div>
        </div>
        <div
          className="flex flex-shrink-0 items-center justify-center py-4 pl-4"
          style={{ paddingRight: 16 + scrollBarWidth }}
        >
          <div
            className={`w-full max-w-2xl overflow-hidden rounded-xl bg-primary-200 p-2 ${
              isSendingMessage || isClearingChat ? 'opacity-50' : ''
            }`}
          >
            {selection && !isSendingMessage && (
              <div className="mb-4 rounded-lg bg-primary-50 p-2">
                <p className="font-bold">Seleced text</p>
                <div className="max-h-48 overflow-y-auto">
                  <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    className="prose text-base"
                  >
                    {selection.selected.text}
                  </ReactMarkdown>
                </div>
              </div>
            )}
            <textarea
              disabled={isSendingMessage || isClearingChat}
              value={isSendingMessage ? '' : text}
              onChange={(e) => setText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleSendMessage();
                }
              }}
              placeholder="Message ai assistant..."
              className="w-full border-0 bg-transparent text-base"
            />
          </div>
        </div>
        {chatMessages?.data &&
          draggableState &&
          createPortal(
            <DragOverlay adjustScale={false} zIndex={2} dropAnimation={null}>
              {chatMessages?.data && draggableState && (
                <AIMessageOverlayV2 text={draggableState.activeElement.text} />
              )}
            </DragOverlay>,
            window.document.body,
          )}
      </div>
    </>
  );
};
