import { ConfirmDialog } from '@/Components/ConfirmDialog';
import { Dialog } from '@/Components/Dialog';
import { WarningAlert } from '@/Components/v2/Alert';
import { Input } from '@/Components/v2/Input/Input';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { useChangeKeyword } from '@/api/openapiComponents';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

type Props = {
  documentId: number;
  projectId: number;
  isOpen: boolean;
  keyword: string;
  onClose: () => void;
};

export const ChangeKeywordDialog = ({
  isOpen,
  keyword,
  onClose,
  documentId,
  projectId,
}: Props) => {
  const appState = useAppStore();
  const client = useQueryClient();

  const [newKeyword, setNewKeyword] = useState(keyword);

  const changeKeywordMutation = useChangeKeyword();

  const handleConfirm = () => {
    changeKeywordMutation.mutate(
      {
        pathParams: {
          document: documentId,
          project: projectId,
        },
        body: {
          keyword: newKeyword,
        },
      },
      {
        onSuccess: (data) => {
          appState.refreshSubscription();

          const queryKey =
            client.getQueryCache().find({
              predicate: (query) => query.queryKey.includes('getDocument'),
            })?.queryKey ?? [];
          client.setQueryData(queryKey, data);
          onClose();
        },
      },
    );
  };

  const hasEnoughContentCredits =
    !!appState.subscription?.usage.credits.content.is_allowed;

  return (
    <ConfirmDialog
      isOpen={isOpen}
      title="Change keyword"
      onClose={onClose}
      isLoading={changeKeywordMutation.isPending}
      content={
        <div className="flex flex-col gap-2">
          <InputDecoration label="New keyword" required>
            <Input
              value={newKeyword}
              onChange={(value) => setNewKeyword(value)}
              placeholder="Enter new keyword"
            />
          </InputDecoration>

          {!hasEnoughContentCredits && (
            <WarningAlert title="You do not have enough credits to change the keyword and generate a new report" />
          )}
        </div>
      }
      error={changeKeywordMutation.error}
      disabled={!hasEnoughContentCredits}
      confirmText="Change"
      onConfirm={handleConfirm}
    />
  );
};
