import { BlockType, LeafType, isLeafNode, NodeTypesEnum } from '@/types';

export const removeLigaturesFromMarkdown = (inputString: string) => {
  // Remove bold formatting
  const withoutBold = inputString.replace(/\*\*(.*?)\*\*/g, '$1');

  // Remove italic formatting
  const withoutItalic = withoutBold.replace(/\*(.*?)\*/g, '$1');

  // Remove strikethrough formatting
  const withoutStrikethrough = withoutItalic.replace(/~~(.*?)~~/g, '$1');

  // Remove underline formatting
  const withoutUnderline = withoutStrikethrough.replace(/_(.*?)_/g, '$1');

  return withoutUnderline;
};

const ligatures = (leaf: LeafType) => {
  let string = '';
  let textValue = leaf.text;
  if (leaf.strikethrough || leaf.underline || leaf.bold || leaf.italic) {
    textValue = textValue.trim();
  }
  if (leaf.strikethrough) {
    string += '~~';
  }
  if (leaf.underline) {
    string += '<ins>';
  }
  if (leaf.bold) {
    string += '**';
  }
  if (leaf.italic) {
    string += '*';
  }

  string += textValue;

  if (leaf.italic) {
    string += '*';
  }
  if (leaf.bold) {
    string += '**';
  }
  if (leaf.underline) {
    string += '</ins>';
  }
  if (leaf.strikethrough) {
    string += '~~';
  }
  if (leaf.strikethrough || leaf.underline || leaf.bold || leaf.italic) {
    if (leaf.text.endsWith(' ')) {
      string = string + ' ';
    }
    if (leaf.text.startsWith(' ')) {
      string = ' ' + string;
    }
  }

  return string;
};

const getMarkDown = (leaf: LeafType, parentType: NodeTypesEnum) => {
  switch (parentType) {
    case NodeTypesEnum.P:
      return ligatures(leaf);
    case NodeTypesEnum.H1:
      return ligatures(leaf);
    case NodeTypesEnum.H2:
      return ligatures(leaf);
    case NodeTypesEnum.H3:
      return ligatures(leaf);
    case NodeTypesEnum.H4:
      return ligatures(leaf);
    case NodeTypesEnum.H5:
      return ligatures(leaf);
    case NodeTypesEnum.H6:
      return ligatures(leaf);
    case NodeTypesEnum.LIC:
      return ligatures(leaf);
    case NodeTypesEnum.BLOCKQUOTE:
      return ligatures(leaf);
    default:
      return ligatures(leaf);
  }
};

export const serializeToMarkdown = (nodes: any) =>
  nodes.map((node) => serialize(node, NodeTypesEnum.P)).join('\n');

export const serialize = (
  chunk: BlockType | LeafType,
  parentType: NodeTypesEnum,
  listType?: NodeTypesEnum.OL | NodeTypesEnum.UL,
  prefix?: string,
  listLevel?: number,
) => {
  if (!chunk) {
    return '';
  }
  if (!isLeafNode(chunk)) {
    const level = !listLevel ? 1 : listLevel + 1;
    switch (chunk.type) {
      case NodeTypesEnum.P:
        const isTable =
          parentType === NodeTypesEnum.TH || parentType === NodeTypesEnum.TD;
        return (
          chunk.children.map((child) => serialize(child, chunk.type)).join('') +
          (!isTable ? '\n' : '')
        );
      case NodeTypesEnum.H1:
        return (
          '# ' +
          chunk.children.map((child) => serialize(child, chunk.type)).join('') +
          '\n'
        );
      case NodeTypesEnum.H2:
        return (
          '## ' +
          chunk.children.map((child) => serialize(child, chunk.type)).join('') +
          '\n'
        );
      case NodeTypesEnum.H3:
        return (
          '### ' +
          chunk.children.map((child) => serialize(child, chunk.type)).join('') +
          '\n'
        );
      case NodeTypesEnum.H4:
        return (
          '#### ' +
          chunk.children.map((child) => serialize(child, chunk.type)).join('') +
          '\n'
        );
      case NodeTypesEnum.H5:
        return (
          '##### ' +
          chunk.children.map((child) => serialize(child, chunk.type)).join('') +
          '\n'
        );
      case NodeTypesEnum.H6:
        return (
          '###### ' +
          chunk.children.map((child) => serialize(child, chunk.type)).join('') +
          '\n'
        );
      case NodeTypesEnum.HR:
        return '---';
      case NodeTypesEnum.UL:
        return (
          chunk.children
            .map((child) =>
              serialize(
                child,
                chunk.type,
                NodeTypesEnum.UL,
                prefix !== undefined ? '   ' + prefix : '- ',
                level,
              ),
            )
            .join('\n') + (level === 1 ? '\n' : '')
        );
      case NodeTypesEnum.OL:
        const getPrefix = (index: number) => {
          const position = (index + 1).toString() + '. ';
          if (prefix === undefined) {
            return position;
          }
          const indent = new Array(level).fill('   ').join('');
          return indent + position;
        };
        return (
          chunk.children
            .map((child, index) =>
              serialize(
                child,
                chunk.type,
                NodeTypesEnum.OL,
                getPrefix(index),
                level,
              ),
            )
            .join('\n') + (level === 1 ? '\n' : '')
        );
      case NodeTypesEnum.LI:
        return (
          `${prefix}` +
          chunk.children
            .map((child) =>
              serialize(child, chunk.type, listType, prefix, listLevel),
            )
            .join('\n')
        );
      case NodeTypesEnum.LIC:
        return chunk.children
          .map((child) =>
            serialize(child, chunk.type, listType, undefined, listLevel),
          )
          .join('');
      case NodeTypesEnum.BLOCKQUOTE:
        return (
          '> ' +
          chunk.children.map((child) => serialize(child, chunk.type)).join('') +
          '  \n'
        );
      case NodeTypesEnum.A:
        return `[${chunk.children
          .map((child) => serialize(child, chunk.type))
          .join('')}](${chunk.url})`;
      case NodeTypesEnum.TABLE:
        const header = chunk.children
          .slice(0, 1)
          .map((child) => serialize(child, chunk.type))
          .join('');
        const lengthOffTableRow = header.match(/\|/g)?.length;
        const heading = new Array(lengthOffTableRow).fill('|').join('---');
        const result = header + heading;
        return (
          result +
          '\n' +
          chunk.children
            .slice(1)
            .map((child) => serialize(child, chunk.type))
            .join('')
        );
      case NodeTypesEnum.TR:
        return (
          `| ` +
          chunk.children
            .map((child) => serialize(child, chunk.type))
            .join(' | ') +
          ` |` +
          '\n'
        );
      case NodeTypesEnum.TD:
        return chunk.children.map((child) => serialize(child, chunk.type));
      case NodeTypesEnum.IMG:
        if (
          !chunk.url?.startsWith('http://') &&
          !chunk.url?.startsWith('https://')
        ) {
          return '';
        }
        return `![${chunk.children[0]?.text ?? ''}](${chunk.url})`;
      case NodeTypesEnum.TH:
        return chunk.children.map((child) => serialize(child, chunk.type));
      default:
        return '';
    }
  }

  if (isLeafNode(chunk)) {
    return getMarkDown(chunk, parentType);
  }
};

export default serialize;
