import React, { ReactNode } from 'react';
import { LoadingSpinner } from '../SvgIcon/components/LoadingSpinner';
import { LucideProps } from 'lucide-react';
import { isComponentFunction } from '../utils';

export type ButtonColors =
  | 'green'
  | 'red'
  | 'blue'
  | 'gray'
  | 'yellow'
  | 'primary'
  | 'secondary'
  | 'white';

export type ButtonSizes = 'xs' | 'sm' | 'base' | 'lg' | 'xl';

export type ButtonProps = {
  /**
   * Identifier for a button
   */
  id?: string;
  /**
   * What type of button is it?
   */
  type?: 'submit' | 'button' | 'reset';
  /**
   * How large should the button be?
   */
  size?: ButtonSizes;
  /**
   * What color to use?
   */
  color?: ButtonColors;
  /**
   * The styling of the button
   */
  variant?: 'fill' | 'outline' | 'ghost';
  /**
   * disable a button
   */
  disabled?: boolean;
  /**
   * Button contents
   */
  text?: string;
  /**
   * Optional click handler
   */
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  /**
   * Optional mouse down handler
   */
  onMouseDown?: React.MouseEventHandler<HTMLButtonElement>;
  /**
   * Optional mouse enter handler
   */
  onMouseEnter?: React.MouseEventHandler<HTMLButtonElement>;
  /**
   * Optional mouse leave handler
   */
  onMouseLeave?: React.MouseEventHandler<HTMLButtonElement>;
  /**
   * If true, the button will take up the full width of its container.
   */
  fullWidth?: boolean;
  /**
   * Makes the padding of the button smaller
   */
  dense?: boolean | 'also-horizontally';
  /**
   * Makes the padding of the button smaller
   */
  isLoading?: boolean;
  /**
   * Makes the button have round ends
   */
  round?: boolean;
  /**
   * Prepends an icon
   */
  prependIcon?: React.ComponentType<LucideProps> | ReactNode;
  /**
   * Appends an icon
   */
  appendIcon?: React.ComponentType<LucideProps> | ReactNode;
  /**
   * Optionale case for the text
   */
  textCase?: 'uppercase' | 'lowercase' | 'capitalize' | 'normal-case';
};

/**
 * Primary UI component for user interaction
 */
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      size = 'base',
      color = 'primary',
      textCase = 'uppercase',
      text,
      variant = 'fill',
      disabled = false,
      fullWidth = false,
      dense = false,
      isLoading = false,
      round = false,
      appendIcon,
      prependIcon,
      ...props
    }: ButtonProps,
    ref,
  ) => {
    const getColor = () => {
      const colors = {
        primary: `bg-primary ${variant !== 'ghost' ? 'border-primary' : ''}`,
        secondary: `bg-secondary ${
          variant !== 'ghost' ? 'border-secondary' : ''
        }`,
        green: `bg-green ${variant !== 'ghost' ? 'border-green' : ''}`,
        red: `bg-red ${variant !== 'ghost' ? 'border-red' : ''}`,
        blue: `bg-blue ${variant !== 'ghost' ? 'border-blue' : ''}`,
        gray: `bg-gray ${variant !== 'ghost' ? 'border-gray' : ''}`,
        white: `bg-white ${variant !== 'ghost' ? 'border-white' : ''}`,
        yellow: `bg-yellow ${variant !== 'ghost' ? 'border-yellow' : ''}`,
      };

      return colors[color];
    };

    const getSpinnerColor = () => {
      if (variant !== 'fill') {
        return color;
      }
      if (color === 'white') {
        return 'gray';
      }
      return undefined;
    };

    const getOutlinedResult = () => {
      const textColors = {
        primary: 'text-primary',
        secondary: 'text-secondary',
        green: 'text-green',
        red: 'text-red',
        blue: 'text-blue',
        gray: 'text-gray',
        white: 'text-white',
        yellow: 'text-yellow',
      };
      if (variant === 'fill') {
        return `${
          color === 'white' ? 'text-primary' : 'text-white'
        } border shadow-sm`;
      }
      if (variant === 'ghost') {
        return `${textColors[color]} bg-opacity-0 border border-transparent`;
      }
      // Outline variant
      return `border ${textColors[color]} bg-opacity-0 shadow-sm`;
    };

    const getTextCasing = () => {
      switch (textCase) {
        case 'uppercase':
          return 'uppercase';
        case 'lowercase':
          return 'lowercase';
        case 'capitalize':
          return 'capitalize';
        default:
          return 'normal-case';
      }
    };

    const textSize = `text-${size}`;

    // When all buttons have been replaced, add this to the non disabled styling: active:scale-90
    const isDisabled =
      disabled || isLoading
        ? 'opacity-30 cursor-not-allowed'
        : ` ${
            variant !== 'fill'
              ? 'hover:bg-opacity-[0.15]'
              : 'hover:bg-opacity-[0.85]'
          }`;

    const isFullWidth = fullWidth ? 'w-full' : '';

    const isOnlyIcon = !!(
      (prependIcon && !text && !appendIcon) ||
      (!prependIcon && !text && appendIcon)
    );

    const padding = !isOnlyIcon
      ? dense
        ? `${dense === 'also-horizontally' ? 'px-0.5' : 'px-3'} py-0.5`
        : `px-4 py-1.5`
      : dense
        ? 'p-0.5'
        : 'px-1.5 py-1.5';

    const getIconSize = () => {
      switch (size) {
        case 'xs':
          return 'h-3 w-3';
        case 'sm':
          return 'h-4 w-4';
        case 'base':
          return 'h-5 w-5';
        case 'lg':
          return 'h-6 w-6';
        case 'xl':
          return 'h-7 w-7';
        default:
          return 'h-5 w-5';
      }
    };

    const rounding = round ? 'rounded-full' : 'rounded-lg';

    const PrependIcon = prependIcon;
    const AppendIcon = appendIcon;

    return (
      <button
        ref={ref}
        type="button"
        className={`relative ${rounding} flex transform items-center justify-center ${
          dense ? 'gap-1' : 'gap-2'
        } whitespace-nowrap font-semibold  ${getTextCasing()} transition ${padding} ${getColor()} ${textSize} ${getOutlinedResult()} ${isDisabled} ${isFullWidth}`}
        disabled={disabled || isLoading}
        {...props}
      >
        <>
          {PrependIcon &&
            (isComponentFunction(PrependIcon) ? (
              <div
                className={`${isLoading ? `invisible` : `visible`} ${textSize}`}
              >
                {<PrependIcon className={getIconSize()} />}
              </div>
            ) : (
              PrependIcon
            ))}
          {text && (
            <p className={isLoading ? `invisible` : `visible`}>{text}</p>
          )}
          {AppendIcon && isComponentFunction(AppendIcon) ? (
            <div
              className={`${isLoading ? `invisible` : `visible`} ${textSize}`}
            >
              {<AppendIcon className={getIconSize()} />}
            </div>
          ) : (
            AppendIcon
          )}

          {/* Loading spinner */}
          <div
            className={`insets-0 absolute ${padding} ${
              isLoading ? 'visible' : 'invisible'
            }`}
          >
            <LoadingSpinner size={size} color={getSpinnerColor()} />
          </div>
        </>
      </button>
    );
  },
);

Button.displayName = 'Button';

export { Button };
