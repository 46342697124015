import {
  useProjectDocumentGenerate,
  useProjectGenerateDocument,
} from '@/api/openapiComponents';
import { AiGeneratedFormState } from './pages/AiGenerated/AiGenerated';
import { DocumentResource } from '@/api/openapiSchemas';
import { DndHeading, DndSubheading } from '@/Components/DocumentForm/hooks';
import { useState } from 'react';
import {
  DragEndEvent,
  DragOverEvent,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { createUniqueIdentifier } from '../Document/components/PlateEditor/lib/transform';
import { arrayMove } from '@dnd-kit/sortable';

export const useGenerateDocument = (
  formState: AiGeneratedFormState,
  projectId: number,
  briefLength: number,
  documentId?: number,
) => {
  const generateExistingDocumentMutation = useProjectDocumentGenerate();
  const generateNewDocumentMutation = useProjectGenerateDocument();

  const generateDocumentMutation = documentId
    ? generateExistingDocumentMutation
    : generateNewDocumentMutation;

  const handleGenerateDocument = (
    onSuccess: (data: { data: DocumentResource }) => void,
  ) => {
    const brief =
      formState.single.selectedBriefType === 'manual'
        ? formState.single.brief
        : formState.single.briefFromUrl;

    generateDocumentMutation.mutate(
      {
        pathParams: {
          project: projectId,
          document: documentId!,
        },
        body: {
          keyword: formState.single.keyword,
          document_template_id: formState.single.templateId ?? undefined,
          title:
            formState.single.title.length === 0
              ? undefined
              : formState.single.title,
          autostart:
            formState.single.templateId === null
              ? !formState.single.optionLiveGenerate
              : true,
          outline:
            formState.single.outline?.length === 0
              ? undefined
              : formState.single.outline?.map((item) => ({
                  type: 'h2',
                  title: item.title,
                  items: item.subHeadings.map((subItem) => ({
                    type: 'h3',
                    title: subItem.title,
                  })),
                })),
          content_type_id: formState.single.contentTypeId,
          ...(formState.single.brief.length > 0 ||
          formState.single.briefFromUrl.length > 0
            ? {
                settings: {
                  context: {
                    value: brief.slice(0, briefLength),
                    type:
                      formState.single.selectedBriefType === 'from-url'
                        ? 'url'
                        : 'none',
                    type_value:
                      formState.single.selectedBriefType === 'from-url'
                        ? formState.single.importedUrl
                        : undefined,
                  },
                },
              }
            : {}),
        },
      },
      {
        onSuccess: onSuccess,
      },
    );
  };

  return {
    error: generateDocumentMutation.error,
    handleGenerateDocument,
    isLoading: generateDocumentMutation.isPending,
  };
};

export const useGenerateDocumentV2 = () => {
  const generateExistingDocumentMutation = useProjectDocumentGenerate();

  const handleGenerateDocument = ({
    projectId,
    document,
    liveGenerate,
    outline,
    onSuccess,
    onError,
  }: {
    projectId: number;
    document: DocumentResource;
    outline?: DndHeading[];
    liveGenerate: boolean;
    onError: () => void;
    onSuccess: (data: { data: DocumentResource }) => void;
  }) => {
    generateExistingDocumentMutation.mutate(
      {
        pathParams: {
          project: projectId,
          document: document.id,
        },
        body: {
          title: document.title,
          autostart: !liveGenerate,
          outline:
            outline?.length === 0
              ? undefined
              : outline?.map((item) => ({
                  type: 'h2',
                  title: item.title,
                  items: item.subHeadings.map((subItem) => ({
                    type: 'h3',
                    title: subItem.title,
                  })),
                })),
        },
      },
      {
        onError: onError,
        onSuccess: onSuccess,
      },
    );
  };

  return {
    handleGenerateDocument,
    isLoading: generateExistingDocumentMutation.isPending,
  };
};

export const useOutlineState = () => {
  const [activeHeading, setActiveHeading] = useState<
    DndHeading | DndSubheading
  >();
  const [outline, setOutline] = useState<DndHeading[]>([]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );

  // *** DND handlers ***

  const getHeading = (items: (DndHeading | DndSubheading)[], id: string) => {
    let found: DndHeading | DndSubheading | undefined = undefined;

    for (const item of items) {
      if (item.id === id) {
        return item;
      }
      found = getHeading((item as DndHeading).subHeadings ?? [], id);
      if (found) {
        break;
      }
    }

    return found;
  };

  const toggleType = (id: string) => {
    if (!outline) return;

    const isH2 = outline.some((item) => item.id === id);

    if (isH2) {
      const index = outline.findIndex((item) => item.id === id);

      // The first h2 cannot be changed to a h3
      if (index === 0) {
        return;
      }

      const h2 = outline[index];
      const newOutline = outline.filter((_, i) => i !== index);

      newOutline[index - 1].subHeadings = [
        ...newOutline[index - 1].subHeadings,
        {
          id: h2.id,
          title: h2.title,
        },
        ...h2.subHeadings,
      ];

      setOutline(newOutline);
    } else {
      const h2Index = outline.findIndex((item) =>
        item.subHeadings.some((subItem) => subItem.id === id),
      );
      const h3Index = outline[h2Index].subHeadings.findIndex(
        (subItem) => subItem.id === id,
      );

      const newOutline = [
        ...outline.map((item) => ({
          ...item,
          subHeadings: [...item.subHeadings],
        })),
      ];

      const subHeadings = [...newOutline[h2Index].subHeadings];
      newOutline[h2Index].subHeadings = subHeadings.slice(0, h3Index);

      const newH2SubHeadings = subHeadings.slice(h3Index + 1);

      const heading = getHeading(outline, id);
      setOutline([
        ...newOutline.slice(0, h2Index + 1),
        {
          id: heading.id,
          title: heading.title,
          subHeadings: newH2SubHeadings,
        },
        ...newOutline.slice(h2Index + 1),
      ]);
    }
  };

  const setText = (id: string, value: string) => {
    const newOutline = outline?.map((item) => ({
      ...item,
      title: id === item.id ? value : item.title,
      subHeadings: item.subHeadings.map((subItem) => ({
        ...subItem,
        title: subItem.id === id ? value : subItem.title,
      })),
    }));

    setOutline(newOutline);
  };

  const deleteItem = (id: string) => {
    const newOutline = outline
      ?.filter((item) => item.id !== id)
      .map((item) => ({
        ...item,
        subHeadings: item.subHeadings.filter((subItem) => subItem.id !== id),
      }));

    setOutline(
      newOutline.length === 0
        ? [{ id: createUniqueIdentifier(), subHeadings: [], title: '' }]
        : newOutline,
    );
  };

  const newItem = () => {
    if (outline?.length === 0) {
      setOutline([
        { id: createUniqueIdentifier(), subHeadings: [], title: '' },
      ]);
    } else {
      const newOutline = [...outline!];
      newOutline[newOutline.length - 1].subHeadings = [
        ...newOutline[newOutline.length - 1].subHeadings,
        { id: createUniqueIdentifier(), title: '' },
      ];

      setOutline(newOutline);
    }
  };

  const handleDragOver = ({ over, active }: DragOverEvent) => {
    if (!over || !outline) return;

    const overType = over.data.current?.type;
    const activeType = active.data.current?.type;

    // Dragging a H3
    if (activeType === 'H3') {
      const activeH2Index = outline.findIndex(
        (item) =>
          item.subHeadings.some((subItem) => subItem.id === active.id) ||
          item.id === active.id,
      );
      const overH2Index = outline.findIndex(
        (item) =>
          item.subHeadings.some((subItem) => subItem.id === over.id) ||
          item.id === over.id,
      );

      if (overType === 'H3') {
        // active and over are in the same H2
        if (activeH2Index === overH2Index) {
          const newOutline = [...outline];
          const subHeadings = newOutline[overH2Index].subHeadings;
          newOutline[overH2Index].subHeadings = arrayMove(
            subHeadings,
            subHeadings.findIndex((item) => item.id === active.id),
            subHeadings.findIndex((item) => item.id == over.id),
          );

          setOutline(newOutline);
          return;
        }

        // active and over are in different H2's
        if (activeH2Index !== overH2Index) {
          const newOutline = [...outline];
          const overH3Index = newOutline[overH2Index].subHeadings.findIndex(
            (subHeading) => subHeading.id === over.id,
          );

          newOutline[overH2Index].subHeadings = [
            ...newOutline[overH2Index].subHeadings.slice(0, overH3Index),
            newOutline[activeH2Index].subHeadings.find(
              (item) => item.id === active.id,
            )!,
            ...newOutline[overH2Index].subHeadings.slice(overH3Index),
          ];
          newOutline[activeH2Index].subHeadings = newOutline[
            activeH2Index
          ].subHeadings.filter((item) => item.id !== active.id);

          setOutline(newOutline);
          return;
        }
      }

      if (overType === 'H2') {
        if (activeH2Index === overH2Index) return;

        const newOutline = [...outline];
        newOutline[overH2Index].subHeadings = [
          ...newOutline[overH2Index].subHeadings,
          newOutline[activeH2Index].subHeadings.find(
            (item) => item.id === active.id,
          )!,
        ];
        newOutline[activeH2Index].subHeadings = newOutline[
          activeH2Index
        ].subHeadings.filter((item) => item.id !== active.id);

        setOutline(newOutline);
        return;
      }
    }
  };

  const handleDragEnd = ({ over, active }: DragEndEvent) => {
    if (!over || !outline) return;

    const overType = over.data.current?.type;
    const activeType = active.data.current?.type;

    // Dragging a H2
    if (activeType === 'H2') {
      if (overType === 'H2') {
        const newOutline = arrayMove(
          outline,
          outline?.findIndex((item) => item.id === active.id),
          outline?.findIndex((item) => item.id === over.id),
        );
        setOutline(newOutline);
        return;
      }

      if (overType === 'H3') {
        const newOutline = arrayMove(
          outline,
          outline?.findIndex((item) => item.id === active.id),
          outline?.findIndex((item) =>
            item.subHeadings.some((subItem) => subItem.id === over.id),
          ),
        );
        setOutline(newOutline);
        return;
      }
    }
    setActiveHeading(undefined);
  };

  const handleDragStart = ({ active }: DragStartEvent) => {
    if (!outline) return;
    setActiveHeading(getHeading(outline, active.id as string));
  };

  return {
    activeHeading,
    handleDragEnd,
    handleDragOver,
    handleDragStart,
    deleteItem,
    newItem,
    setText,
    toggleType,
    outline,
    sensors,
    setOutline,
  };
};
