import dayjs from 'dayjs';

export const getSerpDate = (showDate: boolean) =>
  showDate
    ? dayjs(new Date()).format('D. MMM. YYYY').toLowerCase() + ' - '
    : '';

export const getStringInPixels = (txt: string, font: string) => {
  const canvas = window.document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (ctx) {
    ctx.font = font;
    const width = Math.round(ctx.measureText(txt).width);

    return width;
  }
  return 0;
};

export const getStringFromPixels = (
  txt: string,
  pixelCount: number,
  font: string,
) => {
  const canvas = window.document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (ctx) {
    ctx.font = font;
    const width = Math.round(ctx.measureText(txt).width);

    if (width <= pixelCount) {
      return txt;
    } else {
      let currentWidth = 0;
      for (let index = 0; index < txt.length; index++) {
        const txtWidth = Math.round(ctx.measureText(txt[index]).width);
        currentWidth += txtWidth;
        if (currentWidth >= pixelCount) {
          return txt.substring(0, index) + ' ...';
        }
      }
    }

    return '';
  }
  return '';
};

export const getScreenPropertiesForSerp = (mode: 'Desktop' | 'Mobile') => {
  const containerMax = mode === 'Desktop' ? '580px' : '410px';
  const containerMin = mode === 'Desktop' ? '580px' : '410px';
  const mobileContainerProps =
    mode === 'Desktop'
      ? {}
      : {
          paddingRight: '16px',
          paddingLeft: '16px',
          paddingTop: '16px',
          paddingBottom: '12px',
          backgroundColor: 'white',
        };
  const backgroundColor = mode === 'Desktop' ? 'white' : 'rgb(241,243,244)';
  const itemGap = mode === 'Desktop' ? '1.9rem' : '0.5rem';
  const containerPadding =
    mode === 'Desktop' ? { padding: '0rem 0rem 2rem 3rem' } : {};
  const outerFullContainer =
    mode === 'Desktop'
      ? {}
      : {
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        };
  const loaderPaddingMobile =
    mode === 'Desktop' ? {} : { paddingTop: '1rem', paddingBottom: '1rem' };
  return {
    containerMax,
    containerMin,
    mobileContainerProps,
    backgroundColor,
    itemGap,
    containerPadding,
    outerFullContainer,
    loaderPaddingMobile,
  };
};
