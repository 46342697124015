import * as React from 'react';

type Props = {
  isOpen: boolean;
  children: React.ReactNode;
  direction?: 'right';
}
export function SimpleToolTip({
  children,
  isOpen,
  direction = 'right',
}: Props) {
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  // Only support of right side
  const getDirectionStyles = () => {
    switch (direction) {
      default:
        return {
          inset: `calc(${
            containerRef.current!.offsetHeight / 5
          }px - 0.25rem) auto auto calc(${
            containerRef.current!.offsetWidth
          }px + 0.75rem)`,
          padding: '0.25rem',
        };
    }
  };
  const directionStyles = containerRef.current ? getDirectionStyles() : {};
  return (
    <div className="relative ">
      <div ref={containerRef}>{children}</div>
      {isOpen && (
        <div
          className="absolute rounded-lg border-2 bg-white"
          style={directionStyles}
        >
          <div className=" font-semibold text-green-400">Copied!</div>
        </div>
      )}
    </div>
  );
}
