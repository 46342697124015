import { unified } from 'unified';
import markdown from 'remark-parse';
import remarkGfm from 'remark-gfm';
import slate from './compiler';
import { MarkdownNode } from '@/types';
import { TDescendant } from '@udecode/plate-common';
import { nextLine } from '@/data/initialPlateJSValue';

const processor = unified().use(markdown).use(remarkGfm).use(slate);

export const deserializeSnippet = (markdownString: string) => [
  ...(processor.processSync(markdownString).result as TDescendant[]),
];
export default (markdownString: string) =>
  [
    ...(processor.processSync(markdownString).result as MarkdownNode[]),
    nextLine,
  ] as any[];
