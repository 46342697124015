import { ConfirmDialog } from '@/Components/ConfirmDialog';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { useDeleteDocuments } from '@/api/openapiComponents';
import { DocumentResource } from '@/api/openapiSchemas';

type Props = {
  documentsToBeDeleted: DocumentResource[];
  onRemove: () => void;
  onClose: () => void;
};

export const DeleteDocumentsDialog = ({
  documentsToBeDeleted,
  onClose,
  onRemove,
}: Props) => {
  const appState = useAppStore();

  const deleteDocumentsMutation = useDeleteDocuments();

  const handleSubmit = () => {
    deleteDocumentsMutation.mutate(
      {
        pathParams: {
          project: appState.currentProject!.id,
        },
        queryParams: {
          document_ids: documentsToBeDeleted.map((keyword) => keyword.id),
        },
      },
      {
        onSuccess: () => {
          onRemove();
          onClose();
        },
      },
    );
  };

  return (
    <ConfirmDialog
      isOpen={documentsToBeDeleted.length > 0}
      title="Remove keywords"
      confirmColor="red"
      isLoading={deleteDocumentsMutation.isPending}
      confirmText="Remove"
      onClose={onClose}
      error={deleteDocumentsMutation.error}
      content={
        <div>
          <p className="mb-2">
            Are you sure you want to remove{' '}
            {documentsToBeDeleted.length < 5
              ? 'the following'
              : documentsToBeDeleted.length}{' '}
            documents?
          </p>

          {documentsToBeDeleted.length < 5 && (
            <ul>
              {documentsToBeDeleted.map((document) => (
                <li
                  key={document.id}
                  className={document.title ? '' : 'text-primary-400'}
                >
                  • {document.title ? document.title : 'Untitled'}
                </li>
              ))}
            </ul>
          )}
        </div>
      }
      onConfirm={handleSubmit}
    />
  );
};
