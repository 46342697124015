import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { TElement } from '@udecode/plate-common';

export const usePlateUiDrop = (element: TElement) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
  } = useSortable({
    id: element.id as string,
    data: {
      source: 'editor',
    },
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return { attributes, listeners, setNodeRef, style, setActivatorNodeRef };
};
