import { forwardRef } from 'react';

type Props = {
  isOver: boolean;
  isActive: boolean;
  noLine?: boolean;
  renderContent: (isOver: boolean) => JSX.Element;
};

const DropZone = forwardRef<HTMLDivElement, Props>(
  ({ isActive, isOver, renderContent, noLine }: Props, ref) => {
    return (
      <div className="relative flex w-full justify-center" ref={ref}>
        {!noLine && (
          <div
            className={`absolute left-1/2 top-0 flex h-full w-0.5 justify-center bg-primary-300`}
          />
        )}
        {isActive && (
          <div
            className={`absolute inset-x-0 inset-y-2 flex w-full items-center justify-center rounded-xl border border-dashed p-1 ${
              isOver
                ? 'border-secondary-400 bg-secondary-100'
                : ' border-primary-400 bg-primary-100'
            }`}
          >
            {renderContent(isOver)}
          </div>
        )}
        <div className="w-full p-3 opacity-0">{renderContent(isOver)}</div>
      </div>
    );
  },
);

DropZone.displayName = 'DropZone';
export { DropZone };
