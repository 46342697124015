type Props = {
  title: string;
  description: string;
};

export const SectionParagraph = ({ title, description }: Props) => {
  return (
    <div className="flex flex-col">
      <h1 className="font-bold">{title}</h1>
      <p>{description}</p>
    </div>
  );
};
