import { Dialog } from '@/Components/Dialog';
import { ErrorAlert } from '@/Components/v2/Alert';
import { Input } from '@/Components/v2/Input/Input';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { ErrorHelper } from '@/Services/ErrorHandling';
import { WordPressSettings } from '@/types';

type Props = {
  wordPressDialog: boolean;
  wordPressSettings: WordPressSettings;
  handleChangeWordPress: (value: string, key: keyof WordPressSettings) => void;
  isEstablishingConnection: () => boolean;
  handleWordPressDialog: () => void;
  submitWordPressConnection: () => void;
  errors:
    | {
        error: unknown;
        hasError: true;
      }
    | {
        error: null;
        hasError: boolean;
      };
};

export const WordPressDialog = ({
  wordPressDialog,
  wordPressSettings,
  handleChangeWordPress,
  isEstablishingConnection,
  handleWordPressDialog,
  submitWordPressConnection,
  errors,
}: Props) => {
  const errorHelper = new ErrorHelper(errors?.error as any);

  return wordPressDialog ? (
    <Dialog
      title={
        wordPressSettings.mode === 'EDIT_CONNECTION'
          ? 'Publish to Wordpress'
          : 'Setup Wordpress'
      }
      isOpen={wordPressDialog}
      handleClose={handleWordPressDialog}
      size={'sm'}
      actions={[
        {
          text: 'Connect',
          color: 'secondary',
          onClick: submitWordPressConnection,
          isLoading: isEstablishingConnection(),
        },
      ]}
    >
      <div className="flex flex-col gap-4">
        <InputDecoration label="URL" tooltip="The URL for your domain.">
          <Input
            error={errorHelper.has('settings.url')}
            placeholder="https://example.com"
            onChange={(value) => {
              handleChangeWordPress(value, 'url');
            }}
            value={wordPressSettings.url}
          />
        </InputDecoration>
        <InputDecoration
          label="Username"
          tooltip="The username for your wordpress site."
        >
          <Input
            placeholder="example@hotmail.com"
            error={errorHelper.has('settings.username')}
            onChange={(value) => {
              handleChangeWordPress(value, 'username');
            }}
            value={wordPressSettings.username}
          />
        </InputDecoration>
        <InputDecoration
          label="Token"
          tooltip="Your authorisation token for your wordpress site."
        >
          <Input
            placeholder="xxxx xxxx xxxx xxxx xxxx xxxx"
            error={errorHelper.has('settings.token')}
            onChange={(value) => {
              handleChangeWordPress(value, 'token');
            }}
            value={wordPressSettings.token}
          />
        </InputDecoration>
        {errors.hasError && <ErrorAlert />}
      </div>
    </Dialog>
  ) : null;
};
