import { ConfirmDialog } from '@/Components/ConfirmDialog';
import { Input } from '@/Components/v2/Input/Input';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { useState } from 'react';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  error: any;
  handleSubmit: (name: string) => void;
};

export const AddListDialog = ({
  isOpen,
  onClose,
  error,
  handleSubmit,
}: Props) => {
  const [name, setName] = useState('');

  return (
    <ConfirmDialog
      isOpen={isOpen}
      title="Create new list"
      confirmText="Create"
      onClose={onClose}
      error={error}
      content={
        <>
          <InputDecoration label="Name" required>
            <Input value={name} onChange={setName} counter counterMax={32} />
          </InputDecoration>
        </>
      }
      onConfirm={() => handleSubmit(name)}
    />
  );
};
