import * as React from 'react';
import {
  ArrowRightLeft,
  GripVertical,
  PlusSquare,
  RefreshCcw,
} from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import { useDraggable } from '@dnd-kit/core';
import BeatLoader from '@/Components/BeatLoader';
import remarkGfm from 'remark-gfm';
import { DocumentActionContext } from '@/Pages/Document/context';
import { Button } from '@/Components/v2/Button';
import { IconButton } from '@/Components/v2/IconButton/IconButton';

type AIMessageOverlayProps = {
  text: string;
  id?: number;
};
type Props = {
  isLastAIMessage?: boolean;
  text: string;
  id: number;
  handleRefreshMessage: (id: number) => void;
};

export function AIMessageOverlay({ text, id }: AIMessageOverlayProps) {
  return (
    <div className="flex w-full flex-col border-b-2 bg-primary-50 pt-6 opacity-70">
      <p className="mb-4 px-6">
        <ReactMarkdown remarkPlugins={[remarkGfm]} className="prose text-base">
          {text}
        </ReactMarkdown>
        {id === 0 && (
          <BeatLoader
            className="mt-6"
            size={'0.5rem'}
            color="rgb(74 222 128 / 1)"
          />
        )}
      </p>
      <div className="flex justify-between gap-2 px-2">
        <Button
          prependIcon={GripVertical}
          text="Drag and drop"
          variant="ghost"
          disabled
        />

        <div className="flex">
          <Button
            prependIcon={ArrowRightLeft}
            text="Replace"
            variant="ghost"
            disabled
          />
          <Button
            prependIcon={GripVertical}
            text="Insert"
            variant="ghost"
            disabled
          />
        </div>
      </div>
    </div>
  );
}

export function AIMessage({
  text,
  id,
  handleRefreshMessage,
  isLastAIMessage,
}: Props) {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef } =
    useDraggable({
      data: {
        text,
        source: 'chat',
      },
      id,
    });

  const { handleInsertText, handleReplaceText } = React.useContext(
    DocumentActionContext,
  );

  return (
    <div className="flex w-full flex-col border-b-2 pt-6" ref={setNodeRef}>
      <p className="mb-4 px-6">
        <ReactMarkdown remarkPlugins={[remarkGfm]} className="prose text-base">
          {text}
        </ReactMarkdown>
      </p>
      <div className="flex items-center justify-between gap-2 px-2 pb-2">
        <Button
          {...listeners}
          {...attributes}
          size="sm"
          dense
          ref={setActivatorNodeRef}
          prependIcon={GripVertical}
          text="Drag and drop"
          variant="ghost"
          onClick={(e) => {
            e.preventDefault();
          }}
        />
        <div className="flex items-center gap-2">
          {isLastAIMessage && (
            <IconButton
              icon={RefreshCcw}
              onClick={() => {
                handleRefreshMessage(id);
              }}
            />
          )}

          <Button
            prependIcon={ArrowRightLeft}
            text="Replace"
            variant="ghost"
            dense
            size="sm"
            onClick={() => handleReplaceText(text)}
          />
          <Button
            prependIcon={PlusSquare}
            text="Insert"
            variant="ghost"
            dense
            size="sm"
            onClick={() => handleInsertText(text)}
          />
        </div>
      </div>
    </div>
  );
}
