import { Dialog } from '@/Components/Dialog';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { Select } from '@/Components/v2/Select';
import { TextArea } from '@/Components/v2/TextArea/TextArea';
import { ElementKey } from '../Template';
import {
  DocumentElementResource,
  DocumentResource,
} from '@/api/openapiSchemas';
import { TestPromptSection } from './TestPromptSection';

type Props = {
  keyElement: ElementKey;
  title: string;
  isOpen: boolean;
  onClose: () => void;
  options: (
    | {
        value: number;
        title: string;
      }
    | {
        group: string;
      }
  )[];
  onChangeSelect: (id: string | undefined, keyElement: ElementKey) => void;
  templateElements:
    | (DocumentElementResource & {
        uniqueId: string;
      })
    | null;
  onChangePrompt: (value: string, keyElement: ElementKey) => void;
  testPromptDialog: {
    document?: DocumentResource;
    uniqueId: string;
    preview: string;
  };
  onAddPreview: (preview: string) => void;
  onCloseDialog: () => void;
  onOpenDialog: (uniqueId: string) => void;
  onAddDocumentId: (document?: DocumentResource) => void;
};

export const TemplateMetaFieldDialog = ({
  title,
  isOpen,
  onClose,
  options,
  onChangeSelect,
  keyElement,
  templateElements,
  onChangePrompt,
  onAddDocumentId,
  onAddPreview,
  testPromptDialog,
  onCloseDialog,
}: Props) => {
  return (
    <Dialog
      dense
      title={
        <div className="mt-5 flex w-full items-center gap-2 px-7 pr-4 text-lg font-bold">
          {title}
        </div>
      }
      isOpen={isOpen}
      handleClose={() => {
        onClose();
        onCloseDialog();
      }}
    >
      <div className="bg-primary-50">
        <div className="mt-5 border-b bg-white px-7">
          <div className="mb-8">
            <InputDecoration label="Prompt Template">
              <div className="mt-2">
                <Select
                  clearable
                  options={options}
                  onChange={(id) => onChangeSelect(id, keyElement)}
                  value={templateElements?.id}
                  placeholder="Select a template"
                />
              </div>
            </InputDecoration>
          </div>
          {templateElements && (
            <div className="pb-12">
              <InputDecoration label="Prompt">
                <div className="mt-2">
                  <TextArea
                    rows={12}
                    minHeight={5}
                    maxHeight={20}
                    resize
                    placeholder="Write your prompt instructions here"
                    value={
                      templateElements.values?.prompt_instruction ??
                      templateElements.prompt
                    }
                    onChange={(value) => {
                      onChangePrompt(value, keyElement);
                    }}
                  />
                </div>
              </InputDecoration>
            </div>
          )}
        </div>
        <TestPromptSection
          el={templateElements!}
          testPromptDialog={testPromptDialog}
          onAddDocumentId={onAddDocumentId}
          onAddPreview={onAddPreview}
        />
      </div>
    </Dialog>
  );
};
