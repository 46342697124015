/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
export const getNestedProperty = (obj: any, path: string): any => {
  return path
    .split('.')
    .reduce((o, key) => (o && o[key] !== undefined ? o[key] : undefined), obj);
};

export const isPromise = (obj: any): obj is Promise<any> => {
  return obj && typeof obj.then === 'function';
};

export const isAsyncFunction = (obj: any): obj is () => Promise<any> => {
  return obj && obj.constructor.name === 'AsyncFunction';
};
