import { Dialog } from '@/Components/Dialog';
import { Button } from '@/Components/v2/Button';
import { Alert } from '@/Components/v2/Alert';
import { GoogleDocsStatusSettings } from '@/types';

type Props = {
  statusSettings: GoogleDocsStatusSettings;
  handleCloseGoogleDocsStatusDialog: () => void;
  handleExportDocument: () => void;
  handleTryAgain: () => void;
};

export const GoogleDocsStatusDialog = ({
  statusSettings,
  handleCloseGoogleDocsStatusDialog,
  handleExportDocument,
  handleTryAgain,
}: Props) => {
  return (
    <Dialog
      title={'Google Docs'}
      isOpen={statusSettings.isOpen}
      size={'sm'}
      handleClose={handleCloseGoogleDocsStatusDialog}
    >
      {statusSettings.mode === 'success' && (
        <>
          <Alert body={'Connected successfully'} color={'green'} />
          <br />

          <Button
            text="Export"
            color={'secondary'}
            fullWidth
            onClick={() => {
              handleExportDocument();
              handleCloseGoogleDocsStatusDialog();
            }}
          />
        </>
      )}
      {statusSettings.mode === 'failed' && (
        <>
          <Alert body={'Failed to connect'} color={'red'} />
          <br />

          <Button
            text="Try again"
            color="secondary"
            fullWidth
            onClick={handleTryAgain}
          />
        </>
      )}
    </Dialog>
  );
};
