import { useState, useEffect } from 'react';
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import SimpleButton from '@/Components/SimpleButton';
import { useNavigate } from '@tanstack/react-router';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { tracking } from '@/Services/tracking/Tracking';
import { useOrganisationSubscribe } from '@/api/openapiComponents';
type Props = {
  organisationId: number;
  productId: number;
  hasPreviousSubscription: boolean;
  handleRefetchIntent: () => void;
};

export default function CheckoutForm({
  hasPreviousSubscription,
  organisationId,
  productId,
  handleRefetchIntent,
}: Props) {
  const appState = useAppStore();
  const navigate = useNavigate();
  const [isTrialEligible, setIsTrialEligible] = useState<boolean>(true);
  const {
    mutate: subscribeWithPaymentSetup,
    isPending: isLoadingSubscriptionWithPaymentSetup,
  } = useOrganisationSubscribe({
    onSuccess: () => {
      appState.refreshSubscription().then(() => {
        tracking.event('subscription_started');
        navigate({ to: '/onboarding/create-project' });
      });
    },
    onError: (data) => {
      if (data.status === 402) {
        setIsTrialEligible(false);
        setIsLoadingStripe(false);
      } else {
        setErrors(data);
      }
    },
  });

  const paymentElementOptions: any = {
    layout: {
      type: 'tabs',
      defaultCollapsed: false,
      radios: false,
      spacedAccordionItems: true,
    },
  };

  const [isLoadingStripe, setIsLoadingStripe] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});

  const [setupIntentId, setSetupIntentId] = useState<any>(null);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoadingStripe(true);

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: window.location.origin + '/checkout',
      },
      redirect: 'if_required',
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      setErrors(result.error);
      setIsLoadingStripe(false);
    } else {
      setSetupIntentId(result?.setupIntent.id);
      await handleSuccessfulIntent(
        result?.setupIntent.id,
        hasPreviousSubscription,
      );
    }
  };

  const urlParams = new URLSearchParams(window.location.search);

  const handleSuccessfulIntent = async (
    setupIntent: any,
    skipTrial: boolean,
  ) => {
    if (!setupIntent) {
      setupIntent = setupIntentId;
    }
    subscribeWithPaymentSetup({
      pathParams: {
        organisation: organisationId,
      },
      body: {
        product_id: productId,
        setup_intent: setupIntent,
        skip_trial: skipTrial,
      },
    });
  };

  useEffect(() => {
    if (urlParams.get('redirect_status') === 'succeeded') {
      const setupIntentIdTmp = urlParams.get('setup_intent');
      setSetupIntentId(setupIntentIdTmp);
      handleSuccessfulIntent(setupIntentIdTmp, hasPreviousSubscription);
    }
  }, [urlParams.get('redirect_status')]);

  return (
    <div>
      {isTrialEligible ? (
        <form onSubmit={handleSubmit}>
          <PaymentElement options={paymentElementOptions} />

          <SimpleButton
            type={'submit'}
            disabled={!stripe}
            isLoading={isLoadingStripe}
            className={
              'mt-5 w-full justify-center bg-black text-white hover:bg-black hover:opacity-75'
            }
          >
            {hasPreviousSubscription ? 'Pay and subscribe' : 'Start trial'}
          </SimpleButton>

          <div
            className={
              'mt-5 text-red-500' +
              (errors?.message === 'Your card number is incomplete.'
                ? 'hidden'
                : '')
            }
          >
            {errors?.message}
          </div>
        </form>
      ) : (
        <div>
          <div className={' text-red-500'}>
            Your credit card has previously been used on a trial. Do you wish to
            continue with a paid subscription?
          </div>

          <SimpleButton
            type={'button'}
            onClick={() => handleSuccessfulIntent(null, true)}
            isLoading={isLoadingSubscriptionWithPaymentSetup}
            className={
              'mt-5 w-full justify-center bg-black text-white hover:bg-black hover:opacity-75'
            }
          >
            Pay and subscribe
          </SimpleButton>
          <SimpleButton
            type={'button'}
            onClick={handleRefetchIntent}
            className={'mt-2 w-full justify-center'}
          >
            Try another card
          </SimpleButton>

          <div
            className={'mt-5 text-red-500' + (errors?.message ? '' : 'hidden')}
          >
            {errors.message}
          </div>
        </div>
      )}
    </div>
  );
}
