import React, { useState } from 'react';
import { useNavigate } from '@tanstack/react-router';
import { Link } from '@tanstack/react-router';
import { useAppStore } from '../AppLoader/stores';
import mixPanelClient from 'mixpanel-browser';
import { Button } from '@/Components/v2/Button';
import { Input } from '@/Components/v2/Input/Input';
import { Errors } from '@/Components/Errors';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { SeoAiLogoBlack } from '@/Components/SVGs/SeoAiLogoBlack';
import { GoogleLogo } from '@/Components/GoogleLogo';
import { useGoogleAuth } from './hooks';
import { useAuthSignUp } from '@/api/openapiComponents';

type FormValues = {
  email: string;
};

export default function SignUp() {
  const navigate = useNavigate();
  const goToGoogeAuth = useGoogleAuth('REGISTER');
  const initialValues: FormValues = {
    email: '',
  };
  const appState = useAppStore();

  const [formValues, setFormValues] = useState(initialValues);

  const signUpMutation = useAuthSignUp();

  const onHandleChange = (value: string, name: string) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    signUpMutation.mutate(
      {
        body: {
          email: formValues.email,
          distinct_id: import.meta.env.VITE_MIXPANEL_TOKEN
            ? mixPanelClient.get_distinct_id()
            : undefined,
        },
      },
      {
        onError: (error) => {
          if (error.message === 'The email has already been taken.') {
            navigate({ to: '/login', search: { email: formValues.email } });
          }
        },
        onSuccess: () =>
          navigate({
            to: '/register/verify-your-email',
            search: { email: formValues.email },
          }),
      },
    );
  };

  appState.pageTitle('Create your account');

  return (
    <div className="flex h-screen flex-col items-center justify-between pt-2 text-gray-800">
      <SeoAiLogoBlack />

      <div className="flex flex-col items-center">
        <h2 className="mb-2 text-center font-work text-[25px] font-bold leading-normal ">
          Create your free account
        </h2>
        <p className="mb-2 ">Try SEO.AI free for 7 days.</p>

        <Errors error={signUpMutation.error} />

        <form onSubmit={submit} className="mt-2 flex w-96 flex-col">
          {/* Google sign in */}
          <Button
            fullWidth
            variant="outline"
            prependIcon={<GoogleLogo />}
            onClick={goToGoogeAuth}
            text="Sign up with Google"
          />

          {/* Divider */}
          <div className="my-8 flex w-full items-center">
            <div className="h-[1px] w-full bg-gray-200" />
            <p className="mx-2">or</p>
            <div className="h-[1px] w-full bg-gray-200" />
          </div>

          {/* Email input */}
          <div className="mb-6">
            <InputDecoration label="Email" required>
              <Input
                name="email"
                autoComplete="email"
                placeholder="name@company.com"
                value={formValues.email}
                onChange={(value) => onHandleChange(value, 'email')}
              />
            </InputDecoration>
          </div>

          {/* Submit */}
          <Button
            color="secondary"
            fullWidth
            type="submit"
            isLoading={signUpMutation.isPending}
            text="Sign up"
          />

          <div className="mt-5 flex items-center justify-center">
            <div className="mr-1 ">Already have an account?</div>
            <Link className=" underline" to="/login">
              Sign in here
            </Link>
            <div className="">.</div>
          </div>
        </form>
      </div>
      <div className="mb-4  text-gray-800">
        By continuing, you agree to our{' '}
        <a
          className="underline"
          href="https://seo.ai/terms"
          target="_blank"
          rel="noreferrer"
        >
          Terms and conditions
        </a>
      </div>
    </div>
  );
}
