import { ReactNode } from 'react';

type Props = {
  header?: string;
  before: ReactNode;
  after: ReactNode;
};

export const DiffRow = ({ after, before, header }: Props) => {
  return (
    <div className="flex border-b border-primary">
      <div className="flex-1 p-2">{header}</div>
      <div className="flex-[4] p-2">{before}</div>
      <div className="flex-[4] p-2">{after}</div>
    </div>
  );
};
