import { CustomFieldDefinitionResource } from '@/api/openapiSchemas';
import { DropZone } from '@/Components/DropZone';
import { useDroppable } from '@dnd-kit/core';

type Props = {
  customField: CustomFieldDefinitionResource;
  isActive: boolean;
  isFirst?: boolean;
};

export const CustomFieldDropZone = ({
  customField,
  isActive,
  isFirst,
}: Props) => {
  const { isOver, setNodeRef } = useDroppable({
    id: 'custom-field-reorder-dropzone ' + (isFirst ? 'start' : customField.id),
    data: { customField },
  });

  return (
    <DropZone
      ref={setNodeRef}
      isActive={isActive}
      isOver={isOver}
      renderContent={() => (
        <p className="text-primary">
          Drag here to move custom field to this position
        </p>
      )}
    />
  );
};
