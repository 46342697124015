import React from 'react';
import { Button, ButtonProps } from '../Button';
import { LucideProps } from 'lucide-react';

export type IconButtonProps = {
  text?: string;
  icon: React.ComponentType<LucideProps>;
} & Omit<
  ButtonProps,
  | 'title'
  | 'fullwidth'
  | 'prependIcon'
  | 'appendIcon'
  | 'alignment'
  | 'round'
  | 'fixedWidth'
>;

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ text, ...props }: IconButtonProps, ref) => {
    const { icon, variant = 'ghost', color = 'primary' } = props;

    const button = (
      <Button
        ref={ref}
        round
        prependIcon={icon}
        variant={variant}
        color={color}
        {...props}
      />
    );

    return text ? (
      <div className="flex flex-col items-center">
        {button}
        <p className="text-center text-xs">{text}</p>
      </div>
    ) : (
      button
    );
  },
);

IconButton.displayName = 'IconButton';

export { IconButton };
