import { FormEvent, useState } from 'react';
import { Dialog } from '@/Components/Dialog';
import { Input } from '@/Components/v2/Input/Input';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { Button } from '@/Components/v2/Button';
import { Errors } from '@/Components/Errors';
import { useProfileChangePassword } from '@/api/openapiComponents';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const ChangePasswordDialog = ({ isOpen, onClose }: Props) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatNewPassword, setRepeatNewPassword] = useState('');

  const [passwordMatchingError, setPasswordMatchingError] = useState(false);

  const handleClose = () => {
    setOldPassword('');
    setNewPassword('');
    setRepeatNewPassword('');
    changePasswordMutation.reset();
    onClose();
  };

  const changePasswordMutation = useProfileChangePassword({
    onSuccess: handleClose,
  });

  const handleChangePassword = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (newPassword !== repeatNewPassword) {
      setPasswordMatchingError(true);
      return;
    }
    changePasswordMutation.reset();
    setPasswordMatchingError(false);
    changePasswordMutation.mutate({
      body: {
        current_password: oldPassword,
        password: newPassword,
      },
    });
  };

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      title="Change password"
      size={'sm'}
    >
      <form className="flex flex-col gap-4" onSubmit={handleChangePassword}>
        <InputDecoration label="Old password" required>
          <Input
            type="password"
            placeholder="Old password"
            value={oldPassword}
            onChange={setOldPassword}
          />
        </InputDecoration>
        <InputDecoration label="New password" required>
          <Input
            type="password"
            placeholder="New password"
            value={newPassword}
            onChange={setNewPassword}
          />
        </InputDecoration>
        <InputDecoration label="Repeat new password" required>
          <Input
            type="password"
            placeholder="Repeat new password"
            value={repeatNewPassword}
            onChange={setRepeatNewPassword}
          />
        </InputDecoration>
        <Button
          color="secondary"
          text="Change password"
          isLoading={
            changePasswordMutation.isPending || changePasswordMutation.isSuccess
          }
          type="submit"
        />
        <Errors error={changePasswordMutation.error} />
        {passwordMatchingError && (
          <p className="text-center text-sm text-red-400">
            New password and repeated password does not match
          </p>
        )}
      </form>
    </Dialog>
  );
};
