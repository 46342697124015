import React from 'react';
import { PlateElement, PlateElementProps } from '@udecode/plate-common';
import { useFocused, useSelected } from 'slate-react';

import { cn } from '../lib/utils';
import { usePlateUiDrop } from '@/Hooks/usePlateUiDrop';
import { Icons } from './icons';
import { Divider } from '../components';
import { useMouseOverDraggableIcon } from '@/Hooks/useMouseOverDraggableIcon';

const HrElement = React.forwardRef<
  React.ElementRef<typeof PlateElement>,
  PlateElementProps
>(({ className, nodeProps, element, ...props }, ref) => {
  const { children } = props;
  const { attributes, listeners, setNodeRef, style } = usePlateUiDrop(element);
  const { invisiblity, ...methods } = useMouseOverDraggableIcon();

  const selected = useSelected();
  const focused = useFocused();

  return (
    <>
      <div
        {...methods}
        className="flex"
        ref={setNodeRef}
        {...attributes}
        {...listeners}
        style={style}
      >
        <div className={invisiblity}>
          <Icons.dragHandle className={`text-muted-foreground mt-4 h-4 w-4`} />
        </div>
        <PlateElement className="w-full" ref={ref} element={element} {...props}>
          <div className="py-6" contentEditable={false}>
            <hr
              {...nodeProps}
              className={cn(
                'h-0.5 cursor-pointer rounded-sm border-none bg-slate-100 bg-clip-content dark:bg-slate-800',
                selected &&
                  focused &&
                  'ring-2 ring-slate-950 ring-offset-2 dark:ring-slate-300',
                className,
              )}
            />
          </div>
          {children}
        </PlateElement>
      </div>
      <Divider id={element.id as string} />
    </>
  );
});
HrElement.displayName = 'HrElement';

export { HrElement };
