import { ActiveDraggableState } from '@/types';
import { createContext } from 'react';
import { PlateEditor as PlateEditorRef } from '@udecode/plate-common';

type DocumentActions = {
  handleInsertText: (markdownString: string) => void;
  handleReplaceText: (markdownString: string) => void;
}

export const DocumentActionContext = createContext<DocumentActions>(
  {} as DocumentActions,
);

export const EditorContext = createContext<null | PlateEditorRef>(null);

export const ActiveDraggableContext =
  createContext<null | ActiveDraggableState>(null);
export const EditorRefContext =
  createContext<null | React.RefObject<HTMLDivElement>>(null);
