import { useProjectTrackedKeywordDelete } from '@/api/openapiComponents';
import { ConfirmDialog } from '@/Components/ConfirmDialog';
import { useAppStore } from '@/Pages/AppLoader/stores';

type Props = {
  isOpen: boolean;
  keywords: { id: number; name: string }[];
  onClose: () => void;
  onSuccess: (keywords: { id: number; name: string }[]) => void;
};

export const DeleteKeywordsDialog = ({
  isOpen,
  keywords,
  onClose,
  onSuccess,
}: Props) => {
  const appState = useAppStore();
  const deleteKeywordsMutation = useProjectTrackedKeywordDelete();

  const handleDeleteKeyword = (keywords: { id: number; name: string }[]) => {
    deleteKeywordsMutation.mutate(
      {
        pathParams: {
          project: appState.currentProject!.id,
        },
        queryParams: {
          tracked_keyword_ids: keywords.map((keyword) => keyword.id),
        },
      },
      {
        onSuccess: () => onSuccess(keywords),
      },
    );
  };

  return (
    <ConfirmDialog
      isOpen={isOpen}
      isLoading={deleteKeywordsMutation.isPending}
      title="Delete tracking"
      confirmText="Delete"
      confirmColor="red"
      content={[
        `Are you sure you want to delete ${
          keywords.length < 5
            ? keywords
                .map((tracking) => `"${tracking.name}"`)
                .toString()
                .replaceAll(',', ', ')
            : `${keywords.length} items`
        } as ${keywords.length === 1 ? 'a' : ''} tracked keyword${
          keywords.length > 1 ? 's' : ''
        }?`,
      ]}
      onClose={onClose}
      onConfirm={() => handleDeleteKeyword(keywords)}
    />
  );
};
