import { SlideOver } from '@/Components/SlideOver';
import { DocumentResource } from '@/api/openapiSchemas';
import { ExternalLinkItem } from './components/ExternalLinkItem';

type Props = {
  document: DocumentResource;
  isOpen: boolean;
  onClose: () => void;
};

export const ExternalLinksSlideOver = ({
  document,
  isOpen,
  onClose,
}: Props) => {
  return (
    <SlideOver
      color="bg-lime-500"
      title="External sources and links"
      subHeading="Linking to reputable external sources can boost your contents SEO score."
      onClose={onClose}
      isOpen={isOpen}
    >
      {document.document_report?.is_generating ? (
        <>
          <div className="h-10 w-full animate-pulse rounded-lg bg-gray-100" />
        </>
      ) : document.document_report?.external_links.length === 0 ? (
        <p>No external links found on competitors</p>
      ) : (
        document.document_report?.external_links.map((link) => (
          <ExternalLinkItem url={link.link} />
        ))
      )}
    </SlideOver>
  );
};
