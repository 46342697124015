import { createRoute, redirect } from '@tanstack/react-router';
import { rootRoute } from '../Router';
import {
  guards,
  hasActiveSubscription,
  hasNoProject,
  hasNoSubscription,
  hasProject,
  isAuthenticated,
} from '../Router/guards';
import ChooseKeyword from './Onboarding/ChooseKeyword';
import ChooseTitle from './Onboarding/ChooseTitle';
import CreateProject from './Onboarding/CreateProject';
import Register from './Onboarding/Register';
import Login from './Login';
import SignUp from './SignUp';
import VerifyEmail from './Onboarding/VerifyEmail';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import { SignUpWidget } from './SignUpWidget';
import { Finalize } from './Finalize/Finalize';
import { LoginSearchParams } from '@/types';
import { Create1ClickDraft } from './Onboarding/Create1ClickDraft';

const verifyRoute = createRoute({
  getParentRoute: () => rootRoute,
  validateSearch: (search: Record<string, string>) => ({
    email: search.email,
  }),
  path: '/register/verify-your-email',
  component: () => {
    const params = verifyRoute.useSearch();
    return <VerifyEmail email={params.email} />;
  },
});

const resetPasswordRoute = createRoute({
  getParentRoute: () => rootRoute,
  validateSearch: (search: Record<string, string>) => ({
    email: search.email,
  }),
  path: '/reset-password/$token',
  component: () => {
    const params = resetPasswordRoute.useSearch();
    const pathParams = resetPasswordRoute.useParams();
    return <ResetPassword email={params.email} token={pathParams.token} />;
  },
});

export type LoginRouteParams = {
  email?: string;
  redirect?: string;
} & LoginSearchParams;

const loginRoute = createRoute({
  validateSearch: (search: Record<string, string>): LoginRouteParams => ({
    email: search.email as string | undefined,
    redirect: search.redirect as string | undefined,
    token: search.token as string | undefined,
  }),
  getParentRoute: () => rootRoute,
  beforeLoad: ({ context, search }) => {
    if (!search.token && context.isAuthenticated) {
      throw redirect({
        to: '/',
      });
    }
  },
  path: '/login',
  component: () => {
    const params = loginRoute.useSearch();
    return <Login email={params.email} />;
  },
});

export const routes = [
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasProject]);
    },
    path: '/onboarding/choose-keyword',
    component: () => <ChooseKeyword />,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasNoSubscription]);
    },
    path: '/finalize',
    component: () => <Finalize />,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasProject]);
    },
    path: '/onboarding/choose-title',
    component: () => <ChooseTitle />,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasActiveSubscription, hasNoProject]);
    },
    path: '/onboarding/create-project',
    component: () => <CreateProject />,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasActiveSubscription, hasProject]);
    },
    path: '/onboarding/create-draft',
    component: () => <Create1ClickDraft />,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ search }) => {
      if (!search) {
        throw redirect({
          to: '/login',
        });
      }
    },
    path: '/register/create-account',
    component: () => <Register />,
  }),
  createRoute({
    validateSearch: (search: Record<string, string>): LoginSearchParams => {
      return {
        token: search.token as string | undefined,
      };
    },
    getParentRoute: () => rootRoute,
    path: '/register',
    component: () => <SignUp />,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    path: '/signup/external',
    component: () => <SignUpWidget />,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    path: '/forgot-password',
    component: () => <ForgotPassword />,
  }),
  resetPasswordRoute,
  verifyRoute,
  loginRoute,
];
