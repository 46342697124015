import { Banner } from '../Banner';

import dayjs from 'dayjs';
import { Button } from '@/Components/v2/Button';
import { useOrganisationSubscriptionResume } from '@/api/openapiComponents';
import { useAppStore } from '@/Pages/AppLoader/stores';

export const ResumeSubscriptionBanner = () => {
  const appState = useAppStore();

  const resumeMutation = useOrganisationSubscriptionResume({
    onSuccess: () => appState.refreshSubscription(),
  });

  const handleResume = () => {
    resumeMutation.mutate({
      pathParams: {
        organisation: appState.organisation!.id,
      },
    });
  };

  return (
    <Banner
      isOpen={!!appState.subscription?.is_canceled}
      className="flex items-center gap-4 bg-amber-100 py-3"
    >
      <p>
        Your subscription is cancelled. You will lose access to the platform in{' '}
        <b>
          {dayjs(appState.subscription?.usage.period_end).diff(dayjs(), 'days')}{' '}
          days
        </b>
      </p>
      <Button
        color="white"
        text="REACTIVATE PLAN"
        size="sm"
        dense
        onClick={handleResume}
      />
    </Banner>
  );
};
