import { useEffect, useState } from 'react';
import { PushNotification } from './PushNotification';
import { tracking } from '@/Services/tracking/Tracking';
import { CardActions } from '../Card/components/CardActions';
import { CardHeader } from '../Card/components/CardTitle';
import { CardBody } from '../Card/components/CardBody';

const UpdatePushNotification = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => setIsOpen(false);

  useEffect(() => {
    const url = 'global';
    window.Echo.private(url).listen<{ newHash: string; oldHash: string }>(
      '.FrontendBundleChangedEvent',
      (e) => {
        if (e.newHash !== e.oldHash) {
          tracking.event('update_notification_displayed');
          setIsOpen(true);
        }
      },
    );

    return () => {
      window.Echo.leave(url);
    };
  }, []);

  return isOpen ? (
    <PushNotification onClose={handleClose}>
      <CardHeader title="New version available" />
      <CardBody>
        An updated version of the platform is available. Refresh your browser or
        click "Update" to see it.
      </CardBody>
      <CardActions
        actions={[
          {
            text: 'Later',
            onClick: handleClose,
            variant: 'ghost',
            color: 'primary',
          },
          {
            text: 'Update',
            onClick: () => {
              tracking.event('update_notification_updated_browser');
              window.location.reload();
            },
            color: 'green',
          },
        ]}
      />
    </PushNotification>
  ) : null;
};

export { UpdatePushNotification };
