import { useNavigate } from '@tanstack/react-router';
import { IconButton } from './v2/IconButton/IconButton';
import { PopoverMenu } from './v2/Menu/ButtonMenu';
import {
  ListMinus,
  ListPlus,
  MoreVertical,
  Plus,
  Stars,
  Trash2,
} from 'lucide-react';
import { ContentListResource } from '@/api/openapiSchemas';
import { ContentItem } from '@/types';

type Props = {
  isLoading?: boolean;
  selectedList: ContentListResource | undefined;
  onAdd: () => void;
  onDelete: () => void;
  item: ContentItem;
  onDeleteKeywords: (keywords: number[]) => void;
};

export const CreateContentMoreMenu = ({
  isLoading,
  selectedList,
  onAdd,
  onDelete,
  item,
  onDeleteKeywords,
}: Props) => {
  const navigate = useNavigate();
  return (
    <PopoverMenu
      trigger={
        <IconButton
          disabled={isLoading}
          isLoading={isLoading}
          color="gray"
          icon={MoreVertical}
        />
      }
      items={[
        {
          prependIcon: Plus,
          title: 'Create new Content',
          onClick: () => {
            navigate({
              to: '/create-content/blank',
              search: (prev) => ({ ...prev, keyword: item.keyword }),
            });
          },
        },
        {
          prependIcon: Stars,
          title: 'Create AI draft',
          onClick: () => {
            navigate({
              to: '/create-content/ai-generated',
              search: (prev) => ({ ...prev, keyword: item.keyword }),
            });
          },
        },
        {
          prependIcon: ListPlus,
          title: 'Add to list',
          onClick: onAdd,
        },
        ...(selectedList
          ? [
              {
                prependIcon: ListMinus,
                title: 'Remove from list',
                onClick: onDelete,
              },
            ]
          : []),
        {
          prependIcon: Trash2,
          title: 'Delete keyword',
          onClick: () => onDeleteKeywords([parseInt(item.id!)]),
        },
      ]}
    />
  );
};
