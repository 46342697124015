import { PopoverMenu } from '@/Components/v2/Menu/ButtonMenu';
import { Popover } from '@/Components/v2/Menu/Popover';
import { Check, CheckCircle, Stars, X } from 'lucide-react';

type ReportStatusElement = {
  name: string;
  progress:
    | {
        current: number;
        total: number;
      }
    | 'finished'
    | 'unfinished';
  showProgress?: boolean;
  onClick?: () => void;
};

type Props = {
  elements: ReportStatusElement[];
  onClick?: () => void;
};

const isElementFinished = (element: ReportStatusElement) =>
  element.progress === 'finished' ||
  (typeof element.progress === 'object' &&
    element.progress.current >= element.progress.total);

export const ReportStatusIndicator = ({ elements, onClick }: Props) => {
  const finishedCount = elements.filter(isElementFinished).length;
  const unfinishedCount = elements.length - finishedCount;
  const isFinished = unfinishedCount === 0;

  return (
    <PopoverMenu
      type="hover"
      trigger={
        <div onClick={onClick} className="sticky top-0 p-1">
          {isFinished ? (
            <div className="aspect-square rounded-full bg-green p-1">
              <Check className="text-white" size={18} />
            </div>
          ) : (
            <div className="relative">
              <Stars className="text-primary" />
              <div className="absolute -bottom-1 -right-1 h-4 w-4 rounded-full bg-red text-xs text-white">
                {unfinishedCount}
              </div>
            </div>
          )}
        </div>
      }
      items={elements.map((element) => ({
        title: (
          <div
            className={`flex items-center gap-1 ${
              isElementFinished(element) ? 'text-green' : 'text-red'
            }`}
          >
            {isElementFinished(element) ? (
              <Check className="text-green" />
            ) : (
              <X className="text-red" />
            )}
            {element.showProgress && typeof element.progress === 'object'
              ? `${element.progress.current}/${element.progress.total}`
              : ''}{' '}
            {element.name}
          </div>
        ),
        onClick: element.onClick,
      }))}
    />
  );
};
