import React from 'react';

type DividerProps = {
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  text?: string;
  onClick?: () => void;
};

export const Divider = ({
  text,
  onClick,
  leftIcon,
  rightIcon,
}: DividerProps) => {
  return (
    <div
      onClick={onClick}
      className={`w-full py-2 text-gray-600 ${onClick ? 'cursor-pointer' : ''}`}
    >
      {text ? (
        <div className="flex items-center">
          <div className="mt-px h-[2px] w-full bg-gray-200" />
          <div className="flex flex-shrink-0 items-center gap-2 whitespace-nowrap px-3  text-gray-600">
            {leftIcon}
            {text}
            {rightIcon}
          </div>
          <div className="mt-px h-[2px] w-full bg-gray-200" />
        </div>
      ) : (
        <div className="h-[2px] w-full bg-gray-200" />
      )}
    </div>
  );
};
