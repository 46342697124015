import { useEffect, useState } from 'react';
import { isGroup, isPrimitiveValue } from './utils';
import { ListItemProps } from '../Listitem';

export type DropdownItem = ListItemProps & {
  value?: any;
  persistent?: boolean;
};

export const useDropdownState = ({
  onChange,
  options,
  value,
  filterEnabled = false,
  preselect = false,
  externalSearchValue,
}: {
  filterEnabled?: boolean;
  value?: string | number | DropdownItem;
  options: (DropdownItem | { group: string })[];
  onChange: (value: string, item?: DropdownItem) => void;
  preselect?: boolean;
  externalSearchValue?: string;
}) => {
  const [filter, setFilter] = useState('');
  const filteredOptions = options.filter(
    (item) =>
      isGroup(item) ||
      ('' + (item.title ?? item.value ?? ''))
        .toLowerCase()
        .includes((externalSearchValue ?? filter).toLowerCase()) ||
      !filterEnabled ||
      item.persistent,
  );

  const valueIndex = filteredOptions.findIndex(
    (item) =>
      !isGroup(item) &&
      (isPrimitiveValue(value)
        ? item.value === value
        : item.value === value?.value),
  );

  const [isOpen, setIsOpen] = useState(false);
  const [selectionIndex, setSelectionIndex] = useState<number | undefined>(
    valueIndex === -1 ? undefined : valueIndex,
  );

  const longestOption = (
    options.filter((item) => !isGroup(item)) as { value: string }[]
  ).reduce<DropdownItem | undefined>(
    (acc, option) =>
      (acc?.value?.length ?? 0) > option.value?.length ? acc : option,
    undefined,
  );

  const handleKeydown = (e: React.KeyboardEvent) => {
    e.preventDefault();

    if (!isOpen && (e.key === 'ArrowDown' || e.key === 'ArrowUp')) {
      setIsOpen(true);
    }

    if (e.key === 'ArrowDown') {
      if (
        selectionIndex === undefined ||
        selectionIndex === filteredOptions.length - 1
      ) {
        setSelectionIndex(isGroup(filteredOptions[0]) ? 1 : 0);
      } else {
        setSelectionIndex(
          Math.min(
            selectionIndex +
              (isGroup(filteredOptions[selectionIndex + 1]) ? 2 : 1),
            filteredOptions.length - 1,
          ),
        );
      }
      return;
    }

    if (e.key === 'ArrowUp') {
      if (
        selectionIndex === undefined ||
        selectionIndex === 0 ||
        (isGroup(filteredOptions[0]) && selectionIndex === 1)
      ) {
        setSelectionIndex(filteredOptions.length - 1);
      } else {
        setSelectionIndex(
          Math.max(
            selectionIndex -
              (isGroup(filteredOptions[selectionIndex - 1]) ? 2 : 1),
            0,
          ),
        );
      }
      return;
    }

    if (e.key === 'Enter' || e.code === 'Space') {
      if (selectionIndex !== undefined && isOpen) {
        onChange(
          isPrimitiveValue(value)
            ? filteredOptions[selectionIndex].value
            : filteredOptions[selectionIndex],
          filteredOptions[selectionIndex] as DropdownItem,
        );
        setIsOpen(false);
      }
      return;
    }

    if (e.key === 'Escape') {
      setIsOpen(false);
      return;
    }
  };

  useEffect(() => {
    if (filterEnabled) {
      setFilter(
        (isPrimitiveValue(value)
          ? (options.find((option) => option.value === value)?.title ?? value)
          : value?.title) ?? '',
      );
    }
  }, [value]);

  useEffect(() => {
    if (preselect) {
      const foundIndex = filteredOptions.findIndex((item) => !isGroup(item));
      setSelectionIndex(foundIndex === -1 ? 0 : foundIndex);
    }
  }, [filter, externalSearchValue]);

  useEffect(() => {
    if (!isOpen || !filterEnabled) {
      setSelectionIndex(valueIndex === -1 ? undefined : valueIndex);
    }
  }, [isOpen]);

  return {
    isOpen,
    setIsOpen,
    filter,
    setFilter,
    selectionIndex,
    filteredOptions,
    longestOption,
    handleKeydown,
    option: (isPrimitiveValue(value)
      ? options.find((option) => !isGroup(option) && option.value === value)
      : value) as DropdownItem | undefined,
  };
};
