import SimpleButton from '@/Components/SimpleButton';
import { Stars } from 'lucide-react';
import { PlusSquare } from 'lucide-react';

type Props = {
  question: string;
  onInsert: () => void;
  onAnswer: () => void;
};

export const QuestionItem = ({ question, onAnswer, onInsert }: Props) => {
  return (
    <div className="mb-4 animate-fade-slow rounded-lg border border-gray-300 p-4">
      <p className="mb-2 font-extrabold">{question}</p>
      <div className="flex w-full justify-end gap-4">
        {/* Buttons */}
        <SimpleButton onClick={onInsert} className="p-1">
          INSERT
          <PlusSquare />
        </SimpleButton>
        <SimpleButton onClick={onAnswer} className="p-1">
          ANSWER
          <Stars />
        </SimpleButton>
      </div>
    </div>
  );
};
