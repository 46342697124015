import { SlideOverContentBox } from '@/Components/SlideOver/components';
import { twMerge } from 'tailwind-merge';
import { SuggestedActions } from '../../InternalLinkingSlideOver/components/SuggestionActions';
import { useKeywordInjectionStore } from '@/Pages/Document/stores';
import { useShallow } from 'zustand/react/shallow';
import { useEditor } from '@/Components/Utils/v2/api';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import {
  convertMarkdownToHTML,
  getAnchorAndFocusPosForSentence,
  getInitialPlateJSValue,
} from '@/Pages/Document/utils';
import AcceptedAnimation from '../../InternalLinkingSlideOver/components/AcceptedAnimation';
import { useGetEditorFocusElement } from '@/Pages/Document/hooks';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { tracking } from '@/Services/tracking/Tracking';
import { DocumentResource } from '@/api/openapiSchemas';
import { RejectedAnimation } from '../../InternalLinkingSlideOver/components/RejectedAnimation';
type Props = {
  document: DocumentResource;
};
const SuggestionUpdate = ({ document }: Props) => {
  const editorFocusRef = useGetEditorFocusElement();
  const {
    keyword,
    handleHoverFromTab,
    keywordSuggestions,
    removeKeywordSuggestion,
    handleRejectSuggestion,
    handleAcceptSuggestion,
    keywordType,
  } = useKeywordInjectionStore(
    useShallow((state) => ({
      keyword: state.keyword,
      handleHoverFromTab: state.handleHoverFromTab,
      keywordSuggestions: state.keywordSuggestions,
      removeKeywordSuggestion: state.removeKeywordSuggestion,
      handleAcceptSuggestion: state.handleAcceptSuggestion,
      handleRejectSuggestion: state.handleRejectSuggestion,
      handleRejectNewSuggestion: state.handleRejectNewSuggestion,
      keywordType: state.keywordType,
    })),
  );
  const editor = useEditor();
  const app = useAppStore();

  const getMarkdownText = (markdownText: string) => {
    const regex = new RegExp(`(?<!\\w)(${keyword})(?!\\w)`, 'gi');
    return markdownText.replaceAll(
      regex,
      '<span class="bg-green-200">$1</span>',
    );
  };
  return (
    <>
      {keywordSuggestions.map((keywordSuggestion) => (
        <div
          key={
            keywordSuggestion.text +
            keywordSuggestion.newMarkdownText +
            keywordSuggestion.markdownText
          }
          ref={(e) => {
            keywordSuggestion.hoveredFromEditor &&
              e?.scrollIntoView({ behavior: 'instant', block: 'center' });
          }}
          className={twMerge(
            `relative mb-6 rounded border-2 pb-4 pt-0 text-sm hover:border-emerald-500`,
            keywordSuggestion.hoveredFromEditor ? 'border-emerald-500' : '',
          )}
          onMouseEnter={() => {
            if (
              editor &&
              !keywordSuggestion.accepted &&
              !keywordSuggestion.rejected
            ) {
              handleHoverFromTab(keywordSuggestion, true);
              const positions = getAnchorAndFocusPosForSentence(
                editor,
                keywordSuggestion.text,
              );

              editorFocusRef.focus();

              editor?.select({
                anchor: positions.focus!,
                focus: positions.focus!,
              });
            }
          }}
          onMouseLeave={() => {
            handleHoverFromTab(keywordSuggestion, false);
            editor?.deselect();
          }}
        >
          {keywordSuggestion.accepted && (
            <AcceptedAnimation accepted={keywordSuggestion.accepted} />
          )}
          {keywordSuggestion.rejected && <RejectedAnimation rejected />}
          <div className="px-4">
            <SlideOverContentBox title="CURRENT">
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                className="prose leading-tight"
              >
                {keywordSuggestion.markdownText}
              </ReactMarkdown>
            </SlideOverContentBox>
            <SlideOverContentBox title="SUGGESTED">
              <div
                className="prose leading-tight"
                dangerouslySetInnerHTML={{
                  __html: getMarkdownText(
                    convertMarkdownToHTML(keywordSuggestion.newMarkdownText),
                  ),
                }}
              />
            </SlideOverContentBox>
          </div>
          <SuggestedActions
            onAccept={() => {
              if (editor) {
                const positions = getAnchorAndFocusPosForSentence(
                  editor,
                  keywordSuggestion.text,
                );

                editor.select({
                  anchor: positions.anchor!,
                  focus: positions.focus!,
                });

                editor.delete();

                const nodes = getInitialPlateJSValue(
                  keywordSuggestion.newMarkdownText,
                ).filter((_, i, array) => i !== array.length - 1);

                editor.insertFragment(nodes);

                handleAcceptSuggestion(keywordSuggestion);
                tracking.event('keyword_injection_accepted', {
                  keyword: keyword,
                  document: {
                    id: document.id,
                    word_count:
                      document.document_report?.word_count.actual || 0,
                  },
                  is_new_paragraph: false,
                  language: app.currentProject?.language.language || 'en',
                  keyword_type: keywordType,
                });
              }
            }}
            onReject={() => {
              handleRejectSuggestion(keywordSuggestion);
              tracking.event('keyword_injection_rejected', {
                keyword: keyword,
                document: {
                  id: document.id,
                  word_count: document.document_report?.word_count.actual || 0,
                },
                is_new_paragraph: false,
                language: app.currentProject?.language.language || 'en',
                keyword_type: keywordType,
              });
            }}
            isDisabled={false}
          />
        </div>
      ))}
    </>
  );
};

SuggestionUpdate.displayName = 'SuggestionUpdate';

export { SuggestionUpdate };
