import { Menus } from '@/Components/Menus';
import PageContainer from '@/Components/PageContainer';
import SimpleTitle from '@/Components/SimpleTitle';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { Tabs } from '@/Components/v2/Tabs/Tabs';
import { useEffect, useState } from 'react';
import { CardSection } from '../components';
import { Card } from '@/Components/v2/Card';
import { CardBody } from '@/Components/v2/Card/components/CardBody';
import { SectionHeader } from '../components/SectionHeader';

const cards: {
  url: string;
  title: string;
  description: string;
}[] = [
  {
    title: 'Use the SEO Score to optimize your content',
    description:
      'Ensure new content will rank or optimize already existing content.',
    url: 'https://docs.seo.ai/a-3-how-can-i-get-the-maximum-seo-score',
  },
  {
    title: 'Build templates to scale your content production',
    description:
      'Pick from the template library or build your own custom templates.',
    url: 'https://docs.seo.ai/a-37-build-templates-to-scale-your-content-production',
  },
  {
    title: 'How to use the AI-chat inside the editor',
    description:
      'Use the chat assistant directly within documents to generate or optimize content.',
    url: 'https://docs.seo.ai/a-20-how-to-use-the-chat',
  },
  {
    title: 'Train the AI to write in your brand voice',
    description:
      'The custom brand voice feature ensures all AI writing stays on-brand.',
    url: 'https://docs.seo.ai/a-34-brand-voice',
  },
  {
    title: 'Let AI auto-insert missing keywords in content',
    description:
      'Automatically insert missing keywords to match top search results.',
    url: 'https://docs.seo.ai/a-33-keyword-insertion',
  },
  {
    title: 'Connect to Search Console to view keyword gaps',
    description: 'Learn how to fill the gaps with automated keyword insertion.',
    url: 'https://docs.seo.ai/a-36-keyword-gaps',
  },
];

export const GettingStarted = () => {
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    SavvyCal('init');
    SavvyCal('inline', { link: 'seo-ai/6c17d855', selector: '#booking-page' });
  }, []);

  return (
    <Menus>
      <PageContainer>
        <SimpleTitle>Getting started</SimpleTitle>
        <div className="mb-6 mt-12">
          <Tabs
            onChange={setTabIndex}
            value={tabIndex}
            tabs={[
              {
                text: 'Full intro (14 min)',
              },
              {
                text: 'Find gaps (3 min)',
              },
            ]}
          />
        </div>

        {tabIndex === 0 && (
          <div className="overflow-hidden rounded-lg border border-primary-200">
            <div className="relative aspect-[100/48.78892733564014]">
              <div className="h-full w-full">
                <SkeletonLoader height="full" />
              </div>
              <iframe
                className="absolute inset-0 h-full w-full bg-transparent"
                src="https://www.loom.com/embed/73e313220a134be2b734fbc159e2b53e?sid=b04de3c8-588f-46d6-97b9-b435a2b543e0?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"
                allowFullScreen
              />
            </div>
          </div>
        )}
        {tabIndex === 1 && (
          <div className="overflow-hidden rounded-lg border border-primary-200">
            <div className="relative aspect-[100/49.47976878612717]">
              <div className="h-full w-full">
                <SkeletonLoader height="full" />
              </div>
              <iframe
                className="absolute inset-0 h-full w-full bg-transparent"
                src="https://www.loom.com/embed/f6bea0cd98b244f688d1518092934d9a?sid=5a1353ce-6f8e-476f-a697-fa31e5d93fb8?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"
                allowFullScreen
              />
            </div>
          </div>
        )}
        <div className="mt-10">
          <CardSection
            title="Help guides & tutorials"
            gap={10}
            buttonProps={{
              variant: 'ghost',
              color: 'primary',
              text: 'See all articles',
              toolTip: { description: '', title: '' },
              onClick: () => window.open('https://docs.seo.ai/', '_blank'),
            }}
          >
            {cards.map((item, index) => (
              <Card
                key={item.title + index}
                color="primary"
                elevated
                onClick={() => {}}
              >
                <CardBody>
                  <a href={item.url} target="_blank" rel="noreferrer">
                    <p className="mb-4 mt-2 text-xl font-bold leading-tight">
                      {item.title}
                    </p>
                    <p className="mb-4">{item.description}</p>
                  </a>
                </CardBody>
              </Card>
            ))}
          </CardSection>
        </div>

        <div className="mt-16">
          <SectionHeader title="Book a 30 min demo" />
          <div id="booking-page" className="mt-10" />
        </div>
      </PageContainer>
    </Menus>
  );
};
