import { useAuthAppInitialize } from '@/api/openapiComponents';
import { useAppStore } from '../stores';
import { tracking } from '@/Services/tracking/Tracking';

export const useInitApp = () => {
  const initialize = useAppStore((state) => state.initialize);
  const { mutateAsync: initializeAppAsync } = useAuthAppInitialize({
    onError: () => {
      initialize(undefined);
    },
  });

  const handleInitiateApp = async () => {
    const data = await initializeAppAsync({
      queryParams: {
        project_id: localStorage.getItem('current_project') ?? undefined,
      },
    });
    if (data.data.current_project) {
      tracking.group(
        { id: data.data.current_project.id, type: 'project' },
        { name: data.data.current_project.name },
      );
    }
    tracking.group(
      { id: data.data.organisation.id, type: 'organisation' },
      { name: data.data.organisation.name },
    );
    await initialize(data.data);
    return (cb: () => void) => {
      cb();
    };
  };

  return handleInitiateApp;
};
