import {
  MARK_BOLD,
  MARK_ITALIC,
} from '@udecode/plate-basic-marks';
import {
  collapseSelection,
  focusEditor,
  toggleNodeType,
  useEditorReadOnly,
  useEditorState,
} from '@udecode/plate-common';
import { Icons } from './icons';
import {
  MarkToolbarButton,
  TurnIntoDropdownMenu,
  LinkToolbarButton,
  ToolbarButton,
} from '../components';
import { ELEMENT_BLOCKQUOTE } from '@udecode/plate-block-quote';
import { isInsideTableCell } from '../lib/utils';

export function FloatingToolbarButtons() {
  const readOnly = useEditorReadOnly();

  const editor = useEditorState();

  const toggleBlockQuote = () => {
    toggleNodeType(editor, { activeType: ELEMENT_BLOCKQUOTE });

    collapseSelection(editor);
    focusEditor(editor);
  };

  return (
    <>
      {!readOnly && (
        <>
          <TurnIntoDropdownMenu />

          <MarkToolbarButton nodeType={MARK_BOLD} tooltip="Bold (⌘+B)">
            <Icons.bold />
          </MarkToolbarButton>
          <MarkToolbarButton nodeType={MARK_ITALIC} tooltip="Italic (⌘+I)">
            <Icons.italic />
          </MarkToolbarButton>

          <ToolbarButton
            disabled={isInsideTableCell(editor)}
            tooltip="Blockquote (⌘+shift+.)"
            onClick={toggleBlockQuote}
          >
            <Icons.blockquote />
          </ToolbarButton>
          {/*
          <MarkToolbarButton nodeType={MARK_CODE} tooltip='Code (⌘+E)'>
            <Icons.code />
          </MarkToolbarButton>
          */}
        </>
      )}
      <LinkToolbarButton />
      {/*
        <MoreDropdownMenu />
      */}
    </>
  );
}
