import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { PopoverMenu } from '@/Components/v2/Menu/ButtonMenu';
import { MoreVertical, Plus } from 'lucide-react';

type Props = {
  suggestion: string;
  disabled?: boolean;
  type?: string;
  onMore: (value: string) => void;
  onElaborate: (value: string) => void;
  onParaphrase: (value: string) => void;
  onInsert: (value: string) => void;
};

export const OutlineSuggestionItem = ({
  suggestion,
  type,
  disabled,
  onElaborate,
  onInsert,
  onMore,
  onParaphrase,
}: Props) => {
  return (
    <div className="mb-2 flex w-full items-center gap-2 rounded border border-gray-200 p-1 text-gray-800">
      {/* TODO: add drag n drop */}
      {/* <GripVertical className="flex-shrink-0 text-gray-500" /> */}
      <IconButton
        icon={Plus}
        size="sm"
        onClick={() => onInsert(suggestion)}
        disabled={disabled}
      />
      {type && <p className="-ml-2 text-sm text-gray-400">{type}</p>}
      <p className="flex-grow  font-bold">{suggestion}</p>
      <PopoverMenu
        items={[
          {
            title: 'More like this',
            onClick: () => onMore(suggestion),
          },
          {
            title: 'Elaborate on topic',
            onClick: () => onElaborate(suggestion),
          },
          {
            title: 'Paraphrase',
            onClick: () => onParaphrase(suggestion),
          },
        ]}
        trigger={<IconButton icon={MoreVertical} size="sm" />}
      />
    </div>
  );
};
