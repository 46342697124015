import { ConfirmDialog } from '@/Components/ConfirmDialog';
import { Input } from '@/Components/v2/Input/Input';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { ContentListResource } from '@/api/openapiSchemas';
import { useState } from 'react';

type Props = {
  editedList: ContentListResource | undefined;
  onClose: () => void;
  handleSubmit: (name: string) => void;
  error: any;
};

export const EditListDialog = ({
  onClose,
  editedList,
  handleSubmit,
  error,
}: Props) => {
  const [name, setName] = useState(editedList?.name ?? '');

  return (
    <ConfirmDialog
      isOpen={!!editedList}
      title="Edit list"
      confirmText="Save"
      error={error}
      onClose={onClose}
      content={
        <>
          <InputDecoration label="Name" required>
            <Input value={name} onChange={setName} counter counterMax={32} />
          </InputDecoration>
        </>
      }
      onConfirm={() => handleSubmit(name)}
    />
  );
};
