
type Props = {
  task: string;
  completed: boolean;
}

export const TaskItem = ({ completed, task }: Props) => {
  return (
    <div className="flex w-full items-center justify-between text-sm text-gray-600">
      {task}
      <div className="mx-2 h-px flex-grow border-t border-dotted border-gray-400" />
      <div className="flex items-center gap-1">
        <div
          className={`h-4 w-4 rounded-full ${
            completed ? 'bg-emerald-400' : 'bg-gray-400'
          }`}
        />
      </div>
    </div>
  );
};
