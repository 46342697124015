import { useWindowSize } from '@/Hooks/useWindowSize';
import { convertRemToPixels } from '@/Pages/Document/utils';
import * as React from 'react';

const rowHeight = convertRemToPixels(1.5);
const initialRowHeight = convertRemToPixels(2);

export const useTextAreaResize = (text: string) => {
  const textAreaRef = React.useRef<HTMLTextAreaElement>(null);
  const bottomChatRef = React.useRef<HTMLDivElement>(null);

  const [heightForAnchor, setHeightForAnchor] = React.useState(
    bottomChatRef.current?.clientHeight,
  );

  const windowSize = useWindowSize();
  React.useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.rows = 1;
      textAreaRef.current.style.height = `${initialRowHeight}px`;
      const scrollHeight = textAreaRef.current.scrollHeight;
      const result = scrollHeight / rowHeight;

      if (result < 8) {
        textAreaRef.current.rows = result;
        textAreaRef.current.style.height =
          textAreaRef.current.scrollHeight + 'px';
      }
      if (result >= 8) {
        textAreaRef.current.rows = 7;
        textAreaRef.current.style.height = 7 * rowHeight + 'px';
      }
    }
    if (bottomChatRef.current) {
      if (bottomChatRef.current.offsetHeight !== heightForAnchor) {
        setHeightForAnchor(bottomChatRef.current.offsetHeight);
      }
    }
  }, [textAreaRef, text, windowSize.width, windowSize.height]);

  return { textAreaRef, bottomChatRef };
};
