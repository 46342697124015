import { SvgIcon, CustomSvgProps } from '../SvgIcon';

type LoadingSpinnerProps = Omit<CustomSvgProps, 'children'>;

export const LoadingSpinner = (props: LoadingSpinnerProps) => {
  return (
    <SvgIcon {...props}>
      <circle
        strokeDasharray="50.26548245743669 50.26548245743669"
        strokeWidth="8"
        r="32"
        cy="50"
        cx="50"
        fill="none"
        strokeLinecap="round"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          dur="1s"
          repeatCount="indefinite"
          keyTimes="0;1"
          values="0 50 50;360 50 50"
        ></animateTransform>
      </circle>
      <circle
        strokeDasharray="25.12831551628262 20.12831551628262"
        strokeLinecap="round"
        fill="none"
        strokeDashoffset="36.12832"
        strokeWidth="6"
        r="14"
        cy="49.29577"
        cx="49.71831"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          dur="1s"
          repeatCount="indefinite"
          keyTimes="0;1"
          values="0 50 50;-360 50 50"
        ></animateTransform>
      </circle>
    </SvgIcon>
  );
};
