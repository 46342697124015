import { TaskItem } from '@/Components/TaskItem';

type Props = {
  tasks: { task: string; completed: boolean }[];
};

export const TitleScoreBreakDown = ({ tasks }: Props) => {
  return (
    <div className="flex flex-col gap-1">
      {tasks.map((task) => (
        <TaskItem {...task} />
      ))}
    </div>
  );
};
