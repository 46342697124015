
type Props = {
  label?: string;
  checked: boolean;
  onChange?: (value: boolean) => void;
}

export function SimpleRadioButton({ checked, onChange, label }: Props) {
  return (
    <label className="relative flex cursor-pointer items-center gap-2 rounded-full p-2">
      <input
        type="radio"
        className="relative h-5 w-5 cursor-pointer appearance-none border text-emerald-500 transition-all before:absolute before:left-2/4 before:top-2/4 before:block before:h-12 before:w-12 before:-translate-x-2/4 before:-translate-y-2/4 before:rounded-full before:opacity-0 before:transition-opacity checked:border-emerald-500 checked:bg-emerald-500 checked:before:bg-emerald-500 hover:before:opacity-10 focus:bg-emerald-500 focus:ring-0 focus:ring-offset-0"
        onChange={onChange ? (e) => onChange(e.target.checked) : undefined}
        checked={checked}
      />
      {label}
    </label>
  );
}
