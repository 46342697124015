import { removeSpecialCharacters } from '@/Pages/Document/utils';
import { Button } from '@/Components/v2/Button';
import { PopoverMenu } from '@/Components/v2/Menu/ButtonMenu';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { MoreVertical } from 'lucide-react';

type Props = {
  keyword: string;
  value: string;
  source?: string;
  disabled?: boolean;
  position?: number;
  onClick?: (value: string) => void;
  onParaphrase: (value: string) => void;
  onMore: (value: string) => void;
};

export const MetadataSuggestionItem = ({
  keyword,
  value,
  onClick,
  onParaphrase,
  onMore,
  disabled,
  position,
  source,
}: Props) => {
  const words = value.split(' ').map((word) => ({
    value: word,
    bold:
      keyword
        .toLowerCase()
        .includes(removeSpecialCharacters(word.toLowerCase())) ||
      keyword.split(' ').some((keywordPart) => word.startsWith(keywordPart)),
  }));

  return (
    <div className="mb-4 animate-fade-slow rounded-lg border border-primary-300 p-4">
      <div className="flex items-center justify-between">
        <div>
          {source && (
            <a
              className="mb-2 text-sm hover:underline"
              href={source}
              target="_blank"
              rel="noreferrer"
            >
              {position && <span className="mr-1">#{position}</span>}
              {new URL(source).host.replace('www.', '')}
            </a>
          )}
          <p className="mt-1 flex-grow text-xs text-primary-500">
            {value.length} CHARACTERS
          </p>
        </div>
        <PopoverMenu
          trigger={<IconButton icon={MoreVertical} size="sm" />}
          items={[
            {
              disabled,
              title: 'More like this',
              onClick: () => onMore(value),
            },
            {
              disabled,
              title: 'Paraphrase',
              onClick: () => onParaphrase(value),
            },
          ]}
        />
      </div>

      <p className="my-2">
        {value.length > 0 ? (
          words.map((word) =>
            word.bold ? <b>{word.value + ' '}</b> : word.value + ' ',
          )
        ) : (
          <p className="italic text-primary-500">No meta description</p>
        )}
      </p>
      <div className="flex w-full justify-end">
        {/* Insert */}
        {onClick && value.length > 0 && (
          <Button
            text="Insert"
            onClick={() => onClick(value)}
            variant="ghost"
            color="primary"
            dense
            size="xs"
          />
        )}
      </div>
    </div>
  );
};
