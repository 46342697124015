import * as React from 'react';
import {
  getStringFromPixels,
  getStringInPixels,
} from '@/Components/SerpVisualizer/utils';
import { SerpVisualizerLogic } from '@/types';
import {
  useDocumentMetaDescriptionSuggestions,
  useGetDocument,
} from '@/api/openapiComponents';

export const useDocumentGenerationTitleController = (
  serp: SerpVisualizerLogic,
  documentId: number,
  projectId: number,
) => {
  const [mode, setMode] = React.useState<'Desktop' | 'Mobile'>('Desktop');
  const {
    refetch: fetchMetaDescriptionSuggestions,
    isFetching: isLoadingMetaDescriptionSuggestions,
  } = useDocumentMetaDescriptionSuggestions(
    {
      pathParams: {
        document: documentId,
        project: projectId,
      },
    },
    { enabled: false },
  );

  const {
    data: documentData,
    isLoading: isLoadingCompetitors,
    refetch,
  } = useGetDocument(
    {
      pathParams: {
        document: documentId,
        project: projectId,
      },
    },
    {
      staleTime: 10000,
    },
  );

  const limitTitle = 560;
  const limitDescription: 960 | 1060 = mode === 'Desktop' ? 960 : 1060;

  const [toggles, setToggles] = React.useState({
    position: 1,
    showCompetitors: true,
    showDate: false,
    heatMap: false,
    showMetaDescription: false,
  });

  const [illustration, setIllustration] = React.useState({
    title: serp.metaTitle,
    titleInPixels: getStringFromPixels(
      serp.metaTitle,
      limitTitle,
      '20px Arial,sans-serif',
    ),
    description: serp.metaDescription,
    descriptionInPixels: getStringFromPixels(
      serp.metaDescription,
      limitDescription,
      '14px Arial,sans-serif',
    ),
    titleWidth: getStringInPixels(serp.metaTitle, '20px Arial,sans-serif'),
    descriptionWidth: getStringInPixels(
      serp.metaDescription,
      '14px Arial,sans-serif',
    ),
  });

  const handleInsertTitle = (title: string) => {
    const pixels = getStringInPixels(title, '20px Arial,sans-serif');
    setIllustration((prev) => ({
      ...prev,
      title: title,
      titleInPixels: getStringFromPixels(
        title,
        limitTitle,
        '20px Arial,sans-serif',
      ),
      titleWidth: pixels,
    }));
  };

  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const pixels = getStringInPixels(e.target.value, '20px Arial,sans-serif');
    setIllustration((prev) => ({
      ...prev,
      title: e.target.value,
      titleInPixels: getStringFromPixels(
        e.target.value,
        limitTitle,
        '20px Arial,sans-serif',
      ),
      titleWidth: pixels,
    }));
  };

  const changeDescription = (value: string) => {
    const pixels = getStringInPixels(value, '14px Arial,sans-serif');
    setIllustration((prev) => ({
      ...prev,
      description: value,
      descriptionInPixels: getStringFromPixels(
        value,
        limitDescription,
        '14px Arial,sans-serif',
      ),
      descriptionWidth: pixels,
    }));
  };

  const handleChangeDescription = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    changeDescription(e.target.value);
  };

  const handleGenerateDescription = () => {
    fetchMetaDescriptionSuggestions().then((res) => {
      if (res.status === 'success') {
        const data = res.data.data;
        const randomSuggestions = data[Math.floor(Math.random() * data.length)];
        changeDescription(randomSuggestions.content);
      }
    });
  };

  const handleChangeMode = (mode: 'Desktop' | 'Mobile') => {
    setIllustration((prev) => ({
      ...prev,
      descriptionInPixels: getStringFromPixels(
        prev.description,
        mode === 'Desktop' ? 960 : 1060,
        '14px Arial,sans-serif',
      ),
    }));
    setMode(mode);
  };

  const handleChangePosition = (e: React.ChangeEvent<HTMLInputElement>) => {
    const position = parseInt(e.target.value);
    if (
      documentData?.data.document_report &&
      position > 0 &&
      position <= documentData.data.document_report.competitors.length
    ) {
      setToggles((prev) => ({ ...prev, position: position }));
    }
  };

  return {
    illustration,
    handleChangeDescription,
    handleChangeTitle,
    toggles,
    setToggles,
    handleGenerateDescription,
    isLoadingMetaDescriptionSuggestions,
    handleChangeMode,
    mode,
    handleChangePosition,
    data: documentData?.data,
    isLoadingCompetitors,
    refetch,
    handleInsertTitle,
  };
};
