import {
  KeywordResource,
  TrackedKeywordUpdateResultResource,
} from '@/api/openapiSchemas';
import { SlideOver } from '@/Components/SlideOver';
import * as Table from '@/Components/Table';
import { Tooltip } from '@/Components/v2/Tooltip';
import { TaskDot } from '@/Pages/ContentScanner/components/TaskDot';

type Props = {
  keyword?: KeywordResource;
  results?: TrackedKeywordUpdateResultResource[];
  isOpen: boolean;
  onClose: () => void;
};

export const UrlsSlideover = ({ isOpen, onClose, keyword, results }: Props) => {
  return (
    <SlideOver
      isOpen={isOpen}
      size="lg"
      onClickOutside={onClose}
      title={keyword?.name}
      titleColor="text-primary"
      onClose={onClose}
    >
      <Table.Root
        columns={[
          {
            heading: 'URL',
            render: (item) => (
              <a
                className="cursor-pointer hover:opacity-60"
                href={item.project_url?.url}
                target="_blank"
                rel="noreferrer"
              >
                {item.project_url?.path}
              </a>
            ),
          },
          {
            heading: 'Position',
            rightAlign: true,
            render: (item) => (
              <>
                {
                  results?.find((r) => {
                    return r.url === item.project_url?.url;
                  })?.position
                }
              </>
            ),
          },
          {
            heading: 'T',
            render: (item) => (
              <Tooltip title={item.project_url?.meta_title} side="top">
                <TaskDot dense completed={item.in_meta_title_count > 0} />
              </Tooltip>
            ),
          },
          {
            heading: 'H1',
            render: (item) => (
              <TaskDot dense completed={item.in_title_count > 0} />
            ),
          },
          {
            heading: 'H2',
            render: (item) => (
              <TaskDot dense completed={item.in_h2_count > 0} />
            ),
          },
          {
            heading: 'H3',
            render: (item) => (
              <TaskDot dense completed={item.in_h3_count > 0} />
            ),
          },
          {
            heading: 'P',
            render: (item) => (
              <TaskDot dense completed={item.in_text_count > 0} />
            ),
          },
          {
            heading: 'MD',
            render: (item) => (
              <TaskDot dense completed={item.in_meta_description_count > 0} />
            ),
          },
        ]}
        items={keyword?.keyword_data ?? []}
      ></Table.Root>
    </SlideOver>
  );
};
