import * as React from 'react';
import { ReportSectionHeader } from './ReportSectionHeader';

type Props = {
  isFirst?: boolean;
  title?: string;
  useToolTip?: boolean;
  children: React.ReactNode[] | React.ReactNode;
}
export function ReportSectionKeyword({
  children,
  title,
  isFirst,
  useToolTip,
}: Props) {
  return (
    <div className={`${!isFirst ? 'mt-12' : ''}`}>
      {title && <ReportSectionHeader title={title} useToolTip={useToolTip} />}
      <div className="mt-3 flex flex-wrap gap-2">{children}</div>
    </div>
  );
}
