'use client';

import React from 'react';
import { PlateElement, PlateElementProps } from '@udecode/plate-common';
import { cn } from '../lib/utils';
import { usePlateUiDrop } from '@/Hooks/usePlateUiDrop';
import { Icons } from './icons';
import { Divider } from './divider';
import { useMouseOverDraggableIcon } from '@/Hooks/useMouseOverDraggableIcon';

const BlockquoteElement = React.forwardRef<
  React.ElementRef<typeof PlateElement>,
  PlateElementProps
>(({ className, children, element, ...props }, ref) => {
  const { invisiblity, ...methods } = useMouseOverDraggableIcon();
  const { attributes, listeners, setNodeRef, style, setActivatorNodeRef } =
    usePlateUiDrop(element);
  return (
    <>
      <div className="flex" ref={setNodeRef} style={style} {...methods}>
        <div
          ref={setActivatorNodeRef}
          {...attributes}
          {...listeners}
          className={invisiblity}
        >
          <Icons.dragHandle className={`text-muted-foreground mt-2 h-4 w-4`} />
        </div>
        <PlateElement
          asChild
          ref={ref}
          className={cn(
            'my-1 border-l-2 border-green-600 pl-6 italic',
            className,
          )}
          element={element}
          {...props}
        >
          <blockquote className="my-2 w-full bg-gray-100 py-5 pr-2 text-lg leading-8">
            {children}
          </blockquote>
        </PlateElement>
      </div>
      <Divider id={element.id as string} />
    </>
  );
});
BlockquoteElement.displayName = 'BlockquoteElement';

export { BlockquoteElement };
