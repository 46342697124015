import AppLabel, { Variant } from '@/Components/Labels/AppLabel';
import { Tooltip } from '../v2/Tooltip';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

type Props = {
  difficulty?: number;
  variant?: Variant;
  isLoading?: boolean;
};

export default function DifficultyLabel({
  difficulty,
  variant,
  isLoading,
}: Props) {
  const getVariant = () => {
    if (!difficulty) {
      return {
        variant: variant ?? Variant.gray,
        text: 'Unknown',
      };
    }

    if (difficulty >= 66) {
      return {
        variant: variant ?? Variant.red,
        text: `Hard (${difficulty})`,
      };
    } else if (difficulty >= 33) {
      return {
        variant: variant ?? Variant.yellow,
        text: `Medium (${difficulty})`,
      };
    } else {
      return {
        variant: variant ?? Variant.green,
        text: `Easy (${difficulty})`,
      };
    }
  };

  return isLoading ? (
    <Tooltip description="The data is updating. This may take a while.">
      <AppLabel variant={getVariant().variant}>Loading</AppLabel>
    </Tooltip>
  ) : (
    <AppLabel variant={getVariant().variant}>{getVariant().text}</AppLabel>
  );
}
