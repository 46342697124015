import * as React from 'react';

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

export default function PageContainer({ children }: Props) {
  return (
    <div className="h-full w-full overflow-y-auto">
      <div className="mx-auto flex min-h-full max-w-screen-4xl flex-col px-6 pb-6 pt-6">
        {children}
      </div>
    </div>
  );
}
