import { Card } from '@/Components/v2/Card';
import { CardBody } from '@/Components/v2/Card/components/CardBody';
import { ProductResource } from '@/api/openapiSchemas';
import { CheckIcon } from 'lucide-react';

type Props = {
  isTrial?: boolean;
  product: ProductResource;
  isMostPopular?: boolean;
  isCurrent?: boolean;
  buttonPosition?: 'bottom' | 'top';
  button?: JSX.Element;
  features: (string | JSX.Element)[];
  price?: number;
};

export const ProductItem = ({
  price,
  product,
  isMostPopular,
  isCurrent,
  button,
  features,
  buttonPosition = 'top',
  isTrial,
}: Props) => {
  return (
    <div className={`${isMostPopular ? 'bg-secondary-400' : ''} rounded-xl`}>
      {isMostPopular && (
        <div className="flex h-8 items-center justify-center rounded-t-xl font-bold text-white">
          Most Popular
        </div>
      )}
      <Card color={isMostPopular ? 'secondary' : 'primary'} variant="fill">
        <CardBody>
          <div className="flex items-center justify-between gap-x-4">
            <h3
              className={`
            ${isMostPopular ? 'text-secondary-400' : 'text-gray-900'}
            'text-lg leading-8' font-semibold
            `}
            >
              {product.name?.toUpperCase()} {isTrial ? '(TRIAL)' : ''}
            </h3>
            <div
              className={`${
                isCurrent ? '' : 'invisible'
              } rounded-md bg-secondary-100 p-2 text-sm font-semibold text-secondary-400`}
            >
              CURRENT PLAN
            </div>
          </div>
          <p className="mt-6 flex items-baseline gap-x-1">
            <span className=" font-semibold leading-6 text-gray-600">$</span>
            <span className="text-4xl font-bold tracking-tight text-gray-900">
              {price ?? product.price}
            </span>
            <span className=" font-semibold leading-6 text-gray-600">
              / {product.interval}
            </span>
          </p>
          <div
            className={`mt-4 flex gap-6 xl:gap-8 ${
              buttonPosition === 'top' ? 'flex-col' : 'flex-col-reverse'
            }`}
          >
            <div className={'flex flex-col'}>{button}</div>
            <ul role="list" className="space-y-3  leading-5 text-gray-600">
              {features.map((feature, index) => (
                <li key={index} className="flex">
                  {feature}
                </li>
              ))}
            </ul>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
