import { SerpDebounceProps, SerpVisualizerLogic } from '@/types';

import { DocumentGenerationController } from '../DocumentGeneration/components/DocumentGenerationController';
import { useDocumentGenerationTitleController } from '../DocumentGeneration/hooks/useDocumentGenerationTitleController';
import { SerpSuggestionsOverview } from './components/SerpSuggestionOverview';
import { useDebounce } from '@/Hooks/useDebounce';

type Props = {
  documentId: number;
  projectId: number;
  domain: string;
  keyword: string;
  serp: SerpVisualizerLogic & SerpDebounceProps;
};

export default ({ documentId, projectId, domain, keyword, serp }: Props) => {
  const {
    handleChangeDescription,
    handleChangeTitle,
    illustration,
    toggles,
    setToggles,
    handleGenerateDescription,
    isLoadingMetaDescriptionSuggestions,
    handleChangeMode,
    mode,
    handleChangePosition,
    data,
    isLoadingCompetitors,
  } = useDocumentGenerationTitleController(
    { metaTitle: serp.metaTitle, metaDescription: serp.metaDescription },
    documentId,
    projectId,
  );

  useDebounce(() => {
    serp.setMetaTitle(illustration.title);
  }, [illustration.title]);

  useDebounce(() => {
    serp.setMetaDescription(illustration.description);
  }, [illustration.description]);

  return (
    <div className="mx-auto mt-10 flex w-[85vw] 2xl:w-[76rem]">
      <div className="w-1/3">
        <DocumentGenerationController
          data={data}
          handleChangeDescription={handleChangeDescription}
          handleChangeMode={handleChangeMode}
          setToggles={setToggles}
          mode={mode}
          handleChangePosition={handleChangePosition}
          toggles={toggles}
          isLoadingMetaDescriptionSuggestions={
            isLoadingMetaDescriptionSuggestions
          }
          handleGenerateDescription={handleGenerateDescription}
          illustration={illustration}
          handleChangeTitle={handleChangeTitle}
        />
      </div>
      <div className="flex flex-grow justify-center">
        <SerpSuggestionsOverview
          addMargin
          documentResource={data}
          domain={domain}
          keyword={keyword}
          illustration={illustration}
          isLoadingCompetitors={isLoadingCompetitors}
          mode={mode}
          toggles={toggles}
        />
      </div>
    </div>
  );
};
