import { DocumentElementResource } from '@/api/openapiSchemas';
import { DropZone } from '@/Components/DropZone';
import { useDroppable } from '@dnd-kit/core';

type Props = {
  noIcon?: boolean;
  el?: DocumentElementResource & {
    uniqueId: string;
  };
  isMoving?: boolean;
  activeElement: DocumentElementResource | null;
  noLine?: boolean;
};

export const ElementDropzone = ({
  noIcon,
  noLine,
  isMoving,
  el,
  activeElement,
}: Props) => {
  const { isOver, setNodeRef } = useDroppable({
    id: 'add-item-and-change-order-drop ' + el?.uniqueId ?? 'first',
    data: { el },
    disabled: noIcon && !isMoving,
  });

  return (
    <DropZone
      ref={setNodeRef}
      noLine={noLine}
      isActive={!!(activeElement && (!noIcon || isMoving))}
      isOver={isOver}
      renderContent={() => (
        <p className="text-primary">
          {isMoving
            ? 'Drag here to move element to this place'
            : 'Drag here to add element to this place'}
        </p>
      )}
    />
  );
};
