import { useMemo, useState } from 'react';
import { Pillar } from './components/Pillar';
import { Line } from './components/Line';
import { SlideOver } from '@/Components/SlideOver';
import { PlateEditor } from '@udecode/plate-core';
import { ExternalLink } from 'lucide-react';
import { SlideOverHeading } from '@/Components/SlideOverHeading';
import { TaskItem } from '@/Components/TaskItem';
import { DocumentResource } from '@/api/openapiSchemas';

type Props = {
  document: DocumentResource;
  isOpen: boolean;
  editor: PlateEditor | null;
  onClose: () => void;
};

export const DocumentLengthSlideOver = ({
  document,
  onClose,
  isOpen,
}: Props) => {
  const [highlightedCompetitor, setHighlightedCompetitor] = useState<
    number | null
  >(null);

  const isLoading = Boolean(document.document_report?.is_generating ?? true);

  const competitors = document.document_report?.competitors ?? [];

  const highestWordCount = competitors
    ? [...competitors, { word_count: document.word_count }].reduce(
        (prev, curr) => (prev < curr.word_count! ? curr.word_count! : prev),
        0,
      )
    : 0;

  const intervals = useMemo(() => {
    const values = [0];
    let interval = 0;
    while (interval < highestWordCount) {
      interval += Math.ceil(highestWordCount / 1000) * 100;
      values.push(interval);
    }
    return values;
  }, [highestWordCount]);

  const upperLimit = intervals[intervals.length - 1];

  return (
    <SlideOver
      isOpen={isOpen}
      color="bg-pink-500"
      onClose={onClose}
      title="Length"
      subHeading="Content should be similar in length to competitors' while remaining concise and informative."
    >
      {isLoading ? (
        <div className="w-full py-2">
          <div className="w-full animate-pulse rounded-md bg-gray-300">
            &nbsp;
          </div>
          <div className="mt-10 w-full animate-pulse rounded-md bg-gray-300">
            &nbsp;
          </div>
          <div className="mt-6 aspect-video w-full animate-pulse rounded-md bg-gray-300" />
        </div>
      ) : (
        <div className="w-full py-2">
          <TaskItem
            task={`Write at least ${Math.round(
              document.document_report?.word_count.target ?? 0,
            )} words`}
            completed={!!document.document_report?.word_count.is_completed}
          />

          <SlideOverHeading>COMPETITOR WORD COUNT</SlideOverHeading>

          {/* Chart */}
          <div className="py-6 text-sm">
            <div className="relative aspect-video w-full ">
              {/* Lines */}
              {intervals.map((interval) => (
                <Line value={interval} maxValue={upperLimit} />
              ))}
              {/* Recommended wordcount line */}
              <Line
                maxValue={upperLimit}
                value={document.document_report?.word_count.target ?? 0}
                highlighted
              />

              {/* Pillars */}
              <div className="w-[calc(100% - 3rem)] ml-12 flex h-full items-end justify-around">
                {competitors.map((competitor, index) => (
                  <Pillar
                    value={competitor.word_count}
                    maxValue={upperLimit}
                    label={index + 1}
                    onMouseEnter={() => setHighlightedCompetitor(index)}
                    onMouseLeave={() => setHighlightedCompetitor(null)}
                    className="bg-gray-300 text-gray-400"
                  />
                ))}
                {/* Your wordcount pillar */}
                <Pillar
                  value={Number(document.word_count)}
                  maxValue={upperLimit}
                  label={'YOU'}
                  className="bg-orange-300 text-orange-300"
                />
              </div>
            </div>
          </div>

          <div className="mb-8 mt-2 flex items-center text-sm text-gray-400">
            <div className="ml-6 mr-2 w-4 border-t-2 border-dotted border-emerald-400" />
            Recommended word count
          </div>

          {/* Competitor list */}
          {competitors?.map((competitor, index) => (
            <div
              className={`group mt-2 flex cursor-pointer items-center justify-between text-sm hover:text-gray-600 ${
                index === highlightedCompetitor
                  ? 'text-gray-600'
                  : 'text-gray-400'
              }`}
              onClick={() => window.open(competitor.url)}
            >
              <div className="flex flex-grow">
                <b>{index + 1}</b>:&nbsp;
                <div className="mb-2 flex flex-grow flex-col">
                  <div>{competitor.title}</div>
                  <div
                    className={`${
                      index === highlightedCompetitor
                        ? 'text-blue-600'
                        : 'text-blue-400'
                    } break-all text-[0.65rem] underline group-hover:text-blue-600`}
                  >
                    {competitor.url}
                  </div>{' '}
                </div>
              </div>
              <div className="flex flex-shrink-0 items-center">
                ({competitor.word_count}) &nbsp;
                <ExternalLink className="flex-shrink-0" size={14} />
              </div>
            </div>
          ))}
        </div>
      )}
    </SlideOver>
  );
};
