import { handleAuthRedirect } from '@/utils';
import { OpenapiContext } from './openapiContext';

export type ErrorWrapper<TError> =
  | (TError & { status: number })
  | { status: number | 'unknown'; payload: { [key: string]: any } };

export const SEO_AI_AUTH = 'seo-ai-auth';

const getAuthHeader = (): Record<string, string> =>
  localStorage.getItem(SEO_AI_AUTH)
    ? {
        Authorization: 'Bearer ' + localStorage.getItem(SEO_AI_AUTH),
      }
    : {};

export type OpenapiFetcherOptions<TBody, THeaders, TQueryParams, TPathParams> =
  {
    url: string;
    method: string;
    body?: TBody;
    headers?: THeaders;
    queryParams?: TQueryParams;
    pathParams?: TPathParams;
    signal?: AbortSignal;
  } & OpenapiContext['fetcherOptions'];

export async function openapiFetch<
  TData,
  TError,
  TBody extends {} | FormData | undefined | null,
  THeaders extends {},
  TQueryParams extends {},
  TPathParams extends {},
>({
  url,
  method,
  body,
  headers,
  pathParams,
  queryParams,
  signal,
}: OpenapiFetcherOptions<
  TBody,
  THeaders,
  TQueryParams,
  TPathParams
>): Promise<TData> {
  const requestHeaders: HeadersInit = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...headers, //TODO: insert token auth header here
    ...getAuthHeader(),
  };

  /**
   * As the fetch API is being used, when multipart/form-data is specified
   * the Content-Type header must be deleted so that the browser can set
   * the correct boundary.
   * https://developer.mozilla.org/en-US/docs/Web/API/FormData/Using_FormData_Objects#sending_files_using_a_formdata_object
   */
  if (
    requestHeaders['Content-Type'].toLowerCase().includes('multipart/form-data')
  ) {
    delete requestHeaders['Content-Type'];
  }

  const response = await window.fetch(
    `/api/v2${resolveUrl(url, queryParams, pathParams)}`,
    {
      signal,
      method: method.toUpperCase(),
      body: body
        ? body instanceof FormData
          ? body
          : JSON.stringify(body)
        : undefined,
      headers: requestHeaders,
    },
  );
  if (!response.ok) {
    handleAuthRedirect(response);
    let error: ErrorWrapper<TError>;
    try {
      error = await response.json();
      error.status = response.status;
    } catch (e) {
      error = {
        status: 'unknown' as const,
        payload: {
          message:
            e instanceof Error
              ? `Unexpected error (${e.message})`
              : 'Unexpected error',
        },
      };
    }

    throw error;
  }

  if (response.headers.get('content-type')?.includes('json')) {
    return await response.json();
  } else {
    // if it is not a json response, assume it is a blob and cast it to TData
    return (await response.blob()) as unknown as TData;
  }
}

const resolveUrl = (
  url: string,
  queryParams: Record<string, string> = {},
  pathParams: Record<string, string> = {},
) => {
  let query = getQueryParams(queryParams);
  if (query && query !== '') query = `?${query}`;
  return url.replace(/\{\w*\}/g, (key) => pathParams[key.slice(1, -1)]) + query;
};

export const getQueryParams = (node: any, path = '', root = true) => {
  let parts: string[] = [];

  if (typeof node === 'object') {
    for (const key of Object.keys(node).filter(
      (key) => node[key] !== undefined,
    )) {
      parts.push(
        getQueryParams(
          node[key],
          `${path}${
            root ? camelToSnakeCase(key) : `[${camelToSnakeCase(key)}]`
          }`,
          false,
        ),
      );
    }
    const value = parts.join('&');
    return typeof value === 'boolean'
      ? value
        ? 1
        : 0
      : value.endsWith('&')
        ? value.substring(0, value.length - 1)
        : value;
  } else if (typeof node === undefined) {
    return '';
  } else {
    return `${path}=${encodeURIComponent(
      typeof node === 'boolean' ? (node ? 1 : 0) : node,
    )}`;
  }
};

export const camelToSnakeCase = (str: string): string => {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};
