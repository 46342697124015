'use client';

import {
  useMarkToolbarButton,
  useMarkToolbarButtonState,
} from '@udecode/plate-common';

import { ToolbarButton, ToolbarButtonProps } from '../components';

export type MarkToolbarButtonProps = {
  nodeType: string;
  clear?: string | string[];
  disabled?: boolean;
} & Pick<ToolbarButtonProps, 'tooltip' | 'children'>

/**
 * Toolbar button to toggle the mark of the leaves in selection.
 */
export function MarkToolbarButton({
  clear,
  nodeType,
  ...props
}: MarkToolbarButtonProps) {
  const state = useMarkToolbarButtonState({ clear, nodeType });

  const { props: buttonProps } = useMarkToolbarButton(state);

  return <ToolbarButton {...buttonProps} {...props} />;
}
