import { useDebounce } from '@/Hooks/useDebounce';
import { ErrorHelper } from '@/Services/ErrorHandling';
import { useEffect, useRef, useState } from 'react';

type Props = {
  text: string;
  onChange: (text: string) => void;
  onDebounceChange: (text: string) => void | Promise<any>;
};

export const EditableText = ({
  text: propText,
  onDebounceChange,
  onChange,
}: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState('');
  const [error, setError] = useState<string>();

  const inputRef = useRef<HTMLInputElement>(null);
  const rootRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setText(propText);
  }, [propText]);

  useEffect(() => {
    if (isEditing) {
      if (error) {
        setError(undefined);
      }
      onChange(text);
    }
  }, [text]);

  useDebounce(
    () => {
      const func = async () => {
        if (isEditing) {
          try {
            await onDebounceChange(text);
          } catch (e) {
            const errorHelper = new ErrorHelper(e);
            setError(errorHelper.message());
          }
        }
      };

      func();
    },
    [text],
    500,
  );

  const handleClick = () => {
    if (!isEditing) {
      setIsEditing(true);
    }
    if (inputRef.current && !isEditing) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    const listener = (e: MouseEvent) => {
      if (!rootRef.current?.contains(e.target as HTMLDivElement) && isEditing) {
        setIsEditing(false);
      }
    };
    window.addEventListener('click', listener);
    return () => {
      window.removeEventListener('click', listener);
    };
  }, [rootRef, isEditing]);

  return (
    <div className="flex flex-col">
      <div
        ref={rootRef}
        className={`relative flex min-h-[2.5rem] w-full cursor-text flex-col items-start justify-center rounded-md px-1.5 py-1  ${
          error
            ? 'border border-red'
            : isEditing
              ? 'border border-green-400'
              : 'border border-gray-200 hover:bg-gray-100'
        }`}
        onClick={handleClick}
      >
        <div
          style={{ maxWidth: inputRef.current?.clientWidth }}
          className={`${isEditing ? 'hidden h-0 w-0' : 'w-full'} text-base`}
        >
          {text}
        </div>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            setIsEditing(false);
          }}
          className={`w-full ${isEditing ? '' : 'h-0'}`}
        >
          <input
            ref={inputRef}
            value={text}
            onChange={(e) => setText(e.target.value)}
            className={`border-0 border-transparent p-0 py-0 text-base focus:ring-0 ${
              !isEditing ? 'h-0 opacity-0' : 'w-full'
            }`}
          />
          <button type="submit" className="hidden" />
        </form>
      </div>
      {error && <div className="text-xs text-red">{error}</div>}
    </div>
  );
};
