import { Button, ButtonProps } from '@/Components/v2/Button';
import {
  IconButton,
  IconButtonProps,
} from '@/Components/v2/IconButton/IconButton';
import { Tooltip, TooltipProps } from '@/Components/v2/Tooltip';
import { formatThousandSeperator } from '@/utils';

type Props = {
  name: string;
  description?: string;
  allowMaxedOut?: boolean;
  action?: IconButtonProps & { tooltip?: Omit<TooltipProps, 'children'> };
  usage: {
    limit: string | number;
    used: string | number;
  };
};

export const UsageItem = ({
  usage,
  name,
  description,
  allowMaxedOut,
  action,
}: Props) => {
  const percentage = (Number(usage.used) / Number(usage.limit)) * 100;

  const isRed = allowMaxedOut ? usage.used > usage.limit : percentage > 90;

  return (
    <div className="my-2 flex w-full flex-col">
      <div className="flex w-full items-center justify-between pb-0.5">
        <div className="flex items-center">
          <span className="">{name}</span>
          &nbsp;
          {description && (
            <span className={'text-gray-400'}>
              • <span className="text-sm text-gray-500">{description}</span>
            </span>
          )}
          {action && (
            <Tooltip
              {...(action.tooltip ?? {})}
              disabled={!action.tooltip || action.tooltip.disabled}
            >
              <IconButton {...action} dense size="sm" />
            </Tooltip>
          )}
        </div>
        <div className={`${isRed ? 'text-red-500' : 'text-gray-500'}`}>
          {formatThousandSeperator(usage.used)}/
          {formatThousandSeperator(usage.limit)}
        </div>
      </div>
      <div className="h-4 w-full overflow-hidden rounded-full bg-gray-200 dark:bg-gray-700">
        <div
          className={`h-4 rounded-full ${
            isRed ? 'bg-red-400' : 'bg-secondary-400'
          }`}
          style={{
            width: `${Math.min(100, percentage)}%`,
          }}
        ></div>
      </div>
    </div>
  );
};
