import { PlaceHolder } from '@/types';
import { Button } from './v2/Button';
import { Input } from './v2/Input/Input';
import { Tabs } from './v2/Tabs/Tabs';
import { TextArea } from './v2/TextArea/TextArea';
import { Check } from 'lucide-react';
type Props = {
  brief: string;
  onBriefChange: (value: string) => void;
  briefFromUrl: string;
  onBriefFromUrlChange: (value: string) => void;
  url: string;
  onUrlChange: (value: string) => void;
  isLoading?: boolean;
  briefLimit: number;
  disabled?: boolean;
  isImportSuccess: boolean;
  selectedTab: string;
  onTabChange: (value: string) => void;
  onImport: () => void;
  placeholder: PlaceHolder;
  hideImportedContext?: boolean;
};

export const AdditionalContextForm = ({
  briefLimit,
  brief,
  briefFromUrl,
  onBriefChange,
  onBriefFromUrlChange,
  onUrlChange,
  url,
  isImportSuccess,
  isLoading,
  onImport,
  onTabChange,
  disabled,
  selectedTab,
  placeholder,
  hideImportedContext,
}: Props) => {
  const briefTabs = [
    {
      value: 'manual',
      text: 'Provide text',
    },
    {
      value: 'from-url',
      text: 'Import from url',
    },
  ];

  return (
    <div className="flex flex-col gap-4">
      <Tabs
        disabled={isLoading}
        value={briefTabs.findIndex((tab) => tab.value === selectedTab)}
        onChange={(value) =>
          onTabChange(briefTabs[value].value as 'manual' | 'from-url')
        }
        tabs={[
          {
            text: 'PROVIDE TEXT',
          },
          {
            text: 'IMPORT FROM URL',
          },
        ]}
      />
      {selectedTab === 'manual' && (
        <TextArea
          counter={brief.length}
          disabled={isLoading}
          counterMax={briefLimit}
          value={brief}
          onChange={onBriefChange}
          placeholder={'Provide background information for the AI.'}
        />
      )}
      {selectedTab === 'from-url' && (
        <>
          <div className="flex flex-col gap-6">
            <Input
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  onImport();
                }
              }}
              clearable
              fullWidth
              disabled={disabled || isLoading}
              value={url}
              onChange={onUrlChange}
              placeholder={placeholder.url}
            />

            <div className="flex items-center justify-end gap-2">
              {isImportSuccess && !isLoading && hideImportedContext && (
                <>
                  Imported
                  <Check className="text-secondary" />
                </>
              )}
              <Button
                color={!isImportSuccess ? 'secondary' : 'gray'}
                variant={isImportSuccess ? 'outline' : 'fill'}
                onClick={() => onImport()}
                isLoading={isLoading}
                text={
                  isImportSuccess && !hideImportedContext ? 'Retry' : 'Import'
                }
              />
            </div>
          </div>
          {isImportSuccess && !hideImportedContext && (
            <TextArea
              value={briefFromUrl}
              onChange={onBriefFromUrlChange}
              disabled={isLoading}
              counterMax={briefLimit}
              counter={briefFromUrl.length}
            />
          )}
        </>
      )}
    </div>
  );
};
