import SimpleInput from '@/Components/SimpleInput';
import { useContext, useState } from 'react';
import { PopoverContext } from '@/Components/SimplePopover';
import { Button } from '@/Components/v2/Button';

type Props = {
  name: string;
  value?: string;
  onAccept: (value: string) => void;
}

export const StringSelector = ({ value, onAccept, name }: Props) => {
  const [inputValue, setInputValue] = useState('' + (value ?? ''));

  const closePopover = useContext(PopoverContext);

  const handleAccept = () => {
    closePopover();
    onAccept(inputValue);
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleAccept();
      }}
    >
      <div className="flex justify-between gap-2 px-2 pb-2">
        <div className="flex flex-col text-sm">
          <p className="py-1">{name}</p>
          <SimpleInput
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            className={`-mt-2 h-6 w-full rounded-md px-2`}
          />
        </div>
      </div>
      <Button
        variant="ghost"
        fullWidth
        size="sm"
        dense
        type="submit"
        text="Accept"
      />
    </form>
  );
};
