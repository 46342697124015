import { UserResource } from '@/api/openapiSchemas';
import { Destination } from '../Destinations';

export const consoleDestination: Destination = {
  name: 'console',
  enabledByEnvKey: 'VITE_CONSOLE_ENABLED',
  initialize: () => {
    console.log('Initializing console tracking');
  },
  identify: (user: UserResource) => {
    console.log(`Identifying user ${user.id} for tracking`);
  },
  reset: () => {
    console.log('Resetting user tracking');
  },
  trackEvent: (event) => {
    console.log(event);
  },
};
