import { useOrganisationOrganisationCustomerPortalLink } from '@/api/openapiComponents';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

export const useOpenStripePage = (organisationId: number, key: string) => {
  const client = useQueryClient();
  const invoiceQuery = useOrganisationOrganisationCustomerPortalLink(
    {
      pathParams: {
        organisation: organisationId,
      },
      queryParams: {
        queryKey: key,
      } as any,
    },
    {
      enabled: false,
    },
  );

  const openStripePage = () => {
    invoiceQuery.refetch();
  };

  useEffect(() => {
    if (invoiceQuery.data && invoiceQuery.isFetched) {
      window.open(invoiceQuery.data.data.url);
      const queryKey =
        client.getQueryCache().find({
          predicate: (query) =>
            query.queryKey.includes(
              'organisationOrganisationCustomerPortalLink',
            ),
        })?.queryKey ?? [];

      client.resetQueries({
        queryKey,
      });
    }
  }, [invoiceQuery.data, invoiceQuery.isFetched]);

  return { openStripePage, isLoading: invoiceQuery.isFetching };
};
