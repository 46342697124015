import { PropsWithChildren } from 'react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

type TabItem = {
  name: string;
}

type Props = {
  className?: string;
  tabs: TabItem[];
  selectedTab: string;
  onChange: (value: string) => void;
} & PropsWithChildren

export default function Tabs({
  tabs,
  selectedTab,
  onChange,
  className,
  children,
}: Props) {
  return (
    <div className={className}>
      {children}
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          onChange={(e) => onChange(e.target.value)}
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10  focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:"
          value={tabs.find((tab) => tab.name === selectedTab)?.name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-2" aria-label="Tabs">
            {tabs.map((tab) => (
              <div
                key={tab.name}
                onClick={() => onChange(tab.name)}
                className={classNames(
                  tab.name === selectedTab
                    ? 'border-gray-500 text-gray-600'
                    : 'cursor-pointer border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'whitespace-nowrap border-b-2 px-1 py-2 text-sm font-medium uppercase',
                )}
                aria-current={tab.name === selectedTab ? 'page' : undefined}
              >
                {tab.name}
              </div>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}
