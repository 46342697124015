import { Input } from '@/Components/v2/Input/Input';
import { useSnackbar } from '@/Components/v2/Snackbar';
import { useDebounce } from '@/Hooks/useDebounce';
import { ErrorHelper } from '@/Services/ErrorHandling';
import { useProjectsUpdate } from '@/api/openapiComponents';
import { ProjectResource } from '@/api/openapiSchemas';

type Props = {
  project: ProjectResource;
  value: string;
  onChange: (value: string) => void;
};

export const ProjectCreditsInput = ({ onChange, value, project }: Props) => {
  const updateProjectMutation = useProjectsUpdate();
  const { showSnackbar } = useSnackbar();

  useDebounce(
    () => {
      if (!Number.isNaN(Number(value)) && Number(value) >= 0) {
        updateProjectMutation.mutate(
          {
            pathParams: {
              project: project.id,
            },
            body: {
              url_credits: Number(value),
            },
          },
          {
            onSuccess: () =>
              showSnackbar({ color: 'secondary', message: 'Credits updated' }),
          },
        );
      }
    },
    [value],
    400,
  );

  const errorHelper = new ErrorHelper(updateProjectMutation.error);

  return (
    <div className="w-20 overflow-hidden">
      <Input
        value={value}
        onChange={onChange}
        error={
          (Number.isNaN(Number(value)) || Number(value) < 0
            ? 'Invalid number'
            : undefined) ?? errorHelper.message()
        }
      />
    </div>
  );
};
