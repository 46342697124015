import { ContentItem } from '@/types';
import { CreateContentMoreMenu } from './CreateContentMoreMenu';
import { ContentListResource } from '@/api/openapiSchemas';

type Props = {
  item: ContentItem;
  selectedList: ContentListResource | undefined;
  onAdd: () => void;
  onDelete: () => void;
  onDeleteKeywords: (keywords: number[]) => void;
};

export default function ContentKeywordButtons(props: Props) {
  return (
    <div className="flex w-full items-center justify-end gap-2">
      <CreateContentMoreMenu {...props} />
    </div>
  );
}
