import { useEffect, useState } from 'react';
import { SimpleCheckbox } from '@/Components/SimpleCheckbox';
import { Column } from '../Table';
import { getNestedProperty, isAsyncFunction, isPromise } from '../util';
import {
  SkeletonLoader,
  SkeletonLoaderHeight,
} from '@/Components/v2/SkeletonLoader/SkeletonLoader';

export type SubRow<T, SR> = {
  columns: {
    rightAlign?: boolean;
    render: (item: SR, row: T) => JSX.Element;
    colSpan?: number;
  }[];
  rowCount?: (item: T) => number;
  rowLoaderHeight?: SkeletonLoaderHeight;
  rows: (item: T) => SR[] | undefined;
};

type Props<T, SR> = {
  item: T;
  renderRow?: (args: {
    item: T;
    cells: JSX.Element[];
    index: number;
    classNames: string;
  }) => JSX.Element;
  columns: Column<T>[];
  subRows?: SubRow<T, SR>;
  selectionEnabled?: boolean;
  selection?: T[];
  selectionIdentifyBy: string;
  handleSelect: (value: boolean, item: T) => void;
  rowIndex: number;
  dense: boolean;
  disabled?: boolean;
};

const TableRow = <T, SR>({
  selection,
  selectionEnabled,
  item,
  subRows,
  renderRow,
  handleSelect,
  selectionIdentifyBy,
  columns,
  rowIndex,
  dense,
  disabled,
}: Props<T, SR>) => {
  const [isSubrowsOpen, setIsSubrowsOpen] = useState(false);
  const subRowData = subRows?.rows(item);

  const classNames = `border-b border-gray-200 ${
    item.tableRowClassNames ?? ''
  }`;

  const cells = [
    ...(selectionEnabled
      ? [
          <td key={'select' + getNestedProperty(item, selectionIdentifyBy)}>
            <SimpleCheckbox
              disabled={disabled}
              checked={
                !!selection?.find(
                  (selectedItem) =>
                    getNestedProperty(selectedItem, selectionIdentifyBy) ===
                    getNestedProperty(item, selectionIdentifyBy),
                )
              }
              onChange={(value) => handleSelect(value, item)}
            />
          </td>,
        ]
      : []),
    ...columns.map((column, index) => (
      <td
        key={'' + index + getNestedProperty(item, selectionIdentifyBy)}
        className={`pl-3 pr-3 ${dense ? 'py-0' : 'py-2'}`}
        style={{ width: column.width ? `${column.width}rem` : 'auto' }}
      >
        {column.render ? (
          <div className={`${column.rightAlign ? ' flex justify-end' : ''}`}>
            {column.render(
              item,
              index,
              {
                toggleSubRows: () => setIsSubrowsOpen(!isSubrowsOpen),
                hasSubrows:
                  (subRows?.rowCount?.(item) ?? 0) > 0 ||
                  (subRowData?.length ?? 0) > 0,
                isSubrowsOpen,
                subrowCount:
                  subRows?.rowCount?.(item) ?? subRowData?.length ?? 0,
              },
              rowIndex,
            )}
          </div>
        ) : (
          <div className={`${column.rightAlign ? ' flex justify-end' : ''}`}>
            {column.property ? (item[column.property] as string) : ''}
          </div>
        )}
      </td>
    )),
  ];

  const renderSubrows = (data: SR, index: number) => {
    return (
      <tr
        key={'tr-subrow' + item[selectionIdentifyBy] + index}
        className={classNames}
      >
        {selectionEnabled && <td className="bg-primary-25 bg-clip-padding" />}
        {subRows?.columns.map((subRow, i) => (
          <td
            key={'td-subrow' + item[selectionIdentifyBy] + i}
            className={`${
              dense ? 'py-0' : 'py-2'
            } bg-primary-25 bg-clip-padding pl-3 pr-3`}
            colSpan={subRow.colSpan}
          >
            <div className={`${subRow.rightAlign ? ' flex justify-end' : ''}`}>
              {subRow.render(data, item)}
            </div>
          </td>
        ))}
      </tr>
    );
  };

  return renderRow ? (
    <>
      {renderRow({
        cells,
        classNames,
        index: rowIndex,
        item,
      })}
      {subRows &&
        isSubrowsOpen &&
        subRowData?.map((data, index) => renderSubrows(data, index))}
    </>
  ) : (
    <>
      <tr className={classNames}>{cells}</tr>
      {subRows && isSubrowsOpen && (
        <>
          {subRowData === undefined ? (
            <>
              {Array.from({ length: subRows.rowCount?.(item) ?? 0 }).map(
                (_, index) => (
                  <tr
                    key={'subrowloader' + item[selectionIdentifyBy] + index}
                    className="border-b"
                  >
                    <td
                      colSpan={
                        selection !== undefined
                          ? columns.length + 1
                          : columns.length
                      }
                      className="w-full py-0.5"
                    >
                      <SkeletonLoader height={subRows.rowLoaderHeight} />
                    </td>
                  </tr>
                ),
              )}
            </>
          ) : (
            subRowData?.map((data, index) => renderSubrows(data, index))
          )}
        </>
      )}
    </>
  );
};

TableRow.displayName = 'TableRow';

export { TableRow };
