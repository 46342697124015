import { ConfirmDialog } from '@/Components/ConfirmDialog';
import { WarningAlert } from '@/Components/v2/Alert';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { useRefreshKeywordReport } from '@/api/openapiComponents';
import { useQueryClient } from '@tanstack/react-query';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  documentId: number;
};

export const RefreshReportDialog = ({ isOpen, onClose, documentId }: Props) => {
  const appState = useAppStore();

  const refreshReportMutation = useRefreshKeywordReport();
  const client = useQueryClient();

  const handleRefresh = () => {
    refreshReportMutation.mutate(
      {
        pathParams: {
          document: documentId,
          project: appState.currentProject!.id,
        },
      },
      {
        onSuccess: (data) => {
          appState.refreshSubscription();

          const queryKey =
            client.getQueryCache().find({
              predicate: (query) => query.queryKey.includes('getDocument'),
            })?.queryKey ?? [];
          client.setQueryData(queryKey, data);
          onClose();
        },
      },
    );
  };

  const hasEnoughContentCredits =
    !!appState.subscription?.usage.credits.content.is_allowed;

  return (
    <ConfirmDialog
      isOpen={isOpen}
      title="Refresh report"
      content={
        <div>
          <p>Are you sure you want to refresh your report?</p>
          {!hasEnoughContentCredits && (
            <WarningAlert title="You do not have enough credits to refresh the report" />
          )}
        </div>
      }
      error={refreshReportMutation.error}
      isLoading={refreshReportMutation.isPending}
      disabled={!hasEnoughContentCredits}
      confirmText="Refresh"
      onConfirm={handleRefresh}
      onClose={onClose}
    />
  );
};
