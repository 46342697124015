import { useState } from "react";



export const useMouseOverDraggableIcon = () => {
    const [toggle, setToggle] = useState(false);

    const onMouseOver = () => setToggle(true)
    const onMouseLeave = () => setToggle(false)

    return { onMouseLeave, onMouseOver, invisiblity: !toggle ? "invisible" : "visible" }
}