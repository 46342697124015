import { ListItem } from '../Listitem';
import { VerticalMenu } from '../Menu/VerticalMenu';
import { DropdownSkeleton } from './DropdownSkeleton';
import { DropdownItem, useDropdownState } from './hooks';
import { isGroup } from './utils';

type Props = {
  value: any;
  options: (DropdownItem | { group: string })[];
  onChange: (value?: string | number) => void;
  placeholder?: string;
  error?: string;
  clearable?: boolean;
  disabled?: boolean;
  zIndex?: number;
  fullWidth?: boolean;
};

export const Select = ({
  value,
  options,
  onChange,
  placeholder,
  error,
  clearable,
  disabled,
  zIndex,
  fullWidth,
}: Props) => {
  const {
    handleKeydown,
    isOpen,
    longestOption,
    selectionIndex,
    option,
    setIsOpen,
    filteredOptions,
  } = useDropdownState({ onChange, options, value });
  return (
    <DropdownSkeleton
      disabled={disabled}
      error={error}
      zIndex={zIndex}
      fullWidth={fullWidth}
      selection={
        <div className="text-lg text-gray-800">
          <div className="h-0 overflow-hidden truncate">
            <ListItem
              {...longestOption}
              subtitle={undefined}
              title={
                <>{longestOption?.title ?? longestOption?.value ?? ''}&nbsp;</>
              }
            />
          </div>
          <div className="flex justify-start">
            <ListItem
              {...option}
              subtitle={undefined}
              title={
                <>
                  {option?.title ?? option?.value ?? (
                    <span className={'font-normal italic text-primary-400'}>
                      {placeholder}
                    </span>
                  )}
                  &nbsp;
                </>
              }
            />
          </div>
        </div>
      }
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      onKeyDown={handleKeydown}
      clearable={clearable}
      onClear={
        clearable && (value || value?.length > 0)
          ? () => onChange(undefined)
          : undefined
      }
      dropdown={
        <VerticalMenu
          items={filteredOptions.map((option, index) => ({
            ...(isGroup(option)
              ? { group: option.group }
              : {
                  ...option,
                  title: option.title ?? option.value,
                  isFocused: selectionIndex === index || value === option.value,
                  onClick: () => {
                    onChange(option.value);
                    setIsOpen(false);
                  },
                }),
          }))}
        />
      }
    />
  );
};
