import { AnimatedCollapsibleContainer } from '@/Components/AnimatedCollapsibleContainer';
import { Disclosure, Transition } from '@headlessui/react';
import { ChevronUpIcon } from 'lucide-react';
import React from 'react';

interface Props {
  title: string | React.ReactNode;
  children: React.ReactNode;
  fullWidth?: boolean;
  prependIcon?: React.ReactNode;
  defaultOpen?: boolean;
  paddingX?: 'sm' | 'md' | 'lg';
  onClick?: () => void;
}
export const Collapsable = ({
  title,
  children,
  fullWidth,
  prependIcon,
  defaultOpen,
  paddingX = 'md',
  onClick,
}: Props) => {
  const getContentSize = () => {
    if (paddingX === 'sm') {
      return 'px-2';
    }

    if (paddingX === 'md') {
      return 'px-3.5';
    }

    return 'px-5';
  };

  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => (
        <>
          <Disclosure.Button
            onClick={onClick}
            className={`flex items-center justify-center gap-2 rounded-md ${getContentSize()} py-2 text-lg text-primary hover:bg-primary-125 hover:text-primary-800 ${
              fullWidth ? 'w-full justify-between' : 'justify-center'
            }`}
          >
            <div className="flex items-center gap-2">
              {prependIcon}
              {title}
            </div>
            <ChevronUpIcon
              size={20}
              className={`flex-shrink-0 ${
                !open ? ' rotate-180 transform' : ''
              }`}
            />
          </Disclosure.Button>
          <AnimatedCollapsibleContainer isOpen={open}>
            {children}
          </AnimatedCollapsibleContainer>
        </>
      )}
    </Disclosure>
  );
};
