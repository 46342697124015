import { useEffect, useRef, useState } from 'react';

export const useLoadingEllipsis = (isLoading: boolean) => {
  const intervalIdRef = useRef<number>();
  const ellipsis = ['', '.', '..', '...'];
  const [ellipsisIndex, setEllipsisIndex] = useState(0);

  useEffect(() => {
    if (isLoading) {
      intervalIdRef.current = setInterval(
        () =>
          setEllipsisIndex((prev) =>
            prev < ellipsis.length - 1 ? prev + 1 : 0,
          ),
        500,
      );
    } else if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
    }
  }, [isLoading]);

  return ellipsis[ellipsisIndex];
};
