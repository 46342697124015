import DifficultyLabel from '@/Components/Labels/DifficultyLabel';
import { Button } from '@/Components/v2/Button';
import { Input } from '@/Components/v2/Input/Input';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { tracking } from '@/Services/tracking/Tracking';
import { useGetRandomPlaceHolder } from '@/data/placeholders';
import { useNavigate } from '@tanstack/react-router';
import { useState } from 'react';
import { Plus } from 'lucide-react';
import { useProjectsProjectKeywordsIndex } from '@/api/openapiComponents';

const SkeletonRow = () => {
  return (
    <tr>
      <td colSpan={4}>
        <div className="my-1 h-12 w-full animate-pulse rounded-lg bg-gray-200" />
      </td>
    </tr>
  );
};

export default function ChooseKeyword() {
  const navigate = useNavigate();
  const appState = useAppStore();

  const placeholder = useGetRandomPlaceHolder();
  const {
    data,
    isPending: isLoading,
    isError,
    refetch,
  } = useProjectsProjectKeywordsIndex({
    pathParams: {
      project: appState.currentProject!.id,
    },
    queryParams: {
      limit: 15,
    },
  });

  const [customKeyword, setCustomKeyword] = useState('');

  const handleSelectKeyword = (keyword: string, isCustom = false) => {
    navigate({ to: '/onboarding/choose-title', search: { keyword } });
  };

  const handleSkip = () => {
    tracking.event('onboarding_cancel');
    navigate({ to: '/' });
  };

  appState.pageTitle('Choose a keyword');

  return (
    <div className="flex h-screen w-full flex-col bg-gray-50">
      <div className="absolute right-2 top-2">
        <Button text="SKIP" onClick={handleSkip} variant="ghost" />
      </div>
      <div className="flex w-full justify-center gap-4 border-b-2 border-b-gray-200 p-6">
        <div className="flex w-full max-w-4xl flex-col items-center">
          <p className="mb-1  text-gray-400">CREATE YOUR FIRST CONTENT</p>
          <h1 className="mb-3 w-96 text-center text-3xl font-extrabold text-black">
            Select a keyword you would like to rank on
          </h1>
          <p className="w-[32rem] text-center  text-gray-400">
            Your first content piece will revolve around the keyword you select.
          </p>
        </div>
      </div>
      <div className="mt-12 flex flex-grow justify-center overflow-y-scroll bg-gray-50 px-12">
        <div className={'w-full max-w-4xl'}>
          <div className="rounded-md border border-gray-200 bg-white p-4">
            <div className="mb-10 flex flex-col items-center">
              <div className={'items-center'}>
                <p className="text-google-description mb-4 text-center font-work font-semibold">
                  Provide a custom keyword
                </p>
                <form onSubmit={() => handleSelectKeyword(customKeyword, true)}>
                  <div className="flex items-start gap-2">
                    <Input
                      value={customKeyword}
                      onChange={(value) => setCustomKeyword(value)}
                      placeholder={placeholder.keyword}
                      hint="A keyword is the search phrase used in google"
                    />
                    <div className="flex h-10">
                      <Button
                        disabled={customKeyword.length === 0}
                        type="submit"
                        color="secondary"
                        text="select"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          {(isLoading || data.data.length > 0) && (
            <>
              <div className="my-10 flex items-center px-64">
                <div className="flex-grow border-t border-zinc-300"></div>
                <span className="mx-2 flex-shrink font-normal text-gray-400">
                  or
                </span>
                <div className="flex-grow border-t border-zinc-300"></div>
              </div>

              <div className="mb-8 w-full max-w-4xl rounded-md border border-gray-200 bg-white p-4">
                <p className="text-google-description mb-10 text-center font-work font-semibold">
                  Choose from domain-based suggestions
                </p>

                <table className="w-full">
                  <thead>
                    <tr className="text-left text-sm uppercase">
                      <th className="border-input-border-light-grey border-b py-3 pr-2 text-left font-work font-normal">
                        Keywords
                      </th>
                      <th className=" border-input-border-light-grey border-b py-3 text-center font-work font-normal">
                        Rank difficulty
                      </th>
                      <th className=" border-input-border-light-grey border-b py-3 text-center font-work font-normal">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <>
                        <SkeletonRow />
                        <SkeletonRow />
                        <SkeletonRow />
                        <SkeletonRow />
                        <SkeletonRow />
                        <SkeletonRow />
                        <SkeletonRow />
                        <SkeletonRow />
                        <SkeletonRow />
                        <SkeletonRow />
                      </>
                    ) : (
                      data.data.map((keyword) => (
                        <tr className="animate-fade-slow border-b border-gray-200 font-bold">
                          <td>{keyword.keyword!.name}</td>
                          <td>
                            <div className="flex justify-center">
                              <DifficultyLabel
                                difficulty={keyword.keyword!.difficulty}
                              />
                            </div>
                          </td>
                          <td className="flex justify-center py-2">
                            <Button
                              color="secondary"
                              isLoading={isLoading}
                              prependIcon={Plus}
                              onClick={() =>
                                handleSelectKeyword(keyword.keyword!.name)
                              }
                              text="SELECT"
                            />
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>

                {isError && (
                  <div className="mt-4 flex w-full flex-col items-center gap-2">
                    <p>Something went wrong</p>
                    <Button
                      variant="ghost"
                      text="Try again"
                      onClick={() => refetch()}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
