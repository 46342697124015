import { WriteMorePromptType } from '@/api/openapiSchemas';
import {
  AlignJustify,
  AlignLeft,
  Award,
  FoldVertical,
  Heading2,
  List,
  ListCollapse,
  ListFilter,
  ListRestart,
  ListStart,
  ListStartIcon,
  LucideIcon,
  MessageCircleQuestion,
  Quote,
  SparklesIcon,
  SpellCheck,
  Table,
  ThumbsUp,
  UnfoldVertical,
} from 'lucide-react';

const prompts = [
  {
    title: 'Introduction',
    prompt: 'Write a short two-sentence paragraph.',
    Icon: ListStart,
  },
  {
    title: 'Subheadings',
    prompt: 'Write short and clear subheadings in H2/H3 format.',
    Icon: Heading2,
  },
  {
    title: 'FAQ',
    prompt:
      'Craft a concise FAQ in LANGUAGE: Use H2 for the main title, a one-sentence intro, H3 for questions, and brief paragraph answers.',
    Icon: MessageCircleQuestion,
  },
  {
    title: 'Key Points',
    prompt:
      'Write 3 to 5 key points. Write a short subheading in bold and the shortest possible key points as bullets.',
    Icon: ListCollapse,
  },
  {
    title: 'Quote',
    prompt: "Write a BLOCKQUOTE highlighting this content's core key point.",
    Icon: Quote,
  },
  {
    title: 'List',
    prompt: 'Make it a list.',
    Icon: List,
  },
  {
    title: 'Featured snippet',
    prompt:
      "Write a short definition question (H2) followed by one brief expert answer of 8 words, starting with 'xxx is...'. Then, write a short sentence that repeats the answer but is tailored specifically for the audience, starting with 'For [audience/3 words], xxx is...'.",
    Icon: Award,
  },
  {
    title: 'Improve',
    prompt: 'Improve content by making it concise, clear, and easier to read.',
    Icon: ThumbsUp,
  },
  {
    title: 'Rephrase',
    prompt: 'Rephrase and keep the formatting.',
    Icon: ListRestart,
  },
  {
    title: 'Apply Brand Voice',
    prompt: 'Rewrite in my TONE OF VOICE.',
    Icon: Table,
  },
  {
    title: 'Shorten',
    prompt: 'Make it shorter.',
    Icon: FoldVertical,
  },
  {
    title: 'Lengthen',
    prompt: 'Make it a few sentences longer.',
    Icon: UnfoldVertical,
  },
  {
    title: 'Correct spelling & grammar',
    prompt: 'Correct spelling and grammar.',
    Icon: SpellCheck,
  },
  {
    title: 'Summarize',
    prompt: 'Make a short summary of this content.',
    Icon: ListFilter,
  },
  {
    title: 'Table',
    prompt: 'Create a helpful table based on content in 3 columns.',
    Icon: Table,
  },
];

const promptsForAiInput: {
  Icon: LucideIcon;
  title: string;
  type: WriteMorePromptType;
}[] = [
  {
    Icon: SparklesIcon,
    title: 'Just write more',
    type: 'default',
  },
  {
    Icon: ListStartIcon,
    title: 'Introduction',
    type: 'introduction',
  },
  {
    Icon: AlignLeft,
    title: 'Section (Multiple paragraphs)',
    type: 'section',
  },
  {
    Icon: AlignJustify,
    title: 'Paragraph (Single paragraph)',
    type: 'paragraph',
  },
  { Icon: List, title: 'List', type: 'list' },
  { Icon: Quote, title: 'Quote', type: 'quote' },
  { Icon: MessageCircleQuestion, title: 'FAQ', type: 'faq' },
];
export { prompts, promptsForAiInput };
