import { Destination } from '../Destinations';
import posthog, { Properties } from 'posthog-js';

export const posthogDestination: Destination = {
  name: 'posthog',
  enabledByEnvKey: 'VITE_POSTHOG_API_KEY',
  initialize: () => {
    posthog.init(import.meta.env.VITE_POSTHOG_API_KEY, {
      api_host: import.meta.env.VITE_POSTHOG_HOST,
      person_profiles: 'identified_only',
    });
  },
  identify: (user) => {
    posthog.identify('' + user.id, { email: user.email });
  },
  reset: () => {
    posthog.reset();
  },
  resetGroup: () => {
    posthog.resetGroups();
  },
  group: (group, payload) => {
    posthog.group(group.type, '' + group.id, payload);
  },
  trackEvent: (event) => {
    if (event.name === 'page') {
      posthog.capture('$pageview');
    } else {
      posthog.capture(event.name, event.payload as Properties);
    }
  },
};
