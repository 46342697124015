import { DocumentElementResource } from '@/api/openapiSchemas';
import { DynamicIcon } from '@/Components/v2/DynamicIcon/DynamicIcon';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { GripVertical, FileQuestion, Pencil, Trash2 } from 'lucide-react';

type Props = {
  removeDrag?: boolean;
  isHovering?: boolean;
  hideDescription?: boolean;
  el: DocumentElementResource & { uniqueId: string };
  onOpenDialog?: (uniqueId: string) => void;
  onDelete?: (el: DocumentElementResource & { uniqueId: string }) => void;
};

export const TemplateItemLayout = ({
  el,
  onDelete,
  onOpenDialog,
  hideDescription,
  isHovering,
  removeDrag,
}: Props) => {
  const getDescription = () => {
    switch (el.action_type) {
      case 'raw':
        return el.values?.raw_markdown;
      case 'prompt':
        return el.values?.prompt_instruction;
      default:
        return el.values?.prompt_instruction;
    }
  };
  return (
    <>
      {!removeDrag && (
        <div
          className={`absolute -left-10 bottom-0 top-0 flex h-12 items-center pr-2 ${
            isHovering ? 'opacity-100' : 'opacity-0'
          }`}
        >
          <IconButton icon={GripVertical} />
        </div>
      )}
      <div className="flex flex-grow items-center justify-between overflow-hidden">
        <div className="mt-1 flex flex-grow items-center justify-between">
          <div className="mx-2">
            {el.icon ? (
              <DynamicIcon
                className="stroke-primary opacity-60"
                size={20}
                name={el.icon as any}
              />
            ) : (
              <FileQuestion className="stroke-primary opacity-60" size={20} />
            )}
          </div>
          <p className="flex-grow truncate text-xl font-bold text-primary">
            {el.values?.display_name ? el.values.display_name : el.display_name}
          </p>

          <div className={`${isHovering ? 'opacity-100' : 'opacity-0'}`}>
            <IconButton
              icon={Pencil}
              onClick={() => onOpenDialog?.(el.uniqueId)}
            />
          </div>
        </div>
      </div>
      {getDescription() && !hideDescription && (
        <div className="my-4 line-clamp-3 whitespace-pre pl-[2.375rem] pr-2 italic text-primary">
          {getDescription()}
        </div>
      )}

      <div
        className={`absolute -right-10 bottom-0 top-0 flex h-12 items-center pl-2 ${
          isHovering ? 'opacity-100' : 'opacity-0'
        }`}
      >
        <IconButton
          icon={Trash2}
          onClick={(e) => {
            e.stopPropagation();
            onDelete?.(el);
          }}
        />
      </div>
    </>
  );
};
