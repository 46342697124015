import { motion, useMotionValue } from 'framer-motion';
import CircularProgress from '@/Components/CircularProgress';
import { twMerge } from 'tailwind-merge';

type Props = {
  accepted: boolean;
  width?: number;
  height?: number;
};
function AcceptedAnimation({ accepted, width, height }: Props) {
  const progress = useMotionValue(90);

  return (
    <>
      <motion.div
        initial={{ x: 0 }}
        animate={{ x: 100 }}
        style={{ x: progress }}
        transition={{ duration: 1 }}
      />
      <div
        className={twMerge(
          'absolute z-50 flex h-full w-full items-center justify-center',
          !accepted ? 'hidden' : 'block',
        )}
        style={
          accepted
            ? { backgroundColor: 'rgba(0,0,0,0.5)', display: 'flex' }
            : { display: 'flex' }
        }
      >
        <CircularProgress progress={progress} width={width} height={height} />
      </div>
    </>
  );
}

export default AcceptedAnimation;
