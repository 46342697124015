import React, { forwardRef, useEffect, useState } from 'react';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { PopoverAnchor, PopoverContentProps } from '@radix-ui/react-popover';
import {
  isCollapsed,
  PlateElement,
  PlateElementProps,
  useEditorState,
  useElement,
  useRemoveNodeButton,
} from '@udecode/plate-common';
import {
  TTableElement,
  deleteColumn,
  deleteRow,
  insertTableColumn,
  insertTableRow,
  useTableElement,
  useTableElementState,
} from '@udecode/plate-table';
import { useReadOnly, useSelected } from 'slate-react';
import copyToClipboard from 'copy-to-clipboard';
import { cn } from '../lib/utils';
import { Icons } from './icons';
import { Button } from './button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuPortal,
  DropdownMenuTrigger,
} from './dropdown-menu';
import { Popover, PopoverContent, popoverVariants } from './popover';
import { usePlateUiDrop } from '@/Hooks/usePlateUiDrop';
import { useMouseOverDraggableIcon } from '@/Hooks/useMouseOverDraggableIcon';
import { Divider } from './divider';
import { plateToHTML } from '@/Pages/Document/utils';
import { BlockType } from '@/types';
import { Files } from 'lucide-react';
import { SimpleToolTip } from '@/Components/SimpleToolTip';
import { useInternalLinkingStore } from '@/Pages/Document/stores';

export const IsTableContext = React.createContext(false);
const TableRowsMenu = forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content>
>((props, ref) => {
  const editor = useEditorState();

  return (
    <DropdownMenuContent
      ref={ref}
      className={cn('max-w-[12rem]')}
      side="right"
      align="start"
      sideOffset={0}
      {...props}
    >
      <Button
        contentEditable={false}
        variant="ghost"
        isMenu
        onClick={() => {
          insertTableRow(editor, { header: false });
        }}
      >
        <Icons.add className="mr-2 h-4 w-4" />
        Insert row after
      </Button>
      <Button
        contentEditable={false}
        variant="ghost"
        isMenu
        onClick={() => {
          deleteRow(editor);
        }}
      >
        <Icons.minus className="mr-2 h-4 w-4" />
        Delete row
      </Button>
    </DropdownMenuContent>
  );
});
TableRowsMenu.displayName = 'TableRowsMenu';

const TableColumnsMenu = forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content>
>((props, ref) => {
  const editor = useEditorState();

  return (
    <DropdownMenuContent
      ref={ref}
      className={cn('max-w-[12rem]')}
      side="right"
      align="start"
      sideOffset={0}
      {...props}
    >
      <Button
        contentEditable={false}
        variant="ghost"
        isMenu
        onClick={() => {
          insertTableColumn(editor);
        }}
      >
        <Icons.add className="mr-2 h-4 w-4" />
        Insert column after
      </Button>
      <Button
        contentEditable={false}
        variant="ghost"
        isMenu
        onClick={() => {
          deleteColumn(editor);
        }}
      >
        <Icons.minus className="mr-2 h-4 w-4" />
        Delete column
      </Button>
    </DropdownMenuContent>
  );
});
TableColumnsMenu.displayName = 'TableColumnsMenu';

const TableFloatingToolbar = React.forwardRef<
  React.ElementRef<typeof PopoverContent>,
  PopoverContentProps
>(({ children, ...props }, ref) => {
  const internalLinks = useInternalLinkingStore((state) => state.data);
  const element = useElement<TTableElement>();
  const { props: buttonProps } = useRemoveNodeButton({ element });
  const readOnly = useReadOnly();
  const selected = useSelected();
  const editor = useEditorState();
  const getOpenState = () => {
    if (internalLinks.some((link) => link.isHoveredFromTab)) {
      return false;
    }
    return !readOnly && selected && isCollapsed(editor.selection);
  };
  const open = getOpenState();
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    if (!open && copied) {
      setCopied(false);
      return;
    }
    if (open && copied) {
      timer = setTimeout(() => {
        setCopied(false);
      }, 1500);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [open, copied]);
  return (
    <Popover open={open} modal={false}>
      <PopoverAnchor asChild>{children}</PopoverAnchor>
      <PopoverContent
        side="left"
        ref={ref}
        className={cn(popoverVariants(), 'flex w-[220px] flex-col gap-1 p-1')}
        onOpenAutoFocus={(e) => e.preventDefault()}
        {...props}
      >
        <DropdownMenu modal={false}>
          <DropdownMenuPortal>
            <TableColumnsMenu />
          </DropdownMenuPortal>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" isMenu>
              <div className="flex w-full justify-between">
                <div className="flex items-center">
                  <Icons.column className="mr-2 h-4 w-4" />
                  Columns
                </div>
                <Icons.chevronRight className="h-6 w-6" />
              </div>
            </Button>
          </DropdownMenuTrigger>
        </DropdownMenu>
        <DropdownMenu modal={false}>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" isMenu>
              <div className="flex w-full justify-between">
                <div className="flex items-center">
                  <Icons.row className="mr-2 h-4 w-4" />
                  Rows
                </div>
                <Icons.chevronRight className="h-6 w-6" />
              </div>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuPortal>
            <TableRowsMenu />
          </DropdownMenuPortal>
        </DropdownMenu>

        <Button contentEditable={false} variant="ghost" isMenu {...buttonProps}>
          <Icons.delete className="mr-2 h-4 w-4" />
          Delete Table
        </Button>
        <SimpleToolTip isOpen={copied}>
          <Button
            contentEditable={false}
            variant="ghost"
            isMenu
            onClick={() => {
              copyToClipboard(' ', {
                onCopy: (dataTransfer) => {
                  const data = dataTransfer as DataTransfer;
                  if (!data) return;
                  if (editor.selection) {
                    const table =
                      editor.children[editor.selection.anchor.path[0]];
                    data.setData(
                      'text/plain',
                      `<table>${plateToHTML(
                        table.children as BlockType[],
                      )}</table>`,
                    );
                    setCopied(true);
                  }
                },
              });
            }}
          >
            <Files className="mr-2 h-4 w-4" />
            Copy to HTML
          </Button>
        </SimpleToolTip>
      </PopoverContent>
    </Popover>
  );
});
TableFloatingToolbar.displayName = 'TableFloatingToolbar';

const TableElement = React.forwardRef<
  React.ElementRef<typeof PlateElement>,
  PlateElementProps
>(({ className, children, element, ...props }, ref) => {
  const { colSizes, isSelectingCell, minColumnWidth } = useTableElementState();
  const { props: tableProps, colGroupProps } = useTableElement();
  const { invisiblity, ...methods } = useMouseOverDraggableIcon();
  const { attributes, listeners, setNodeRef, style, setActivatorNodeRef } =
    usePlateUiDrop(element);
  return (
    <IsTableContext.Provider value={true}>
      <TableFloatingToolbar>
        <div
          ref={setNodeRef}
          className="mr-5 flex w-[97.5%]"
          style={style}
          {...methods}
        >
          <div
            className={`mr-1 mt-4 ${invisiblity}`}
            ref={setActivatorNodeRef}
            {...attributes}
            {...listeners}
          >
            <Icons.dragHandle className="text-muted-foreground mt-[0.3rem] h-4 w-4" />
          </div>
          <PlateElement
            ref={ref}
            element={element}
            asChild
            className={cn(
              'my-4 ml-px mr-0 table h-px w-full table-fixed border-collapse',
              isSelectingCell && '[&_*::selection]:bg-none',
              className,
            )}
            {...tableProps}
            {...props}
          >
            <table className="z-0">
              <colgroup {...colGroupProps}>
                {colSizes.map((width, index) => (
                  <col
                    key={index}
                    style={{
                      minWidth: minColumnWidth,
                      width: width || undefined,
                    }}
                  />
                ))}
              </colgroup>

              <tbody className="min-w-full">{children}</tbody>
            </table>
          </PlateElement>
        </div>
      </TableFloatingToolbar>
      <Divider id={element.id as string} />
    </IsTableContext.Provider>
  );
});
TableElement.displayName = 'TableElement';

export { TableElement, TableFloatingToolbar, TableRowsMenu };
