import { useRef } from 'react';
import { ListItem } from '../Listitem';
import { VerticalMenu } from '../Menu/VerticalMenu';
import { DropdownSkeleton } from './DropdownSkeleton';
import { DropdownItem, useDropdownState } from './hooks';
import { isGroup } from './utils';
import { SkeletonLoader } from '../SkeletonLoader/SkeletonLoader';

type Props = {
  value: string;
  options: (
    | (Omit<DropdownItem, 'value'> & { value: string })
    | { group: string }
  )[];
  onChange: (value: string, item?: DropdownItem) => void;
  error?: string;
  isLoading?: boolean;
  backendSearch?: boolean;
  clearable?: boolean;
  zIndex?: number;
  placeholder?: string;
  fullWidth?: boolean;
  disabled?: boolean;
};

export const AutocompleteTextInput = ({
  onChange,
  value,
  options,
  placeholder,
  error,
  zIndex,
  clearable,
  backendSearch,
  isLoading,
  disabled,
  fullWidth,
}: Props) => {
  const {
    filter,
    filteredOptions,
    handleKeydown,
    isOpen,
    selectionIndex,
    setFilter,
    setIsOpen,
  } = useDropdownState({
    onChange,
    options,
    value,
    filterEnabled: !backendSearch,
  });

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleOpen = (open: boolean) => {
    setIsOpen(open);
    if (open) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 1);
    }
  };

  return (
    <DropdownSkeleton
      error={error}
      zIndex={zIndex}
      disabled={disabled}
      fullWidth={fullWidth}
      selection={
        <div className="relative flex flex-col text-lg text-gray-800">
          <input
            ref={inputRef}
            size={1}
            onClick={(e) => e.stopPropagation()}
            placeholder={placeholder}
            onFocus={(e) => {
              e.target.select();
              handleOpen(true);
            }}
            value={value ?? filter}
            className="-my-1 h-10 w-full min-w-0 border-0 bg-transparent outline-none ring-0"
            onKeyDown={(e) => {
              if (
                e.key !== 'ArrowDown' &&
                e.key !== 'ArrowUp' &&
                e.key !== 'Enter' &&
                e.key !== 'Escape'
              ) {
                e.stopPropagation();
              }
            }}
            onChange={(e) => {
              if (onChange) return onChange(e.target.value);
              setFilter(e.target.value);
            }}
          />
        </div>
      }
      clearable={clearable}
      onClear={clearable && value !== '' ? () => onChange('') : undefined}
      isOpen={isOpen}
      isEmpty={
        filteredOptions.filter((option) => !isGroup(option)).length === 0
      }
      onClose={() => handleOpen(false)}
      onOpen={() => handleOpen(true)}
      onKeyDown={handleKeydown}
      dropdown={
        isLoading ? (
          <div className="flex flex-col gap-1 bg-white p-1">
            <SkeletonLoader />
            <SkeletonLoader />
            <SkeletonLoader />
            <SkeletonLoader />
          </div>
        ) : filteredOptions.filter((option) => !isGroup(option)).length ===
          0 ? (
          <div className="bg-white">
            <ListItem title="No results..." disabled />
          </div>
        ) : (
          <VerticalMenu
            items={filteredOptions.map((option, index) => ({
              ...(isGroup(option)
                ? { group: option.group }
                : {
                    ...option,
                    title: option.title ?? option.value,
                    isFocused:
                      selectionIndex === index || value === option.value,
                    onClick: () => {
                      onChange(option.title ?? option.value ?? '', option);
                      setIsOpen(false);
                    },
                  }),
            }))}
          />
        )
      }
    />
  );
};
