import SimpleTitle from '@/Components/SimpleTitle';
import { Button, ButtonProps } from '@/Components/v2/Button';
import { Tooltip } from '@/Components/v2/Tooltip';

type Props = {
  title: string;
  buttonProps?: {
    toolTip?: {
      title: string;
      description: string;
    };
  } & ButtonProps;
};

export const SectionHeader = ({ title, buttonProps }: Props) => {
  return (
    <>
      <div className="flex w-full items-center justify-between">
        <SimpleTitle>{title}</SimpleTitle>
        {buttonProps &&
          (buttonProps.toolTip ? (
            <Tooltip
              disabled={!buttonProps.disabled}
              title={buttonProps.toolTip.title}
              description={buttonProps.toolTip.description}
              side="top"
            >
              <Button color="secondary" {...buttonProps} />
            </Tooltip>
          ) : (
            <Button {...buttonProps} />
          ))}
      </div>
      <div className="mt-2 h-0.5 w-full bg-gray-200" />
    </>
  );
};
