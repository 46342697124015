import { SelectionActions } from '@/Components/Table/components/SelectionActions';
import { useCopyTable } from '@/Components/Table/hooks';
import { SlimKeywordResource } from '@/api/openapiSchemas';
import { Copy } from 'lucide-react';

type Props = {
  selection: SlimKeywordResource[];
};

export const CopySelectedAction = ({ selection }: Props) => {
  const handleCopyToClipboard = useCopyTable(
    selection.map((item) => item.name).join('\n'),
  );

  return (
    <SelectionActions
      selection={selection}
      actions={[
        {
          prependIcon: Copy,
          text: 'Copy',
          variant: 'ghost',
          onClick: handleCopyToClipboard,
        },
      ]}
    />
  );
};
