import { Banner } from '../Banner';
import { useNavigate } from '@tanstack/react-router';
import { Button } from '@/Components/v2/Button';
import { useAppStore } from '@/Pages/AppLoader/stores';

export const OutOfCreditsBanner = () => {
  const navigate = useNavigate();

  const appState = useAppStore();

  return (
    <Banner
      isOpen={
        appState.subscription?.usage.credits.content.is_allowed === false &&
        appState.subscription?.is_canceled !== true
      }
      className="flex items-center gap-4 bg-amber-100 py-3"
    >
      Oops! You've hit your credit limit. Upgrade for more.
      <Button
        color="white"
        text="VIEW PLANS"
        size="sm"
        dense
        onClick={() => navigate({ to: '/account/subscription' })}
      />
    </Banner>
  );
};
