import { ConfirmDialog } from '@/Components/ConfirmDialog';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { PopoverMenu } from '@/Components/v2/Menu/ButtonMenu';
import { Tooltip } from '@/Components/v2/Tooltip';
import {
  useOrganisationsOrganisationInvitesDestroy,
  useOrganisationsOrganisationInvitesStore,
  useOrganisationsUsersDestroy,
} from '@/api/openapiComponents';
import {
  OrganisationInviteResource,
  OrganisationUserResource,
  UserResource,
} from '@/api/openapiSchemas';
import { MoreHorizontal } from 'lucide-react';
import { useState } from 'react';

type Props = {
  auth: { user: UserResource & { org_id: number } };
  user?: OrganisationUserResource;
  invite?: OrganisationInviteResource;
  onClose: (type: 'user' | 'invite') => void;
  isAdmin?: boolean;
};

export const UserAction = ({ auth, user, invite, onClose, isAdmin }: Props) => {
  const [action, setAction] = useState<{ name: string; value: string }>();

  const retractMutation = useOrganisationsOrganisationInvitesDestroy({
    onSuccess: () => {
      setAction(undefined);
      onClose(user ? 'user' : 'invite');
    },
  });

  const resendMutation = useOrganisationsOrganisationInvitesStore({
    onSuccess: () => {
      setAction(undefined);
      onClose(user ? 'user' : 'invite');
    },
  });

  const deleteUserMutation = useOrganisationsUsersDestroy();

  const options: { value: string; name: string }[] = [];
  if (invite) {
    options.push({ value: 'retract', name: 'Retract' });
    options.push({ value: 'resend', name: 'Resend Invite' });
  }

  if (isAdmin) {
    options.push({ value: 'delete', name: 'Delete user' });
  }

  // TODO: enable when make admin functionality is ready
  // if (!isMe && user && !user.invite_hash && user.role === 'member') {
  //   options.push({ value: 'promote', name: 'Make admin' });
  // }

  const isLoading =
    retractMutation.isPending ||
    resendMutation.isPending ||
    deleteUserMutation.isPending;
  const error =
    retractMutation.error || resendMutation.error || deleteUserMutation.error;

  const handleAction = () => {
    if (action?.value === 'retract') {
      retractMutation.mutate({
        pathParams: {
          organisation: auth.user.org_id,
          organisationInvite: invite!.id,
        },
      });
    }
    if (action?.value === 'resend') {
      resendMutation.mutate({
        pathParams: {
          organisation: auth.user.org_id,
        },

        body: {
          email: invite!.email,
          name: invite!.name,
        },
      });
    }
    if (action?.value === 'delete') {
      deleteUserMutation.mutate(
        {
          pathParams: {
            organisation: auth.user.org_id,
            user: user!.id,
          },
        },
        {
          onSuccess: () => {
            setAction(undefined);
            onClose(user ? 'user' : 'invite');
          },
        },
      );
    }
  };

  const isDisabled = () => {
    if (options.length === 0) {
      return true;
    }

    return false;
  };

  return (
    <>
      <ConfirmDialog
        title={action?.name + '?'}
        description={[
          `Are you sure you want to ${action?.value} the ${
            invite ? 'invite for' : 'user'
          } ${invite?.email ?? user?.email}`,
        ]}
        isOpen={!!action}
        isLoading={isLoading}
        error={error}
        onConfirm={handleAction}
        onClose={() => setAction(undefined)}
      />
      {auth.user.id !== user?.id && (
        <PopoverMenu
          trigger={
            <Tooltip
              side="top"
              description="Only the account owner can modify users"
              disabled={!isDisabled()}
            >
              <IconButton
                disabled={isDisabled()}
                isLoading={isLoading}
                color="gray"
                icon={MoreHorizontal}
              />
            </Tooltip>
          }
          items={options.map((options) => ({
            title: options.name,
            onClick: () => setAction(options),
          }))}
        />
      )}
    </>
  );
};
