import { useState } from 'react';
import { CancelSubscriptionForm } from './Account/components/CancelSubscriptionForm';
import { useAppStore } from './AppLoader/stores';
import { useOpenStripePage } from './Account/hooks';
import { Button } from '@/Components/v2/Button';
import LogoutLink from './Auth/Finalize/LogoutLink';
import { useOrganisationSubscriptionIndex } from '@/api/openapiComponents';

export default function SubscriptionUnpaid() {
  const appState = useAppStore();
  const [showCancelForm, setShowCancelForm] = useState(false);

  const { openStripePage: handleViewInvoices, isLoading: isLoadingInvoices } =
    useOpenStripePage(appState.auth!.user.org_id, '');

  const subscriptionQuery = useOrganisationSubscriptionIndex({
    pathParams: {
      organisation: appState.auth!.user.org_id,
    },
  });

  const handleCancelSuccess = () => {
    setShowCancelForm(false);
    subscriptionQuery.refetch();
  };

  appState.pageTitle('Payment Unsuccessful: Action Needed');

  return (
    <div className="flex h-full overflow-y-auto">
      <div className="flex min-h-screen w-full flex-wrap content-start justify-center">
        <div className="flex w-full max-w-7xl flex-col">
          <header className="relative mx-6 my-7 flex h-max w-full items-center justify-between">
            <h3 className="font-work text-2xl font-bold">
              Payment Unsuccessful: Action Needed
            </h3>
            <LogoutLink />
          </header>

          <div className="text-keyword-explorer-input mx-6 mb-7 h-max w-full justify-center rounded-lg border border-gray-200 bg-white p-10 py-12 font-work">
            <div>
              <p>
                We're unable to process your recent subscription payment. To
                ensure your subscription remains active and uninterrupted, we
                kindly ask that you update your payment information or settle
                the outstanding invoice.
              </p>
              <br />

              <p>Please click the button below to resolve this issue:</p>
              <br />
            </div>

            <div className="mx-auto flex w-96 flex-col gap-2">
              <div>
                <Button
                  onClick={handleViewInvoices}
                  isLoading={isLoadingInvoices}
                  color="secondary"
                  fullWidth
                  text="VIEW INVOICES"
                />
              </div>
              {subscriptionQuery.isSuccess && (
                <>
                  {subscriptionQuery.data?.data.is_canceled ? (
                    <p className="text-center">
                      Subscription has been cancelled
                    </p>
                  ) : (
                    <Button
                      onClick={() => setShowCancelForm(true)}
                      color="gray"
                      variant="outline"
                      text="CANCEL SUBSCRIPTION"
                    />
                  )}
                </>
              )}
            </div>
          </div>

          {showCancelForm && (
            <div className="text-keyword-explorer-input mx-6 w-full overflow-hidden rounded-lg border border-gray-200">
              <CancelSubscriptionForm
                auth={appState.auth!}
                onCancel={handleCancelSuccess}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
