import { Menus } from '@/Components/Menus';
import PageContainer from '@/Components/PageContainer';
import SimpleTitle from '@/Components/SimpleTitle';
import * as Table from '@/Components/Table';
import {
  fetchListUrlKeywords,
  useListUrls,
  useProjectContentScannerUpdate,
  useProjectImportUrls,
  useStartScan,
} from '@/api/openapiComponents';
import { useAppStore } from '../AppLoader/stores';
import { TaskDot } from './components/TaskDot';
import { Button } from '@/Components/v2/Button';
import { formatThousandSeperator } from '@/utils';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import Pagination from '@/Components/ProjectKeywords/Pagination';
import { useNavigate, useSearch } from '@tanstack/react-router';
import { useEffect, useState } from 'react';
import { Tooltip } from '@/Components/v2/Tooltip';
import { useConnectToGoogleSearchConsole } from '../Settings/hooks';
import { ChevronDown, Globe, RefreshCcw, Scan, Trophy } from 'lucide-react';
import AppLabel, { GradualScoreVariant } from '@/Components/Labels/AppLabel';
import {
  ScannedContentResource,
  ScannedContentSubResource,
} from '@/api/openapiSchemas';
import { useSnackbar } from '@/Components/v2/Snackbar';
import {
  formatRange,
  RangeSelector,
} from '@/Components/Table/components/RangeSelector';
import { PopoverMenu } from '@/Components/v2/Menu/ButtonMenu';
import { useQueryClient } from '@tanstack/react-query';
import { ContentKeywordsSlideover } from './components/ContentKeywordsSlideover';
import { getOppVariant } from './util';
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleConsoleConnect } from '@/Components/Table/components/GoogleConsoleConnect';
import { UrlCreditsManagementDialog } from '@/Components/UrlCreditsManagementDialog/UrlCreditsManagementDialog';
import { OutOfUrlCreditsBanner } from '@/Components/Banner/specific/OutOfUrlCreditsBanner';
import { useAllPageActions } from '../AllPages/hooks';
import { ConfirmDialog } from '@/Components/ConfirmDialog';
import { WarningAlert } from '@/Components/v2/Alert';

const dummyData = [
  {
    path: '/seo/keyword-research/basics',
    title: 'Mastering Keyword Research: Basics',
    total_clicks: 0,
    keyword: 'Keyword Research',
    opportunity_score: 14,
    position: 1,
    clicks: 4,
    impressions: 100,
  },
  {
    path: '/seo/on-page-optimization/guide',
    title: 'On-Page Optimization: The Ultimate Guide',
    total_clicks: 0,
    keyword: 'On-Page SEO',
    opportunity_score: 19,
    position: 1,
    clicks: 40,
    impressions: 1000,
  },
  {
    path: '/seo/link-building/strategies',
    title: 'Effective Link Building Strategies for 2024',
    total_clicks: 0,
    keyword: 'Link Building',
    opportunity_score: 22,
    position: 1,
    clicks: 4,
    impressions: 100,
  },
  {
    path: '/seo/local-seo/ranking-factors',
    title: 'Top Local SEO Ranking Factors',
    total_clicks: 0,
    keyword: 'Local SEO',
    opportunity_score: 17,
    position: 1,
    clicks: 40,
    impressions: 10000,
  },
  {
    path: '/seo/meta-tags/importance',
    title: 'The Importance of Meta Tags in SEO',
    total_clicks: 0,
    keyword: 'Meta Tags',
    opportunity_score: 12,
    position: 1,
    clicks: 400,
    impressions: 1000,
  },
  {
    path: '/seo/technical-seo/checklist',
    title: 'Technical SEO Checklist for Beginners',
    total_clicks: 0,
    keyword: 'Technical SEO',
    opportunity_score: 80,
    position: 1,
    clicks: 400,
    impressions: 10000,
  },
  {
    path: '/seo/website-speed/optimization',
    title: 'How Website Speed Impacts SEO',
    total_clicks: 0,
    keyword: 'Website Speed',
    opportunity_score: 15,
    position: 1,
    clicks: 400,
    impressions: 1000,
  },
  {
    path: '/seo/google-algorithm/updates',
    title: 'Navigating Google Algorithm Updates',
    total_clicks: 0,
    keyword: 'Google Algorithm',
    opportunity_score: 23,
    position: 1,
    clicks: 400,
    impressions: 1000,
  },
  {
    path: '/seo/mobile-seo/best-practices',
    title: 'Best Practices for Mobile SEO',
    total_clicks: 0,
    keyword: 'Mobile SEO',
    opportunity_score: 16,
    position: 1,
    clicks: 400,
    impressions: 10000,
  },
  {
    path: '/seo/content-marketing/seo-strategy',
    title: 'How Content Marketing Supports SEO Strategy',
    total_clicks: 0,
    keyword: 'Content Marketing',
    opportunity_score: 20,
    position: 1,
    clicks: 4000,
    impressions: 100000,
  },
  {
    path: '/seo/image-optimization/tips',
    title: 'SEO Image Optimization Tips',
    total_clicks: 0,
    keyword: 'Image Optimization',
    opportunity_score: 50,
    position: 1,
    clicks: 40,
    impressions: 10000,
  },
  {
    path: '/seo/voice-search/optimization',
    title: 'Optimizing for Voice Search in 2024',
    total_clicks: 0,
    keyword: 'Voice Search',
    opportunity_score: 18,
    position: 1,
    clicks: 4,
    impressions: 100,
  },
  {
    path: '/seo/rich-snippets/guide',
    title: 'A Guide to Rich Snippets for SEO',
    total_clicks: 0,
    keyword: 'Rich Snippets',
    opportunity_score: 50,
    position: 1,
    clicks: 400,
    impressions: 1000,
  },
  {
    path: '/seo/user-experience/impact',
    title: 'How User Experience (UX) Impacts SEO',
    total_clicks: 0,
    keyword: 'User Experience',
    opportunity_score: 21,
    position: 1,
    clicks: 40,
    impressions: 1000,
  },
];

export const ContentScanner = () => {
  const appState = useAppStore();
  const { showSnackbar } = useSnackbar();

  const search = useSearch({ from: '/page-scanner' });
  const navigate = useNavigate();

  const [contentToBeShownInSlideover, setContentToBeShownInSlideover] =
    useState<ScannedContentResource>();

  const [isScanning, setIsScanning] = useState(
    appState.currentProject!.is_crawling,
  );
  const [showSyncConfirmDialog, setShowSyncConfirmDialog] = useState(false);

  const client = useQueryClient();

  const gscHookProps = useConnectToGoogleSearchConsole(
    appState.currentProject!.id,
  );

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      gscHookProps.setupGoogleConsole({
        pathParams: {
          project: appState.currentProject!.id,
        },
        body: {
          code: codeResponse.code,
        },
      });
    },
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/webmasters.readonly',
    ux_mode: 'popup',
  });

  const gscIsActive =
    gscHookProps.googleSearchConsoleData?.data.state === 'active';

  const sitemapSyncMutation = useProjectImportUrls();
  const scannedContentQuery = useListUrls(
    {
      pathParams: {
        project: appState.currentProject!.id,
      },
      queryParams: {
        page: search.page,
        limit: 100,
        sort_by: search.sortBy,
        sort_direction: search.sortDirection,
        filters: {
          search: search.filters?.search,
          keyword_clicks_max: search.filters?.keyword_clicks_max,
          keyword_clicks_min: search.filters?.keyword_clicks_min,
          keyword_impressions_max: search.filters?.keyword_impressions_max,
          keyword_impressions_min: search.filters?.keyword_impressions_min,
          opportunity_score_max: search.filters?.opportunity_score_max,
          opportunity_score_min: search.filters?.opportunity_score_min,
          keyword_position_max: search.filters?.keyword_position_max,
          keyword_position_min: search.filters?.keyword_position_min,
          total_clicks_max: search.filters?.total_clicks_max,
          total_clicks_min: search.filters?.total_clicks_min,
        },
      },
    },
    {
      enabled: gscIsActive,
    },
  );

  const injectSubrowData = (
    id: number,
    data: ScannedContentSubResource,
    total: number,
  ) => {
    client.setQueriesData(
      { predicate: (query) => query.queryKey.includes('listUrls') },
      (prev: any) => {
        return {
          ...prev,
          data: prev.data.map((item: ScannedContentResource) => {
            if (item.id === id) {
              return {
                ...item,
                ...(data ?? {}),
                keyword_count: total,
              };
            }
            return item;
          }),
        };
      },
    );
  };

  useEffect(() => {
    const callBack = () => {
      setIsScanning(false);
      scannedContentQuery.refetch();
      showSnackbar({
        message: `Scan complete`,
        color: 'secondary',
      });
    };
    const importCallback = () => {
      if (search.page === 1) {
        scannedContentQuery.refetch();
      } else {
        navigate({
          search: { page: 1, sortBy: 'total_clicks', sortDirection: 'DESC' },
        });
      }
    };
    const contentScanChannel = window.Echo.private(
      `projects.${appState.currentProject?.id}`,
    ).listen('.ContentScanCompleted', callBack);
    const importCompleteChannel = window.Echo.private(
      `projects.${appState.currentProject?.id}`,
    ).listen('.ImportCompleted', importCallback);

    const singleScanChannel = window.Echo.private(
      `projects.${appState.currentProject?.id}`,
    ).listen('.ProjectUrlScanCompleted', (e: { project_url_id: number }) => {
      if (
        scannedContentQuery.data?.data.some((item) => {
          return item.id === e.project_url_id;
        })
      ) {
        loadSubrowData(e.project_url_id);
      }
    });

    return () => {
      importCompleteChannel.stopListening('.ImportCompleted');
      singleScanChannel.stopListening('.ProjectUrlScanCompleted');
      contentScanChannel.stopListening('.ContentScanCompleted', callBack);
    };
  }, [scannedContentQuery.data?.data]);

  const handleSitemapSync = () => {
    setShowSyncConfirmDialog(false);
    client.setQueriesData(
      { predicate: (query) => query.queryKey.includes('listUrls') },
      (prev: any) => {
        return {
          ...prev,
          data: [],
        };
      },
    );
    sitemapSyncMutation.mutate(
      {
        pathParams: {
          project: appState.currentProject!.id,
        },
      },
      {
        onSuccess: () => {
          setIsScanning(true);
          appState.refreshCurrentProject();
        },
      },
    );
  };

  const { handleRescan } = useAllPageActions();

  const loadSubrowData = async (id: number) => {
    const response = await fetchListUrlKeywords({
      pathParams: {
        project: appState.currentProject!.id,
        url: id,
      },
      queryParams: {
        limit: 1,
      },
    });
    const data = {
      ...(response.data[0] ?? {}),
      is_single_scan_loading: false,
    };
    injectSubrowData(id, data, response.meta.total);
  };

  const scannedContent = (scannedContentQuery.data?.data ??
    []) as (ScannedContentResource & {
    subRows?: ScannedContentSubResource[];
  })[];

  appState.pageTitle('Content scanner');

  return (
    <>
      <ConfirmDialog
        title="Are you sure you want to sync the sitemap?"
        isOpen={showSyncConfirmDialog}
        onClose={() => setShowSyncConfirmDialog(false)}
        onConfirm={handleSitemapSync}
      />
      <ContentKeywordsSlideover
        content={contentToBeShownInSlideover}
        onClose={() => setContentToBeShownInSlideover(undefined)}
      />
      <Menus additionalBanners={<OutOfUrlCreditsBanner />}>
        <PageContainer>
          <div className="flex justify-between">
            <SimpleTitle>Page scanner</SimpleTitle>{' '}
            <div className="flex items-center gap-2">
              {gscIsActive && (
                <Tooltip
                  title="Pages are currently scanning"
                  disabled={!isScanning}
                >
                  <Button
                    disabled={!gscIsActive}
                    text="Sitemap sync"
                    prependIcon={RefreshCcw}
                    variant="ghost"
                    isLoading={isScanning || sitemapSyncMutation.isPending}
                    onClick={() => setShowSyncConfirmDialog(true)}
                  />
                </Tooltip>
              )}
            </div>
          </div>
          <p className="my-4">
            Find pages that are not fully optimized for their main
            traffic-generating keywords.{' '}
            <a
              href="https://docs.seo.ai/a-39-page-scanner"
              target="_blank"
              className="underline"
              rel="noreferrer"
            >
              Read more
            </a>
            .
          </p>
          {appState.subscription?.is_trialing && gscIsActive && (
            <WarningAlert title="Only 10 URLs are scanned in trial version" />
          )}
          <Table.Root<ScannedContentResource, ScannedContentSubResource>
            isLoading={scannedContentQuery.isLoading}
            items={
              !gscIsActive && !scannedContentQuery.isLoading
                ? [...scannedContent, ...dummyData]
                : (scannedContent ?? [])
            }
            meta={scannedContentQuery.data?.meta}
            overlay={
              gscHookProps && (
                <GoogleConsoleConnect
                  {...gscHookProps}
                  page="all scanned pages"
                />
              )
            }
            onSortChange={({ sortBy, sortDirection }) => {
              navigate({
                search: (prev) => ({
                  ...prev,
                  sortBy,
                  sortDirection,
                }),
              });
            }}
            renderRow={({ index, cells, classNames }) => (
              <tr
                className={`${
                  !gscIsActive ? 'pointer-events-none blur' : ''
                } ${classNames}`}
              >
                {cells}
              </tr>
            )}
            sorting={search}
            columns={[
              {
                heading: 'URL',
                render: (item) => (
                  <a
                    title={item.url ?? ''}
                    href={item.url ?? ''}
                    target="_blank"
                    className="block max-w-[250px] hover:opacity-50"
                    rel="noreferrer"
                  >
                    {item.path}
                  </a>
                ),
              },
              {
                heading: 'TOTAL CLICKS',
                sortableHeader: 'total_clicks',
                rightAlign: true,
                render: (item) =>
                  item.total_clicks === undefined ? (
                    <SkeletonLoader height="2xs" />
                  ) : (
                    <p>{formatThousandSeperator(item.total_clicks)}</p>
                  ),
              },
              {
                heading: 'OPP.',
                sortableHeader: 'opportunity_score',
                render: (item) =>
                  item.is_scan_loading ? (
                    <SkeletonLoader height="2xs" />
                  ) : (
                    <p>
                      {item.keyword ? (
                        <AppLabel
                          variant={
                            GradualScoreVariant[
                              getOppVariant(item.opportunity_score)
                            ]
                          }
                        >
                          <div className="flex w-6 justify-center">
                            {item.opportunity_score}
                          </div>
                        </AppLabel>
                      ) : (
                        '-'
                      )}
                    </p>
                  ),
              },
              {
                heading: 'TOP KEYWORD',
                sortableHeader: 'keyword_name',
                render: (item) =>
                  item.is_scan_loading ? (
                    <SkeletonLoader height="2xs" />
                  ) : (
                    <div
                      className={`cursor-pointer gap-1 font-bold hover:opacity-60`}
                      onClick={() => setContentToBeShownInSlideover(item)}
                    >
                      {item.keyword ?? '-'}{' '}
                      {item.keyword_count > 1 && (
                        <span className="text-xs font-normal">
                          (+{item.keyword_count})
                        </span>
                      )}
                    </div>
                  ),
              },
              {
                heading: 'KW CLICKS',
                sortableHeader: 'keyword_clicks',
                rightAlign: true,
                render: (item) =>
                  item.is_scan_loading ? (
                    <SkeletonLoader height="2xs" />
                  ) : (
                    <p className={gscIsActive ? '' : '-mr-7'}>
                      {gscIsActive
                        ? item.keyword
                          ? formatThousandSeperator(item.clicks)
                          : '-'
                        : 'Connect'}
                    </p>
                  ),
              },
              {
                heading: 'KW IMPR.',
                sortableHeader: 'keyword_impressions',
                rightAlign: true,
                render: (item) =>
                  item.is_scan_loading ? (
                    <SkeletonLoader height="2xs" />
                  ) : (
                    <p className="whitespace-nowrap">
                      {gscIsActive
                        ? item.keyword
                          ? formatThousandSeperator(item.impressions)
                          : '-'
                        : 'Search Console'}
                    </p>
                  ),
              },
              {
                heading: 'GSC POS.',
                sortableHeader: 'keyword_position',
                rightAlign: true,
                render: (item) =>
                  item.is_scan_loading ? (
                    <SkeletonLoader height="2xs" />
                  ) : (
                    <p>
                      {item.keyword ? (item.position.toFixed(0) ?? '-') : '-'}
                    </p>
                  ),
              },
              {
                heading: 'T',
                render: (item) =>
                  item.is_scan_loading ? (
                    <SkeletonLoader height="2xs" />
                  ) : (
                    <Tooltip
                      side="top"
                      title={item.meta_title}
                      disabled={item.meta_title?.length === 0}
                    >
                      <TaskDot
                        completed={item.in_meta_title_count > 0}
                        disabled={!item.keyword}
                      />
                    </Tooltip>
                  ),
              },
              {
                heading: 'H1',
                render: (item) =>
                  item.is_scan_loading ? (
                    <SkeletonLoader height="2xs" />
                  ) : (
                    <Tooltip
                      side="top"
                      title={item.title}
                      disabled={item.title.length === 0}
                    >
                      <TaskDot
                        completed={item.in_title_count > 0}
                        disabled={!item.keyword}
                      />
                    </Tooltip>
                  ),
              },
              {
                heading: 'H2',
                render: (item) =>
                  item.is_scan_loading ? (
                    <SkeletonLoader height="2xs" />
                  ) : (
                    <TaskDot
                      completed={item.in_h2_count > 0}
                      disabled={!item.keyword}
                    />
                  ),
              },
              {
                heading: 'H3',
                render: (item) =>
                  item.is_scan_loading ? (
                    <SkeletonLoader height="2xs" />
                  ) : (
                    <TaskDot
                      completed={item.in_h3_count > 0}
                      disabled={!item.keyword}
                    />
                  ),
              },
              {
                heading: 'P',
                render: (item) =>
                  item.is_scan_loading ? (
                    <SkeletonLoader height="2xs" />
                  ) : (
                    <TaskDot
                      completed={item.in_text_count > 0}
                      disabled={!item.keyword}
                    />
                  ),
              },
              {
                heading: 'MD',
                render: (item) =>
                  item.is_scan_loading ? (
                    <SkeletonLoader height="2xs" />
                  ) : (
                    <TaskDot
                      completed={item.in_meta_description_count > 0}
                      disabled={!item.keyword}
                    />
                  ),
              },
              {
                render: (item) => (
                  <PopoverMenu
                    trigger={
                      <Button
                        text="action"
                        variant="outline"
                        dense
                        size="xs"
                        disabled={item.is_scan_loading}
                        isLoading={item.is_single_scan_loading}
                        appendIcon={ChevronDown}
                      />
                    }
                    items={[
                      {
                        title: 'Rescan',
                        prependIcon: Scan,
                        onClick: () => handleRescan(item.id),
                      },
                      {
                        title: 'Open in editor',
                        prependIcon: Globe,
                        onClick: () =>
                          navigate({
                            to: '/create-content/import',
                            search: (prev) => ({
                              ...prev,
                              url: item.url,
                            }),
                          }),
                      },
                    ]}
                  />
                ),
              },
            ]}
          >
            <Table.Header
              disabled={!gscIsActive}
              search={search.filters?.search}
              onSearchChange={(value) => {
                navigate({
                  search: {
                    ...search,
                    page: 1,
                    filters: {
                      ...search.filters,
                      search: value,
                    },
                  },
                });
              }}
            >
              <Table.ResultsTotal
                title="URLs"
                total={scannedContentQuery.data?.meta.total}
              />

              <Table.FilterItem
                active={
                  search.filters?.keyword_clicks_min === 1 &&
                  search.filters.opportunity_score_min === 50 &&
                  search.filters.keyword_position_min === 5
                }
                disabled={!gscIsActive}
                onRemove={() => navigate({ search: { filters: {} } })}
                onClick={() =>
                  navigate({
                    search: {
                      ...search,
                      filters: {
                        keyword_clicks_min: 1,
                        opportunity_score_min: 50,
                        keyword_position_min: 5,
                      },
                    },
                  })
                }
              >
                <Trophy size={14} className="text-primary" />
                Quick wins
              </Table.FilterItem>
              <Table.FilterPopover
                name="Total clicks"
                onRemoveFilter={() =>
                  navigate({
                    search: {
                      filters: {
                        ...search.filters,
                        total_clicks_min: undefined,
                        total_clicks_max: undefined,
                      },
                    },
                  })
                }
                disabled={!gscIsActive}
                filterName={formatRange({
                  min: search.filters?.total_clicks_min,
                  max: search.filters?.total_clicks_max,
                  name: 'Total clicks',
                })}
              >
                <RangeSelector
                  values={{
                    min: search.filters?.total_clicks_min,
                    max: search.filters?.total_clicks_max,
                  }}
                  onAccept={(range) => {
                    navigate({
                      search: {
                        ...search,
                        filters: {
                          ...search.filters,
                          total_clicks_min: range.min,
                          total_clicks_max: range.max,
                        },
                      },
                    });
                  }}
                />
              </Table.FilterPopover>
              <Table.FilterPopover
                name="Opp."
                onRemoveFilter={() =>
                  navigate({
                    search: {
                      filters: {
                        ...search.filters,
                        opportunity_score_min: undefined,
                        opportunity_score_max: undefined,
                      },
                    },
                  })
                }
                disabled={!gscIsActive}
                filterName={formatRange({
                  min: search.filters?.opportunity_score_min,
                  max: search.filters?.opportunity_score_max,
                  name: 'Opportunity score',
                })}
              >
                <RangeSelector
                  values={{
                    min: search.filters?.opportunity_score_min,
                    max: search.filters?.opportunity_score_max,
                  }}
                  onAccept={(range) => {
                    navigate({
                      search: {
                        ...search,
                        filters: {
                          ...search.filters,
                          opportunity_score_min: range.min,
                          opportunity_score_max: range.max,
                        },
                      },
                    });
                  }}
                />
              </Table.FilterPopover>
              <Table.FilterPopover
                name="KW clicks"
                onRemoveFilter={() =>
                  navigate({
                    search: {
                      filters: {
                        ...search.filters,
                        keyword_clicks_min: undefined,
                        keyword_clicks_max: undefined,
                      },
                    },
                  })
                }
                disabled={!gscIsActive}
                filterName={formatRange({
                  min: search.filters?.keyword_clicks_min,
                  max: search.filters?.keyword_clicks_max,
                  name: 'KW clicks',
                })}
              >
                <RangeSelector
                  values={{
                    min: search.filters?.keyword_clicks_min,
                    max: search.filters?.keyword_clicks_max,
                  }}
                  onAccept={(range) => {
                    navigate({
                      search: {
                        ...search,
                        filters: {
                          ...search.filters,
                          keyword_clicks_min: range.min,
                          keyword_clicks_max: range.max,
                        },
                      },
                    });
                  }}
                />
              </Table.FilterPopover>
              <Table.FilterPopover
                name="KW impressions"
                onRemoveFilter={() =>
                  navigate({
                    search: {
                      filters: {
                        ...search.filters,
                        keyword_impressions_min: undefined,
                        keyword_impressions_max: undefined,
                      },
                    },
                  })
                }
                disabled={!gscIsActive}
                filterName={formatRange({
                  min: search.filters?.keyword_impressions_min,
                  max: search.filters?.keyword_impressions_max,
                  name: 'KW impressions',
                })}
              >
                <RangeSelector
                  values={{
                    min: search.filters?.keyword_impressions_min,
                    max: search.filters?.keyword_impressions_max,
                  }}
                  onAccept={(range) => {
                    navigate({
                      search: {
                        ...search,
                        filters: {
                          ...search.filters,
                          keyword_impressions_min: range.min,
                          keyword_impressions_max: range.max,
                        },
                      },
                    });
                  }}
                />
              </Table.FilterPopover>
              <Table.FilterPopover
                name="GSC position"
                onRemoveFilter={() =>
                  navigate({
                    search: {
                      filters: {
                        ...search.filters,
                        keyword_position_min: undefined,
                        keyword_position_max: undefined,
                      },
                    },
                  })
                }
                disabled={!gscIsActive}
                filterName={formatRange({
                  min: search.filters?.keyword_position_min,
                  max: search.filters?.keyword_position_max,
                  name: 'GSC position',
                })}
              >
                <RangeSelector
                  values={{
                    min: search.filters?.keyword_position_min,
                    max: search.filters?.keyword_position_max,
                  }}
                  onAccept={(range) => {
                    navigate({
                      search: {
                        ...search,
                        filters: {
                          ...search.filters,
                          keyword_position_min: range.min,
                          keyword_position_max: range.max,
                        },
                      },
                    });
                  }}
                />
              </Table.FilterPopover>
            </Table.Header>
            <Table.NoContent>
              {isScanning ? (
                'Pages are currently scanning'
              ) : (
                <div className="flex h-96 w-full items-center justify-center">
                  <Button
                    color="secondary"
                    text={gscIsActive ? 'Start scan' : 'Connect search console'}
                    onClick={gscIsActive ? handleSitemapSync : login}
                    isLoading={sitemapSyncMutation.isPending}
                    disabled={isScanning}
                  />
                </div>
              )}
            </Table.NoContent>
            <Table.Footer>
              {gscIsActive && scannedContent.length > 0 && (
                <Pagination
                  currentPage={Number(search.page)}
                  lastPage={scannedContentQuery.data?.meta.last_page}
                  setCurrentPage={(page) =>
                    navigate({ search: { ...search, page } })
                  }
                />
              )}
            </Table.Footer>
          </Table.Root>
        </PageContainer>
      </Menus>
    </>
  );
};
