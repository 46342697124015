import Lottie from 'react-lottie';
import lottieReplaceLoader from '../../../../../../../public/lottie/internal-linking.json';
export const LoadingSuggestionsAnimation = () => (
  <Lottie
    width={'20rem'}
    height={'auto'}
    isClickToPauseDisabled
    style={{ cursor: 'default' }}
    options={{
      animationData: lottieReplaceLoader,
      loop: true,
      autoplay: true,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    }}
  />
);
