import { ArrowLeft } from 'lucide-react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from '@/Components/Stripe/CheckoutForm';
import { useAppStore } from './AppLoader/stores';
import { Loader } from '@/Components/Loader';
import { Link } from '@tanstack/react-router';
import {
  useOrganisationPaymentSetup,
  useOrganisationSubscriptionList,
  useProductsShow,
} from '@/api/openapiComponents';

// Make sure to call `loadStripe` outside a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);

type Props = {
  productId: number;
};

export default function Checkout({ productId }: Props) {
  const appState = useAppStore();

  const productQuery = useProductsShow({ pathParams: { product: productId } });
  const subscriptionsQuery = useOrganisationSubscriptionList({
    pathParams: {
      organisation: appState.organisation!.id,
    },
  });

  const {
    data: intentData,
    isRefetching: isRefetchingNewIntentData,
    refetch: refreshIntentData,
  } = useOrganisationPaymentSetup({
    pathParams: {
      organisation: appState.organisation!.id,
    },
  });

  const handleRefetchIntent = () => {
    refreshIntentData();
  };
  if (!intentData || !productQuery.data || !subscriptionsQuery.data) {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <Loader className="h-20 w-20 stroke-green-500" />
      </div>
    );
  }

  const options: any = {
    // passing the client secret obtained from the server
    clientSecret: intentData.data.client_secret,
    appearance: {
      theme: 'stripe',
    },
  };

  const product = productQuery.data?.data;
  const hasPreviousSubscription = subscriptionsQuery.data.meta.total > 0;

  appState.pageTitle('Choose product');

  return (
    <div className={'min-h-full'}>
      <div className={'flex'}>
        <div className={'hidden h-screen w-[50%] bg-zinc-50 lg:block'}></div>
        <div
          className={'h-screen w-full lg:w-[50%]'}
          style={{
            boxShadow: '15px 0 30px 0 rgba(0,0,0,0.18)',
          }}
        ></div>

        <div
          className={
            'absolute left-1/2 top-20 grid min-w-full -translate-x-1/2 transform grid-cols-1 md:min-w-[768px] lg:min-w-[920px] lg:grid-cols-2 lg:gap-x-36'
          }
        >
          <div className={'hidden lg:block'}>
            <Link
              to="/finalize"
              className={'flex items-center hover:ml-[-1px]'}
            >
              <ArrowLeft className={'mr-2'} size={20} />

              <img
                src="/svg/logo_16_bars_black.svg"
                className="h-6 grayscale"
              />
            </Link>

            <div className={'pl-7'}>
              <div className={'mt-8 text-3xl font-bold'}>
                {hasPreviousSubscription
                  ? `Subscribe to '${product.name}'`
                  : 'Try 7-day FREE trial. Cancel Anytime.'}
              </div>
              <div className={'mt-2 text-sm text-gray-500'}>
                {hasPreviousSubscription
                  ? `$${product.price} USD + VAT per ${product.interval}.`
                  : `Then $${product.price} USD + VAT per ${product.interval} afterwards.`}
              </div>

              <div className={'absolute bottom-0 text-sm text-gray-500'}>
                Powered by
                <img src="/svg/stripe_logo.svg" className="inline-block h-6" />
              </div>
            </div>
          </div>
          <div className={'px-8 sm:px-24 md:px-48 lg:px-0'}>
            <Link
              to="/finalize"
              className={'xs:mb-0 mb-5 flex items-center lg:hidden'}
            >
              <ArrowLeft className={'mr-2'} size={20} />

              <img
                src="/svg/logo_16_bars_black.svg"
                className="h-6 grayscale"
              />
            </Link>

            <div className={'mb-5 text-xl font-semibold'}>
              Enter payment details
            </div>

            <input
              className={
                'mb-5 w-full rounded border-0 bg-gray-100 focus:ring-0'
              }
              disabled={true}
              type={'text'}
              value={appState.auth!.user.email}
              style={{
                boxShadow:
                  '0 0 0 1px #e0e0e0,0 2px 4px 0 rgba(0,0,0,0.07),0 1px 1.5px 0 rgba(0,0,0,0.05)',
              }}
            />

            {!isRefetchingNewIntentData && (
              <Elements
                key={intentData.data.client_secret}
                stripe={stripePromise}
                options={options}
              >
                <CheckoutForm
                  organisationId={appState.organisation!.id}
                  productId={product.id}
                  hasPreviousSubscription={hasPreviousSubscription}
                  handleRefetchIntent={handleRefetchIntent}
                />
              </Elements>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
