import { VerticalMenuProps, VerticalMenu } from './VerticalMenu';
import { Popover, PopoverContext, PopoverProps } from './Popover';

type Props = VerticalMenuProps & Omit<PopoverProps, 'children'>;

export const PopoverMenu = (props: Props) => {
  return (
    <Popover {...props}>
      <div className="overflow-hidden rounded">
        <PopoverContext.Consumer>
          {(closePopover) => (
            <VerticalMenu
              {...props}
              items={props.items.map((item) =>
                item.onClick
                  ? {
                      ...item,
                      onClick: () => {
                        item.onClick!();
                        props.onClose ? props.onClose() : closePopover();
                      },
                    }
                  : item,
              )}
            />
          )}
        </PopoverContext.Consumer>
      </div>
    </Popover>
  );
};
