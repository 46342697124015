import { Dialog } from '@/Components/Dialog';
import SerpVisualizer from '@/Components/SerpVisualizer';
import SimpleButton from '@/Components/SimpleButton';
import { useState } from 'react';
import { ReportSectionRecommendationsContent } from './ReportSectionRecommendationContent';
import { Crosshair } from 'lucide-react';
import { useShallow } from 'zustand/react/shallow';
import { useDocumentStore } from '@/Pages/Document/stores';
import { tracking } from '@/Services/tracking/Tracking';
import { Button } from '@/Components/v2/Button';

type Props = {
  documentId: number;
  projectId: number;
  domain: string;
  keyword: string;
  isInsideTitleTag?: boolean;
};

export const SerpVisualizerButton = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleCloseDialog = () => setIsOpen(false);
  const handleOpenDialog = () => {
    tracking.event('serp_visualizer_opened');
    setIsOpen(true);
  };
  const serp = useDocumentStore(
    useShallow(
      ({ metaTitle, metaDescription, setMetaTitle, setMetaDescription }) => ({
        metaTitle,
        metaDescription,
        setMetaTitle,
        setMetaDescription,
      }),
    ),
  );
  return (
    <>
      {props.isInsideTitleTag ? (
        <Button
          fullWidth
          variant="outline"
          prependIcon={<Crosshair size={20} />}
          onClick={handleOpenDialog}
          text="SERP VISUALIZER"
        />
      ) : (
        <SimpleButton
          className="h-8 w-full rounded-md bg-white p-2 font-bold shadow-lg"
          onClick={handleOpenDialog}
        >
          <ReportSectionRecommendationsContent
            title="SERP VISUALIZER"
            icon={<Crosshair size={20} />}
          />
        </SimpleButton>
      )}

      <Dialog
        isOpen={isOpen}
        handleClose={handleCloseDialog}
        title="Serp visualizer"
        fullScreen={true}
        backgroundColor={'gray'}
      >
        <SerpVisualizer {...props} serp={serp} />
      </Dialog>
    </>
  );
};
