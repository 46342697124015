import React from 'react';
import { Dialog, DialogSize } from './Dialog';
import { Errors } from './Errors';
import { ButtonColors } from './v2/Button';

type Props = {
  isOpen: boolean;
  isLoading?: boolean;
  noCancel?: boolean;
  onClose?: () => void;
  title: string;
  description?: string[];
  content?: React.ReactNode;
  error?: null | any;
  confirmText?: string;
  cancelText?: string;
  disabled?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmColor?: ButtonColors;
  size?: DialogSize;
};

export const ConfirmDialog = ({
  description,
  isOpen,
  isLoading,
  noCancel,
  onCancel,
  onConfirm,
  error,
  confirmText,
  cancelText,
  disabled,
  content,
  title,
  confirmColor = 'secondary',
  onClose,
  size = 'sm',
}: Props) => {
  return (
    <Dialog
      isOpen={isOpen}
      persistent={isLoading}
      handleClose={onClose}
      title={title}
      size={size}
      actions={[
        ...(noCancel
          ? []
          : [
              {
                text: cancelText ?? 'Cancel',
                onClick: onCancel ?? onClose,
                variant: 'outline' as const,
                color: 'gray' as const,
                disabled: isLoading,
              },
            ]),
        {
          text: confirmText ?? 'Confirm',
          onClick: onConfirm,
          variant: 'fill',
          color: confirmColor,
          isLoading: isLoading,
          disabled: disabled,
        },
      ]}
    >
      <div>
        {description?.map((paragraph) => (
          <p className="my-1 text-gray-500">{paragraph}</p>
        ))}
        {content && (
          <div className="my-1">
            <form
              onSubmit={(e) => {
                if (!disabled) onConfirm?.();
                e.preventDefault();
              }}
            >
              {content}
              <button type="submit" hidden />
            </form>
          </div>
        )}
        <div className={error ? 'mb-4' : ''}>
          <Errors error={error} />
        </div>
      </div>
    </Dialog>
  );
};
