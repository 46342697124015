import { useListUrlKeywords } from '@/api/openapiComponents';
import { SlideOver } from '@/Components/SlideOver';
import { useAppStore } from '@/Pages/AppLoader/stores';
import * as Table from '@/Components/Table';
import { ScannedContentResource } from '@/api/openapiSchemas';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { TaskDot } from './TaskDot';
import AppLabel, { GradualScoreVariant } from '@/Components/Labels/AppLabel';
import { formatThousandSeperator } from '@/utils';
import { getOppVariant } from '../util';
import { Tooltip } from '@/Components/v2/Tooltip';

type Props = {
  content?: ScannedContentResource;
  onClose: () => void;
};

export const ContentKeywordsSlideover = ({ content, onClose }: Props) => {
  const appState = useAppStore();

  const keywordsQuery = useListUrlKeywords(
    {
      pathParams: {
        project: appState.currentProject!.id,
        url: content?.id,
      },
      queryParams: {
        limit: 100,
      },
    },
    {
      enabled: !!content,
    },
  );

  return (
    <SlideOver
      isOpen={!!content}
      size="lg"
      onClickOutside={onClose}
      title={content?.path}
      titleColor="text-primary"
      onClose={onClose}
    >
      <Table.Root
        items={keywordsQuery.data?.data ?? []}
        isLoading={keywordsQuery.isFetching}
        columns={[
          {
            heading: 'Keyword',
            render: (item) =>
              item.keyword === undefined ? (
                <SkeletonLoader height="2xs" />
              ) : (
                <div className="font-bold">{item.keyword}</div>
              ),
          },
          {
            heading: 'Opp.',
            render: (item) =>
              item.opportunity_score === undefined ? (
                <SkeletonLoader height="2xs" />
              ) : (
                <p>
                  <AppLabel
                    variant={
                      GradualScoreVariant[getOppVariant(item.opportunity_score)]
                    }
                  >
                    <div className="flex w-6 justify-center">
                      {item.opportunity_score}
                    </div>
                  </AppLabel>
                </p>
              ),
          },
          {
            rightAlign: true,
            heading: 'Clicks',
            render: (item) =>
              item.clicks === undefined ? (
                <SkeletonLoader height="2xs" />
              ) : (
                <div>{formatThousandSeperator(item.clicks)}</div>
              ),
          },
          {
            heading: 'Impressions',
            rightAlign: true,
            render: (item) =>
              item.impressions === undefined ? (
                <SkeletonLoader height="2xs" />
              ) : (
                <div>{formatThousandSeperator(item.impressions)}</div>
              ),
          },
          {
            heading: 'Gsc Pos.',
            rightAlign: true,
            render: (item) =>
              item.position === undefined ? (
                <SkeletonLoader height="2xs" />
              ) : (
                <div>{item.position.toFixed(0)}</div>
              ),
          },
          {
            heading: 'T',
            render: (item) =>
              item.is_scan_loading ? (
                <SkeletonLoader height="2xs" />
              ) : (
                <Tooltip
                  side="top"
                  title={item.meta_title}
                  disabled={item.meta_title.length === 0}
                >
                  <TaskDot
                    completed={item.in_meta_title_count > 0}
                    disabled={!item.keyword}
                  />
                </Tooltip>
              ),
          },
          {
            heading: 'H1',
            render: (item) =>
              item.is_scan_loading ? (
                <SkeletonLoader height="2xs" />
              ) : (
                <Tooltip
                  side="top"
                  title={item.title}
                  disabled={item.title.length === 0}
                >
                  <TaskDot
                    completed={item.in_title_count > 0}
                    disabled={!item.keyword}
                  />
                </Tooltip>
              ),
          },
          {
            heading: 'H2',
            render: (item) =>
              item.is_scan_loading ? (
                <SkeletonLoader height="2xs" />
              ) : (
                <TaskDot
                  completed={item.in_h2_count > 0}
                  disabled={!item.keyword}
                />
              ),
          },
          {
            heading: 'H3',
            render: (item) =>
              item.is_scan_loading ? (
                <SkeletonLoader height="2xs" />
              ) : (
                <TaskDot
                  completed={item.in_h3_count > 0}
                  disabled={!item.keyword}
                />
              ),
          },
          {
            heading: 'P',
            render: (item) =>
              item.is_scan_loading ? (
                <SkeletonLoader height="2xs" />
              ) : (
                <TaskDot
                  completed={item.in_text_count > 0}
                  disabled={!item.keyword}
                />
              ),
          },
          {
            heading: 'MD',
            render: (item) =>
              item.is_scan_loading ? (
                <SkeletonLoader height="2xs" />
              ) : (
                <TaskDot
                  completed={item.in_meta_description_count > 0}
                  disabled={!item.keyword}
                />
              ),
          },
        ]}
      ></Table.Root>
      <div className="text-sm italic text-primary">
        This only shows the top 5 keywords. There might be more keywords that
        are not shown here.
      </div>
    </SlideOver>
  );
};
