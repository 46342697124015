import { Tooltip } from '@/Components/v2/Tooltip';
import { HelpCircle } from 'lucide-react';

type Props = {
  title: string;
  useToolTip?: boolean;
};

export function ReportSectionHeader({ title, useToolTip }: Props) {
  return (
    <div>
      <div className="flex items-center gap-2">
        <p className="">{title}</p>
        {useToolTip && (
          <Tooltip
            side="top"
            title={`${'Click the keywords to get AI suggestions'}`}
          >
            <HelpCircle size={20} />
          </Tooltip>
        )}
      </div>

      <div className="mt-3 border-b-2" />
    </div>
  );
}
