import { Dialog } from '@/Components/Dialog';
import { WarningAlert } from '@/Components/v2/Alert';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { Select } from '@/Components/v2/Select';

type GoogleDialogState = {
  isOpen: boolean;
  sites: string[];
  selectedSite: string | undefined;
};

type Props = {
  isOpen: boolean;
  isLoading: boolean;
  hasNoAvailableSites: boolean;
  onClose: () => void;
  onConnect: () => void;
  setGoogleDialog: React.Dispatch<React.SetStateAction<GoogleDialogState>>;
  googleDialog: {
    isOpen: boolean;
    sites: string[];
    selectedSite: string | undefined;
  };
};

export const GSCChooseSiteDialog = ({
  isLoading,
  isOpen,
  hasNoAvailableSites,
  onClose,
  onConnect,
  googleDialog,
  setGoogleDialog,
}: Props) => {
  return (
    <Dialog
      isOpen={isOpen}
      handleClose={onClose}
      size="sm"
      actions={
        hasNoAvailableSites
          ? []
          : [
              {
                text: 'Connect',
                color: 'secondary',
                onClick: onConnect,
                isLoading: isLoading,
              },
            ]
      }
    >
      {hasNoAvailableSites ? (
        <WarningAlert title="You have no access to any available sites." />
      ) : (
        <InputDecoration
          label="Choose site"
          tooltip="Site use with your google search console connection."
        >
          <Select
            onChange={(selectedSite) => {
              setGoogleDialog((prev) => ({ ...prev, selectedSite }));
            }}
            value={googleDialog.selectedSite}
            options={
              googleDialog.sites.map((site) => ({ value: site, id: site })) ??
              []
            }
            placeholder="Choose a site for your connection"
          />
        </InputDecoration>
      )}
    </Dialog>
  );
};
