import { Edit, Trash2 } from 'lucide-react';
import { useRef, useState } from 'react';

type Props = {
  value: string;
  type: 'H2' | 'H3';
  typeDisabled?: boolean;
  onChange?: (value: string) => void;
  onDelete?: () => void;
  onTypeChange?: () => void;
}

export const DndItemInput = ({
  type,
  value,
  typeDisabled,
  onChange = () => null,
  onDelete = () => null,
  onTypeChange = () => null,
}: Props) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [isEditing, setIsEditing] = useState(false);

  const handleFocus = () => {
    if (inputRef.current != null) {
      inputRef.current?.focus();
    }
  };

  return (
    <div
      className={`group/input border-input-border-light-grey relative mb-2 flex w-full rounded-3xl border font-work  hover:shadow-dnd-item
          ${isEditing ? 'shadow-dnd-item' : ''}
          `}
    >
      <div className="flex items-center">
        <div
          onClick={onTypeChange}
          className={`mx-4 flex w-5 items-center justify-center rounded uppercase text-zinc-400 ${
            typeDisabled ? '' : 'hover:bg-gray-200'
          }`}
        >
          {type}
        </div>
      </div>
      <div className="mr-4 w-full text-black">
        <input
          className={`w-full border-none bg-transparent px-0 py-2.5 focus:outline-none focus:ring-0 ${
            type === 'H2' ? 'font-bold' : ''
          }`}
          type="text"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onFocus={() => setIsEditing(true)}
          onBlur={() => setIsEditing(false)}
          ref={inputRef}
        />
      </div>
      <div className="hidden h-full items-center gap-2 pr-4 group-hover/input:flex">
        <Edit
          onClick={handleFocus}
          color="#6D6D6D"
          size={16}
          className="cursor-pointer hover:opacity-50"
        />
        <Trash2
          onClick={onDelete}
          color="#6D6D6D"
          size={16}
          className="cursor-pointer hover:opacity-50"
        />
      </div>
    </div>
  );
};
