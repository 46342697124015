import { formatThousandSeperator } from '@/utils';
import { HelpCircle } from 'lucide-react';

type Props = {
  name: string;
  amount: number;
  highlighted?: boolean;
  readMoreUrl?: string;
};

export const CreditsInfoBox = ({
  amount,
  name,
  readMoreUrl,
  highlighted,
}: Props) => {
  return (
    <div
      className={`relative flex flex-1 flex-col items-center justify-between gap-2 rounded-lg p-2 ${highlighted ? 'bg-primary-100' : ''}`}
    >
      <p
        className={`flex items-center gap-2 text-center font-bold ${highlighted ? 'text-primary-500' : 'text-primary-400'}`}
      >
        {name}
        {readMoreUrl && (
          <a href={readMoreUrl} target="_blank" rel="noreferrer">
            <HelpCircle size={20} className="text-primary-500" />
          </a>
        )}
      </p>
      <p
        className={`font-bold ${highlighted ? 'text-lg text-primary' : 'text-lg text-primary '}`}
      >
        {formatThousandSeperator(amount)}
      </p>
    </div>
  );
};
