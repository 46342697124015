import { ReactNode } from 'react';
import { ResumeSubscriptionBanner } from './Banner/specific/ResumeSubcriptionBanner';
import { OutOfCreditsBanner } from './Banner/specific/OutOfCreditsBanner';
import { TrialPeriodBanner } from './Banner/specific/TrialPeriodBanner';
import { twMerge } from 'tailwind-merge';
import { Sidebar } from './v2/Sidebar/Sidebar';
import { useAppStore } from '@/Pages/AppLoader/stores';

type Props = {
  children: ReactNode;
  additionalBanners?: ReactNode;
  hideBanners?: boolean;
};

export const Menus = (props: Props) => {
  const { children, hideBanners } = props;

  const appState = useAppStore();

  return (
    <div className="flex h-screen w-screen flex-col">
      {!hideBanners && (
        <>
          <ResumeSubscriptionBanner />
          <OutOfCreditsBanner />
          <TrialPeriodBanner />
          {props.additionalBanners}
        </>
      )}
      <div className="flex flex-grow overflow-hidden bg-primary-50">
        <Sidebar
          projects={appState.projects!}
          auth={appState.auth!}
          usage={appState.subscription!.usage}
          current_project={appState.currentProject}
          subscription={appState.subscription!}
        />

        <div
          className={twMerge(
            'h-full flex-grow overflow-y-auto rounded-2xl border border-primary-200',
            'bg-white',
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
