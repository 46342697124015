import NotFound from '../../../../../../../../../public/svg/no-link-suggestions.png';
import LinksReviewed from '../../../../../../../../../public/svg/links-reviewed.png';
import { Link } from '@tanstack/react-router';
import { LoadingSuggestionsAnimation } from '../../LoadingSuggestionsAnimation';

type Props = {
  title: string;
  status: 'Searching' | 'Not Found' | 'Links Reviewed';
};

export default function LinkSuggestionStatus({ title, status }: Props) {
  return (
    <div className="flex w-full flex-col items-center justify-center">
      <p className="text-md mb-6 mt-4 text-center font-extrabold uppercase">
        {title}
      </p>
      {status === 'Searching' && <LoadingSuggestionsAnimation />}
      {status === 'Not Found' && (
        <>
          <img src={NotFound} className="w-56" />
          <p className={'mb-3 mt-8 text-center'}>
            No matching keywords in your{' '}
            <Link className={'text-blue-500 underline'} to={'/all-pages'}>
              Pages
            </Link>
            .
          </p>
          <p className={'text-center'}>
            Find out more about the{' '}
            <a
              className={'text-blue-500 underline'}
              href={'https://docs.seo.ai/a-13-internal-linking'}
              target={'_blank'}
              rel="noreferrer"
            >
              Link Suggestion feature.
            </a>{' '}
          </p>
        </>
      )}
      {status === 'Links Reviewed' && (
        <>
          <img src={LinksReviewed} className="w-56" />
          <p className={'mb-3 mt-8 text-center'}>
            Learn how to control the{' '}
            <a
              className={'text-blue-500 underline'}
              target={'_blank'}
              href={'https://docs.seo.ai/a-13-internal-linking'}
              rel="noreferrer"
            >
              internal link suggestions
            </a>
            .
          </p>
        </>
      )}
    </div>
  );
}
