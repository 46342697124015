import { Card } from '@/Components/v2/Card';
import { CreatePageContainer } from '../../../components';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { Input } from '@/Components/v2/Input/Input';
import { FormEvent, useEffect, useRef, useState } from 'react';
import {
  useAnalyzePage,
  useCreateDocument,
  useListContentTypes,
  useListDocumentTemplates,
  useProjectGenerateDocument,
} from '@/api/openapiComponents';
import { Button } from '@/Components/v2/Button';
import { useGetRandomPlaceHolder } from '@/data/placeholders';
import { Tabs } from '@/Components/v2/Tabs/Tabs';
import { useNavigate, useSearch } from '@tanstack/react-router';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { TextArea } from '@/Components/v2/TextArea/TextArea';
import { useAppStore } from '../../../AppLoader/stores';
import { hasher, useFeatureFlagging } from '../../../AppLoader';
import { CardBody } from '@/Components/v2/Card/components/CardBody';
import { ContentTypeSelect } from '@/Components/ContentTypeSelect';
import { TemplateSelect } from '@/TemplateSelect';
import { ErrorHelper } from '@/Services/ErrorHandling';
import { ErrorAlert } from '@/Components/v2/Alert';

export const ImportFromUrl = () => {
  const placeHolder = useGetRandomPlaceHolder();
  const navigate = useNavigate();
  const appState = useAppStore();
  const search = useSearch({ from: '/create-content/import' });

  const { hasFeature } = useFeatureFlagging();

  const [urlinput, setUrlinput] = useState(search.url ?? '');
  const [metaTitleInput, setMetaTitleInput] = useState('');
  const [metaDescriptionInput, setMetaDescriptionInput] = useState('');
  const [keywordInput, setKeywordInput] = useState(search.keyword ?? '');
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedContentTypeId, setSelectedContentTypeId] = useState<number>();
  const [isFirstImport, setIsFirstImport] = useState(true);
  const [templateId, setTemplateId] = useState<number>();

  const importMutation = useAnalyzePage();
  const createDocumentMutation = useCreateDocument();
  const contentTypeQeury = useListContentTypes({});
  const generateNewDocumentMutation = useProjectGenerateDocument();

  const titleRef = useRef('');

  const customTemplatesQuery = useListDocumentTemplates({
    queryParams: {
      filters: {
        project_id: appState.currentProject!.id,
      },
      limit: 100,
    },
  });

  const customTemplates = customTemplatesQuery.data?.data ?? [];

  const predefinedTemplatesQuery = useListDocumentTemplates({
    queryParams: {
      limit: 100,
    },
  });

  const predefinedTemplates = predefinedTemplatesQuery.data?.data ?? [];

  const handleImport = (e?: FormEvent) => {
    e?.preventDefault();
    importMutation.reset();
    generateNewDocumentMutation.reset();
    createDocumentMutation.reset();
    importMutation.mutate(
      {
        body: {
          url: urlinput,
          content_format: 'markdown',
          page_type: 'article',
          guess_target_keyword: true,
        },
      },
      {
        onSuccess: (data) => {
          setIsFirstImport(false);
          titleRef.current = data.data.title;
          setKeywordInput(
            search.keyword && keywordInput.length > 0
              ? search.keyword
              : data.data.target_keyword,
          );
          setMetaTitleInput(data.data.meta_title);
          setMetaDescriptionInput(data.data.meta_description);
        },
      },
    );
  };

  useEffect(() => {
    if (search.url) {
      handleImport();
    }
  }, []);

  const handleCreateDocument = () => {
    if (templateId) {
      generateNewDocumentMutation.mutate(
        {
          pathParams: {
            project: appState.currentProject!.id,
          },
          body: {
            autostart: false,
            document_template_id: templateId,
            keyword: keywordInput,
            content_type_id: selectedContentTypeId,
            settings: {
              context: {
                value: importMutation.data?.data.content,
              },
            },
          },
        },
        {
          onSuccess: (data) => {
            navigate({
              to: `/documents/$documentId`,
              params: { documentId: hasher.encode(data.data.id) },
              search: { generate: true, document_template: templateId },
            });
          },
        },
      );
    } else {
      createDocumentMutation.mutate(
        {
          pathParams: {
            project: appState.currentProject!.id,
          },
          body: {
            keyword: keywordInput,
            content_type_id: selectedContentTypeId,
            content: importMutation.data?.data?.content,
            meta_title: metaTitleInput,
            title: titleRef.current,
            meta_description: metaDescriptionInput,
            url: urlinput,
          },
        },
        {
          onSuccess: (data) => {
            navigate({
              to: `/documents/$documentId`,
              params: { documentId: hasher.encode(data.data.id) },
            });
          },
        },
      );
    }
  };

  const errorHelper = new ErrorHelper(importMutation.error as any);
  const submitErrorHelper = new ErrorHelper(
    generateNewDocumentMutation.error ?? createDocumentMutation.error,
  );

  return (
    <CreatePageContainer title="Optimize existing">
      <div className="mt-4 w-[min(40rem,90vw)]">
        <Card fullWidth>
          <CardBody>
            <div className="flex max-h-[75vh] flex-col gap-6 overflow-y-auto px-10 py-4">
              {errorHelper.message() && (
                <ErrorAlert title={errorHelper.message()} />
              )}
              <form onSubmit={handleImport} className="flex flex-col gap-6">
                <InputDecoration label="URL to optimize" required>
                  <Input
                    clearable
                    disabled={importMutation.isPending}
                    fullWidth
                    value={urlinput}
                    onChange={setUrlinput}
                    placeholder={placeHolder.url}
                  />
                </InputDecoration>

                {isFirstImport ? (
                  <div className="mx-auto w-1/2">
                    <Button
                      fullWidth
                      text="import"
                      color="secondary"
                      type="submit"
                      isLoading={importMutation.isPending}
                    />
                  </div>
                ) : (
                  <div className="flex justify-end">
                    <Button
                      text="retry"
                      variant="outline"
                      type="submit"
                      isLoading={importMutation.isPending}
                    />
                  </div>
                )}
              </form>
              {importMutation.isSuccess && (
                <>
                  <InputDecoration label="Target keyword" required>
                    <Input
                      value={keywordInput}
                      onChange={setKeywordInput}
                      clearable
                      error={submitErrorHelper.has('keyword')}
                    />
                  </InputDecoration>
                  {hasFeature('template-for-optimize-content') && (
                    <TemplateSelect
                      customTemplates={customTemplates}
                      predefinedTemplates={predefinedTemplates}
                      onChange={(value) => {
                        setTemplateId(
                          value.templateId
                            ? Number(value.templateId)
                            : undefined,
                        );
                        setSelectedContentTypeId(value.contentTypeId);
                      }}
                      value={templateId}
                      disabled={
                        !appState.subscription?.permissions
                          .custom_document_templates
                      }
                      featureLocked={
                        !appState.subscription?.permissions
                          .custom_document_templates
                      }
                    />
                  )}
                  <ContentTypeSelect
                    contentTypes={contentTypeQeury.data?.data ?? []}
                    isLoading={contentTypeQeury.isLoading}
                    onChange={(value) => setSelectedContentTypeId(value)}
                    value={selectedContentTypeId}
                  />
                  <InputDecoration label="Imported text">
                    <Tabs
                      value={templateId === undefined ? selectedTab : 0}
                      onChange={setSelectedTab}
                      tabs={[
                        { text: 'CONTENT' },
                        {
                          text: 'SEO TITLE',
                          disabled: templateId !== undefined,
                        },
                        {
                          text: 'META DESC',
                          disabled: templateId !== undefined,
                        },
                      ]}
                    />
                  </InputDecoration>

                  {/* TODO: Fix so it doesn't check on indexes but use actual names! It's too error prone and not very readable */}
                  {(selectedTab === 0 || templateId !== undefined) && (
                    <div className="h-full">
                      <Card>
                        <CardBody>
                          <div className="h-[160px] overflow-y-auto">
                            <ReactMarkdown
                              disallowedElements={['img']}
                              remarkPlugins={[remarkGfm]}
                              className="prose cursor-default leading-tight"
                            >
                              {`# ${titleRef.current}

${importMutation.data?.data?.content}`}
                            </ReactMarkdown>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                  )}
                  {selectedTab === 1 && templateId === undefined && (
                    <TextArea
                      rows={6}
                      value={metaTitleInput}
                      onChange={setMetaTitleInput}
                    />
                  )}
                  {selectedTab === 2 && templateId === undefined && (
                    <TextArea
                      rows={6}
                      value={metaDescriptionInput}
                      onChange={setMetaDescriptionInput}
                    />
                  )}
                  <div className="mx-auto w-1/2">
                    <Button
                      fullWidth
                      text={templateId ? 'Generate new' : 'Open in editor'}
                      color="secondary"
                      disabled={
                        !appState.subscription!.usage.credits.content.is_allowed
                      }
                      onClick={handleCreateDocument}
                      isLoading={
                        createDocumentMutation.isPending ||
                        createDocumentMutation.isSuccess ||
                        generateNewDocumentMutation.isPending ||
                        generateNewDocumentMutation.isSuccess
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </CardBody>
        </Card>
      </div>
    </CreatePageContainer>
  );
};
