import { Button, ButtonProps } from '@/Components/v2/Button';

type Props<T> = {
  selection: T[];
  actions: ButtonProps[];
};

export const SelectionActions = <T,>({ actions, selection }: Props<T>) => {
  if (selection.length === 0) return null;

  return (
    <div className="flex items-center gap-1">
      <div className="text-primary">{selection.length} items</div>
      {actions.map((action, index) => (
        <Button key={index} {...action} />
      ))}
    </div>
  );
};
