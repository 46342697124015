import { GradualScoreVariant } from '@/Components/Labels/AppLabel';

export const getOppVariant = (
  score: number,
): keyof typeof GradualScoreVariant => {
  if (score >= 90) {
    return 'best';
  } else if (score >= 80) {
    return 'great';
  } else if (score >= 60) {
    return 'good';
  } else if (score >= 40) {
    return 'ok';
  } else {
    return 'remaining';
  }
};
