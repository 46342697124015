import { useContext } from 'react';
import { CardColorStyles, CardContext } from '..';
import { Button, ButtonProps } from '../../Button';

type Props = {
  /**
   * Configures action buttons on the bottom right of the card
   */
  actions?: ButtonProps[];
};

export const CardActions = ({ actions }: Props) => {
  const { color, dense } = useContext(CardContext);

  return (
    <div className={`${dense ? 'p-1' : 'px-6 pb-6'}`}>
      {actions && (
        <div className={`${dense ? 'mt-2' : 'mt-4'} flex justify-end gap-2`}>
          {actions.map((action) => (
            <Button {...action} color={action.color ?? color} />
          ))}
        </div>
      )}
    </div>
  );
};
