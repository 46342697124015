import { useEffect, useState } from 'react';
import { SlideOver } from '@/Components/SlideOver';
import { Button } from '@/Components/v2/Button';
import { Stars, X } from 'lucide-react';
import Tabs from '@/Components/Tabs';
import {
  useDocumentSubHeadingSuggestions,
  useProjectGenerateOutline,
} from '@/api/openapiComponents';
import { DocumentResource } from '@/api/openapiSchemas';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { OutlineCompetitorItem } from './components/OutlineCompetitorItem';
import { OutlineSuggestionItem } from './components/OutlineSuggestionItem';
import { SlideOverGeneratedContent } from '@/Components/SlideOverGeneratedContent';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { OutlineItem } from '@/Components/DocumentForm/hooks';
import { tracking } from '@/Services/tracking/Tracking';
import { TaskItem } from '@/Components/TaskItem';

type Props = {
  isOpen: boolean;
  subHeadings: string[];
  document: DocumentResource;
  disabled?: boolean;
  onClose?: () => void;
  onInsert: (value: string, type?: string) => void;
  onGenerateOutline: (outline: OutlineItem[]) => void;
};

export const OutlineSlideOver = ({
  isOpen,
  onClose,
  onGenerateOutline,
  onInsert,
  subHeadings,
  disabled,
  document,
}: Props) => {
  const appState = useAppStore();
  const [selectedTab, setSelectedTab] = useState('Ideas');
  const [rewriteParams, setRewriteParams] = useState<{
    title: string;
    type: 'variants' | 'paraphrase' | 'elaborate';
  }>();

  const [opCompetitorIndex, setOpCompetitorIndex] = useState<number>();
  const generateOutlineMutation = useProjectGenerateOutline();
  const aiSuggestionsQuery = useDocumentSubHeadingSuggestions(
    {
      pathParams: {
        document: document.id,
        project: appState.currentProject!.id,
      },
      queryParams: {
        context: rewriteParams?.title,
        type: rewriteParams?.type,
      },
    },
    {
      enabled: false,
      structuralSharing: (oldData: any, newData: any) => {
        if (oldData) {
          return { data: [...oldData.data, ...newData.data] };
        }
        return newData;
      },
    },
  );

  const handleGenerateOutline = () => {
    tracking.event('subheading_suggestions_generated_outline');

    generateOutlineMutation.mutate(
      {
        pathParams: {
          project: appState.currentProject!.id,
        },
        body: {
          content_type_id: document?.content_type?.id,
          title: document.title,
          keyword: document.keyword.name,
        },
      },
      {
        onSuccess: (data) => {
          const outline = data.data.outline as unknown as OutlineItem[];

          onGenerateOutline(outline);
        },
      },
    );
  };

  useEffect(() => {
    generateOutlineMutation.reset();
  }, [subHeadings.length]);

  useEffect(() => {
    if (
      isOpen &&
      !aiSuggestionsQuery.isFetchedAfterMount &&
      !aiSuggestionsQuery.data
    ) {
      aiSuggestionsQuery.refetch();
    }
  }, [isOpen, aiSuggestionsQuery.isFetchedAfterMount, rewriteParams]);

  return (
    <SlideOver
      isOpen={isOpen}
      onClose={onClose}
      title="Subheadings"
      subHeading="Create an outline that will meet the search intent of your target audience."
      color="bg-sky-500"
    >
      <div className="mb-6 flex flex-col gap-1">
        <TaskItem
          completed={
            document.document_report?.subheadings?.elements
              .subheadings_are_present
          }
          task="Subheadings are present"
        />
        <TaskItem
          completed={
            document.document_report?.subheadings?.elements
              .subheadings_contain_target_keyword
          }
          task="Subheadings contain target keyword"
        />
      </div>

      <div className="flex w-full flex-col gap-2">
        <div className="mb-4 flex w-full justify-center">
          <Button
            text="Generate outline"
            prependIcon={Stars}
            color="secondary"
            variant={subHeadings.length > 0 ? 'outline' : 'fill'}
            isLoading={generateOutlineMutation.isPending}
            onClick={handleGenerateOutline}
            disabled={disabled}
            size="sm"
          />
        </div>
        <div className="mb-2 w-full">
          <Tabs
            className="w-full"
            selectedTab={selectedTab}
            onChange={(value) => setSelectedTab(value)}
            tabs={[
              {
                name: 'Ideas',
              },
              {
                name: 'Competitors',
              },
              {
                name: 'People also ask',
              },
              {
                name: 'You',
              },
            ]}
          />
        </div>
        {selectedTab === 'Ideas' && (
          <SlideOverGeneratedContent
            loading={
              aiSuggestionsQuery.isLoading || aiSuggestionsQuery.isFetching
            }
            skeletonLoaders={{
              height: 'sm',
            }}
            topContent={
              <>
                {rewriteParams && (
                  <p className="flex items-center justify-between text-sm italic text-gray-600">
                    <>
                      {{
                        variants: 'More like',
                        optimised: 'Optimizing',
                        paraphrase: 'Paraphrasing',
                        elaborate: 'Elaborating on',
                      }[rewriteParams.type] +
                        ' "' +
                        rewriteParams.title +
                        '"'}
                      <IconButton
                        size="sm"
                        icon={X}
                        onClick={() => setRewriteParams(undefined)}
                      />
                    </>
                  </p>
                )}
              </>
            }
            onFetchItems={() => aiSuggestionsQuery.refetch()}
            error={!!aiSuggestionsQuery.error}
          >
            {aiSuggestionsQuery.data?.data.map((suggestion) => (
              <OutlineSuggestionItem
                disabled={disabled}
                suggestion={suggestion.title}
                onInsert={(value) => {
                  onInsert(value);
                }}
                onElaborate={(value) => {
                  setSelectedTab('Ideas');
                  setRewriteParams({ title: value, type: 'elaborate' });
                }}
                onMore={(value) => {
                  setSelectedTab('Ideas');
                  setRewriteParams({ title: value, type: 'variants' });
                }}
                onParaphrase={(value) => {
                  setSelectedTab('Ideas');
                  setRewriteParams({ title: value, type: 'paraphrase' });
                }}
              />
            ))}
          </SlideOverGeneratedContent>
        )}
        {selectedTab === 'Competitors' &&
          (!document.document_report ||
          document.document_report.is_generating ? (
            <div className="flex flex-col gap-2">
              <div className="h-12 w-full animate-pulse rounded-md bg-gray-300" />
              <div className="h-12 w-full animate-pulse rounded-md bg-gray-300" />
              <div className="h-12 w-full animate-pulse rounded-md bg-gray-300" />
            </div>
          ) : (
            document.document_report.competitors.map((competitor, index) => (
              <OutlineCompetitorItem
                disabled={disabled}
                onInsert={onInsert}
                onElaborate={(value) => {
                  setSelectedTab('Ideas');
                  setRewriteParams({ title: value, type: 'elaborate' });
                }}
                onMore={(value) => {
                  setSelectedTab('Ideas');
                  setRewriteParams({ title: value, type: 'variants' });
                }}
                onParaphrase={(value) => {
                  setSelectedTab('Ideas');
                  setRewriteParams({ title: value, type: 'paraphrase' });
                }}
                competitor={competitor}
                isOpen={index === opCompetitorIndex}
                setIsCollapsed={() =>
                  setOpCompetitorIndex(
                    index === opCompetitorIndex ? undefined : index,
                  )
                }
              />
            ))
          ))}
        {selectedTab === 'People also ask' && (
          <div className="mb-2 flex flex-col gap-2">
            {document.document_report?.questions.map((question) => (
              <OutlineSuggestionItem
                suggestion={question.value}
                onInsert={onInsert}
                onElaborate={(value) => {
                  setSelectedTab('Ideas');
                  setRewriteParams({ title: value, type: 'elaborate' });
                }}
                onMore={(value) => {
                  setSelectedTab('Ideas');
                  setRewriteParams({ title: value, type: 'variants' });
                }}
                onParaphrase={(value) => {
                  setSelectedTab('Ideas');
                  setRewriteParams({ title: value, type: 'paraphrase' });
                }}
              />
            ))}
          </div>
        )}
        {selectedTab === 'You' && (
          <div className="mb-2 flex flex-col gap-2">
            {document.subheadings.map((heading) => (
              <OutlineSuggestionItem
                suggestion={heading.title}
                type={heading.type}
                onInsert={onInsert}
                onElaborate={(value) => {
                  setSelectedTab('Ideas');
                  setRewriteParams({ title: value, type: 'elaborate' });
                }}
                onMore={(value) => {
                  setSelectedTab('Ideas');
                  setRewriteParams({ title: value, type: 'variants' });
                }}
                onParaphrase={(value) => {
                  setSelectedTab('Ideas');
                  setRewriteParams({ title: value, type: 'paraphrase' });
                }}
              />
            ))}
          </div>
        )}
      </div>
    </SlideOver>
  );
};
