import Toggle from '@/Components/Toggle';

type Props = {
  disabled?: boolean;
  text: string;
  value: boolean;
  onChange: (value: boolean) => void;
};

export const SettingsToggle = ({ onChange, text, value, disabled }: Props) => {
  return (
    <div className="flex items-center justify-between text-primary">
      {text}
      <Toggle value={value} onChange={onChange} disabled={disabled} />
    </div>
  );
};
