import {
  ContentListResource,
  KeywordResource,
  TrackedKeywordResource,
  TrackedKeywordUpdateResultResource,
} from '@/api/openapiSchemas';
import { RankLabel } from '@/Components/Labels/RankLabel';
import Pagination from '@/Components/ProjectKeywords/Pagination';
import * as Table from '@/Components/Table';
import { SelectionActions } from '@/Components/Table/components/SelectionActions';
import { useCopyTable } from '@/Components/Table/hooks';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { Tooltip } from '@/Components/v2/Tooltip';
import { TaskDot } from '@/Pages/ContentScanner/components/TaskDot';
import { getRankTrackingVariant } from '@/Pages/RankTracking/RankTracking';
import { formatThousandSeperator } from '@/utils';
import { Copy, ListMinus, ListPlus, Trash2 } from 'lucide-react';

type Props = {
  selection: TrackedKeywordResource[];
  onSelectionChange: (
    added: TrackedKeywordResource[],
    removed: TrackedKeywordResource[],
  ) => void;
  isLoading: boolean;
  items: TrackedKeywordResource[];
  selectedList?: ContentListResource;
  pagination: {
    page: number;
    lastPage: number;
    onPageChange: (page: number) => void;
  };
  selectionActions: {
    isLoading: boolean;
    onDelete: (selection: TrackedKeywordResource[]) => void;
    onAddToList: (selection: TrackedKeywordResource[]) => void;
    onRemoveFromList: (selection: TrackedKeywordResource[]) => void;
  };
  onOpenUrlsSlideover: (
    keyword: KeywordResource,
    results: TrackedKeywordUpdateResultResource[],
  ) => void;
  v2?: boolean;
};

export const KeywordsTable = ({
  isLoading,
  items,
  onSelectionChange,
  onOpenUrlsSlideover,
  pagination,
  selection,
  selectionActions,
  selectedList,
  v2,
}: Props) => {
  const handleCopyToClipboard = useCopyTable(
    selection.map((item) => item.keyword?.name).join('\n'),
  );

  return (
    <Table.Root
      items={items}
      selection={selection}
      onSelectionChange={onSelectionChange}
      isLoading={isLoading}
      columns={[
        {
          heading: 'Keyword',
          render: (item) => (
            <div className="my-2 flex items-center font-bold">
              {item.keyword?.name}
            </div>
          ),
        },
        {
          heading: 'Position',
          rightAlign: true,
          render: (item) =>
            !item.latest_update ? (
              <SkeletonLoader height="2xs" />
            ) : (
              <Tooltip
                side="top"
                disabled={!item.latest_update.position}
                title={item.latest_update.meta_title ?? ''}
                link={{
                  text: item.latest_update.path ?? '',
                  url: item.latest_update.url ?? '',
                }}
              >
                {v2 && item.latest_update.position ? (
                  <RankLabel
                    variant={getRankTrackingVariant(
                      item.latest_update.position,
                    )}
                    rank={item.latest_update.position}
                  />
                ) : (
                  <div>
                    {item.latest_update.position ? (
                      item.latest_update.position
                    ) : (
                      <div className="pr-1">-</div>
                    )}
                  </div>
                )}
              </Tooltip>
            ),
        },
        {
          heading: 'Volume',
          rightAlign: true,
          render: (item) =>
            item.keyword?.search_volume === undefined ? (
              <SkeletonLoader height="2xs" />
            ) : (
              <div>
                {formatThousandSeperator(item.keyword?.search_volume ?? 0)}
              </div>
            ),
        },
        {
          heading: 'BEST MATCH URL',
          render: (item) =>
            !item.keyword?.keyword_data ? (
              <SkeletonLoader height="2xs" />
            ) : (
              <div className="flex items-center gap-2">
                {item.keyword.keyword_data.length > 0 ? (
                  <>
                    <a
                      className="cursor-pointer hover:opacity-60"
                      href={item.keyword.keyword_data[0].project_url?.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.keyword.keyword_data[0].project_url?.path}
                    </a>
                    {item.keyword.keyword_data.length > 1 && (
                      <p
                        className="cursor-pointer text-primary-400 hover:opacity-60"
                        onClick={() =>
                          onOpenUrlsSlideover(
                            item.keyword!,
                            item.latest_update?.results ?? [],
                          )
                        }
                      >
                        (+{item.keyword.keyword_data.length - 1} more)
                      </p>
                    )}
                  </>
                ) : (
                  '-'
                )}
              </div>
            ),
        },
        {
          heading: 'T',
          render: (item) =>
            item.keyword?.keyword_data === undefined ? (
              <SkeletonLoader height="2xs" />
            ) : (
              <Tooltip
                side="top"
                disabled={(item.keyword.keyword_data?.length ?? 0) === 0}
                title={
                  item.keyword.keyword_data?.[0]?.project_url?.meta_title ?? ''
                }
              >
                <TaskDot
                  completed={
                    item.keyword.keyword_data?.[0]?.in_meta_title_count > 0
                  }
                />
              </Tooltip>
            ),
        },
        {
          heading: 'H1',
          render: (item) =>
            item.keyword?.keyword_data === undefined ? (
              <SkeletonLoader height="2xs" />
            ) : (
              <TaskDot
                completed={item.keyword.keyword_data.some(
                  (data) => data.in_title_count > 0,
                )}
              />
            ),
        },
        {
          heading: 'H2',
          render: (item) =>
            item.keyword?.keyword_data === undefined ? (
              <SkeletonLoader height="2xs" />
            ) : (
              <TaskDot
                completed={item.keyword.keyword_data.some(
                  (data) => data.in_h2_count > 0,
                )}
              />
            ),
        },
        {
          heading: 'H3',
          render: (item) =>
            item.keyword?.keyword_data === undefined ? (
              <SkeletonLoader height="2xs" />
            ) : (
              <TaskDot
                completed={item.keyword.keyword_data.some(
                  (data) => data.in_h3_count > 0,
                )}
              />
            ),
        },
        {
          heading: 'P',
          render: (item) =>
            item.keyword?.keyword_data === undefined ? (
              <SkeletonLoader height="2xs" />
            ) : (
              <TaskDot
                completed={item.keyword.keyword_data.some(
                  (data) => data.in_text_count > 0,
                )}
              />
            ),
        },
        {
          heading: 'MD',
          render: (item) =>
            item.keyword?.keyword_data === undefined ? (
              <SkeletonLoader height="2xs" />
            ) : (
              <TaskDot
                completed={item.keyword.keyword_data.some(
                  (data) => data.in_meta_description_count > 0,
                )}
              />
            ),
        },
      ].filter((_, index) => !v2 || index < 3)}
    >
      <Table.Header>
        {selection.length > 0 ? (
          <SelectionActions
            selection={selection}
            actions={[
              {
                prependIcon: Trash2,
                size: 'sm',
                dense: true,
                text: 'Delete',
                variant: 'ghost',
                onClick: () => selectionActions.onDelete(selection),
                disabled: selectionActions.isLoading,
              },
              {
                prependIcon: Copy,
                size: 'sm',
                dense: true,
                text: 'Copy',
                variant: 'ghost',
                onClick: handleCopyToClipboard,
              },
              {
                prependIcon: ListPlus,
                size: 'sm',
                dense: true,
                text: 'Add to list',
                variant: 'ghost',
                onClick: () => selectionActions.onAddToList(selection),
              },
              ...(selectedList
                ? [
                    {
                      prependIcon: ListMinus,
                      size: 'sm' as 'sm',
                      dense: true,
                      text: 'Remove from list',
                      variant: 'ghost' as 'ghost',
                      onClick: () =>
                        selectionActions.onRemoveFromList(selection),
                    },
                  ]
                : []),
            ]}
          />
        ) : (
          <Table.ResultsTotal
            title="keywords"
            total={items?.length}
            isLoading={isLoading}
          />
        )}
      </Table.Header>
      <Table.Footer>
        <Pagination
          currentPage={pagination.page}
          lastPage={pagination.lastPage}
          setCurrentPage={pagination.onPageChange}
        />
      </Table.Footer>
    </Table.Root>
  );
};
