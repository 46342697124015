import { handleLogout } from '@/utils';
import { Button } from '../../../Components/v2/Button';
import { LogOut } from 'lucide-react';

export default () => (
  <Button
    onClick={handleLogout}
    variant="ghost"
    size="sm"
    prependIcon={LogOut}
    color="primary"
    text="Logout"
  />
);
