import { createRoute } from '@tanstack/react-router';
import { rootRoute } from '../Router';
import {
  guards,
  hasActiveSubscription,
  hasProject,
  isAuthenticated,
} from '../Router/guards';
import { validateTanStackSearch } from '@/utils';
import { GettingStarted } from './GettingStarted';

export const routes = [
  createRoute({
    getParentRoute: () => rootRoute,
    validateSearch: validateTanStackSearch,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
    },
    path: '/getting-started',
    component: () => <GettingStarted />,
  }),
];
