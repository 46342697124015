import { useAppStore } from '@/Pages/AppLoader/stores';
import { useProjectKeywordResearchExport } from '@/api/openapiComponents';
import { downloadCSV } from '@/utils';

type Variables = {
  sort_by?:
    | 'id'
    | 'keyword'
    | 'search_volume'
    | 'difficulty'
    | 'name'
    | undefined;
  sort_direction?: 'ASC' | 'DESC' | undefined;
  ids: string[];
};

export const useKeywordExport = (body: Variables, title: string) => {
  const projectId = useAppStore((state) => state.currentProject?.id);
  const {
    mutateAsync: exportKeywordResearch,
    isPending: isPendingKeywordResearch,
  } = useProjectKeywordResearchExport();

  const onExportCSV = {
    isLoading: isPendingKeywordResearch,
    onClick: () => {
      exportKeywordResearch({
        pathParams: { project: projectId! },
        body: {
          ...body,
          sort_by: body.sort_by === 'name' ? 'keyword' : body.sort_by,
        },
      }).then((response) => {
        downloadCSV(response, `${title}.csv`);
      });
    },
  };

  return onExportCSV;
};
