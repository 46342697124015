type Props = {
  value: boolean;
  disabled?: boolean;
  onChange: (value: boolean) => void;
};

export default function Toggle({ value, onChange, disabled }: Props) {
  return (
    <div className="flex">
      <div
        onClick={(e) => !disabled && onChange(!value)}
        className={`${value ? 'bg-[#73E298]' : 'bg-[#898989]'} ${
          disabled ? 'cursor-not-allowed opacity-60' : 'cursor-pointer'
        } relative h-4 w-8  rounded-full`}
      >
        <div
          className={`${
            value ? 'left-[17px]' : 'left-px'
          } transition-300 absolute top-px h-[14px] w-[14px] rounded-full bg-white transition-[left]`}
        ></div>
      </div>
    </div>
  );
}
