import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { useGetTrackedKeywordSerpOverview } from '@/api/openapiComponents';
import { SerpOverviewLines } from './SerpOverviewLines';

type Props = {
  trackedKeywordId: number;
};

export const TrackedKeywordSerpOverview = ({ trackedKeywordId }: Props) => {
  const appState = useAppStore();

  const serpOverviewQuery = useGetTrackedKeywordSerpOverview(
    {
      pathParams: {
        trackedKeyword: trackedKeywordId,
        project: appState.currentProject!.id,
      },
    },
    {
      enabled: !!trackedKeywordId,
    },
  );

  return (
    <div className="mt-4">
      {serpOverviewQuery.isLoading ? (
        <div className="flex flex-col gap-2">
          {Array.from({ length: 100 }).map((_, index) => (
            <SkeletonLoader key={index} />
          ))}
        </div>
      ) : (
        <div className="w-full overflow-hidden text-primary-600">
          <div className="flex">
            <div className="w-12 text-end">#</div>
            <div className="pl-4 text-start">SEARCH RESULTS</div>
          </div>
          <div className="flex flex-col">
            {serpOverviewQuery.data?.data.map((serp, index) => (
              <SerpOverviewLines
                index={index}
                serp={{
                  favicon: serp.favicon,
                  is_ours: serp.is_ours,
                  title: serp.title,
                  url: serp.url,
                }}
                green={serp.is_ours}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
