import { useEffect, useState } from 'react';
import Tabs from '@/Components/Tabs';
import { SlideOverGeneratedContent } from '../../../../../../Components/SlideOverGeneratedContent';
import { SlideOver } from '@/Components/SlideOver';
import { Sparkles, X } from 'lucide-react';
import {
  DocumentResource,
  DocumentTitleSuggestionsPromptType,
} from '@/api/openapiSchemas';
import { SuggestionItem } from './components/SuggestionItem';
import { TitleScoreBreakDown } from '../../../TitleScoreBreakdown';
import { Button } from '@/Components/v2/Button';
import { useDocumentTitleSuggestions } from '@/api/openapiComponents';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { CompetitorItem } from './components/CompetitorItem';
import { NoYourElement } from '../NoYourElement';
import { useDocumentStore } from '@/Pages/Document/stores';
import { useShallow } from 'zustand/react/shallow';

type Props = {
  projectId: number;
  document: DocumentResource;
  isOpen: boolean;
  onTitleChange?: (title: string) => void;
  onClose?: () => void;
};

export const TitleScoreSlideOver = ({
  projectId,
  document,
  isOpen,
  onTitleChange,
  onClose,
}: Props) => {
  const { setHeading } = useDocumentStore(
    useShallow(({ setHeading }) => ({
      setHeading,
    })),
  );

  const [tab, setTab] = useState<'IDEAS' | 'OPTIMIZE' | 'COMPETITORS' | 'YOU'>(
    'IDEAS',
  );
  const [rewriteParams, setRewriteParams] = useState<{
    title: string;
    type: 'variants' | 'optimised' | 'paraphrase' | 'elaborate' | 'default';
  }>({
    title: document.title,
    type: 'default',
  });

  const {
    data: titleSuggestions,
    isLoading: isLoadingTitleSuggestions,
    isFetching: isFetchingTitleSuggestions,
    refetch: fetchIdeas,
    isError: ideasError,
    isFetchedAfterMount: ideasIsFetchedAfterMount,
  } = useDocumentTitleSuggestions(
    {
      pathParams: {
        document: document.id,
        project: projectId,
      },
      queryParams: {
        type: rewriteParams
          ? (rewriteParams.type as DocumentTitleSuggestionsPromptType)
          : undefined,
        context:
          rewriteParams?.title.length > 0 ? rewriteParams?.title : undefined,
      },
    },
    {
      enabled: false,
      structuralSharing: (oldData: any, newData: any) => {
        if (oldData) {
          return { data: [...oldData.data, ...newData.data] };
        }
        return newData;
      },
    },
  );

  useEffect(() => {
    if (isOpen && !ideasIsFetchedAfterMount && !titleSuggestions?.data) {
      fetchIdeas();
    }
  }, [isOpen, ideasIsFetchedAfterMount, rewriteParams]);

  const handleSetTab = (newTab: string) => {
    if (newTab !== 'OPTIMIZE') {
      setTab(newTab as any);
    }
    if (newTab === 'OPTIMIZE') {
      setRewriteParams({ title: document.title, type: 'optimised' });
    }
  };

  const handleSuggestionsBasedOf = (
    title: string,
    type: 'variants' | 'optimised' | 'paraphrase' | 'elaborate',
  ) => {
    setTab('IDEAS');
    setRewriteParams({ title, type });
  };

  const handleSetHeading = (title: string) => {
    if (onTitleChange) {
      onTitleChange(title);
    }
    setHeading(title);
  };

  return (
    <>
      <SlideOver
        title="H1"
        subHeading="Benchmark your H1 against competitors and get suggestions to improve it."
        color="bg-yellow-400"
        onClose={onClose}
        isOpen={isOpen}
      >
        <div className="flex w-full flex-col">
          {!document.document_report ? (
            <div className="flex flex-col gap-2">
              <div className="mb-6">
                <SkeletonLoader />
              </div>
              <SkeletonLoader height="2xs" />
              <SkeletonLoader height="2xs" />
              <SkeletonLoader height="2xs" />
              <SkeletonLoader height="2xs" />
              <SkeletonLoader height="2xs" />
              <SkeletonLoader height="2xs" />
              <SkeletonLoader height="2xs" />
            </div>
          ) : (
            <TitleScoreBreakDown
              tasks={[
                {
                  task: 'Includes target keyword',
                  completed:
                    !!document.document_report?.title_competitiveness_score
                      .elements.includes_target_keyword,
                },
                {
                  task: 'Keywords in close proximity',
                  completed:
                    !!document.document_report?.title_competitiveness_score
                      .elements.keywords_in_close_proximity,
                },
                {
                  task: 'Target keyword in initial part',
                  completed:
                    !!document.document_report?.title_competitiveness_score
                      .elements.target_keyword_in_initial_part,
                },
              ]}
            />
          )}
          {/* Tab section */}

          <Tabs
            className="relative mb-4 mt-6"
            tabs={[
              {
                name: 'IDEAS',
              },
              { name: 'COMPETITORS' },
              { name: 'YOU' },
            ]}
            onChange={handleSetTab}
            selectedTab={tab}
          >
            <div className="absolute bottom-0 right-0 top-0">
              <Button
                color="secondary"
                variant="ghost"
                prependIcon={Sparkles}
                disabled={document.title.length === 0}
                onClick={() => handleSetTab('OPTIMIZE')}
                text="Optimize"
                size="xs"
              />
            </div>
          </Tabs>
          <SlideOverGeneratedContent
            key={tab}
            loading={isLoadingTitleSuggestions || isFetchingTitleSuggestions}
            error={ideasError}
            canGenerateMore={tab !== 'COMPETITORS' && tab !== 'YOU'}
            topContent={
              <>
                {tab === 'IDEAS' &&
                  rewriteParams &&
                  rewriteParams.type !== 'default' && (
                    <p className="mb-2 flex items-center justify-between text-sm italic text-gray-600">
                      {{
                        variants: 'More like',
                        optimised: 'Optimizing',
                        paraphrase: 'Paraphrasing',
                        elaborate: 'Elaborating on',
                      }[rewriteParams.type] +
                        ' "' +
                        rewriteParams.title +
                        '"'}
                      <IconButton
                        size="sm"
                        icon={X}
                        onClick={() =>
                          setRewriteParams({
                            title: document.title,
                            type: 'default',
                          })
                        }
                      />
                    </p>
                  )}
              </>
            }
            onFetchItems={fetchIdeas}
          >
            {tab === 'IDEAS' && (
              <>
                {(titleSuggestions?.data ?? [])?.map((item) => (
                  <SuggestionItem
                    key={item.title + item.title_competitiveness_score}
                    value={{
                      title: item.title,
                    }}
                    onInsert={handleSetHeading}
                    onElaborate={(title) =>
                      handleSuggestionsBasedOf(title, 'elaborate')
                    }
                    onParaphrase={(title) =>
                      handleSuggestionsBasedOf(title, 'paraphrase')
                    }
                    onOptimize={(title) =>
                      handleSuggestionsBasedOf(title, 'optimised')
                    }
                    onMore={(title) =>
                      handleSuggestionsBasedOf(title, 'variants')
                    }
                  />
                ))}
              </>
            )}
            {tab === 'COMPETITORS' && (
              <>
                {document.document_report?.competitors.map((competitor) => (
                  <CompetitorItem
                    key={competitor.position + competitor.url}
                    competitor={competitor}
                    onInsert={handleSetHeading}
                    onElaborate={(title) =>
                      handleSuggestionsBasedOf(title, 'elaborate')
                    }
                    onParaphrase={(title) =>
                      handleSuggestionsBasedOf(title, 'paraphrase')
                    }
                    onOptimize={(title) =>
                      handleSuggestionsBasedOf(title, 'optimised')
                    }
                    onMore={(title) =>
                      handleSuggestionsBasedOf(title, 'variants')
                    }
                  />
                ))}
              </>
            )}
            {tab === 'YOU' && (
              <>
                {document.title.length ? (
                  <SuggestionItem
                    value={{
                      title: document.title,
                    }}
                    onElaborate={(title) =>
                      handleSuggestionsBasedOf(title, 'elaborate')
                    }
                    onParaphrase={(title) =>
                      handleSuggestionsBasedOf(title, 'paraphrase')
                    }
                    onOptimize={(title) =>
                      handleSuggestionsBasedOf(title, 'optimised')
                    }
                    onMore={(title) =>
                      handleSuggestionsBasedOf(title, 'variants')
                    }
                  />
                ) : (
                  <NoYourElement
                    type="a heading"
                    onClick={() => setTab('IDEAS')}
                  />
                )}
              </>
            )}
          </SlideOverGeneratedContent>
        </div>
      </SlideOver>
    </>
  );
};
