import React from 'react';

type Props = {
  value: number;
  maxValue: number;
  label: string | number;
  className?: string;
} & React.HTMLAttributes<HTMLDivElement>

export const Pillar = ({
  value,
  maxValue,
  label,
  className,
  ...props
}: Props) => {
  const heightPercentage = (value / maxValue) * 100;
  return (
    <div className="group mx-2 flex h-full w-full items-end" {...props}>
      <div
        className={`relative w-full group-hover:outline group-hover:outline-1 ${className}`}
        style={{ height: heightPercentage + '%' }}
      >
        <div className="pointer-events-none absolute -top-8 left-0 right-0 flex justify-center  opacity-0 group-hover:opacity-100">
          <div className="rounded-sm border border-gray-100 bg-white bg-opacity-50 p-1">
            {value}
          </div>
        </div>
        <div className="absolute -bottom-6 left-0 right-0 flex justify-center">
          {label}
        </div>
      </div>
    </div>
  );
};
