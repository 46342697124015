import { ApiError } from '@/types';

type Props = {
  errors?: ApiError['errors'];
  apiError?: ApiError | null;
}

export default function ValidationErrors({ errors, apiError }: Props) {
  if (!errors && apiError) {
    return (
      <div className="my-4">
        <div className="font-medium text-red-600">
          Whoops! Something went wrong.
        </div>

        <p className="mt-3 list-inside list-disc  text-red-600">
          {apiError.message}
        </p>
      </div>
    );
  }

  return (
    errors &&
    Object.keys(errors).length > 0 && (
      <div className="my-4">
        <div className="font-medium text-red-600">
          Whoops! Something went wrong.
        </div>

        <ul className="mt-3 list-inside list-disc  text-red-600">
          {Object.keys(errors).map((key, index) => (
            <li key={index}>{errors[key]}</li>
          ))}
        </ul>
      </div>
    )
  );
}
