import React, { useState, useEffect } from 'react';
type Props = {
  className?: string;
  children: React.ReactNode | React.ReactNode[];
}
const LoadingTextAnimation = ({ children, className }: Props) => {
  const [loadingText, setLoadingText] = useState('.');

  useEffect(() => {
    const intervalId = setInterval(() => {
      setLoadingText((prevText) => {
        if (prevText === '.') {
          return '..';
        } else if (prevText === '..') {
          return '...';
        } else {
          return '.';
        }
      });
    }, 500);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <p className={className}>
      {children}
      {loadingText}
    </p>
  );
};

export default LoadingTextAnimation;
