import {
  useCreate,
  usePlannedContentListIndex,
  usePlannedContentListItemStore,
  usePlannedContentListStore,
  useRankTrackingListIndex,
  useRankTrackingListStore,
} from '@/api/openapiComponents';
import { OrganisationTagResource } from '@/api/openapiSchemas';
import { Dialog } from '@/Components/Dialog';
import { Input } from '@/Components/v2/Input/Input';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { Select } from '@/Components/v2/Select';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { TextArea } from '@/Components/v2/TextArea/TextArea';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { TagEditor } from '@/Pages/Document/components/TagEditor/TagEditor';
import { handleSplitOnPaste } from '@/utils';
import { Plus } from 'lucide-react';
import { useState } from 'react';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (listId?: number) => void;
};

export const AddPlannedKeywordsDialog = ({
  isOpen,
  onClose,
  onSuccess,
}: Props) => {
  const appState = useAppStore();
  const [tags, setTags] = useState<OrganisationTagResource[]>([]);

  const listQuery = usePlannedContentListIndex({
    pathParams: {
      project: appState.currentProject!.id,
    },
    queryParams: {
      limit: 100,
    },
  });
  const [listNameInput, setListNameInput] = useState('');
  const [selectedListId, setSelectedListId] = useState<number>();

  const [keywordsInput, setKeywordsInput] = useState('');
  const keywords = keywordsInput
    .split('\n')
    .filter((value) => value.trim() !== '');

  const createListMutation = usePlannedContentListStore();
  const addToListMutation = usePlannedContentListItemStore();
  const plannedContentCreateItems = useCreate();

  const handleSubmit = async () => {
    const plannedContentResponse = await plannedContentCreateItems.mutateAsync({
      body: {
        items: keywords.map((keyword) => ({
          keyword,
          tags: tags.map((tag) => tag.id),
        })),
      },
      pathParams: {
        project: appState.currentProject!.id,
      },
    });

    let newListId: number | undefined;

    if (selectedListId === -1) {
      const listResponse = await createListMutation.mutateAsync({
        body: {
          name: listNameInput,
        },
        pathParams: {
          project: appState.currentProject!.id,
        },
      });

      newListId = Number(listResponse.data.id);
    }
    if (selectedListId !== undefined) {
      await addToListMutation.mutateAsync({
        body: {
          items: plannedContentResponse.data.map((keyword) =>
            Number(keyword.id),
          ),
        },
        pathParams: {
          project: appState.currentProject!.id,
          plannedContentList: newListId ?? selectedListId,
        },
      });
    }
    setKeywordsInput('');
    setListNameInput('');
    setTags([]);
    onSuccess(newListId ?? selectedListId);
    setSelectedListId(undefined);
    onClose();
    listQuery.refetch();
  };

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={onClose}
      title="Add keyword"
      size={'sm'}
      actions={[
        {
          color: 'secondary',
          text: 'Add keyword',
          isLoading: plannedContentCreateItems.isPending,
          onClick: () => handleSubmit(),
        },
      ]}
    >
      <InputDecoration label="Keywords" required>
        <TextArea
          resize
          maxHeight={20}
          value={keywordsInput}
          onChange={setKeywordsInput}
          counterMax={1000}
          counter={keywords.length}
          onPaste={async (e) => {
            const value = await handleSplitOnPaste(e, keywordsInput);
            setKeywordsInput(value);
          }}
        />
      </InputDecoration>
      <InputDecoration label="Tags">
        <TagEditor
          onTagUpdated={(tags) => {
            setTags(tags);
          }}
          tags={tags}
          organisationId={appState.organisation!.id}
        />
      </InputDecoration>
      <div className="mt-6">
        <InputDecoration label="Add to list">
          {listQuery.isLoading ? (
            <SkeletonLoader height="sm" />
          ) : (
            <Select
              fullWidth
              clearable
              value={selectedListId}
              placeholder="No list"
              onChange={(value) =>
                setSelectedListId(value ? Number(value) : undefined)
              }
              options={[
                {
                  title: 'Create new list',
                  value: -1,
                  prependIcon: Plus,
                },
                ...(listQuery.data?.data.map((list) => ({
                  title: list.name,
                  value: list.id,
                })) ?? []),
              ]}
            />
          )}
        </InputDecoration>

        {selectedListId === -1 && (
          <div className="mt-4">
            <InputDecoration label="New list name" required>
              <Input
                value={listNameInput}
                onChange={setListNameInput}
                counter
                counterMax={32}
              />
            </InputDecoration>
          </div>
        )}
      </div>
    </Dialog>
  );
};
