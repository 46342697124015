import { createRoute } from '@tanstack/react-router';
import { rootRoute } from '../Router';
import {
  guards,
  hasActiveSubscription,
  hasProject,
  isAuthenticated,
} from '../Router/guards';
import DocumentLoader from './DocumentLoader';
import { hasher } from '../AppLoader';
import DocumentLoaderV2 from './DocumentLoaderV2';

export const documentRoute = createRoute({
  getParentRoute: () => rootRoute,
  validateSearch: (
    search: Record<string, boolean | undefined>,
  ): {
    generate?: boolean;
    googleDocs?: 'success' | 'failed';
    redirect?: boolean;
    document_template?: number;
  } => ({
    generate: search.generate,
    redirect: search.redirect,
    googleDocs: search.googleDocs as 'success' | 'failed' | undefined,
    document_template: search.document_template as number | undefined,
  }),
  beforeLoad: ({ context }) => {
    guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
  },
  path: '/documents/$documentId',
  component: () => {
    const params = documentRoute.useParams();

    return (
      <DocumentLoader
        documentId={hasher.decode(params.documentId)[0] as number}
      />
    );
  },
});

export const documentV2Route = createRoute({
  getParentRoute: () => rootRoute,
  validateSearch: (
    search: Record<string, boolean | undefined>,
  ): {
    generate?: boolean;
    googleDocs?: 'success' | 'failed';
    redirect?: boolean;
    document_template?: number;
  } => ({
    generate: search.generate,
    redirect: search.redirect,
    googleDocs: search.googleDocs as 'success' | 'failed' | undefined,
    document_template: search.document_template as number | undefined,
  }),
  beforeLoad: ({ context }) => {
    guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
  },
  path: '/documents/v2/$documentId',
  component: () => {
    const params = documentV2Route.useParams();

    return (
      <DocumentLoaderV2
        documentId={hasher.decode(params.documentId)[0] as number}
      />
    );
  },
});

export const routes = [documentRoute, documentV2Route];
