import { DocumentTemplateResource } from '@/api/openapiSchemas';

export const setTemplateToForm = (
  allTemplates: DocumentTemplateResource[],
  value: string | undefined,
) => {
  const templateFound = allTemplates.find(
    (temp) => temp.id === (value ? parseInt(value) : null),
  );

  const values: {
    templateId: number | null;
    contentTypeId?: number;
  } = {
    templateId: value ? parseInt(value) : null,
  };

  if (templateFound && templateFound.content_type) {
    values.contentTypeId = templateFound.content_type.id;
  }

  return values;
};
