
type Props = {
  value: number;
  maxValue: number;
  highlighted?: boolean;
}

export const Line = ({ maxValue, value, highlighted }: Props) => {
  return (
    <div
      className={`pointer-events-none absolute left-0 right-0 -mb-2 flex h-4 items-center text-right ${
        highlighted ? 'z-30' : ''
      }`}
      style={{ bottom: (value / maxValue) * 100 + '%' }}
    >
      <div
        className={`w-12 pr-2 ${highlighted ? 'opacity-0' : 'text-gray-400'}`}
      >
        {value}
      </div>
      <div
        className={`flex-grow border-t-2 ${
          value === 0 ? '' : 'border-dotted'
        } ${highlighted ? 'border-t-emerald-400' : 'border-t-gray-300'}`}
      />
    </div>
  );
};
