import { PropsWithChildren, useEffect, useState } from 'react';

type Props = {
  noShrink?: boolean;
};

export const ShrinkableDragOverlay = ({
  noShrink,
  children,
}: PropsWithChildren<Props>) => {
  const [shrink, setShrink] = useState(false);

  useEffect(() => {
    const listener = () => {
      setShrink(false);
    };

    window.addEventListener('mouseup', listener);
    setShrink(true);

    return () => {
      window.removeEventListener('mouseup', listener);
    };
  }, []);

  return (
    <div className="pointer-events-none flex w-full max-w-[calc(100vw-28rem)] justify-center">
      <div
        className="transition-all"
        style={{
          opacity: shrink && !noShrink ? 0.6 : 1,
          width: shrink && !noShrink ? '80%' : '100%',
          margin: shrink && !noShrink ? '0 auto' : '',
        }}
      >
        {children}
      </div>
    </div>
  );
};
