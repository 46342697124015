import { LucideProps } from 'lucide-react';
import React from 'react';
import { Button } from '../Button';
import { isComponentFunction } from '../utils';

export type AlertColors =
  | 'green'
  | 'red'
  | 'blue'
  | 'gray'
  | 'yellow'
  | 'primary'
  | 'secondary'
  | 'white';

type Props = {
  title?: string;
  icon?: React.ComponentType<LucideProps> | React.ReactNode;
  body?: string | string[];
  color?: AlertColors;
  actions?: { name: string; onClick: () => void }[];
};

export const Alert = ({
  body,
  title,
  actions,
  color = 'white',
  icon,
}: Props) => {
  const getButtonColor = () => {
    switch (color) {
      case 'green':
        return 'green';
      case 'red':
        return 'red';
      case 'blue':
        return 'blue';
      case 'gray':
        return 'gray';
      case 'yellow':
        return 'yellow';
      case 'primary':
        return 'primary';
      case 'secondary':
        return 'secondary';
      default:
        return 'gray';
    }
  };

  const getTitleColor = () => {
    switch (color) {
      case 'green':
        return 'text-green-700';
      case 'red':
        return 'text-red-700';
      case 'blue':
        return 'text-blue-700';
      case 'gray':
        return 'text-gray-700';
      case 'yellow':
        return 'text-yellow-700';
      case 'primary':
        return 'text-primary-700';
      case 'secondary':
        return 'text-secondary-700';
      default:
        return 'text-gray-700';
    }
  };

  const getBackgroundColor = () => {
    switch (color) {
      case 'green':
        return 'bg-green-50';
      case 'red':
        return 'bg-red-50';
      case 'blue':
        return 'bg-blue-50';
      case 'gray':
        return 'bg-gray-50';
      case 'yellow':
        return 'bg-yellow-50';
      case 'primary':
        return 'bg-primary-50';
      case 'secondary':
        return 'bg-secondary-50';
      default:
        return 'bg-white';
    }
  };

  const getBodyTextColor = () => {
    switch (color) {
      case 'green':
        return 'text-green-700';
      case 'red':
        return 'text-red-700';
      case 'blue':
        return 'text-blue-700';
      case 'gray':
        return 'text-gray-700';
      case 'yellow':
        return 'text-yellow-700';
      case 'primary':
        return 'text-primary-700';
      case 'secondary':
        return 'text-secondary-700';
      default:
        return 'text-gray-700';
    }
  };

  const Icon = icon;

  return (
    <div className={`rounded-md p-4 ${getBackgroundColor()} text-base`}>
      <div className="flex items-center">
        <div className="flex-shrink-0">
          {Icon ? (
            isComponentFunction(Icon) ? (
              <Icon className={`mt-[0.02rem] h-5 w-5 ${getTitleColor()}`} />
            ) : (
              <div className="h-5 w-5">{Icon}</div>
            )
          ) : (
            Icon
          )}
        </div>
        <div className="ml-3">
          {title && (
            <h3 className={`font-semibold ${getTitleColor()}`}>{title}</h3>
          )}
          {body &&
            (!Array.isArray(body) ||
              (Array.isArray(body) && body.length !== 0)) && (
              <div className={`${getBodyTextColor()} mt-2`}>
                {Array.isArray(body) ? (
                  <ul role="list" className="list-disc space-y-1 pl-5">
                    {body.map((b) => (
                      <li key={b}>{b}</li>
                    ))}
                  </ul>
                ) : (
                  <p>{body}</p>
                )}
              </div>
            )}
          {actions && actions.length > 0 && (
            <div className="mt-4">
              <div className="-mx-2 -my-1.5 flex gap-1">
                {actions.map((action) => (
                  <Button
                    key={action.name}
                    text={action.name}
                    onClick={action.onClick}
                    variant="ghost"
                    dense
                    color={getButtonColor()}
                    size="xs"
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
