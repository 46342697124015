import { create } from 'zustand';

type State = {
  [key: string]: boolean | ((key: string) => void);
};

const initialState: State = {};

export const useSidebarStore = create<State>((set) => ({
  ...initialState,
  openMenu: (key: string) => {
    set((state) => ({
      ...state,
      [key]: !state[key],
    }));
  },
}));
