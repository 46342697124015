import { HelpCircle, MoreVertical } from 'lucide-react';
import { IconButton } from '../../IconButton/IconButton';
import { ListItemProps } from '../../Listitem';
import { PopoverMenu } from '../../Menu/ButtonMenu';
import { useContext } from 'react';
import { CardContext } from '..';
import { Tooltip, TooltipProps } from '../../Tooltip';

type Props = {
  /**
   * Displays untitled in the header if title is empty or missing
   */
  displayUntitled?: boolean;

  /**
   * Displays a header with the text
   */
  title?: string;

  /**
   * Displays a subheader with the text. Will only display if you also have a header
   */
  subtitle?: string;

  /**
   * Function to call when the title is clicked
   */
  onTitleClick?: () => void;

  /**
   * Configures actions shown in a menu in the top right of the card
   */
  actions?: ListItemProps[];
};

export const CardHeader = ({
  actions,
  subtitle,
  title,
  onTitleClick,
  displayUntitled,
}: Props) => {
  const { dense } = useContext(CardContext);

  const actualTitle = displayUntitled
    ? !title?.length
      ? 'Untitled'
      : title
    : title;

  return (
    <div className={`${dense ? 'p-3' : 'px-6 pt-3'}`}>
      {(actualTitle ?? actions) && (
        <div className="flex justify-between">
          {actualTitle ? (
            <div
              className={`${
                dense ? 'mb-1' : 'mb-2'
              } flex flex-grow flex-col truncate ${
                onTitleClick ? 'cursor-pointer hover:opacity-60' : ''
              }`}
              onClick={onTitleClick}
            >
              {actualTitle && (
                <h1
                  className={`truncate text-xl font-bold ${
                    !title && displayUntitled ? 'text-primary-400' : ''
                  }`}
                >
                  {actualTitle}
                </h1>
              )}
              {subtitle && actualTitle && (
                <h2 className="truncate text-primary-600">{subtitle}</h2>
              )}
            </div>
          ) : (
            <div />
          )}
          {actions && (
            <PopoverMenu
              trigger={<IconButton icon={MoreVertical} />}
              items={actions.map((action) => ({
                title: action.title,
                onClick: action.onClick,
              }))}
            />
          )}
        </div>
      )}
    </div>
  );
};
