import { GradualScoreVariant } from './AppLabel';

type Props = {
  rank: number | string;
  variant: keyof typeof GradualScoreVariant;
};

export const RankLabel = ({ variant, rank }: Props) => {
  return (
    <div
      className={`flex h-6 items-center justify-center rounded  ${GradualScoreVariant[variant]} w-8`}
    >
      {rank}
    </div>
  );
};
