import { CurrentSlideOver } from '@/types';
import { useEffect, useState } from 'react';
import { UrlInput } from './PlateEditor/components/url-input';
import { TitleInput } from './PlateEditor/components/title-input';
import { MetaDescriptionInput } from './PlateEditor/components/meta-description-input';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { DocumentResource } from '@/api/openapiSchemas';
import { ReportStatusIndicator } from './ReportStatusIndicator';
import { useMetaDescriptionState, useMetaTitleState } from '../hooks';
import { CollapsableInputField } from './PlateEditor/components/collapsable-input-field';
import { MetaFieldDecoration } from './MetaFieldDecoration';

type Props = {
  document: DocumentResource;
  onMetaTitleFocus: () => void;
  onMetaDescriptionFocus: () => void;
  currentSlideOver?: CurrentSlideOver;
  setCurrentSlideOver: (slideOver: CurrentSlideOver) => void;
};

export const CollapsibleMetadataV2 = ({
  currentSlideOver,
  document,
  onMetaDescriptionFocus,
  setCurrentSlideOver,
  onMetaTitleFocus,
}: Props) => {
  const [isOpen, setIsOpen] = useState(
    !document.meta_description || !document.meta_title,
  );
  const [metaTitle, setMetaTitle] = useMetaTitleState();
  const [metaDescription, setMetaDescription] = useMetaDescriptionState();

  useEffect(() => {
    if (
      currentSlideOver === 'meta-title' ||
      currentSlideOver === 'meta-description'
    ) {
      setIsOpen(true);
    }
  }, [currentSlideOver]);

  const metaTitleCompetitiveScore =
    document.document_report?.meta_title_competitiveness_score;

  return (
    <div>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="flex cursor-pointer items-center gap-2 p-2 text-lg text-primary-600 hover:bg-primary-50"
      >
        URL, title and meta description
        {isOpen ? <ChevronUp /> : <ChevronDown />}
      </div>
      {isOpen && (
        <div className="flex flex-col gap-6 pt-6">
          <MetaFieldDecoration label="URL" centered>
            <UrlInput />
          </MetaFieldDecoration>
          <div className="relative">
            <CollapsableInputField
              handleOpenSlideOver={onMetaDescriptionFocus}
              placeholder="Click here to get SEO title ideas"
              label="TITLE"
              value={metaTitle}
              onChange={(e) => {
                setMetaTitle(e.target.value);
              }}
            />
            <div className="absolute bottom-0 left-full top-0 flex items-center">
              <ReportStatusIndicator
                onClick={() => setCurrentSlideOver('meta-title')}
                elements={[
                  {
                    name: 'Includes target keyword',
                    progress: metaTitleCompetitiveScore?.elements
                      .includes_target_keyword
                      ? 'finished'
                      : 'unfinished',
                  },
                  {
                    name: 'Keywords in close proximity',
                    progress: metaTitleCompetitiveScore?.elements
                      .keywords_in_close_proximity
                      ? 'finished'
                      : 'unfinished',
                  },
                  {
                    name: 'Target keyword in initial part',
                    progress: metaTitleCompetitiveScore?.elements
                      .target_keyword_in_initial_part
                      ? 'finished'
                      : 'unfinished',
                  },
                  {
                    name: `Character count between ${metaTitleCompetitiveScore?.meta.character_count_min}-${metaTitleCompetitiveScore?.meta.character_count_max} (Current: ${metaTitle.length})`,
                    progress: metaTitleCompetitiveScore?.elements
                      .character_count_within_optimal_range
                      ? 'finished'
                      : 'unfinished',
                  },
                  {
                    name: 'Uniqueness vs competitors',
                    progress: metaTitleCompetitiveScore?.elements
                      .uniqueness_vs_competitors
                      ? 'finished'
                      : 'unfinished',
                  },
                  {
                    name: `Reach ${metaTitleCompetitiveScore?.target}% to average top competitors (current: ${metaTitleCompetitiveScore?.score}%)`,
                    progress: {
                      current: metaTitleCompetitiveScore?.score ?? 0,
                      total: metaTitleCompetitiveScore?.target ?? 0,
                    },
                  },
                ]}
              />
            </div>
          </div>
          <div className="relative">
            <CollapsableInputField
              handleOpenSlideOver={onMetaDescriptionFocus}
              placeholder="Click here to get meta description ideas"
              label="DESC."
              value={metaDescription}
              onChange={(e) => {
                setMetaDescription(e.target.value);
              }}
            />
            <div className="absolute bottom-0 left-full top-0 flex items-center">
              <ReportStatusIndicator
                onClick={() => setCurrentSlideOver('meta-description')}
                elements={[
                  {
                    name: 'Include target keyword',
                    progress: metaDescription
                      .toLowerCase()
                      .includes(document.keyword.name?.toLowerCase())
                      ? 'finished'
                      : 'unfinished',
                  },
                  {
                    name: 'Between 100-200 characters long',
                    progress:
                      metaDescription.length >= 100 &&
                      metaDescription.length <= 200
                        ? 'finished'
                        : 'unfinished',
                  },
                ]}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
