import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { DocumentResource, ProjectResource } from '@/api/openapiSchemas';
import { ArrowLeft, X } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { KeywordInjectionContent } from '../../KeywordInjection/components/KeywordInjectionContent';
import { Button } from '@/Components/v2/Button';

type Props = {
  keyword?: string;
  project: ProjectResource;
  document: DocumentResource;
  onClose: () => void;
};

export const KeywordInjectionOverlay = ({
  document,
  project,
  keyword,
  onClose,
}: Props) => {
  const [show, setShow] = useState(false);
  const [animationYOffset, setAnimationYOffset] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (keyword) {
      setAnimationYOffset(0);
      setShow(true);
    } else {
      setAnimationYOffset(containerRef.current?.clientHeight ?? 0);
    }
  }, [keyword]);

  const handleAnimationEnd = () => {
    if (!keyword) {
      setShow(false);
    }
  };

  return (
    <div
      ref={containerRef}
      className={`absolute bottom-0 left-0 right-0 top-0 z-50 -mx-6 -mb-4 -mt-4 overflow-hidden ${
        show ? '' : 'pointer-events-none'
      }`}
    >
      {show && (
        <motion.div
          transition={{ type: 'easeout' }}
          initial={{ y: containerRef.current?.clientHeight }}
          animate={{ y: animationYOffset }}
          onAnimationComplete={handleAnimationEnd}
          className="flex h-full w-full flex-col bg-white"
        >
          <div className={`px-4 py-4 sm:px-4`}>
            <div className="flex items-center justify-between">
              <Button
                onClick={onClose}
                prependIcon={ArrowLeft}
                text="back to keyword gaps"
                size="sm"
                dense="also-horizontally"
                variant="ghost"
              />
            </div>
            <h4 className="mt-6 leading-6">
              Insert "<b>{keyword}</b>"
            </h4>
          </div>
          <div className="flex-grow overflow-y-auto px-4 pb-4">
            <KeywordInjectionContent document={document} project={project} />
          </div>
        </motion.div>
      )}
    </div>
  );
};
