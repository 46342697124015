import React, {
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

export type MoveEvent = {
  offset: Offset;
  dimensions: Offset;
  element: HTMLDivElement;
}

export type MoveStateChangeEvent = {
  position: Offset;
  dimensions: Offset;
  element: HTMLDivElement;
}

type Offset = {
  x: number;
  y: number;
}

type Props = {
  children: ReactNode;
  onMoveStart?: (event: MoveStateChangeEvent) => void;
  onMoveEnd?: (event: MoveStateChangeEvent) => void;
  onMove: (event: MoveEvent) => void;
  onInit?: (event: MoveEvent) => void;
  onResize?: (event: MoveEvent) => void;
}

export const Movable = ({
  children,
  onMove,
  onInit,
  onResize,
  onMoveEnd,
  onMoveStart,
}: Props) => {
  const prevOffset = useRef<Offset>();
  const ref = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const isMovingRef = useRef<boolean>(false);

  const imgRef = useRef<HTMLImageElement>();

  useEffect(() => {
    if (isDragging && ref.current) {
      const handleMouseMove = (e: MouseEvent) => {
        if (!prevOffset.current || !ref.current) {
          return;
        }
        if (
          e.clientX - prevOffset.current.x > -10 &&
          e.clientX - prevOffset.current.x < 10 &&
          e.clientY - prevOffset.current.y > -10 &&
          e.clientY - prevOffset.current.y < 10 &&
          !isMovingRef.current
        ) {
          return;
        } else {
          isMovingRef.current = true;
          if (onMoveStart) {
            onMoveStart({
              position: {
                x: e.clientX - prevOffset.current.x,
                y: e.clientY - prevOffset.current.y,
              },
              dimensions: {
                x: ref.current.clientWidth,
                y: ref.current.clientHeight,
              },
              element: ref.current,
            });
          }
        }

        onMove({
          offset: {
            x: e.clientX - prevOffset.current.x,
            y: e.clientY - prevOffset.current.y,
          },
          dimensions: {
            x: ref.current.clientWidth,
            y: ref.current.clientHeight,
          },
          element: ref.current,
        });

        prevOffset.current = {
          x: e.clientX,
          y: e.clientY,
        };
      };
      document.addEventListener('pointermove', handleMouseMove);

      return () => {
        document.removeEventListener('pointermove', handleMouseMove);
      };
    }
  }, [isDragging]);

  useLayoutEffect(() => {
    const handleResize = () => {
      if (!onResize || !ref.current) {
        return;
      }
      onResize({
        offset: {
          x: 0,
          y: 0,
        },
        dimensions: {
          x: ref.current.clientWidth,
          y: ref.current.clientHeight,
        },
        element: ref.current,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useLayoutEffect(() => {
    const img = new Image(2, 2);
    img.src = '/svg/invisible.png';
    imgRef.current = img;
    if (ref.current && onInit) {
      onInit({
        offset: {
          x: 0,
          y: 0,
        },
        dimensions: {
          x: ref.current.clientWidth,
          y: ref.current.clientHeight,
        },
        element: ref.current,
      });
    }
  }, []);

  const handleDragStart = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.button === 0) {
      e.stopPropagation();
      setIsDragging(true);
      prevOffset.current = {
        x: e.clientX,
        y: e.clientY,
      };
    } else {
      handleDragEnd(e);
    }
  };

  const handleDragEnd = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    prevOffset.current = undefined;
    isMovingRef.current = false;
    setIsDragging(false);

    if (onMoveEnd) {
      setTimeout(() => {
        if (!ref.current) return;
        onMoveEnd({
          position: {
            x: e.clientX,
            y: e.clientY,
          },
          dimensions: {
            x: ref.current.clientWidth,
            y: ref.current.clientHeight,
          },
          element: ref.current,
        });
      }, 100);

      prevOffset.current = {
        x: e.clientX,
        y: e.clientY,
      };
    }
  }, []);

  const handleWindowMouseDown = useCallback((e: MouseEvent) => {
    if (e.button !== 0) {
      handleDragEnd(e);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('mouseup', handleDragEnd);
    window.addEventListener('mousedown', handleWindowMouseDown);

    return () => window.removeEventListener('mouseup', handleDragEnd);
  }, []);

  return (
    <div
      ref={ref}
      onClick={(e) => e.stopPropagation()}
      onMouseUp={handleDragEnd}
      onMouseDown={handleDragStart}
      className={`${
        isDragging ? 'cursor-grabbing' : 'cursor-grab'
      } select-none`}
    >
      {children}
    </div>
  );
};
