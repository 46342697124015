import { ReactNode, createContext, useRef, useState } from 'react';
import * as RadixPopover from '@radix-ui/react-popover';

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const PopoverContext = createContext<() => void>(() => {});

export type PopoverProps = {
  trigger: ReactNode;
  children: ReactNode;
  isOpen?: boolean;
  side?: 'top' | 'right' | 'bottom' | 'left';
  align?: 'start' | 'center' | 'end';
  onOpen?: () => void;
  onClose?: () => void;
  type?: 'default' | 'hover' | 'tooltip';
  disabled?: boolean;
  asChild?: boolean;
};

export const Popover = ({
  children,
  trigger,
  asChild,
  onOpen,
  onClose,
  side,
  disabled,
  align,
  isOpen,
  type = 'default',
}: PopoverProps) => {
  const [internalIsOpen, setInternalIsOpen] = useState(false);
  const triggerRef = useRef<HTMLButtonElement>(null);

  const handleOpen = () => {
    if (disabled) return;
    setInternalIsOpen(true);
    if (onOpen) onOpen();
  };

  const handleClose = () => {
    if (isOpen === true || internalIsOpen === true) {
      setInternalIsOpen(false);
      if (onClose) onClose();
    }
  };

  const handleInteractOutside = (e: Event) => {
    if (!triggerRef.current?.contains(e.target as Node)) {
      handleClose();
    }
  };

  return (
    <PopoverContext.Provider value={handleClose}>
      <RadixPopover.Root open={isOpen ?? internalIsOpen}>
        <span
          className={
            type === 'hover' && (isOpen ?? internalIsOpen) ? 'opacity-60' : ''
          }
          onMouseEnter={() => {
            if (type === 'hover' || type === 'tooltip') {
              handleOpen();
            }
          }}
          onMouseLeave={() => {
            if (type === 'hover' || type === 'tooltip') {
              handleClose();
            }
          }}
        >
          <RadixPopover.Trigger ref={triggerRef} asChild={asChild}>
            {type === 'default' ? (
              <button
                className="flex items-center text-sm"
                onClick={handleOpen}
              >
                {trigger}
              </button>
            ) : (
              trigger
            )}
          </RadixPopover.Trigger>
          <RadixPopover.Portal>
            <RadixPopover.Content
              side={side}
              align={align}
              className={`z-[9999] ${
                type === 'hover' || type === 'tooltip' ? 'm-2' : 'mx-4'
              }`}
              onInteractOutside={handleInteractOutside}
              onOpenAutoFocus={(e) => e.preventDefault()}
              onCloseAutoFocus={(e) => e.preventDefault()}
            >
              <div className="animate-fade overflow-hidden rounded-lg border border-gray-200 bg-white shadow-lg">
                {children}
              </div>
            </RadixPopover.Content>
          </RadixPopover.Portal>
        </span>
      </RadixPopover.Root>
    </PopoverContext.Provider>
  );
};
