import React, { useState, useEffect } from 'react';
type Props = {
  children?: React.ReactNode | React.ReactNode[];
}
const TextAnimation = ({ children }: Props) => {
  const [loadingText, setLoadingText] = useState('.');

  useEffect(() => {
    const intervalId = setInterval(() => {
      setLoadingText((prevText) => {
        if (prevText === '.') {
          return '..';
        } else if (prevText === '..') {
          return '...';
        } else {
          return '.';
        }
      });
    }, 500);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <p>
      {children && children}
      {loadingText}
    </p>
  );
};

export default TextAnimation;
