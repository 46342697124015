import { HeadingTypes, Ligatures, MarkdownNode, NodeTypesEnum } from '@/types';

const transformToHeading = (depth?: number): NodeTypesEnum => {
  switch (depth) {
    case HeadingTypes.H2:
      return NodeTypesEnum.H2;
    case HeadingTypes.H3:
      return NodeTypesEnum.H3;
    case HeadingTypes.H4:
      return NodeTypesEnum.H4;
    case HeadingTypes.H5:
      return NodeTypesEnum.H5;
    case HeadingTypes.H6:
      return NodeTypesEnum.H6;
    default:
      return NodeTypesEnum.H1;
  }
};

const getChildren = (children: MarkdownNode[], parentType?: NodeTypesEnum) => {
  if (parentType === NodeTypesEnum.LI) {
    return children.length === 0
      ? [
          {
            type: NodeTypesEnum.LIC,
            children: [{ text: '' }],
            id: createUniqueIdentifier(),
          },
        ]
      : children
          .flatMap((c) => transform(c, parentType))
          .filter((c) => c !== null);
  }
  return children.length === 0
    ? [{ text: '' }]
    : children
        .flatMap((c) => transform(c, parentType))
        .filter((c) => c !== null);
};

const strikethroughRegex = /~~([~]*[^~]*)~~/;

// This is identical to how PlateJS internally creates unique identifiers
export const createUniqueIdentifier = () =>
  Math.random().toString(36).slice(2, 7);

const createIdentifier = (parentType?: NodeTypesEnum): { id: string } | {} => {
  return !parentType ? { id: createUniqueIdentifier() } : {};
};

const getTextForImage = (node: MarkdownNode) => {
  if (node.alt) {
    return node.alt;
  }
  if (node.url) {
    return node.url;
  }

  return 'image';
};
const getCustomLigatures = (
  node: MarkdownNode,
  parentType?: NodeTypesEnum,
  ligatures?: Ligatures,
) => {
  let underline = {};
  let strikeThrough = {};
  return node.children
    .flatMap((c) => {
      if (c.type === 'text' && c.value === '~~') {
        strikeThrough = { strikethrough: true };
        return [];
      }
      if (c.type === 'html' && c.value === '<ins>') {
        underline = { underline: true };
        return [];
      }
      if (c.type === 'html' && c.value === '</ins>') {
        underline = {};
        return [];
      }
      if (c.type === 'text' && c.value === '~~') {
        strikeThrough = {};
        return [];
      }
      if (c.type === 'image') {
        // TODO: Support images. Currently we transform images to links instead:
        return [
          {
            type: NodeTypesEnum.IMG,
            url: c.url,
            children: [{ text: getTextForImage(c) }],
            id: createUniqueIdentifier(),
          },
        ];
      }
      if (c.type === 'break') {
        return [{ text: '\n' }];
      }
      return transform(c, parentType, {
        ...ligatures,
        ...underline,
        ...strikeThrough,
      });
    })
    .filter((value) => value !== null);
};

const isImageUrl = (url: string) => {
  return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
};

export default function transform(
  node: MarkdownNode,
  parentType?: NodeTypesEnum,
  ligatures?: Ligatures,
) {
  switch (node.type) {
    case 'paragraph':
      const children = getCustomLigatures(node, parentType, ligatures);
      if (parentType === NodeTypesEnum.LI) {
        return {
          type: NodeTypesEnum.LIC,
          children,
          id: createUniqueIdentifier(),
        };
      }
      if (parentType === NodeTypesEnum.BLOCKQUOTE) {
        return children;
      }
      return {
        type: NodeTypesEnum.P,
        children,
        ...createIdentifier(parentType),
      };
    case 'heading':
      return {
        type: transformToHeading(node.depth),
        children: getChildren(node.children),
        id: createUniqueIdentifier(),
      };
    case 'blockquote':
      return {
        type: NodeTypesEnum.BLOCKQUOTE,
        children: getChildren(node.children, NodeTypesEnum.BLOCKQUOTE),
        id: createUniqueIdentifier(),
      };
    case 'link':
      return {
        type: isImageUrl(node.url) ? NodeTypesEnum.IMG : NodeTypesEnum.A,
        url: node.url,
        children: isImageUrl(node.url)
          ? [{ text: '' }]
          : node.children.length !== 0
            ? getCustomLigatures(node, parentType, ligatures)
            : [{ text: node.url }],
        id: createUniqueIdentifier(),
      };
    case 'text':
      let value = [{ text: node.value, ...ligatures }];

      if (node.value && strikethroughRegex.test(node.value)) {
        value = node.value
          .split(strikethroughRegex)
          .map((e, i) =>
            i % 2 === 1
              ? { text: e, strikethrough: true, ...ligatures }
              : { text: e, ...ligatures },
          )
          .filter((e) => e.text.length);
      }
      return value;
    case 'strong':
      return getCustomLigatures(node, undefined, { bold: true, ...ligatures });
    case 'emphasis':
      return getCustomLigatures(node, undefined, {
        italic: true,
        ...ligatures,
      });
    case 'list':
      return {
        type: node.ordered ? NodeTypesEnum.OL : NodeTypesEnum.UL,
        children: node.children.flatMap((c) => transform(c, NodeTypesEnum.UL)),
        id: createUniqueIdentifier(),
      };
    case 'listItem':
      return {
        type: NodeTypesEnum.LI,
        children: getChildren(node.children, NodeTypesEnum.LI),
        id: createUniqueIdentifier(),
      };
    case 'thematicBreak':
      return {
        type: NodeTypesEnum.HR,
        children: [{ text: getTextForImage(node) }],
        id: createUniqueIdentifier(),
      };
    case 'linkReference':
      return null;
    case 'definition':
      return null;
    case 'inlineCode':
      return null;
    case 'code':
      return null;
    case 'table':
      return {
        type: NodeTypesEnum.TABLE,
        children: node.children.map((c, i) =>
          transform(c, i === 0 ? NodeTypesEnum.TH : undefined),
        ),
        id: createUniqueIdentifier(),
      };
    case 'tableRow':
      return {
        type: NodeTypesEnum.TR,
        children: node.children.flatMap((c) => transform(c, parentType)),
        id: createUniqueIdentifier(),
      };
    case 'tableCell':
      return {
        type: parentType ? NodeTypesEnum.TH : NodeTypesEnum.TD,
        children: [
          {
            type: NodeTypesEnum.P,
            children:
              node.children.length !== 0
                ? node.children
                    .flatMap((c) => transform(c))
                    .filter((v) => v !== null)
                : [{ text: '' }],
            id: createUniqueIdentifier(),
          },
        ],
        id: createUniqueIdentifier(),
      };
    case 'image':
      return {
        type: NodeTypesEnum.IMG,
        url: node.url,
        children: [{ text: '' }],
        id: createUniqueIdentifier(),
      };
    case 'html':
      if (node.value === '<br>') return { text: '\n' };
      if (
        parentType === NodeTypesEnum.BLOCKQUOTE ||
        parentType === NodeTypesEnum.LI
      ) {
        return { text: node.value };
      }

      return null;

    default:
      return null;
  }
}
