import {
  CustomValueType,
  MotionStyle,
  MotionValue,
  motion,
  useTransform,
} from 'framer-motion';
type Props = {
  progress: MotionValue<number>;
  className?:
    | string
    | CustomValueType
    | MotionValue<number>
    | MotionValue<string>
    | MotionValue<any>
    | undefined;
  style?: MotionStyle | undefined;
  removeCheckMark?: boolean;
  width?: number;
  height?: number;
  color?: string;
}

function CircularProgress({
  progress,
  removeCheckMark,
  width,
  height,
  color,
}: Props) {
  const circleLength = useTransform(progress, [0, 100], [0, 1]);
  const checkmarkPathLength = useTransform(progress, [0, 95, 100], [0, 0, 1]);
  const circleColor = useTransform(
    progress,
    [0, 95, 100],
    [
      color ? color : '#FFCC66',
      color ? color : '#FFCC66',
      color ? color : '#66BB66',
    ],
  );

  return (
    <>
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        width={!width ? '180' : width}
        height={!height ? '180' : height}
        viewBox="0 0 258 258"
      >
        {/* Check mark  */}
        {!removeCheckMark && (
          <motion.path
            transform="translate(60 85)"
            d="M3 50L45 92L134 3"
            fill="transparent"
            stroke="#7BB86F"
            strokeWidth={8}
            style={{ pathLength: checkmarkPathLength }}
          />
        )}

        {/* Circle */}
        <motion.path
          d="M 130 6 C 198.483 6 254 61.517 254 130 C 254 198.483 198.483 254 130 254 C 61.517 254 6 198.483 6 130 C 6 61.517 61.517 6 130 6 Z"
          fill="transparent"
          strokeWidth="8"
          stroke={circleColor}
          style={{
            pathLength: circleLength,
          }}
        />
      </motion.svg>
    </>
  );
}

export default CircularProgress;
