import { useAppStore } from '@/Pages/AppLoader/stores';
import { useBrandVoiceIndex } from '@/api/openapiComponents';
import { useEffect } from 'react';

export const useTrackCustomBrandVoices = () => {
  const currentProject = useAppStore((state) => state.currentProject);
  const result = useBrandVoiceIndex({
    queryParams: {
      filters: { project_id: currentProject?.id },
      sort_by: 'created_at',
      sort_direction: 'DESC',
      limit: 100,
    },
  });
  useEffect(() => {
    const url = `projects.${currentProject?.id}`;
    window.Echo.private(url).listen(`BrandVoiceTrainingUpdatedEvent`, (e) => {
      result.refetch();
    });
    return () => {
      window.Echo.leave(url);
    };
  }, []);

  return result;
};
