import { ScoreLabel } from '@/Components/Labels/ScoreLabel';
import SimpleInput from '@/Components/SimpleInput';
import { useGetRandomPlaceHolder } from '@/data/placeholders';
import { DocumentResource } from '@/api/openapiSchemas';
import * as React from 'react';

type Props = {
  illustration: {
    title: string;
    titleWidth: number;
    titleInPixels: string;
  };
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  documentData: DocumentResource;
};

export const TitleSection = ({
  illustration,
  onChange,
  documentData,
}: Props) => {
  const placeholder = useGetRandomPlaceHolder();

  return (
    <>
      <div className={`relative mb-6 mt-2`}>
        <div className="absolute left-3 top-2 z-10 translate-y-[0.8rem]">
          <ScoreLabel
            score={
              documentData?.document_report?.meta_title_competitiveness_score
                .score || 0
            }
            color="green"
          />
        </div>
        <SimpleInput
          className="w-full border border-green-400 pl-12 font-bold placeholder:pr-12 placeholder:text-gray-400"
          onChange={onChange}
          value={illustration.title}
          placeholder={placeholder.title}
        />
      </div>
    </>
  );
};
