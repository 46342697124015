import { useState } from 'react';
import { useAppStore } from '../AppLoader/stores';
import { Errors } from '@/Components/Errors';
import { Button } from '@/Components/v2/Button';
import { SeoAiLogoBlack } from '@/Components/SVGs/SeoAiLogoBlack';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { Input } from '@/Components/v2/Input/Input';
import { Link } from '@tanstack/react-router';
import { useAuthPasswordResetLink } from '@/api/openapiComponents';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const {
    mutate: forgotPassword,
    isPending: isSubmitting,
    error: errors,
    isSuccess: forgotPasswordIsSuccess,
  } = useAuthPasswordResetLink();

  const appState = useAppStore();

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    forgotPassword({
      body: {
        email,
      },
    });
  };

  appState.pageTitle('Forgot password');

  return (
    <div className="flex h-screen flex-col items-center justify-center text-gray-800">
      <div className="absolute left-0 right-0 top-2 flex w-full justify-center">
        <SeoAiLogoBlack />
      </div>
      <div className="w-96">
        <h2 className="mb-10 text-center font-work text-[25px] font-bold leading-normal text-black">
          Forgot password?
        </h2>

        {!!errors && (
          <div className={'mb-5'}>
            <Errors error={errors} />
          </div>
        )}

        {forgotPasswordIsSuccess && (
          <div className="mb-5  font-medium text-green-600">
            Check your inbox for a recovery email. If it's not there, look in
            your spam folder.
          </div>
        )}

        <form onSubmit={submit}>
          <InputDecoration required label="Email">
            <Input
              type="text"
              name="email"
              placeholder="name@company.com"
              value={email}
              autoComplete="username"
              onChange={setEmail}
            />
          </InputDecoration>

          <div className="mt-4 flex items-center justify-center">
            <Button
              color="secondary"
              fullWidth
              type="submit"
              isLoading={isSubmitting}
              text="Reset password"
            />
          </div>
          <div className={'mt-5 text-center'}>
            <Link to="/login" className=" underline">
              Back to login
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
