import { PropsWithChildren } from 'react';
import { create } from 'zustand';
import { Snackbar, SnackbarAlignment, SnackbarColors } from './Snackbar';

type SnackbarEntry = {
  message: string;
  color: SnackbarColors;
  action?: { text: string; onClick: () => void };
  duration?: number | 'infinite';
  closeable?: boolean;
  alignment?: SnackbarAlignment;
}

type SnackbarState = SnackbarEntry & { isOpen: boolean };

type SnackbarStore = {
  snackBars: SnackbarState[];
  dismiss: () => void;
  showSnackbar: (entry: SnackbarEntry) => void;
}

const useSnackbarStore = create<SnackbarStore>((set, get) => ({
  snackBars: [] as SnackbarState[],
  dismiss: () => {
    set((state) => ({
      snackBars: state.snackBars.map((snackbar, index) => {
        if (index === 0) {
          return { ...snackbar, isOpen: false };
        }
        if (index === 1) {
          return { ...snackbar, isOpen: true };
        }

        return snackbar;
      }),
    }));

    setTimeout(() => {
      set((state) => ({
        snackBars: state.snackBars.slice(1),
      }));
    }, 100);
  },
  showSnackbar: (entry: SnackbarEntry) => {
    set((state) => ({
      snackBars: [
        ...state.snackBars,
        { ...entry, isOpen: state.snackBars.length === 0 },
      ],
    }));
    if (get().snackBars.length > 1) {
      get().dismiss();
    }
  },
}));

export const useSnackbar = () => {
  const showSnackbar = useSnackbarStore((state) => state.showSnackbar);

  return { showSnackbar };
};

export const SnackbarProvider = ({ children }: PropsWithChildren) => {
  const snackBarStore = useSnackbarStore();

  return (
    <>
      {children}
      {snackBarStore.snackBars.map((snackbar, index) => (
        <Snackbar
          key={index}
          alignment={snackbar.alignment}
          closeable={!!snackbar.closeable}
          isOpen={snackbar.isOpen}
          message={snackbar.message}
          color={snackbar.color}
          action={snackbar.action}
          onClose={snackBarStore.dismiss}
          duration={snackbar.duration}
        />
      ))}
    </>
  );
};
