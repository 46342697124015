import { Dialog } from '@/Components/Dialog';
import { Button } from '@/Components/v2/Button';

type Props = {
  reconnectGoogleDialog: boolean;
  handleOpenReconnectDialog: () => void;
  handleCreateGoogleDocConnection: () => void;
};

export const GoogleDocsReconnectDialog = ({
  handleCreateGoogleDocConnection,
  handleOpenReconnectDialog,
  reconnectGoogleDialog,
}: Props) => {
  return (
    <Dialog
      isOpen={reconnectGoogleDialog}
      title="Reconnect Google Docs"
      handleClose={handleOpenReconnectDialog}
      size={'sm'}
    >
      <p>You'll be redirected to google to authenticate.</p>
      <br />

      <Button
        text="Reconnect"
        color="secondary"
        fullWidth
        onClick={handleCreateGoogleDocConnection}
      />
    </Dialog>
  );
};
