import { GoogleDesktop } from './GoogleDesktop';
import { GoogleMobile } from './GoogleMobile';
import { getScreenPropertiesForSerp } from '../utils';
import { SerpSuggestions } from './SerpSuggestions';
import { SerpState, isNotDocumentReportCompetitorResource } from '@/types';
import {
  DocumentReportCompetitorResource,
  DocumentResource,
} from '@/api/openapiSchemas';

type Props = {
  illustration: {
    title: string;
    titleInPixels: string;
    titleWidth: number;
    description: string;
    descriptionWidth: number;
    descriptionInPixels: string;
  };
  domain: string;
  keyword: string;
  documentResource?: DocumentResource;
  toggles: {
    position: number;
    showCompetitors: boolean;
    showDate: boolean;
  };
  isLoadingCompetitors: boolean;
  mode: 'Desktop' | 'Mobile';
  addMargin?: boolean;
};
const SerpSuggestionsOverview = ({
  illustration,
  documentResource,
  domain,
  keyword,
  toggles,
  isLoadingCompetitors,
  mode,
  addMargin,
}: Props) => {
  const getCompetitors = () => {
    const serpState: SerpState = {
      id: -1,
      title: illustration.titleInPixels,
      description: illustration.descriptionInPixels,
      url: 'https://' + domain + '/' + keyword,
    };

    const competitors: (DocumentReportCompetitorResource | SerpState)[] = [];
    documentResource?.document_report?.competitors?.forEach(
      (competitor, i, array) => {
        if (toggles.position === i + 1 && i + 1 < array.length) {
          competitors.push(serpState);
        }
        competitors.push(competitor);
        if (toggles.position === i + 1 && i + 1 === array.length) {
          competitors.push(serpState);
        }
      },
    );

    return competitors;
  };

  const screenProps = getScreenPropertiesForSerp(mode);

  return (
    <div
      style={screenProps.outerFullContainer}
      className={`mb-2 ${addMargin ? 'ml-[1.5%]' : ''}`}
    >
      <div
        style={{
          backgroundColor: screenProps.backgroundColor,
        }}
        className="mb-8 h-full rounded-md shadow-serp"
      >
        {mode === 'Desktop' ? (
          <GoogleDesktop keyword={keyword} />
        ) : (
          <GoogleMobile keyword={keyword} />
        )}

        <div
          className="flex w-full flex-col"
          style={{
            backgroundColor: screenProps.backgroundColor,
            gap: screenProps.itemGap,
            ...screenProps.containerPadding,
          }}
        >
          {isLoadingCompetitors
            ? new Array(9).fill(null).map(() => (
                <div
                  style={{
                    maxWidth: screenProps.containerMax,
                    ...screenProps.loaderPaddingMobile,
                  }}
                  className={`flex animate-pulse flex-col gap-1 bg-white`}
                >
                  <div className="h-8 w-1/3 bg-gray-300" />
                  <div className="h-6 w-2/3 bg-indigo-300" />
                  <div className="h-8 w-full bg-gray-300" />
                </div>
              ))
            : getCompetitors().map((competitor) => {
                return toggles.showCompetitors ? (
                  <SerpSuggestions
                    competitor={competitor}
                    keyword={keyword}
                    showDate={toggles.showDate}
                    mode={mode}
                  />
                ) : isNotDocumentReportCompetitorResource(competitor) ? (
                  <SerpSuggestions
                    competitor={competitor}
                    keyword={keyword}
                    showDate={toggles.showDate}
                    mode={mode}
                  />
                ) : (
                  <div
                    style={{
                      maxWidth: screenProps.containerMax,
                      ...screenProps.loaderPaddingMobile,
                    }}
                    className={`flex flex-col gap-1 bg-white`}
                  >
                    <div className="h-8 w-1/3 bg-gray-300" />
                    <div className="h-6 w-2/3 bg-indigo-300" />
                    <div className="h-8 w-full bg-gray-300" />
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
};

SerpSuggestionsOverview.displayName = 'SerpSuggestionsOverview';
export { SerpSuggestionsOverview };
