'use client';
import React from 'react';
import { PortalBody, useComposedRef } from '@udecode/plate-common';
import {
  flip,
  FloatingToolbarState,
  offset,
  useFloatingToolbar,
  useFloatingToolbarState,
} from '@udecode/plate-floating';
import { cn } from '../lib/utils';
import { Toolbar, ToolbarProps } from '../components';

export type FloatingToolbarProps = {
  state?: FloatingToolbarState;
} & ToolbarProps;

const FloatingToolbar = React.forwardRef<
  React.ElementRef<typeof Toolbar>,
  FloatingToolbarProps
>(({ state, children, ...props }, componentRef) => {
  const floatingToolbarState = useFloatingToolbarState({
    ...state,
    floatingOptions: {
      placement: 'top',
      middleware: [
        offset(12),
        flip({
          padding: 12,
          fallbackPlacements: [
            'top-start',
            'top-end',
            'bottom-start',
            'bottom-end',
          ],
        }),
      ],
      ...state?.floatingOptions,
    },
  });

  const {
    ref: floatingRef,
    props: rootProps,
    hidden,
  } = useFloatingToolbar(floatingToolbarState);

  const ref = useComposedRef<HTMLDivElement>(componentRef, floatingRef);

  if (hidden) return null;

  return (
    <Toolbar
      ref={ref}
      className={cn(
        'absolute z-50 whitespace-nowrap border border-slate-200 bg-white px-1 opacity-100 shadow-md dark:border-slate-800 dark:bg-slate-950 print:hidden',
      )}
      {...rootProps}
      {...props}
    >
      {children}
    </Toolbar>
  );
});
FloatingToolbar.displayName = 'FloatingToolbar';

export { FloatingToolbar };
