import { SlideOver } from '@/Components/SlideOver';
import Tabs from '@/Components/Tabs';
import { DocumentResource, ProjectResource } from '@/api/openapiSchemas';
import { NodeTypesEnum } from '@/types';
import { useState } from 'react';
import { QuestionItem } from './components/QuestionItem';
import { PlateEditor } from '@udecode/plate-common';
import { ReactEditor } from 'slate-react';
import { useQueryClient } from '@tanstack/react-query';
import { SlideOverGeneratedContent } from '@/Components/SlideOverGeneratedContent';
import { useAiGeneratedQuestions } from './hooks';
import { NoQuestions } from './components/NoQuestions';

type Props = {
  isOpen: boolean;
  project: ProjectResource;
  editor: PlateEditor | null;
  document: DocumentResource;
  onClose: () => void;
};

export const QuestionSlideover = ({
  document,
  isOpen,
  editor,
  project,
  onClose,
}: Props) => {
  const [tab, setTab] = useState('PEOPLE ALSO ASK');
  const client = useQueryClient();

  const handleAnswer = (answer: string) => {
    ReactEditor.focus(editor as ReactEditor);
    client.setQueryData(['intent-write-more'], {
      prompt: answer,
      type: 'answer-question',
    });
  };

  const { error, fetchQuestions, loading, questions } = useAiGeneratedQuestions(
    document.id,
  );

  const handleInsert = (question: string) => {
    editor?.insertNode({
      type: NodeTypesEnum.H2,
      children: [{ text: question }],
    });
    // To supress the warning in Sentry we have to set a timeout
    setTimeout(() => {
      ReactEditor.focus(editor as ReactEditor);
    }, 0);
  };

  return (
    <SlideOver
      title="People also ask + AI Questions"
      color="bg-indigo-500"
      isOpen={isOpen}
      subHeading="Incorporating Google's 'People Also Ask' questions is an excellent content starting point."
      onClose={onClose}
    >
      <Tabs
        selectedTab={tab}
        onChange={setTab}
        tabs={[
          {
            name: 'PEOPLE ALSO ASK',
          },
          {
            name: 'AI SUGGESTED QUESTIONS',
          },
        ]}
      />
      {tab === 'PEOPLE ALSO ASK' && (
        <div className="mt-4">
          <p className="mb-6  text-gray-600">
            The 'People Also Ask' questions based on the SERP for the target
            keyword.
          </p>
          {document?.document_report?.questions &&
          document?.document_report?.questions?.length > 0 ? (
            document?.document_report?.questions?.map((question) => (
              <QuestionItem
                onAnswer={() => handleAnswer(question.value)}
                onInsert={() => handleInsert(question.value)}
                question={question.value}
              />
            ))
          ) : (
            <NoQuestions />
          )}
        </div>
      )}
      {tab === 'AI SUGGESTED QUESTIONS' && (
        <div className="mt-4">
          <p className="mb-6  text-gray-600">
            Questions that are natural related to your target keyword based on
            AI analysis.
          </p>
          <SlideOverGeneratedContent
            loading={loading}
            error={error}
            onFetchItems={() => {
              fetchQuestions({
                pathParams: { document: document.id, project: project.id },
              });
            }}
            skeletonLoaders={{ count: 5 }}
          >
            {questions?.map((question) => (
              <QuestionItem
                question={question.content}
                onAnswer={() => handleAnswer(question.content)}
                onInsert={() => handleInsert(question.content)}
              />
            ))}
          </SlideOverGeneratedContent>
        </div>
      )}
    </SlideOver>
  );
};
