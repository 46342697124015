import {
  InitializeGoogleLoginRequestBody,
  useInitializeGoogleLogin,
} from '@/api/openapiComponents';
import { getCookieValue, getHostName } from '@/utils';
import { useNavigate, useSearch } from '@tanstack/react-router';
import mixPanelClient from 'mixpanel-browser';
import { useEffect } from 'react';
import { useInitApp } from '../AppLoader/hooks/useInitApp';
import { SEO_AI_AUTH } from '@/api/openapiFetcher';
import { LoginRouteParams } from './routes';
export type GoogleAuthMode = 'REGISTER' | 'LOGIN';
export const useGoogleAuth = (mode: GoogleAuthMode) => {
  const params = useSearch({ from: mode === 'LOGIN' ? '/login' : '/register' });
  const navigate = useNavigate();
  const handleInitializeApp = useInitApp();
  const { mutate: initiateGoogleLogin } = useInitializeGoogleLogin({
    onSuccess: (data) => {
      window.open(data.data.redirect_url, '_self');
    },
  });

  useEffect(() => {
    if (params.token) {
      localStorage.setItem(SEO_AI_AUTH, params.token);
      handleInitializeApp().then(() => {
        if (mode === 'REGISTER') {
          navigate({ to: '/', replace: true });
        }

        if (mode === 'LOGIN') {
          const loginParams = params as LoginRouteParams;
          const redirect = loginParams.redirect
            ? decodeURIComponent(loginParams.redirect)
            : '/';
          navigate({ to: redirect, replace: true });
        }
      });
    }
  }, [params.token]);
  const goToGoogleAuth = () => {
    const hostName = getHostName();

    const body: InitializeGoogleLoginRequestBody = {
      success_url: hostName,
      failure_url: hostName,
    };
    if (mode === 'LOGIN') {
      initiateGoogleLogin({
        body,
      });
    }
    if (mode === 'REGISTER') {
      const seoAppDataCookie = getCookieValue('seo-ai-app-data');
      let trackingData = undefined;
      if (seoAppDataCookie) {
        try {
          trackingData = JSON.parse(seoAppDataCookie);
        } catch (e) {}
      }
      initiateGoogleLogin({
        body: {
          ...body,
          distinct_id: import.meta.env.VITE_MIXPANEL_TOKEN
            ? mixPanelClient.get_distinct_id()
            : undefined,
          tracking_data: trackingData,
        },
      });
    }
  };

  return goToGoogleAuth;
};
