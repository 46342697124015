import { Button } from '@/Components/v2/Button';
import { Trash2, X } from 'lucide-react';

type Props = {
  canIgnore?: boolean;
  rejected: boolean;
  onIgnore?: () => void;
};

export const RejectedAnimation = ({ rejected, canIgnore, onIgnore }: Props) => {
  return (
    <div
      className="absolute z-10 flex h-full w-full flex-col items-center justify-center gap-2 px-4 text-2xl text-white"
      style={{
        backgroundColor: 'rgba(0,0,0,0.5)',
      }}
    >
      <X
        className={`${
          canIgnore ? 'h-32 w-32' : 'h-64 w-64'
        } -m-6 animate-fade-slow text-red-400`}
        strokeWidth={0.6}
      />
      {canIgnore && (
        <>
          <p className="text-center text-lg font-bold">
            {rejected
              ? 'Ignore website url permanently for all content?'
              : 'Invalid sentence'}
          </p>
          <Button
            prependIcon={rejected ? Trash2 : undefined}
            onClick={onIgnore}
            text={rejected ? 'IGNORE' : 'REJECT'}
            variant="fill"
            color="white"
          />
        </>
      )}
    </div>
  );
};
