import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

type Props = {
  text: string;
}

export function SelectedTextMessage({ text }: Props) {
  return (
    <div className="border-b-2 bg-zinc-100 px-6 pt-2">
      <label className="text-sm font-semibold text-gray-400">
        SELECTED TEXT
      </label>
      <p className="mb-3 mt-1 rounded border-2 border-dashed border-gray-200 p-3">
        <ReactMarkdown remarkPlugins={[remarkGfm]} className="prose text-base">
          {text}
        </ReactMarkdown>
      </p>
    </div>
  );
}

export default React.memo(SelectedTextMessage, (a, b) => a.text === b.text);
