import { useEditor } from '@/Components/Utils/v2/api';
import { useGetEditorFocusElement } from '@/Pages/Document/hooks';
import { useKeywordInjectionStore } from '@/Pages/Document/stores';
import {
  convertMarkdownToHTML,
  getAnchorAndFocusPosForSentence,
  getInitialPlateJSValue,
  getMarkdownText,
  isInList,
} from '@/Pages/Document/utils';
import { twMerge } from 'tailwind-merge';
import { useShallow } from 'zustand/react/shallow';
import AcceptedAnimation from '../../InternalLinkingSlideOver/components/AcceptedAnimation';
import { SuggestedActions } from '../../InternalLinkingSlideOver/components/SuggestionActions';
import { DocumentResource } from '@/api/openapiSchemas';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { tracking } from '@/Services/tracking/Tracking';
import { RejectedAnimation } from '../../InternalLinkingSlideOver/components/RejectedAnimation';

type Props = {
  document: DocumentResource;
};
const SuggestionInsert = ({ document }: Props) => {
  const editorFocusRef = useGetEditorFocusElement();
  const editor = useEditor();
  const {
    newKeywordSuggestions,
    keyword,
    handleHoverNewKeywordPositionFromTab,
    handleAcceptNewSuggestion,
    removeNewKeywordSuggestion,
    handleRejectNewSuggestion,
    keywordType,
  } = useKeywordInjectionStore(
    useShallow((state) => ({
      newKeywordSuggestions: state.newKeywordSuggestions,
      keyword: state.keyword,
      handleHoverNewKeywordPositionFromTab:
        state.handleHoverNewKeywordPositionFromTab,
      handleAcceptNewSuggestion: state.handleAcceptNewSuggestion,
      removeNewKeywordSuggestion: state.removeNewKeywordSuggestion,
      handleRejectNewSuggestion: state.handleRejectNewSuggestion,
      keywordType: state.keywordType,
    })),
  );
  const app = useAppStore();

  return (
    <>
      {newKeywordSuggestions.map((newKeywordSuggestion, index) => (
        <div
          key={
            newKeywordSuggestion.text +
            newKeywordSuggestion.newMarkdownText +
            index
          }
          ref={(e) => {
            newKeywordSuggestion.hoveredFromEditor &&
              e?.scrollIntoView({ behavior: 'instant', block: 'center' });
          }}
          className={twMerge(
            `relative mb-6 rounded border-2 pb-4 pt-0 text-sm hover:border-emerald-500`,
            newKeywordSuggestion.hoveredFromEditor ? 'border-emerald-500' : '',
          )}
          onMouseEnter={() => {
            if (
              editor &&
              !newKeywordSuggestion.accepted &&
              !newKeywordSuggestion.rejected
            ) {
              handleHoverNewKeywordPositionFromTab(newKeywordSuggestion, true);
              const positions = getAnchorAndFocusPosForSentence(
                editor,
                newKeywordSuggestion.text,
              );

              editorFocusRef.focus();

              editor?.select({
                anchor: positions.focus!,
                focus: positions.focus!,
              });
            }
          }}
          onMouseLeave={() => {
            handleHoverNewKeywordPositionFromTab(newKeywordSuggestion, false);
            editor?.deselect();
          }}
        >
          {newKeywordSuggestion.accepted && (
            <AcceptedAnimation
              width={150}
              height={150}
              accepted={newKeywordSuggestion.accepted}
            />
          )}
          {newKeywordSuggestion.rejected && <RejectedAnimation rejected />}
          <div className="mt-3 overflow-hidden px-4">
            <div
              className="prose leading-tight"
              dangerouslySetInnerHTML={{
                __html: getMarkdownText(
                  convertMarkdownToHTML(newKeywordSuggestion.newMarkdownText),
                  keyword,
                ),
              }}
            />

            <SuggestedActions
              onAccept={() => {
                if (editor) {
                  const positions = getAnchorAndFocusPosForSentence(
                    editor,
                    newKeywordSuggestion.text,
                  );

                  editor.select({
                    anchor: positions.focus!,
                    focus: positions.focus!,
                  });

                  const isListItem = isInList(editor);

                  if (isListItem) {
                    editor.insertBreak();
                  }

                  const node: any = getInitialPlateJSValue(
                    newKeywordSuggestion.newMarkdownText,
                  ).filter((_, i, array) => i !== array.length - 1);

                  editor.insertNodes(node);

                  handleAcceptNewSuggestion(newKeywordSuggestion);
                  tracking.event('keyword_injection_accepted', {
                    keyword: keyword,
                    document: {
                      id: document.id,
                      word_count:
                        document.document_report?.word_count.actual || 0,
                    },
                    is_new_paragraph: true,
                    language: app.currentProject?.language.language || 'en',
                    keyword_type: keywordType,
                  });
                }
              }}
              onReject={() => {
                tracking.event('keyword_injection_rejected', {
                  keyword: keyword,
                  document: {
                    id: document.id,
                    word_count:
                      document.document_report?.word_count.actual || 0,
                  },
                  is_new_paragraph: true,
                  language: app.currentProject?.language.language || 'en',
                  keyword_type: keywordType,
                });
                handleRejectNewSuggestion(newKeywordSuggestion);
              }}
              isDisabled={false}
            />
          </div>
        </div>
      ))}
    </>
  );
};

SuggestionInsert.displayName = 'SuggestionInsert';

export { SuggestionInsert };
