import { Dialog } from '@/Components/Dialog';
import { SimilarityDialogSection } from './components/SimilarityDialogSection';
import { useEffect, useRef } from 'react';

export type SimilarityLines = {
  title: string;
  url: string;
  image: string;
};

type Props = {
  leftLines: { keyword: string; items: SimilarityLines[] };
  rightLines: { keyword: string; items: SimilarityLines[] };
  isOpen: boolean;
  onClose: () => void;
};

const start = 86;
const jump = 44;

export const SimilarityDialog = ({
  leftLines,
  rightLines,
  isOpen,
  onClose,
}: Props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d');
      if (ctx) {
        ctx.beginPath();
        ctx.strokeStyle = 'rgb(134 239 172 / 1)';
        ctx.lineWidth = 2;
        leftLines.items.forEach((item, i) => {
          const index = rightLines.items.findIndex(
            (leftItem) => leftItem.url === item.url,
          );
          if (index !== -1) {
            ctx.moveTo(8, i === 0 ? start : start + jump * i);
            ctx.lineTo(492, start + jump * index);
            ctx.stroke();
          }
        });
      }
    }
  }, [canvasRef.current, isOpen]);

  return (
    <Dialog handleClose={onClose} isOpen={isOpen} size="xl">
      <div className="flex w-full">
        <SimilarityDialogSection
          key={0}
          keyword={leftLines.keyword}
          currentLines={leftLines.items}
          compareLines={rightLines.items}
        />
        <canvas ref={canvasRef} height={500} width={500} className="w-48" />
        <SimilarityDialogSection
          key={1}
          keyword={rightLines.keyword}
          currentLines={rightLines.items}
          compareLines={leftLines.items}
        />
      </div>
    </Dialog>
  );
};
