import { UserResource } from '@/api/openapiSchemas';
import { destinations } from './Destinations';
import { events } from './Events';

export type TrackingEvent = {
  name: keyof typeof events;
  displayName: string;
  payload: any;
};

let identifiedUser: UserResource | undefined = undefined;

const initialize = async () => {
  const promises: (Promise<any> | void)[] = [];
  for (const destination of destinations) {
    if (!import.meta.env[destination.enabledByEnvKey]) {
      continue;
    }
    try {
      promises.push(destination.initialize());
    } catch (e) {
      console.error(`Error initializing ${destination.name}`, e);
    }
  }

  await Promise.all(promises);
};

const identify = (user: UserResource) => {
  identifiedUser = user;
  for (const destination of destinations) {
    if (!import.meta.env[destination.enabledByEnvKey]) {
      continue;
    }
    try {
      destination.identify(user);
    } catch (e) {
      console.error(
        `Error identifying user ${user.id} in ${destination.name}`,
        e,
      );
    }
  }
};

const reset = () => {
  identifiedUser = undefined;
  for (const destination of destinations) {
    if (!import.meta.env[destination.enabledByEnvKey]) {
      continue;
    }
    try {
      destination.reset();
    } catch (e) {
      console.error(`Error resetting user in ${destination.name}`, e);
    }
  }
};

const group = (
  group: { id: number; type: string },
  payload?: Record<string, any>,
) => {
  for (const destination of destinations) {
    if (!import.meta.env[destination.enabledByEnvKey]) {
      continue;
    }
    try {
      destination.group?.(group, payload);
    } catch (e) {
      console.error(`Error setting group in ${destination.name}`, e);
    }
  }
};

const resetGroups = () => {
  for (const destination of destinations) {
    if (!import.meta.env[destination.enabledByEnvKey]) {
      continue;
    }
    try {
      destination.resetGroup?.();
    } catch (e) {
      console.error(`Error setting group in ${destination.name}`, e);
    }
  }
};

const event = (name: keyof typeof events, payload?: any) => {
  const event: TrackingEvent = {
    name,
    displayName: events[name],
    payload,
  };

  for (const destination of destinations) {
    if (destination.onlyAllow && !destination.onlyAllow.includes(event.name)) {
      continue;
    }
    if (destination.ignore && destination.ignore.includes(event.name)) {
      continue;
    }
    if (!import.meta.env[destination.enabledByEnvKey]) {
      continue;
    }

    try {
      destination.trackEvent(event, identifiedUser);
    } catch (e) {
      console.error(
        `Error tracking event ${event.name} in ${destination.name}`,
        e,
      );
    }
  }
};

initialize();

export const tracking = {
  reset,
  identify,
  group,
  event,
  resetGroups,
};
