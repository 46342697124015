import { Dialog } from '@/Components/Dialog';
import { SectionParagraph } from './SectionParagraph';
import { TextArea } from '@/Components/v2/TextArea/TextArea';
import { Button } from '@/Components/v2/Button';
import { useAppStore } from '@/Pages/AppLoader/stores';
import {
  useProjectsUpdate,
  useProjectsUrlsIndex,
} from '@/api/openapiComponents';
import { useState } from 'react';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import ValidationErrors from '@/Components/ValidationErrors';
import { useSnackbar } from '@/Components/v2/Snackbar';

type Props = {
  sitemapSettingsDialog: boolean;
  setSitemapSettingsDialog: React.Dispatch<React.SetStateAction<boolean>>;
  refetchProjectUrls: () => void;
};
export const URLDialog = ({
  setSitemapSettingsDialog,
  sitemapSettingsDialog,
  refetchProjectUrls,
}: Props) => {
  const refreshCurrentProject = useAppStore(
    (state) => state.refreshCurrentProject,
  );
  const { projectId, crawlFilterStart } = useAppStore((state) => ({
    crawlFilterStart: (
      state.currentProject!.crawl_settings?.patterns ?? []
    ).join('\n'),
    projectId: state.currentProject!.id,
  }));
  const [crawlFilter, setCrawlFilter] = useState(crawlFilterStart ?? '');

  const {
    mutate: updateCrawls,
    isPending: isUpdatingCrawls,
    error: crawlValidationErrors,
    isError: crawlValidationHasError,
  } = useProjectsUpdate({
    onSuccess: () => {
      setSitemapSettingsDialog(false);
      refetchProjectUrls();
    },
  });

  const handleSaveCrawlFilter = () => {
    updateCrawls(
      {
        pathParams: {
          project: projectId,
        },
        body: {
          crawl_settings: {
            patterns: crawlFilter.split('\n').filter((line) => line.length > 0),
          },
        },
      },
      {
        onSuccess: () => {
          refreshCurrentProject();
        },
      },
    );
  };

  const domainQueryTotal = useProjectsUrlsIndex({
    pathParams: {
      project: projectId,
    },
    queryParams: {
      limit: 5,
    },
  });

  return (
    <Dialog
      title="Sitemap Settings"
      isOpen={sitemapSettingsDialog}
      handleClose={() => {
        setSitemapSettingsDialog(false);
      }}
    >
      <div className="flex flex-col gap-6">
        <p>
          {domainQueryTotal.isLoading ? (
            <SkeletonLoader height="2xs" />
          ) : (
            <>
              <b>{domainQueryTotal.data?.meta.total ?? 1}</b> URLs found.
            </>
          )}
        </p>
        <p>
          Customize which URLs we should crawl on your website and which to
          avoid with filters.
        </p>
        <SectionParagraph
          title="Blacklist path"
          description="You can exclude specific paths by using a ! before the slug (e.g.,
              !/products/). Then we will not crawl pages containing this path."
        />
        <SectionParagraph
          title="Whitelist path"
          description="If you only want specific sections on your site, you can include them below. (e.g., /blog/) Then we will only crawl these."
        />
        <TextArea
          rows={6}
          value={crawlFilter}
          placeholder="Insert sitemap filters here."
          onChange={(value) => setCrawlFilter(value)}
        />
        <div className="flex justify-center">
          <div className="w-32">
            <Button
              color="secondary"
              text="Save"
              fullWidth
              onClick={handleSaveCrawlFilter}
              isLoading={isUpdatingCrawls}
              disabled={isUpdatingCrawls}
            />
          </div>
          {crawlValidationHasError && (
            <ValidationErrors
              apiError={crawlValidationErrors}
              errors={crawlValidationErrors.errors}
            />
          )}
        </div>
      </div>
    </Dialog>
  );
};
