import { ActiveDraggableContext } from '@/Pages/Document/context';
import { useContext } from 'react';

export const useGetDraggableState = () => {
  const draggableState = useContext(ActiveDraggableContext);

  const isHoveredElement = (id: string) => {
    if (draggableState?.hoveredElementID) {
      const hoveredElement = draggableState.hoveredElementID;
      if (hoveredElement === id) {
        return true;
      }
    }
    return false;
  };

  return { draggableState, isHoveredElement };
};
