import { useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ReactNode } from 'react';
import { GripVertical } from 'lucide-react';

type Props = {
  id: string;
  type: 'H2' | 'H3';
  children: ReactNode;
}

export const DndHandle = ({ children, id, type }: Props) => {
  const [isHovered, setIsHovered] = useState(false);

  const {
    attributes,
    listeners,
    setNodeRef,
    isDragging,
    transform,
    transition,
  } = useSortable({ id, data: { type } });

  const styleDragAndDrop = {
    transform: CSS.Transform.toString(transform),
    // transition,
    zIndex: attributes['aria-pressed'] ? 20 : 0,
  };
  return (
    <div
      style={styleDragAndDrop}
      {...attributes}
      ref={setNodeRef}
      className={`flex flex-col`}
    >
      <div
        className={`relative flex w-full bg-white
          ${isDragging ? 'opacity-20' : ''}
        `}
        onMouseOver={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div
          {...listeners}
          className={`mt-2.5 flex cursor-move opacity-0 ${
            isHovered ? 'opacity-100' : ''
          }`}
        >
          <GripVertical />
        </div>
        {children}
      </div>
    </div>
  );
};
