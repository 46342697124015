import { ChevronLeft } from 'lucide-react';
import { CancelSubscriptionForm } from './components/CancelSubscriptionForm';
import { Menus } from '@/Components/Menus';
import { useAppStore } from '../AppLoader/stores';
import { useNavigate } from '@tanstack/react-router';
import { Button } from '@/Components/v2/Button';
import { Card } from '@/Components/v2/Card';
import PageContainer from '@/Components/PageContainer';
import { CardBody } from '@/Components/v2/Card/components/CardBody';

export const CancelSubscription = () => {
  const appState = useAppStore();
  const navigate = useNavigate();

  appState.pageTitle('Cancel subscription');

  return (
    <Menus hideBanners>
      <PageContainer>
        <div className="my-2">
          <Button
            size="sm"
            text="Back to subscription"
            onClick={() => navigate({ to: '/account/subscription' })}
            prependIcon={ChevronLeft}
            color="primary"
            variant="ghost"
          />
        </div>
        <Card>
          <CardBody>
            <CancelSubscriptionForm
              auth={appState.auth!}
              onCancel={() => navigate({ to: '/account' })}
            />
          </CardBody>
        </Card>
      </PageContainer>
    </Menus>
  );
};
