import * as React from 'react';
import { getInitialPlateJSValue } from '../utils';
import {
  QueryObserverResult,
  RefetchOptions,
  UseMutateFunction,
} from '@tanstack/react-query';
import { DocumentResource } from '@/api/openapiSchemas';
import { serializeToMarkdown } from './PlateEditor/lib/serialize';
import {
  GuestLogErrorError,
  GuestLogErrorVariables,
  UpdateDocumentResponse,
  useGuestLogError,
  useUpdateDocument,
} from '@/api/openapiComponents';

type Props = {
  document: number;
  projectId: number;
  fallback: React.ReactNode;
  children: React.ReactNode;
  text: string;
  refetchDocument: (options?: RefetchOptions | undefined) => Promise<
    QueryObserverResult<
      {
        data: DocumentResource;
      },
      unknown
    >
  >;
};

class PlateEditorErrorBoundary extends React.Component<
  Omit<Props, 'document' | 'projectId'> & {
    handleUpdateText: (text: string) => Promise<UpdateDocumentResponse>;
    logPlateEditorError: UseMutateFunction<
      undefined,
      GuestLogErrorError,
      GuestLogErrorVariables,
      unknown
    >;
  },
  { hasError: boolean }
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any) {
    const tree = getInitialPlateJSValue(this.props.text);

    this.props.logPlateEditorError({
      body: { message: error.message, context: [this.props.text] },
    });

    const position: number = parseInt(
      error.message
        .split(' ')
        .filter((word: string) => word.startsWith('[') && word.endsWith(']'))
        .join(' ')
        .replace('[', '')
        .replace(']', '')
        .split(',')[0],
    );

    const newValue = tree.filter((_, i) => i !== position);
    const markdownText = serializeToMarkdown(newValue);

    this.props.handleUpdateText(markdownText).then(() => {
      this.props.refetchDocument().then(() => {
        this.setState({ hasError: false });
      });
    });
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }

    return this.props.children;
  }
}

const PlateEditorErrorBoundaryHOC = ({
  document,
  projectId,
  ...props
}: Props) => {
  const { mutateAsync: updateText } = useUpdateDocument();
  const { mutate: logPlateEditorError } = useGuestLogError();
  const handleUpdateText = (text: string) =>
    updateText({
      pathParams: {
        document,
        project: projectId,
      },
      body: { body: text },
    });
  return (
    <PlateEditorErrorBoundary
      {...props}
      handleUpdateText={handleUpdateText}
      logPlateEditorError={logPlateEditorError}
    />
  );
};

export default PlateEditorErrorBoundaryHOC;
