type Props = {
  value?: number;
  isSubrow?: boolean;
};

export const ChangeIndicator = ({ value, isSubrow }: Props) => {
  return (
    <div
      className={`flex h-6 items-center ${isSubrow ? 'opacity-60' : ''} ${
        (value ?? 0) > 0 ? 'text-green-500' : ''
      } ${(value ?? 0) < 0 ? 'text-red-500' : ''}`}
    >
      {(value ?? 0) > 0 && '+'}
      {value ?? '-'}
    </div>
  );
};
