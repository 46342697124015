import * as React from 'react';
import {
  ArrowRightLeft,
  GripVertical,
  PlusSquare,
  RefreshCcw,
} from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import { useDraggable } from '@dnd-kit/core';
import BeatLoader from '@/Components/BeatLoader';
import remarkGfm from 'remark-gfm';
import { DocumentActionContext } from '@/Pages/Document/context';
import { Button } from '@/Components/v2/Button';
import { IconButton } from '@/Components/v2/IconButton/IconButton';

type AIMessageOverlayProps = {
  text: string;
  id?: number;
};
type Props = {
  isLastAIMessage?: boolean;
  text: string;
  id: number;
  handleRefreshMessage: (id: number) => void;
};

export function AIMessageOverlayV2({ text, id }: AIMessageOverlayProps) {
  return (
    <div className="flex w-full flex-col pt-6 opacity-70">
      <p className="mb-4 px-6">
        <ReactMarkdown remarkPlugins={[remarkGfm]} className="prose text-base">
          {text}
        </ReactMarkdown>
        {id === 0 && (
          <BeatLoader
            className="mt-6"
            size={'0.5rem'}
            color="rgb(74 222 128 / 1)"
          />
        )}
      </p>
    </div>
  );
}

export function AIMessageV2({
  text,
  id,
  handleRefreshMessage,
  isLastAIMessage,
}: Props) {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef } =
    useDraggable({
      data: {
        text,
        source: 'chat',
      },
      id,
    });

  const { handleInsertText, handleReplaceText } = React.useContext(
    DocumentActionContext,
  );

  return (
    <div className="pr-16">
      <div
        className="group flex w-full flex-col rounded-xl pt-6 hover:bg-white hover:shadow-md"
        ref={setNodeRef}
      >
        <p className="mb-4 cursor-move px-6" {...listeners} {...attributes}>
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            className="prose text-base"
          >
            {text}
          </ReactMarkdown>
        </p>
        <div className="flex items-center justify-between gap-2 px-2 pb-2 opacity-0 group-hover:opacity-100">
          {/* <Button
            {...listeners}
            {...attributes}
            size="sm"
            dense
            ref={setActivatorNodeRef}
            prependIcon={GripVertical}
            text="Drag"
            variant="ghost"
            onClick={(e) => {
              e.preventDefault();
            }}
          /> */}
          <div>
            {isLastAIMessage && (
              <IconButton
                icon={RefreshCcw}
                onClick={() => {
                  handleRefreshMessage(id);
                }}
              />
            )}
          </div>
          <div className="flex items-center gap-2">
            <Button
              prependIcon={ArrowRightLeft}
              text="Replace"
              variant="ghost"
              dense
              size="sm"
              onClick={() => handleReplaceText(text)}
            />
            <Button
              prependIcon={PlusSquare}
              text="Insert"
              variant="ghost"
              dense
              size="sm"
              onClick={() => handleInsertText(text)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
