import { ConfirmDialog } from '@/Components/ConfirmDialog';
import { KeywordTargetResource } from '@/api/openapiSchemas';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { TextArea } from '@/Components/v2/TextArea/TextArea';
import { handleSplitOnPaste } from '@/utils';
import { useUpdateDocument } from '@/api/openapiComponents';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  projectId: number;
  documentId: number;
  initialKeywords: KeywordTargetResource[];
};

export const AddKeywordsDialog = ({
  isOpen,
  onClose,
  initialKeywords,
  documentId,
  projectId,
}: Props) => {
  const [keywords, setKeywords] = useState(
    initialKeywords.map((keyword) => keyword.keyword).join('\n'),
  );

  useEffect(() => {
    if (isOpen) {
      setKeywords(initialKeywords.map((keyword) => keyword.keyword).join('\n'));
    } else {
      updateDocumentMutation.reset();
    }
  }, [isOpen]);

  const client = useQueryClient();
  const updateDocumentMutation = useUpdateDocument();

  const handleUpdate = () => {
    updateDocumentMutation.mutate(
      {
        pathParams: {
          document: documentId,
          project: projectId,
        },
        body: {
          secondary_keywords: [
            ...new Set(
              keywords.split('\n').filter((keyword) => keyword.length > 0),
            ),
          ],
        },
      },
      {
        onSuccess: () => {
          client.refetchQueries({
            predicate: (query) => query.queryKey.includes('getDocument'),
          });
          onClose();
        },
      },
    );
  };

  return (
    <ConfirmDialog
      isOpen={isOpen}
      onClose={onClose}
      title={initialKeywords.length > 0 ? 'Edit Keywords' : 'Add Keywords'}
      confirmText="Save"
      content={
        <div className="flex flex-col">
          <p className={'mb-2 text-sm'}>One keyword per line</p>
          <TextArea
            rows={10}
            placeholder={'beaches of france\nfrench cuisine\n...'}
            value={keywords}
            onChange={(value) => setKeywords(value)}
            counter={keywords.split('\n').filter(Boolean).length}
            counterMax={100}
            resize={false}
            onPaste={async (e) => {
              const value = await handleSplitOnPaste(e, keywords);
              setKeywords(value);
            }}
          />
        </div>
      }
      isLoading={updateDocumentMutation.isPending}
      error={updateDocumentMutation.error}
      onConfirm={handleUpdate}
    />
  );
};
