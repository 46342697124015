import React, { useEffect, useRef, useState } from 'react';
import SimpleButton from '@/Components/SimpleButton';
import deserialize, {
  deserializeSnippet,
} from './components/PlateEditor/lib/deserialize';
import {
  BlockSelection,
  isEditorUniqueID,
  ActiveDraggableState,
  CurrentSlideOver,
  Generating,
  NodeTypesEnum,
} from '@/types';
import { PlateEditor as PlateEditorRef } from '@udecode/plate-common';
import { PenLine, Settings } from 'lucide-react';
import { ChatTab, PlateEditor, ReportSection } from './components';
import {
  DndContext,
  DragEndEvent,
  DragStartEvent,
  pointerWithin,
} from '@dnd-kit/core';
import { usePollIsGeneratingDocument } from '@/Components/Utils/v2/api';
import { useGetSettingsState } from './hooks';
import { DocumentSlideOvers } from './components/DocumentSlideOvers';
import {
  QueryObserverResult,
  RefetchOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { Loader } from '@/Components/Loader';
import ConfettiExplosion from 'react-confetti-explosion';
import { Banner } from '@/Components/Banner/Banner';
import {
  DocumentResource,
  ProjectResource,
  UserResource,
} from '@/api/openapiSchemas';
import {
  ActiveDraggableContext,
  DocumentActionContext,
  EditorContext,
  EditorRefContext,
} from './context';
import { ArrowLeft } from 'lucide-react';
import { DocumentInfoContext } from './components/PlateEditor/contexts/DocumentInfoContext';
import { useInternalLinkingStore } from './stores';
import { useNavigate, useRouter } from '@tanstack/react-router';
import { tracking } from '@/Services/tracking/Tracking';
import { Button } from '@/Components/v2/Button';
import PlateEditorErrorBoundary from './components/PlateEditorErrorBoundary';
import { DocumentLoading } from './DocumentLoading';
import { OutlineItem } from '@/Components/DocumentForm/hooks';
import { ReactEditor } from 'slate-react';
import { useAppStore } from '../AppLoader/stores';
import { SettingsDialog } from './components/SettingDialogs/SettingsDialog';
import { DocumentExports } from './components/DocumentExports';

export type Props = {
  refetchDocument: (options?: RefetchOptions | undefined) => Promise<
    QueryObserverResult<
      {
        data: DocumentResource;
      },
      unknown
    >
  >;
  auth: { user: UserResource & { org_id: number } };
  document: DocumentResource;
  currentProject: ProjectResource;
};

export default function Document({
  auth,
  document,
  currentProject,
  refetchDocument,
}: Props) {
  const { history } = useRouter();
  const appState = useAppStore();

  const navigate = useNavigate();
  const editorFocusRef = useRef<HTMLDivElement>(null);
  const [editor, setEditor] = useState<PlateEditorRef | null>(null);
  const [selection, setSelection] = useState<BlockSelection | null>(null);
  const [currentSlideOver, setCurrentSlideOver] = useState<CurrentSlideOver>();

  const { settings, handleSettings } = useGetSettingsState(document);
  const [showSettingsDialog, setShowSettingsDialog] = useState(false);
  const [isMaximized, setIsMaximized] = useState(false);

  const hardResetInternalLinking = useInternalLinkingStore(
    (state) => state.hardReset,
  );

  const client = useQueryClient();

  // State for completion banner
  const [isDoneGenerating, setIsDoneGenerating] = useState(false);
  const previousGeneratingState = useRef<Generating>(false);
  const pollIsGenerating = usePollIsGeneratingDocument(
    currentProject.id,
    document.id,
  );

  const { data: isLoading } = useQuery<Generating>({
    queryKey: ['autoGenerationStream'],
  });

  useEffect(() => {
    if (isLoading === undefined) {
      return;
    }
    if (
      previousGeneratingState.current !== 'write-more' &&
      previousGeneratingState.current !== false &&
      isLoading === false
    ) {
      setIsDoneGenerating(true);
    }
    previousGeneratingState.current = isLoading;
  }, [isLoading]);

  useEffect(() => {
    return () => {
      hardResetInternalLinking();
    };
  }, []);

  const [draggableState, setDraggableState] =
    useState<ActiveDraggableState | null>(null);

  const deselectSelection = () => {
    if (editor) {
      setSelection((prev) => {
        if (prev) {
          return { ...prev, selected: { ...prev.selected, showInChat: false } };
        }
        return prev;
      });
    }
  };

  const { data: isGenerating } = useQuery<boolean>({
    queryKey: ['autoGenerationStream'],
  });

  const handleInsertText = (markdownString: string) => {
    if (editor) {
      const nodes = deserializeSnippet(markdownString);
      if (editor.selection) {
        tracking.event('document_chat_inserted_content');
        const focusPath = editor.selection.focus.path[0];
        const anchorPath = editor.selection.anchor.path[0];
        if (focusPath >= anchorPath) {
          editor.insertNodes(nodes, {
            at: [focusPath + 1],
          });
          return;
        }
        if (anchorPath > focusPath) {
          editor.insertNodes(nodes, {
            at: [anchorPath + 1],
          });
          return;
        }
      }
    }
  };

  const handleReplaceText = (markdownString: string) => {
    if (editor) {
      editor.deleteFragment();
      const nodes = deserializeSnippet(markdownString);
      editor.insertFragment(nodes);
      tracking.event('document_chat_replaced_content');
    }
  };

  const handleDragStart = (e: DragStartEvent) => {
    if (e.active.data.current?.text) {
      setDraggableState((prev) =>
        prev === null
          ? {
              activeElement: {
                id: e.active.id,
                text: e.active.data.current?.text,
              },
              hoveredElementID: undefined,
            }
          : { ...prev },
      );
    }
  };

  const handleDraft = () => {
    navigate({
      to: '/create-content/ai-generated',
      search: (prev) => ({
        ...prev,
        documentId: document.id,
        keyword: document.keyword.name,
      }),
    });
  };

  const handleDrag = (e: DragEndEvent) => {
    const activeId = e.active.id;
    const overId = e.over?.id;
    if (editor) {
      const overIndex = editor.children.findIndex((x) => x.id === overId);
      const activeIndex = editor.children.findIndex((x) => x.id === activeId);
      if (overId && isEditorUniqueID(activeId) && isEditorUniqueID(overId)) {
        if (activeIndex >= 0 && overIndex >= 0) {
          editor.moveNodes({
            at: [activeIndex],
            to: [overIndex],
          });
        }
      }
      if (
        overId &&
        !isEditorUniqueID(activeId) &&
        isEditorUniqueID(overId) &&
        draggableState
      ) {
        editor.insertNodes(
          deserializeSnippet(draggableState.activeElement.text),
          {
            at: [overIndex + 1],
          },
        );
      }
    }
    tracking.event('document_chat_dragged_content');
    setDraggableState(null);
  };

  const handleMaximize = () =>
    setIsMaximized((prev) => {
      tracking.event('document_chat_toggle_maximize', {
        is_open: !prev,
      });
      return !prev;
    });
  const handleGeneratePoll = (document: DocumentResource) => {
    if (editor) {
      editor.select({
        anchor: {
          offset: 0,
          path: [0, 0],
        },
        focus: {
          offset: 0,
          path: [editor.children.length - 1, 0],
        },
      });
      editor.delete();
      editor.insertFragment(deserialize(document.text));
      if (window.document.activeElement instanceof HTMLElement) {
        window.document.activeElement.blur();
      }
    }
  };

  useEffect(() => {
    if (document.is_generating && editor) {
      pollIsGenerating.startPolling({
        onPoll: handleGeneratePoll,
        onFinish: handleGeneratePoll,
      });
    }

    return () => pollIsGenerating.stopPolling();
  }, [editor]);

  const handleInsertHeading = (value: string, type?: string, focus = true) => {
    if (!editor) return;

    tracking.event('subheading_suggestions_inserted', {
      type: type,
      value: value,
    });

    const wasEmptyDocument = editor.children.length === 1;

    const fragment = editor.children[editor.selection?.anchor.path[0] ?? 0];
    if (fragment.children[0]?.text === '') {
      if (editor.children.length - 1 === editor.selection?.anchor.path[0]) {
        editor.deleteBackward('block');
      } else {
        editor.deleteForward('block');
      }
    }

    editor?.insertNode({
      type: (() => {
        if (type === 'H1') return NodeTypesEnum.H2;
        if (type === 'H6') return NodeTypesEnum.H5;
        return type
          ? (NodeTypesEnum[type as keyof typeof NodeTypesEnum] ??
              NodeTypesEnum.H2)
          : NodeTypesEnum.H2;
      })(),
      children: [{ text: value }],
    });

    if (wasEmptyDocument) {
      editor.removeNodes({ at: { path: [0], offset: 0 } });
    }

    if (focus) {
      // To supress the warning in Sentry we have to catch and ignore the error
      try {
        ReactEditor.focus(editor as ReactEditor);
      } catch (e) {}
    }
  };

  const handleInsertOutline = (outline: OutlineItem[]) => {
    for (const h2 of outline) {
      handleInsertHeading(h2.title, 'H2', false);
      for (const h3 of h2.items ?? []) {
        handleInsertHeading(h3.title, 'H3', false);
      }
    }

    ReactEditor.focus(editor as ReactEditor);
  };

  appState.pageTitle(document.title || 'Untitled Document');

  return (
    <div className="overflow-hidden bg-slate-50">
      {isLoading === 'offline' && (
        <div className="pointer-events-none fixed z-50 flex h-screen w-screen items-center justify-center">
          <div className="pointer-events-auto flex w-96 flex-col items-center gap-2 rounded-lg border border-gray-200 bg-white p-4 shadow-2xl">
            <Loader className="h-12 w-12 stroke-emerald-400" />
            <p className="text-center">
              Your document is generating. You can leave this page without
              interupting the process
            </p>
            <SimpleButton
              className="rounded-full border border-gray-200 px-4 py-1"
              onClick={() => {
                navigate({ to: '/created-content' });
              }}
            >
              Go back
            </SimpleButton>
          </div>
        </div>
      )}
      <SettingsDialog
        isOpen={showSettingsDialog}
        onClose={() => setShowSettingsDialog(false)}
        document={document}
        handleSettings={handleSettings}
        settings={settings}
      />
      <DocumentInfoContext.Provider
        value={{
          documentId: document.id,
          projectId: currentProject.id,
        }}
      >
        <EditorRefContext.Provider value={editorFocusRef}>
          <DndContext
            onDragOver={(e) => {
              if (draggableState) {
                setDraggableState((prev) => {
                  if (prev) {
                    return { ...prev, hoveredElementID: e.over?.id };
                  }
                  return prev;
                });
              }
            }}
            onDragStart={handleDragStart}
            onDragEnd={handleDrag}
            autoScroll={{ layoutShiftCompensation: false }}
            collisionDetection={pointerWithin}
          >
            <ActiveDraggableContext.Provider value={draggableState}>
              <EditorContext.Provider value={editor}>
                <DocumentActionContext.Provider
                  value={{
                    handleInsertText,
                    handleReplaceText,
                  }}
                >
                  <div className="flex h-screen w-full flex-col items-center overflow-hidden">
                    {/* Streaming completion confetti */}
                    {isDoneGenerating && (
                      <div className="z-50 flex w-full justify-center">
                        <ConfettiExplosion
                          force={0.8}
                          duration={3000}
                          particleCount={250}
                          width={1600}
                        />
                      </div>
                    )}
                    <Banner
                      isOpen={!!isGenerating || isDoneGenerating}
                      className={`top-0 bg-emerald-400 text-white ${
                        isDoneGenerating ? '' : 'animate-pulse'
                      }`}
                    >
                      {isDoneGenerating ? (
                        <div className="flex flex-shrink-0 flex-col items-center gap-2 px-2 py-4">
                          <h1 className="text-xl font-bold">
                            Your document is ready!
                          </h1>
                          <SimpleButton
                            className="rounded-md border bg-white px-2 py-1"
                            onClick={() => setIsDoneGenerating(false)}
                          >
                            Start editing
                          </SimpleButton>
                        </div>
                      ) : (
                        <>Generating...</>
                      )}
                    </Banner>
                    <div className="flex w-full flex-grow overflow-hidden ">
                      <div className="flex h-[53px] flex-grow items-center border-b border-gray-200">
                        <SimpleButton
                          onClick={() => {
                            client.setQueryData(['document-form-state'], null);
                            const tableState =
                              history.location.state.tableState ?? {};
                            navigate({
                              to: '/created-content',
                              search: (prev) => ({ ...prev, ...tableState }),
                            });
                          }}
                          className=" ml-3 block h-8 w-8 rounded-2xl bg-slate-200 p-1"
                        >
                          <ArrowLeft color="black" />
                        </SimpleButton>
                      </div>
                      <PlateEditorErrorBoundary
                        document={document.id}
                        projectId={currentProject.id}
                        fallback={<DocumentLoading errorBoundary />}
                        text={document.text}
                        refetchDocument={refetchDocument}
                      >
                        <PlateEditor
                          refetchDocument={refetchDocument}
                          user={auth.user}
                          setCurrentSlideOver={setCurrentSlideOver}
                          currentSlideOver={currentSlideOver}
                          projectId={currentProject.id}
                          selection={selection}
                          setSelection={setSelection}
                          setEditor={setEditor}
                          document={document}
                          editor={editor}
                          extraToolbarbuttons={
                            <div className="mt-1 flex">
                              <Button
                                variant="ghost"
                                color="secondary"
                                prependIcon={PenLine}
                                disabled={!!isGenerating}
                                onClick={handleDraft}
                                text="AI-DRAFT"
                              />
                              <Button
                                size="sm"
                                text="SETTINGS"
                                variant="ghost"
                                disabled={!!isGenerating}
                                appendIcon={
                                  <Settings
                                    size={20}
                                    className="text-gray-500"
                                  />
                                }
                                onClick={() => setShowSettingsDialog(true)}
                              />
                              <DocumentExports
                                document={document}
                                refetchDocument={refetchDocument}
                              />
                            </div>
                          }
                        />
                      </PlateEditorErrorBoundary>
                      <div className="flex flex-grow flex-col overflow-hidden">
                        <div className="h-[53px] w-full border-b border-gray-200" />
                      </div>
                      <div className="flex h-full flex-col overflow-hidden">
                        <div className="h-[53px] w-full flex-shrink-0 border-b border-gray-200" />
                        <div className="thick-scroll flex-grow overflow-y-auto overflow-x-hidden">
                          <ReportSection
                            auth={auth}
                            targetKeyword={document.keyword.name}
                            isLoadingReport={Boolean(
                              document.document_report?.is_generating ?? true,
                            )}
                            currentProject={currentProject}
                            document={document}
                            setCurrentSlideOver={setCurrentSlideOver}
                            isGenerating={isGenerating}
                            editor={editor}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <ChatTab
                    deselectSelection={deselectSelection}
                    selection={selection}
                    documentId={document.id}
                    onMaximize={handleMaximize}
                    isMaximized={isMaximized}
                  />
                </DocumentActionContext.Provider>
              </EditorContext.Provider>
            </ActiveDraggableContext.Provider>
          </DndContext>
          <DocumentSlideOvers
            onInsertHeading={handleInsertHeading}
            onInsertOutline={handleInsertOutline}
            editor={editor}
            document={document}
            project={currentProject}
            onClose={() => setCurrentSlideOver(undefined)}
            currentlyOpenSlideover={currentSlideOver}
          />
        </EditorRefContext.Provider>
      </DocumentInfoContext.Provider>
    </div>
  );
}
