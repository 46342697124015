import SimpleButton from '@/Components/SimpleButton';
import { Copy } from 'lucide-react';
import { useEffect, useState } from 'react';

type Props = {
  url: string;
}

export const ExternalLinkItem = ({ url }: Props) => {
  const [showCopied, setShowCopied] = useState(false);

  useEffect(() => {
    if (showCopied) {
      const timeout = setTimeout(() => setShowCopied(false), 2000);

      return () => clearTimeout(timeout);
    }
  }, [showCopied]);

  const handleCopy = () => {
    navigator.clipboard.writeText(url);
    setShowCopied(true);
  };

  return (
    <div className="mb-4 animate-fade-slow rounded-lg border border-gray-300 p-4">
      <div
        onClick={() => window.open(url)}
        className="mb-4 w-full cursor-pointer break-all  underline"
      >
        {url}
      </div>
      <div className="flex w-full justify-end">
        {/* Insert */}
        {
          <SimpleButton
            className="relative flex cursor-pointer items-center justify-end gap-1 py-1 text-sm text-gray-400"
            onClick={handleCopy}
          >
            COPY <Copy />
            <p
              className={`absolute -top-5 bg-white  text-green-500 transition-opacity ${
                showCopied ? 'opacity-100' : 'opacity-0'
              }`}
            >
              Copied!
            </p>
          </SimpleButton>
        }
      </div>
    </div>
  );
};
