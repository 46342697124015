import { ListItem, ListItemProps } from '../Listitem/ListItem';

export type VerticalMenuItem =
  | Omit<ListItemProps, 'dense'>
  | { group: string; onClick?: () => void };
export type VerticalMenuProps = {
  title?: string;
  dense?: boolean;
  items: VerticalMenuItem[];
};

export const VerticalMenu = ({ items, title, dense }: VerticalMenuProps) => {
  return (
    <div className={`flex w-full flex-col rounded bg-white`}>
      {title && (
        <p className={`mb-1 text-lg text-primary-800 ${dense ? 'p-1' : 'p-2'}`}>
          {title}
        </p>
      )}
      {items.map((item, index) => {
        if ('group' in item) {
          if (index + 1 >= items.length || 'group' in items[index + 1]) {
            return null;
          }

          return (
            <p key={index} className="p-2 text-sm font-bold text-primary-800">
              {item.group}
            </p>
          );
        }

        return (
          <ListItem
            {...item}
            key={index}
            dense={dense}
            underlined={index !== items.length - 1}
          />
        );
      })}
    </div>
  );
};
