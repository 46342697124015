import { Button } from '@/Components/v2/Button';
import { Icon } from '@/Components/v2/Icon';
import { Popover } from '@/Components/v2/Menu/Popover';
import { Check, Plus, Undo2 } from 'lucide-react';
import { PropsWithChildren, ReactNode } from 'react';

type Props = {
  newText: ReactNode;
  oldText: ReactNode;
  onAccept?: () => void;
  onReject?: () => void;
};

export const ChangePopover = ({
  newText,
  oldText,
  onAccept,
  onReject,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <Popover
      asChild
      type="hover"
      trigger={
        <span
          className={(() => {
            if (newText && oldText) return 'bg-green-100 text-green-700';
            if (!oldText && newText) return 'bg-green-100 text-green-700';
            return 'bg-red-100 text-red-700 line-through';
          })()}
        >
          {newText && !oldText && '+'}
          {children}
        </span>
      }
    >
      <div className="flex max-w-md flex-col gap-2 p-2">
        <div>
          {newText && oldText && 'Changed:'}
          {newText && !oldText && 'Added:'}
          {!newText && oldText && 'Removed:'}
        </div>
        {oldText && (
          <div className="bg-red-100 text-red-700 line-through">{oldText}</div>
        )}
        {newText && oldText && 'Replaced with:'}
        <div className="bg-green-100 text-green-700">{newText}</div>

        <div className="mt-2 flex justify-end gap-2">
          <Button
            variant="outline"
            dense
            size="xs"
            appendIcon={Check}
            text={'Accept'}
            onClick={onAccept}
          />
          <Button
            variant="outline"
            dense
            size="xs"
            appendIcon={Undo2}
            text="Reject"
            onClick={onReject}
          />
        </div>
      </div>
    </Popover>
  );
};
