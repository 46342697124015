import {
  useCreateCustomFieldDefinition,
  useUpdateCustomFieldDefinition,
} from '@/api/openapiComponents';
import { CustomFieldDefinitionResource } from '@/api/openapiSchemas';
import { Dialog } from '@/Components/Dialog';
import { ErrorAlert } from '@/Components/v2/Alert';
import { Button } from '@/Components/v2/Button';
import { Input } from '@/Components/v2/Input/Input';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { TextArea } from '@/Components/v2/TextArea/TextArea';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { ErrorHelper } from '@/Services/ErrorHandling';
import { useState } from 'react';

type Props = {
  onSuccess: () => void;
  onClose: () => void;
  isOpen: boolean;
};

export const AddCustomFieldDialog = ({ isOpen, onClose, onSuccess }: Props) => {
  const appState = useAppStore();

  const [nameInput, setNameInput] = useState('');
  const [descriptionInput, setDescriptionInput] = useState('');

  const createCustomFieldMutation = useCreateCustomFieldDefinition();

  const handleSubmit = () => {
    createCustomFieldMutation.mutate(
      {
        pathParams: {
          project: appState.currentProject!.id,
        },
        body: {
          name: nameInput,
          description: descriptionInput,
        },
      },
      {
        onSuccess: () => {
          onSuccess();
          onClose();
        },
      },
    );
  };

  const errorHelper = new ErrorHelper(createCustomFieldMutation.error);

  return (
    <Dialog title="Add new custom field" isOpen={isOpen} handleClose={onClose}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
        className="flex flex-col gap-6"
      >
        {errorHelper.message() && <ErrorAlert title={errorHelper.message()} />}
        <InputDecoration label="Name" required>
          <Input
            value={nameInput}
            onChange={setNameInput}
            error={errorHelper.has('name')}
          />
        </InputDecoration>
        <InputDecoration label="Description">
          <TextArea
            resize
            counter
            counterMax={1000}
            value={descriptionInput}
            onChange={setDescriptionInput}
            error={errorHelper.has('description')}
          />
        </InputDecoration>
        <div className="flex w-full justify-end">
          <Button
            type="submit"
            isLoading={createCustomFieldMutation.isPending}
            text="Save"
            color="secondary"
          />
        </div>
      </form>
    </Dialog>
  );
};
