import { SerpOverviewLines } from '@/Pages/RankTracking/components/SerpOverviewLines';
import { SimilarityLines } from '../SimilarityDialog';

type Props = {
  currentLines: SimilarityLines[];
  compareLines: SimilarityLines[];
  keyword: string;
};

export const SimilarityDialogSection = ({
  currentLines,
  compareLines,
  keyword,
}: Props) => (
  <div className="w-[calc(50%-6rem)] flex-1">
    <h1 className="truncate px-6 text-xl font-bold">{keyword}</h1>
    <div className="flex truncate border-b py-4 text-primary-700">
      <div className="w-12 text-end">#</div>
      <div className="pl-4 text-start">SEARCH RESULTS</div>
    </div>
    {currentLines.map((currentLine, i) => (
      <SerpOverviewLines
        green={compareLines.some(
          (compareLine) => compareLine.url === currentLine.url,
        )}
        index={i}
        serp={{
          favicon: currentLine.image,
          title: currentLine.title,
          url: currentLine.url,
        }}
      />
    ))}
  </div>
);
