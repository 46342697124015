import { rootRoute } from '@/Pages/Router/Router';
import { createRoute } from '@tanstack/react-router';
import AIKeywords from './AiKeywords';
import MatchingTerms from './MatchingTerms';
import AudienceExplorer from './AudienceKeywords';
import {
  guards,
  hasActiveSubscription,
  hasProject,
  isAuthenticated,
} from '../Router/guards';
import SearchVolume from './SearchVolume';

export const routes = [
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasActiveSubscription, hasProject]);
    },
    path: '/keyword-research/ai-keywords',
    component: () => <AIKeywords />,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasActiveSubscription, hasProject]);
    },
    path: '/keyword-research/matching-terms',
    component: () => <MatchingTerms />,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasActiveSubscription, hasProject]);
    },
    path: '/keyword-research/audience-keywords',
    component: () => <AudienceExplorer />,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasActiveSubscription, hasProject]);
    },
    path: '/keyword-research/search-volume',
    component: () => <SearchVolume />,
  }),
];
